import { Center } from "@chakra-ui/react";

type Props = {
  activeBg?: string;
  activeBorderColor?: string;
  focusBoxShadow?: string;
  hoverBg?: string;
  hoverOpacity?: string;
  isDisabled?: boolean;
  transform?: string;
} & React.ComponentProps<typeof Center>;

export const DefaultButton = ({
  activeBg = "activeButton",
  activeBorderColor = "borderColor",
  bg = "buttons",
  focusBoxShadow = "0px 8px 12px rgba(0, 0, 0, 0.12)",
  borderColor = "#ccd0d5",
  boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.08)",
  children = null,
  fontFamily = "Grotesk",
  h = "52px",
  hoverBg = "",
  hoverOpacity = "0.8",
  isDisabled = false,
  rounded = "button",
  transform = "scale(0.98)",
  w = "168px",
  ...props
}: Props) => (
  <Center
    as="button"
    h={h}
    w={w}
    boxShadow={boxShadow}
    borderRadius={rounded}
    transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
    border="1px"
    px="8px"
    fontSize="14px"
    fontWeight="semibold"
    fontFamily={fontFamily}
    bg={isDisabled ? "#F5F5F5" : bg}
    disabled={isDisabled}
    cursor={isDisabled ? "not-allowed" : "pointer"}
    borderColor={borderColor}
    color={isDisabled ? "#9E9E9E" : "white"}
    outline="0"
    _hover={{
      bg: { hoverBg },
      opacity: hoverOpacity,
      transform: { transform },
    }}
    _active={{
      bg: { activeBg },
      transform: { transform },
      borderColor: { activeBorderColor },
      outlineColor: "0",
    }}
    _focus={{
      boxShadow: { focusBoxShadow },
    }}
    {...props}
  >
    {children}
  </Center>
);
