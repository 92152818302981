import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  fontSize?: string | string[];
  fontFamily?: string;
  fontWeight?: string;
  color?: string;
};

export function Title({
  children,
  fontSize = "2xl",
  fontFamily = "Roslindale",
  fontWeight = "700",
  color = "black",
  ...props
}: Props & TextProps) {
  return (
    <Text
      fontSize={fontSize}
      color={color}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      {...props}
    >
      {children}
    </Text>
  );
}
