import { VariantProps } from "@stitches/react";
import { backgroundColors, styled } from "src/styles/stitches/theme";

const MarkWrapper = styled("div", {
  variants: {
    size: {
      lg: { width: "4.25rem" },
      md: { width: "2.75rem" },
      sm: { width: "2rem" },
      xs: { width: "1.375rem" },
    },
  },
});

const LogoSVG = styled("svg", {
  width: "100%",
});

type FillColors = keyof typeof backgroundColors;
type MarkProps = {
  color: Extract<
    FillColors,
    "fillBaseAccent" | "fillPrimaryDefault" | "fillSecondary" | "fillAccent"
  >;
  size: Required<VariantProps<typeof MarkWrapper>["size"]>;
};

export function Mark({ color, size }: MarkProps) {
  const fill = color === "fillAccent" ? "url(#b)" : backgroundColors[color].value;

  return (
    <MarkWrapper size={size}>
      <LogoSVG fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 64">
        <svg width="68" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M50.0032 55.0709C49.9931 53.4901 50.4538 51.942 51.3269 50.6229C52.2 49.3038 53.4461 48.2731 54.9072 47.6615C56.3683 47.05 57.9785 46.8852 59.5336 47.188C61.0887 47.4908 62.5187 48.2475 63.6421 49.3623C64.7654 50.4771 65.5317 51.8996 65.8435 53.4494C66.1554 54.9993 65.9989 56.6066 65.3938 58.0676C64.7887 59.5285 63.7623 60.7773 62.4448 61.6554C61.1273 62.5336 59.5781 63.0016 57.9937 63C55.8844 62.9917 53.8633 62.1549 52.3679 60.6709C50.8724 59.187 50.0229 57.1751 50.0032 55.0709Z"
            fill={fill}
          />
          <path
            d="M32.9221 41.8698V2.03711H44.4441V62.1363H33.2382V57.1292H33.0936C29.4092 62.532 23.4498 62.8929 20.8158 62.8929C9.3661 62.8929 2 53.0202 2 42.3911C2 32.4088 9.60994 21.9615 21.0168 21.9615C22.77 21.9653 24.5117 22.245 26.1776 22.7902L26.3143 22.8356C26.7027 22.961 27.0751 23.1315 27.4236 23.3436C27.4536 23.3635 27.4833 23.383 27.513 23.4024C27.5867 23.4508 27.6599 23.4987 27.7344 23.5521C28.5793 24.1557 29.2091 25.0124 29.5325 25.9976C29.8559 26.9829 29.8559 28.0453 29.5326 29.0306C29.2093 30.0159 28.5795 30.8726 27.7347 31.4763C26.89 32.08 25.8742 32.3991 24.8352 32.3875L23.4954 32.3741C18.0773 32.3741 13.7097 36.4055 13.7097 42.3884C13.7097 48.3713 18.0773 52.4428 23.4579 52.4428C29.3958 52.4428 33.115 47.9035 32.9221 41.8698Z"
            fill={fill}
          />
          <defs>
            <radialGradient
              id="a"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(-72.77012 -67.91863 117.11842 -125.48431 69.927 66.166)"
            >
              <stop stopColor="#4E68F6" />
              <stop offset="1" stopColor="#0B2AD6" />
            </radialGradient>
            <radialGradient
              id="b"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(-72.77012 -67.91863 117.11842 -125.48431 69.927 66.166)"
            >
              <stop stopColor="#4E68F6" />
              <stop offset="1" stopColor="#0B2AD6" />
            </radialGradient>
          </defs>
        </svg>
      </LogoSVG>
    </MarkWrapper>
  );
}
