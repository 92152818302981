import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SANDBOX } from "src/config";

Sentry.init({
  dsn: "https://45d4309858be4cf2ab895020dc105f80@o946967.ingest.sentry.io/5896122",
  release: "web-reactjs@" + import.meta.env.REACT_APP_SHA,
  integrations: [new Integrations.BrowserTracing()],
  enabled: import.meta.env.MODE === "production",
  environment: SANDBOX ? "dev" : "production",
  denyUrls: [
    // Ignore errors raised by intercom (this is a third party integration)
    "https://js.intercomcdn.com/",
  ],

  // Set tracesSampleRate to 1.0 to capture 100% in production
  tracesSampleRate: SANDBOX ? 0 : 1,
});
