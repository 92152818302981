import { CSS, VariantProps } from "@stitches/react";
import { styled, iconColors, theme } from "src/styles/stitches/theme";
import { arrowsIcons } from "./icons/_arrows";
import { filledIcons } from "./icons/_filled";
import { outlinedIcons } from "./icons/_outlined";
import { otherIcons } from "./icons/_other";
const gradientColors = [
  "iconWarning",
  "iconDecorativeLightBlue",
  "iconDecorativePurple",
  "iconDecorativeGreen",
  "iconAccent",
];

// Icons can have basic colors or gradients. Gradients are not done in CSS but with SVG tags 🤯
// You can access the gradients in the index.html file and use them with links like this:
// fill="url(#gradient-1)"
// This function will return the correct color or gradient
function getColor(color: IconProps["color"]): string {
  let fill: string | { [key: string]: string } = "currentColor";

  if (typeof color === "string") {
    fill = gradientColors.includes(color)
      ? `url(#${color})`
      : color === "currentColor"
      ? "currentColor"
      : theme.colors[color].value;
  }

  const getColorOrUrlToTheGradient = (color?: string) => {
    if (color) {
      return gradientColors.includes(color) ? `url(#${color})` : color;
    }

    return "currentColor";
  };

  if (typeof color === "object") {
    Object.entries(color).forEach(([key, value]) => {
      fill =
        typeof fill === "object"
          ? { ...fill, [key]: getColorOrUrlToTheGradient(value) }
          : { [key]: getColorOrUrlToTheGradient(value) };
    });
  }

  return fill;
}

const allIcons = {
  ...arrowsIcons,
  ...filledIcons,
  ...outlinedIcons,
  ...otherIcons,
};

export type ArrowsNames = keyof typeof arrowsIcons;
export type FilledNames = keyof typeof filledIcons;
export type OutlinedNames = keyof typeof outlinedIcons;
export type OtherNames = keyof typeof otherIcons;

// This code is a bit of a hack to get the typescript compiler to accept the type of the `variant` prop
type ColorVariant = Record<keyof typeof iconColors, CSS>;
const colorsVariant = Object.entries(iconColors).reduce<ColorVariant>(
  (previousValue, [key, value]) => {
    return {
      ...previousValue,
      [key]: {
        color: value,
      },
    };
  },
  {} as any as ColorVariant
);

const StyledIcon = styled("svg", {
  flexShrink: 0,

  variants: {
    color: {
      ...colorsVariant,
      currentColor: {
        color: "currentColor",
      },
    },
    size: {
      8: {
        width: "0.5rem",
        height: "0.5rem",
      },
      12: {
        width: "0.75rem",
        height: "0.75rem",
      },
      16: {
        width: "1rem",
        height: "1rem",
      },
      18: {
        width: "1.125rem",
        height: "1.125rem",
      },
      20: {
        width: "1.25rem",
        height: "1.25rem",
      },
      24: {
        width: "1.5rem",
        height: "1.5rem",
      },
    },
  },
  defaultVariants: {
    size: "24",
  },
});

export type IconProps = {
  name: ArrowsNames | FilledNames | OutlinedNames | OtherNames;
  color?: VariantProps<typeof StyledIcon>["color"];
  size?: VariantProps<typeof StyledIcon>["size"];
};

export function Icon({ name, color, ...rest }: IconProps) {
  return (
    <StyledIcon {...rest} viewBox="0 0 24 24" fill={getColor(color)}>
      {allIcons[name]}
    </StyledIcon>
  );
}

Icon.displayName = "Icon";
