import { baseColors, keyframes, rgba, styled, theme } from "src/styles/stitches/theme";

const Wrapper = styled("div", {
  width: "1.5rem",
  height: "1.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const rotate = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const getRGBAColorWithOpacity = (opacity: number) => {
  return rgba(baseColors.blue500, opacity);
};

const StyledSpinner = styled("div", {
  animation: `${rotate} 1.4s linear infinite`,
  background: "transparent",
  borderRadius: "50%",
  width: "1.375rem",
  height: "1.375rem",
  position: "relative",

  "&::before": {
    content: "",
    position: "absolute",
    borderRadius: "50%",
    background:
      `linear-gradient(0deg, ${getRGBAColorWithOpacity(1)}  50%, ${getRGBAColorWithOpacity(
        0.9
      )} 100%) 0% 0%,` +
      `linear-gradient(90deg, ${getRGBAColorWithOpacity(0.9)} 0%, ${getRGBAColorWithOpacity(
        0.6
      )} 100%) 100%   0%,` +
      `linear-gradient(180deg, ${getRGBAColorWithOpacity(0.6)} 0%, ${getRGBAColorWithOpacity(
        0.3
      )} 100%) 100% 100%,` +
      `linear-gradient(360deg, ${getRGBAColorWithOpacity(0.3)} 0%, ${getRGBAColorWithOpacity(
        0
      )} 100%) 0% 100%`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50% 50%",
    top: -1,
    bottom: -1,
    left: -1,
    right: -1,
  },

  "&::after": {
    content: "",
    position: "absolute",
    background: theme.colors.fillSecondary,
    borderRadius: "50%",
    inset: "10%",
  },
});

export function Spinner() {
  return (
    <Wrapper>
      <StyledSpinner />
    </Wrapper>
  );
}
