import { Flex } from "@chakra-ui/react";

import { Title } from "src/components/texts/Title";

type AccountPriceCardProps = {
  price?: number;
  formulaName: string;
};

export const AccountPriceCard = ({ price = 0, formulaName }: AccountPriceCardProps) => {
  return (
    <Flex
      flex={1}
      bg="#144283"
      maxH={"130px"}
      maxW={["100%", "100%", "100%"]}
      minW={["100%", "100%", "260px"]}
      rounded="8px"
      p="32px"
      direction="column"
    >
      <Flex w="100%">
        <Flex flex={1} direction="column">
          <Flex>
            <Title color="white" fontSize="24px">
              Formule
            </Title>
          </Flex>

          <Flex>
            <Title color="#ffb3ef" fontSize="24px">
              {formulaName}
            </Title>
          </Flex>
        </Flex>

        <Flex justify="center" align="flex-end" direction="column">
          <Flex>
            <Flex align="flex-end" lineHeight="30px">
              <Title color="white" fontSize="32px">
                {Math.trunc(price)}
              </Title>
            </Flex>
            <Flex align="flex-end">
              <Title color="white" fontSize="14px">
                {`,${(price - Math.trunc(price)).toFixed(2).split(".")[1]}€`}
              </Title>
            </Flex>
          </Flex>

          <Flex w="100%">
            <Title color="#899fc1" fontSize="12px">
              par mois
            </Title>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
