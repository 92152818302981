import { API, Auth } from "aws-amplify";

type Card = {
  last4: string;
  brand: string;
};

export type Sepa = {
  last4: string;
  brand: string;
};
export type SelectedPaymentMethod = {
  default: Card | Sepa;
  refund: Sepa | null;
};

export type PaymentResponse = {
  success: boolean;
  selected_payment: SelectedPaymentMethod;
  sepa: Sepa[];
  card: Card[];
};

export async function fetchPaymentMethods(): Promise<PaymentResponse> {
  const path = "/payment-method";
  const apiName = "apiMySpaceGateway";

  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: { Authorization: token },
      response: false,
    };

    const response = await API.get(apiName, path, myInit);
    return response;
  } catch (error) {
    // TODO error handling
    //@ts-expect-error message may not be property of error
    const message = error && typeof error === "object" ? error.message : undefined;
    const formattedError = new Error(message);
    throw formattedError;
  }
}
