import { forwardRef } from "react";
import { Icon } from "src/components/01_atoms/icon";
import { InlineHint } from "src/components/01_atoms/inline-hint";
import { Input } from "src/components/01_atoms/input";
import { Outlet } from "src/components/01_atoms/outlet";

type PriceInputProps = {
  error?: boolean;
};

export const PriceInput = forwardRef<HTMLInputElement, PriceInputProps>(
  ({ error = false, ...rest }, ref) => {
    return (
      <Outlet vertical="spacing8px" justify="center" fluid>
        <Input
          rightIcon="outlinedEuros"
          placeholder="Montant en euros"
          critical={error}
          ref={ref}
          {...rest}
        />
        {error ? (
          <InlineHint color="textCritical" leftIcon={<Icon name="filledClose" />}>
            Le montant doit être exclusivement numérique
          </InlineHint>
        ) : null}
      </Outlet>
    );
  }
);

PriceInput.displayName = "PriceInput";
