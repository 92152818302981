//FIP = FIC
export const legal: { [index: string]: any } = {
  v1: {
    ipid: "https://editique.s3.eu-west-3.amazonaws.com/public/IPID+Sante%CC%81+Animale+sans+RC_VF+27-04-2021.pdf",
    fic: "https://editique.s3.eu-west-3.amazonaws.com/public/FIP+Sante%CC%81+Animale+sans+RC_VF+27-04-2021.pdf",
    ni: "https://editique.s3.eu-west-3.amazonaws.com/public/NI+Dalma+sans+RC_VF+27-04-2021.pdf",
    merged: "https://editique.s3.eu-west-3.amazonaws.com/public/merged_documents_contractuels.pdf",
    ipid_ni:
      "https://editique.s3.eu-west-3.amazonaws.com/public/IPID+Sante%CC%81+Animale+sans+RC_VF+%2B+NI+27-04-2021.pdf",
  },
  v2: {
    ipid: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/IPID_Sante_Animale_sans_RC.pdf",
    fic: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/FIP_Sante%CC%81_Animale_sans_RC.pdf",
    ni: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/NI_Dalma_sans_RC.pdf",
    merged: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/FIP_NI.pdf",
  },
  v3: {
    ipid: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v3/IPID_Sante_Animale_sans_RC.pdf",
    fic: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v3/FIP_Sante%CC%81_Animale_sans_RC.pdf",
    ni: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v3/NI_Dalma_sans_RC.pdf",
    merged: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v3/FIP_NI.pdf",
  },
  v4: {
    privacy: "https://editique.dalma.co/formule_dalma/Dalma-Politique_de_confidentialite_v2.pdf",
    cgu: "https://editique.dalma.co/formule_dalma/CGU_Dalma_v2.pdf",
    ipid: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v4/IPID_Sante_Animale_sans_RC.pdf",
    fic: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v4/FIP_Sante%CC%81_Animale_sans_RC.pdf",
    ni: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v4/FIP_NI.pdf",
    merged: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v4/FIP_NI.pdf",
  },
  v5: {
    ipid: "https://editique.dalma.co/formule_dalma/v4/IPID_Sante_Animale_sans_RC.pdf",
    ni: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v5/NI_Dalma_sans_RC.pdf", //online
    fic: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma/v5/NI_Dalma_sans_RC.pdf",
  },
  v6: {
    ipid: "https://editique.dalma.co/formule_dalma/v6/IPID_Sante_Animale_sans_RC.pdf",
    ni: "https://editique.dalma.co/formule_dalma/v6/NI_Dalma_sans_RC.pdf",
    fic: "https://editique.dalma.co/formule_dalma/v6/NI_Dalma_sans_RC.pdf",
  },
  v7: {
    ipid: "https://editique.dalma.co/formule_dalma/v7/ipid_sante_animale_sans_rc_maj_20-01-2023.pdf",
    fic: "https://editique.dalma.co/formule_dalma/v7/NI_Dalma_sans_RC.pdf",
  },
  v8: {
    ipid: "https://editique.dalma.co/formule_dalma/v8/IPID_Dalma_fr.pdf",
    fic: "https://editique.dalma.co/formule_dalma/v8/FIC_NI_Dalma_fr.pdf",
  },
  v9: {
    ipid: "https://editique.dalma.co/formule_dalma/v9/IPID_Dalma_fr.pdf",
    fic: "https://editique.dalma.co/formule_dalma/v9/FIC_NI_Dalma_fr.pdf",
  },
};

export const GUARANTEES_TABLE =
  "https://editique.dalma.co/formule_dalma/latest/Tableau_de_garanties_Dalma_fr.pdf";

export const FDS = "https://editique.dalma.co/formule_dalma/latest/Feuille_de_soin_Dalma_fr.pdf";
export const LEGAL_NOTICES =
  "https://editique.dalma.co/formule_dalma/latest/Dalma+-+Mentions+legales.pdf";
export const PRIVACY_POLICY =
  "https://editique.dalma.co/formule_dalma/latest/Dalma-Politique_de_confidentialite.pdf";
