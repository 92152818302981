import { legal } from "src/configs/Legal";
import { Contract } from "src/types";
import { SANDBOX } from "../config";

// TODO
type TrackProps = any;

export function track({ event = "amplitude_click", eventType, eventProperties }: TrackProps) {
  // @ts-ignore
  window.dataLayer.push({
    event,
    eventType,
    eventProperties: {
      ...eventProperties,
      livemode: !SANDBOX,
    },
  });
}

export function pWait(timeout: number) {
  return new Promise((res) => setTimeout(res, timeout));
}

export function openInNewTab(url: string) {
  window.open(url, "_blank", "noopener,noreferrer");
}

export const getUniqueValues = <T,>(array?: T[]) => [...new Set(array)];

// Legal versioning
function compareLegalVersions(
  firstItemVersion: string | null,
  secondItemVersion: string | null
): string | null {
  if (!secondItemVersion || !firstItemVersion) {
    return null;
  }
  return secondItemVersion > firstItemVersion ? secondItemVersion : firstItemVersion;
}

function getMostRecentLegalVersion(contracts: Contract[] | null | undefined) {
  return (
    contracts?.map((contract) => contract.legal_version).reduce(compareLegalVersions, "v1") ||
    FALLBACK_VERSION
  );
}

const FALLBACK_VERSION = "v4";

export function getLegalVersion(contracts: Contract[] | null | undefined) {
  const version = getMostRecentLegalVersion(contracts);

  return {
    ficLink: legal[version].fic, // FIP means FIC in urls
    ipidLink: legal[version].ipid,
    //@ts-ignore
    // hack to tranform a string in number
    niName: version.replace("v", "") * 1 >= 5 ? "FIC + NI" : "Fiche info conseil",
    FALLBACK_VERSION,
  };
}
