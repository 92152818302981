import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchPaymentMethods, PaymentResponse } from "src/backApi/fetchPaymentMethods";
import { fetchProfile } from "src/backApi/fetchProfile";
import { Contact } from "src/types";

export const useProfile = () => {
  const [profile, setProfile] = useState<Contact | undefined>(undefined);
  const [paymentMethod, setPaymentMethod] = useState<PaymentResponse | undefined>(undefined);

  const history = useHistory();
  const initializePaymentMethods = async () => {
    const paymentMethods = await fetchPaymentMethods();
    setPaymentMethod(paymentMethods);
  };

  useEffect(() => {
    const onLoad = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        fetchProfile(setProfile);
        initializePaymentMethods();
      } catch (error) {
        // TODO sentry
      }
    };
    onLoad();
  }, [history, setPaymentMethod, setProfile]);

  return { profile, paymentMethod, initializePaymentMethods };
};
