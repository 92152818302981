export const getFormattedDateWithoutYear = (date: string) => {
  try {
    const formatter = new Intl.DateTimeFormat("fr-FR", {
      month: "long",
      day: "numeric",
    });
    return formatter.format(new Date(date));
  } catch {
    return date;
  }
};

export const getFormattedDateWithYear = (date: string) => {
  try {
    const formatter = new Intl.DateTimeFormat("fr-FR", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formatter.format(new Date(date));
  } catch {
    return date;
  }
};

export const getYear = (date: string) => {
  return new Date(date).getFullYear();
};

export const getFormattedPrice = (price: number) => {
  return price.toFixed(2).replace(/\.0+$/, "").replace(".", ",") + " €";
};
