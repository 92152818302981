import { Menu, MenuButton, MenuList, MenuItem, Flex } from "@chakra-ui/react";
import { Title } from "../texts/Title";
import ArrowDown from "@iconscout/react-unicons/icons/uil-angle-down";
import { Dispatch, SetStateAction } from "react";

type SelectPetProps = {
  pets: { petName: string }[];
  selectedPet?: number;
  setSelectedPet: Dispatch<SetStateAction<number>>;
};

export function SelectPet({ pets = [], selectedPet = 0, setSelectedPet }: SelectPetProps) {
  return (
    <Menu>
      <MenuButton>
        <Flex rounded="8px" p="12px" bg="#DBEBFF" align="center">
          <Flex>
            <Title ml="8px" w="100%" color="#4E68F6" fontSize={["20px"]}>
              {pets[selectedPet].petName}
            </Title>
          </Flex>

          <Flex pl="1" color="#4E68F6">
            <ArrowDown color="#4E68F6" />
          </Flex>
        </Flex>
      </MenuButton>
      <MenuList>
        {pets.map((item, idx) => (
          <MenuItem minH="48px" onClick={() => setSelectedPet(idx)} key={idx}>
            <span>{item.petName}</span>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
