import { differenceInBusinessDays } from "date-fns";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { Avatar } from "src/components/02_molecules/avatar";
import { InlineAlert } from "src/components/02_molecules/inline-alert";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";
import { getFormattedDateWithYear } from "src/utils/format";
import { InAnalysisCardSection } from "./in-analysis-card-section";
import { NeedInfoCardSection } from "./need-info-card-section";
import { RefusedCardSection } from "./refused-card-section";
import { ReimbursedCardSection } from "./reimbursed-card-section";
import { ToBeCompletedCardSection } from "./to-be-completed-card-section";

const Container = styled("div", {
  borderRadius: theme.radii[24],
  width: "100%",
  boxShadow: theme.elevation.elevation1Purple,

  "@bp3": {
    border: `2px solid ${theme.colors.borderSecondary}`,
  },
});

const CardHeader = styled(Outlet, {
  padding: theme.spacing.spacing24px,
  background: "#F9FAFD", // Theme not used here : hack to see the opacity on white background.
  borderBottom: `2px solid ${theme.colors.borderSecondary}`,
  borderRadius: `${theme.radii[24]} ${theme.radii[24]} 0 0`,
});

const StyledAvatar = styled(Avatar, {
  padding: "0.25rem",
});

const Img = styled("img", {
  width: "2rem",
  height: "2rem",
});

export function ClaimStatusCard() {
  const { petType, selectedClaim, refundAmount } = useClaimStatusSidebar();

  if (!selectedClaim) return null;

  const { pet_name, date, claim_id, status } = selectedClaim;

  //SLA: Service-level agreement
  const isSLAover48h = differenceInBusinessDays(new Date(), new Date(date)) > 2;
  const cardSectionStatus = {
    NEED_INFO: <NeedInfoCardSection />,
    TO_BE_COMPLETED: (
      <ToBeCompletedCardSection
        title={"Traitement par votre vétérinaire"}
        showTooltip={!isSLAover48h}
      />
    ),
    IN_ANALYSIS: (
      <InAnalysisCardSection
        title={!isSLAover48h ? "Traitement sous 2 jours ouvrés" : "Traitement en cours"}
        showTooltip={!isSLAover48h}
      />
    ),
    CLOSE: refundAmount === 0 ? <RefusedCardSection /> : <ReimbursedCardSection />,
  };
  return (
    <>
      {status === "IN_ANALYSIS" && isSLAover48h ? (
        <InlineAlert
          background="fillBlueAccent"
          iconColor="iconSecondaryDefault"
          color={"textSecondaryDefault"}
          iconName="filledInfo"
          text={
            <>
              Nous avons bien commencé à traiter votre demande. Pas d&apos;inquiétude, nous
              reviendrons vers vous dès que celle-ci sera finalisée.
              <br />
              Notre service client est à votre disposition en cas de questions.
            </>
          }
        />
      ) : null}
      {status === "CLOSE" && refundAmount > 0 ? (
        <InlineAlert
          background="fillBlueAccent"
          iconColor="iconSecondaryDefault"
          color={"textSecondaryDefault"}
          iconName="filledInfo"
          text={
            <>
              Vous n’avez pas encore reçu le virement ? <br />
              Pas d’inquiétude, le virement apparait généralement sous 2 à 3 jours en fonction de
              votre banque après confirmation du remboursement de votre demande.
            </>
          }
        />
      ) : null}
      <Container>
        <CardHeader vertical="spacing16px">
          <Outlet horizontal="spacing16px" align="center">
            <StyledAvatar size="lg" withHover={false}>
              {petType === "cat" ? (
                <Img src="/assets/media/cat-02.svg" alt="Avatar d'un chat" />
              ) : (
                <Img src="/assets/media/photomaton-dog.svg" alt="Avatar d'un chien" />
              )}
            </StyledAvatar>
            <Outlet vertical="spacing2px">
              <Text variant="paragraph1-Bold" color="textBaseDefault">
                {pet_name}
              </Text>
              <Text variant="paragraph3-Medium" color="textBaseSubdued">
                {getFormattedDateWithYear(date)}
              </Text>
            </Outlet>
          </Outlet>
          <Text variant="paragraph3-Bold" color="textBaseSubdued">
            Remboursement n°{claim_id}
          </Text>
        </CardHeader>
        {cardSectionStatus[status]}
      </Container>
    </>
  );
}
