import { ChangeEvent } from "react";
import { UseFieldArrayReturn } from "react-hook-form";
import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Spinner } from "src/components/01_atoms/spinner";
import { Text } from "src/components/01_atoms/text";
import { VisuallyHidden } from "src/components/01_atoms/visually-hidden";
import { baseColors, rgba, styled, theme } from "src/styles/stitches/theme";
import { Documents } from "./index";

const Container = styled("div", {
  position: "relative",
  borderRadius: theme.radii[24],
  boxShadow: theme.elevation.elevation1Purple,
  border: `2px solid ${theme.colors.borderSecondary}`,
  backgroundColor: theme.colors.fillSecondarySubdued,
  transition: "all .15s ease-out",
  width: "100%",
  overflow: "hidden",

  "@withHover": {
    "&:hover": {
      borderColor: theme.colors.borderBaseDisabled,
      backgroundColor: theme.colors.fillSecondary,
    },
  },
});

const Label = styled("label", {
  padding: theme.spacing["spacing24px"],
  display: "block",
  // We fake a filter drop shadow with rbga background
  backgroundColor: rgba(baseColors.neutral100, 0.4),

  variants: {
    hasSibling: {
      true: {
        borderBottom: `2px solid ${theme.colors.borderSecondary}`,
      },
    },
    clickable: {
      true: { cursor: "pointer" },
      false: { cursor: "default" },
    },
  },

  defaultVariants: {
    clickable: true,
  },
});

const VisuallyHiddenInput = styled("input", VisuallyHidden);

const IconWrapper = styled("div", {
  padding: theme.spacing.spacing8px,
});

const FileButton = styled("div", {
  // We fake a filter drop shadow with rbga background
  backgroundColor: rgba(baseColors.neutral100, 0.4),

  padding: theme.spacing.spacing24px,
  width: "100%",

  variants: {
    hasSibling: {
      true: {
        borderBottom: `2px solid ${theme.colors.borderSecondary}`,
      },
    },
  },
});

const ReservedSpace = styled("div", {
  width: "2.5rem",
  height: "2.5rem",
});

const Step = styled("div", {
  marginLeft: theme.spacing.spacing24px,
});

type ImportDocumentProps<T extends "invoice" | "careRecord" | "prescription"> = {
  step?: number | string;
  loading?: boolean;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name: T;
  title: string;
  description: string;
  methods: T extends "invoice"
    ? UseFieldArrayReturn<Documents, "invoice", "id">
    : T extends "careRecord"
    ? UseFieldArrayReturn<Documents, "careRecord", "id">
    : T extends "prescription"
    ? UseFieldArrayReturn<Documents, "prescription", "id">
    : never;
};

export function ImportDocument<T extends "invoice" | "careRecord" | "prescription">({
  step,
  onFileChange,
  name,
  title,
  description,
  methods,
  loading,
}: ImportDocumentProps<T>) {
  const hasFiles = methods.fields.length > 0;
  return (
    <Outlet vertical="spacing16px" fluid>
      {step ? (
        <Step>
          <Text variant="caption1-Medium" color="textBaseSubdued">
            Étape {step}
          </Text>
        </Step>
      ) : null}
      <Container>
        <div>
          <VisuallyHiddenInput
            accept=".jpg, .jpeg, .png, .heic, .pdf"
            type="file"
            id={`input-${name}`}
            name={name}
            onChange={onFileChange}
          />
          <Label
            htmlFor={`input-${name}`}
            hasSibling={methods.fields.length > 0}
            as={hasFiles ? "div" : "label"}
            clickable={!hasFiles}
          >
            <Outlet
              horizontal="spacing16px"
              align="center"
              justify={hasFiles ? "start" : "spaceBetween"}
              fluid
            >
              <IconWrapper>
                {loading ? <Spinner /> : <Icon name="filledFile" color="iconBaseSubdued" />}
              </IconWrapper>
              <Outlet vertical="spacing2px">
                <Text variant="paragraph2-Bold">{title}</Text>
                <Text variant="paragraph3-Medium" color="textBaseSubdued">
                  {description}
                </Text>
              </Outlet>
              {hasFiles ? null : <Icon name="outlinedPlus" color="iconAccent" />}
            </Outlet>
          </Label>
        </div>
        {methods.fields.map((field, index) => (
          <FileButton key={field.id} hasSibling={true}>
            <Outlet horizontal="spacing16px" justify="spaceBetween" align="center" fluid>
              <IconWrapper>
                {loading ? <Spinner /> : <Icon name="filledCheckCircle" color="iconAccent" />}
              </IconWrapper>
              <Outlet vertical="spacing2px" fluid>
                <Text variant="paragraph2-Bold" css={{ overflowWrap: "anywhere" }}>
                  {field.value.name}
                </Text>
                <Text variant="paragraph3-Medium" color="textBaseSubdued">
                  {field.value.size / 1000} KB
                </Text>
              </Outlet>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  methods.remove(index);
                }}
                type="button"
                title="Supprimer le fichier"
                aria-label="Supprimer le fichier"
              >
                <Icon name="outlinedClose" color="iconBaseSubdued" />
              </button>
            </Outlet>
          </FileButton>
        ))}
        {hasFiles ? (
          <Label htmlFor={`input-${name}`}>
            <Outlet horizontal="spacing16px" justify="spaceBetween" align="center">
              <ReservedSpace />
              <Text variant="paragraph2-Bold" color="textBaseDefault">
                Ajouter un autre document
              </Text>
              <Icon name="outlinedPlus" color="iconAccent" />
            </Outlet>
          </Label>
        ) : null}
      </Container>
    </Outlet>
  );
}
