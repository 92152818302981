import { CSS, VariantProps } from "@stitches/react";
import { AnchorHTMLAttributes, ComponentProps, forwardRef } from "react";
import { styled, theme } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";
import { Icon } from "../icon";
import { Outlet } from "../outlet";
import { display5CSS, paragraph1CSS, paragraph2CSS } from "../text";
import { Link as RouterLink } from "react-router-dom";

export const StyledLink = styled("a", {
  display: "inline-block",
  transition: "color 0.15s ease-in",

  "&span": {
    fontSize: "1em",
  },

  "&:focus, &:focus-within": {
    borderRadius: theme.radii[1],
    boxShadow: theme.boxShadow.focus,
    outline: "none",
  },

  "&:disabled": {
    color: theme.colors.buttonLabelBaseDisabled,
  },

  variants: {
    size: {
      sm: {
        ...paragraph2CSS,
        fontWeight: theme.fontWeights.medium,
      },
      md: {
        ...paragraph1CSS,
        fontWeight: theme.fontWeights.medium,
      },
      lg: {
        ...display5CSS,
        fontWeight: theme.fontWeights.medium,
      },
    },
    color: {
      accent: {
        color: theme.colors.buttonLabelOnSecondaryDefault,

        "&:hover": {
          color: theme.colors.buttonLabelOnSecondaryHovered,
        },
      },
      base: {
        color: theme.colors.buttonLabelBaseDefault,

        "&:hover": {
          color: theme.colors.buttonLabelBaseHovered,
        },
      },
      secondary: {
        color: theme.colors.buttonLabelOnAccentDefault,

        "&:hover": {
          color: theme.colors.buttonLabelOnAccentHovered,
        },
      },
    },
  },
  defaultVariants: {
    color: "base",
    size: "md",
  },
});

const StyledLinkRouter = styled(RouterLink, StyledLink);

type LinkProps = WithChildren<
  | (AnchorHTMLAttributes<HTMLAnchorElement> & {
      size: VariantProps<typeof StyledLink>["size"];
      color: VariantProps<typeof StyledLink>["color"];
      iconLeft?: ComponentProps<typeof Icon>["name"];
      iconRight?: ComponentProps<typeof Icon>["name"];
    })
  | {
      to: string;
      size: VariantProps<typeof StyledLink>["size"];
      color: VariantProps<typeof StyledLink>["color"];
      iconLeft?: ComponentProps<typeof Icon>["name"];
      iconRight?: ComponentProps<typeof Icon>["name"];
    }
> & { css?: CSS };

// This is a workaround for transforming a size "sm" | "md" | "lg" into a size "16" | "24" with stitches responisve styling object
function getIconSize(size: LinkProps["size"]) {
  let iconSize:
    | 16
    | 24
    | "16"
    | "24"
    | {
        "@bp1"?: 16 | 24 | "16" | "24";
        "@bp2"?: 16 | 24 | "16" | "24";
        "@bp3"?: 16 | 24 | "16" | "24";
        "@initial"?: 16 | 24 | "16" | "24";
      } = 24;

  if (typeof size === "string") {
    iconSize = size === "sm" ? 16 : 24;
  }

  if (typeof size === "object") {
    Object.entries(size).forEach(([key, value]) => {
      iconSize =
        typeof iconSize === "object"
          ? { ...iconSize, [key]: value === "sm" ? "16" : "24" }
          : { [key]: value === "sm" ? "16" : "24" };
    });

    // In case of an empty object, we set the default value
    if (Object.keys(iconSize).length === 0) {
      iconSize = 24;
    }
  }

  return iconSize;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, size, iconLeft, iconRight, ...rest }, ref) => {
    const iconSize = getIconSize(size);

    const innerLink = (
      <Outlet horizontal="spacing16px" justify="start" align="center" as="span">
        {iconLeft && <Icon name={iconLeft} size={iconSize} />}
        <span>{children}</span>
        {iconRight && <Icon name={iconRight} size={iconSize} />}
      </Outlet>
    );

    if ("to" in rest) {
      return (
        <StyledLinkRouter ref={ref} size={size} {...rest}>
          {innerLink}
        </StyledLinkRouter>
      );
    }

    return (
      <StyledLink ref={ref} size={size} {...rest}>
        <Outlet horizontal="spacing16px" justify="start" align="center" as="span">
          {innerLink}
        </Outlet>
      </StyledLink>
    );
  }
);

Link.displayName = "Links";

export const AccessibleLink = styled(RouterLink, {
  display: "block",

  "&:focus-visible": {
    borderRadius: theme.radii[1],
    boxShadow: theme.boxShadow.focus,
    outline: "none",
  },
});
