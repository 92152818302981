import { useGetContract } from "src/hooks/data";
import { toastClose } from "src/components/01_atoms/toast";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { Claim_v2 } from "src/types";
// TODO: should be a claim's module helper
import { formatClaimActCategories } from "src/components/03_organisms/claims/_utils";
import { StyledNotification } from "./claims-notification.styled";
import { Text } from "src/components/01_atoms/text";
import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { getContractByPetName } from "../claim/_utils";

export function ClaimsNotification({ claim }: { claim: Claim_v2 }) {
  const { openClaimStatusSidebar } = useClaimStatusSidebar();
  const { data } = useGetContract();

  const { claim_acts, pet_name } = claim;

  if (!data) return null;

  const contract = getContractByPetName(data, pet_name);

  const petType = contract?.pet.pet_type;

  if (!petType) return null;

  const categories = claim_acts.map(({ actCategory }) => actCategory);

  return (
    <StyledNotification>
      <Outlet horizontal="spacing16px" align="center">
        <Text variant="paragraph2-Bold" color="textBaseDefault">
          Votre demande a été traitée : il nous manque des informations
        </Text>
        <Button
          variant="smallActionAccent"
          onClick={(event) => {
            event.stopPropagation();
            openClaimStatusSidebar({
              formattedCategories: formatClaimActCategories(categories),
              petType,
              claim,
            });
            toastClose();
          }}
        >
          Compléter
        </Button>
      </Outlet>
    </StyledNotification>
  );
}

export function MultipleClaimsNotification() {
  return (
    <div>
      <Text variant="paragraph2-Bold" color="textBaseDefault">
        Des informations sont à compléter dans plusieurs de vos demandes.
      </Text>
      <Text variant="paragraph3-Medium" color="textBaseSubdued">
        Les demandes concernées affichent un badge “À compléter” dans la liste de vos
        remboursements.
      </Text>
    </div>
  );
}
