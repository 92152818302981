import "@reach/dialog/styles.css";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { AnimatePresence, motion } from "framer-motion";

import { WithChildren } from "src/types";
import { styled, theme } from "src/styles/stitches/theme";

const Overlay = styled(DialogOverlay, {
  "&[data-reach-dialog-overlay]": {
    background: theme.colors.fillOverlay,
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 999,
  },
  "& > [data-reach-dialog-content]": {
    backgroundColor: theme.colors.fillSecondary,
    boxShadow: theme.elevation.elevation1Dark,
    padding: 0,
    margin: 0,
    width: "100%",
    maxWidth: "26.25rem",
    display: "flex",
    flexDirection: "column",
    // To fix transition issue on Safari :
    position: "fixed",
    height: "100%",
  },
});

const MotionDialogOverlay = motion(Overlay);

const MotionDialogContent = motion(DialogContent);

type Props = WithChildren<{
  isOpen: boolean;
  label: string;
  onDismiss?: () => void;
  onExitComplete?: () => void;
}>;

export const SidesheetLayout = ({ isOpen, label, onDismiss, onExitComplete, children }: Props) => {
  return (
    <AnimatePresence onExitComplete={onExitComplete}>
      {isOpen ? (
        <MotionDialogOverlay
          onDismiss={onDismiss}
          aria-label={label}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
        >
          {/* @ts-ignore */}
          <MotionDialogContent
            aria-label={label}
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            {children}
          </MotionDialogContent>
        </MotionDialogOverlay>
      ) : null}
    </AnimatePresence>
  );
};
