import { Contact } from "src/types";
import { fetchMySpace } from "./fetchMySpace";

export type SetProfile =
  | React.Dispatch<React.SetStateAction<string>>
  | React.Dispatch<React.SetStateAction<Contact | undefined>>
  | React.Dispatch<React.SetStateAction<Contact | null>>;

export function fetchProfile(setProfile: SetProfile) {
  const path = "/contact";
  fetchMySpace(path, setProfile);
}
