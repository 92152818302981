import { Button } from "../../01_atoms/button";
import { Input } from "../../01_atoms/input";
import { Outlet } from "../../01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { useEffect } from "react";
import { useChangePassword } from "src/hooks/useChangePassword";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { PasswordHint } from "src/components/02_molecules/password-hint/password-hint";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "src/routes";
import { newPasswordCriterias, PasswordCheck, PasswordCriteriasChecker } from "./_utils";
import { InputPasswordWithCriteria } from "./input-password-with-criterias";
import { useForgotPassword } from "src/hooks/signin/use-forgot-password";

type ChangePasswordFormProps = {
  nextPage: string;
};

type ChangePassword = {
  email: string;
  verificationCode: string;
  newPassword: string;
};

const changePasswordSchema = z.object({
  email: z.string().email(),
  verificationCode: z
    .string()
    .regex(/^\d+$/, "Le code de vérification doit être exclusivement numérique"),
  newPassword: z.string().refine(
    (val) => PasswordCheck(val).length === 0,
    (val) => ({ message: PasswordCheck(val)[0] })
  ),
});

export function ChangePasswordForm({ nextPage }: ChangePasswordFormProps) {
  const history = useHistory();
  const location = useLocation<{ email?: string }>();

  let email = "";

  if (location?.state?.email) {
    email = location.state.email;
  } else {
    history.push(routes.login);
  }

  const { isLoading, forgotPasswordSubmit } = useChangePassword({ nextPage });
  const { sendForgotPasswordEmail, isLoading: isForgotPasswordLoading } = useForgotPassword({
    nextPage: routes.changePassword,
  });

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
    watch,
  } = useForm<ChangePassword>({
    resolver: zodResolver(changePasswordSchema),
    mode: "onChange",
    defaultValues: {
      newPassword: "",
    },
  });

  const onSubmit: SubmitHandler<ChangePassword> = async (data) => {
    await forgotPasswordSubmit(data);
  };

  // Set the verification code input focus on first mount
  useEffect(() => {
    setFocus("verificationCode");
  }, [setFocus]);

  const verificationCodeState = !watch("verificationCode")
    ? "idle"
    : errors.verificationCode
    ? "invalid"
    : "valid";

  return (
    <Outlet vertical="spacing48px">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Outlet vertical="spacing48px">
          <Outlet vertical="spacing32px">
            <Outlet vertical="spacing8px">
              <Text variant="display4-Bold">Nouveau mot de passe</Text>
              <Text variant="paragraph1-Medium" color="textBaseSubdued">
                Renseignez le code de vérification reçu par mail et définissez un nouveau mot de
                passe.
              </Text>
            </Outlet>
            <Outlet vertical="spacing16px" fluid>
              <input type="hidden" value={email} {...register("email")} />
              <Input
                placeholder="Code de vérification"
                type="text"
                autoComplete="one-time-code"
                {...register("verificationCode")}
              />
              <Outlet vertical="spacing8px" fluid>
                <InputPasswordWithCriteria
                  criterias={newPasswordCriterias}
                  criteriasChecker={PasswordCheck as PasswordCriteriasChecker}
                  placeholder="Nouveau mot de passe"
                  type="password"
                  {...register("newPassword")}
                  value={watch("newPassword")}
                />
                <PasswordHint
                  state={verificationCodeState}
                  label="Le code de vérification doit être exclusivement numérique"
                />
              </Outlet>
            </Outlet>
          </Outlet>
          <Button
            size="lg"
            variant="accent"
            fluid
            disabled={isLoading || !isValid}
            loading={isLoading}
            type="submit"
          >
            Confirmer
          </Button>
        </Outlet>
      </form>
      <Outlet vertical="spacing8px">
        <Text variant="display5-Bold" color="textBaseDefault">
          Vous n’avez rien reçu ?
        </Text>
        <Outlet horizontal="spacing4px" align="baseline">
          <Text variant="paragraph1-Medium" color="textBaseSubdued">
            Vérifiez vos spams ou
          </Text>
          <Button
            variant="smallActionAccent"
            size="sm"
            onClick={() => sendForgotPasswordEmail(email)}
            loading={isForgotPasswordLoading}
            inline
          >
            renvoyer un email
          </Button>
        </Outlet>
      </Outlet>
    </Outlet>
  );
}
