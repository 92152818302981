import { Slide, ToastContainer } from "react-toastify";
import { keyframes, styled, theme } from "src/styles/stitches/theme";
import { Icon } from "../icon";

// GLOBAL
const progressBar = keyframes({
  "0%": { transform: "scaleX(0)" },
  "100%": { transform: "scaleX(1)" },
});

export const StyledToasts = styled(ToastContainer, {
  "&.Toastify__toast-container": {
    padding: theme.spacing.spacing24px,
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    pointerEvents: "none",
  },
  ".Toastify__close-button": {
    alignSelf: "initial",
  },
  ".Toastify__toast-body": {
    zIndex: 1003,
    padding: 0,
    margin: 0,
    fontSize: theme.fontSizes.textParagraph2,
    fontWeight: theme.fontWeights.bold,
  },
  ".Toastify__progress-bar": {
    right: 0,
    left: "initial",
    height: "100%",
    zIndex: 1002,
    background: "rgba(255,255,255,.2)",
    animation: `${progressBar} linear 1 forwards`,
    "&--info": {
      background: "rgba(115,175,255,.1)",
    },
  },
  ".Toastify__toast": {
    color: theme.colors.buttonLabelOnAccentDefault,
    margin: ".5rem auto",
    borderRadius: theme.radii[16],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "0px",
    width: "fit-content",
    pointerEvents: "all",
    padding: `${theme.spacing.spacing12px} ${theme.spacing.spacing8px}`,
    "@bp1": {
      padding: `${theme.spacing.spacing16px} ${theme.spacing.spacing12px}`,
    },

    "&--default": {
      background: `${theme.colors.fillAccent}`,
    },
    "&--info": {
      background: `${theme.colors.fillSecondary}`,
      color: theme.colors.textBaseDefault,
      boxShadow: theme.elevation.elevation1Primary,
    },
    "&--success": {
      background: `${theme.colors.fillAccent}`,
    },
    "&--warning": {
      background: `${theme.colors.fillSecondary}`,
      color: theme.colors.textBaseDefault,
      boxShadow: theme.elevation.elevation1Primary,
    },
    "&--error": {
      background: `${theme.colors.fillCriticalAccent}`,
    },
  },
  ".Toastify--animate": {
    animationDuration: "0.5s",
  },
});

type CloseButtonProps = {
  closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <button onClick={closeToast}>
    <Icon name="outlinedClose" />
  </button>
);

// Component injected to the root to style toasts when used
export const Toastify = () => {
  return (
    <StyledToasts
      position="top-center"
      autoClose={4000}
      pauseOnFocusLoss
      pauseOnHover
      icon={false}
      closeButton={CloseButton}
      transition={Slide}
    />
  );
};
