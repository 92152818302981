import { AnchorButton } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { externalRoutes } from "src/routes";

export function HaveAnAccount() {
  return (
    <Outlet vertical="spacing32px" fluid>
      <Text as="h2" variant="display5-Bold">
        Pas encore de compte ?
      </Text>

      <AnchorButton
        variant="secondary"
        size="lg"
        iconRight="outlinedDog"
        fluid
        href={externalRoutes.subscriptionFlow}
      >
        Rejoindre Dalma
      </AnchorButton>
    </Outlet>
  );
}
