/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { Button } from "src/components/01_atoms/button";
import { Input } from "src/components/01_atoms/input";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

export function formatCauseValue(causeValue: string) {
  return causeValue.toUpperCase().replace(/ /g, "_");
}

interface EmptyVisitMotiveListProps {
  fieldName: string;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  isCustomMotifClicked: boolean;
  setIsCustomMotifClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmptyVisitMotiveList = ({
  fieldName,
  setValue,
  getValues,
  isCustomMotifClicked,
  setIsCustomMotifClicked,
}: EmptyVisitMotiveListProps) => {
  const [causeInputValue, setCauseInputValue] = useState({ label: "", value: "" });
  const formValues = getValues();

  const handleAddCauseClick = () => {
    if (causeInputValue.value.trim() !== "") {
      const newCauses = [
        ...((formValues as Record<string, any>)[fieldName] || []),
        formatCauseValue(causeInputValue.value),
      ];

      setValue(fieldName, newCauses, { shouldValidate: true });
      setCauseInputValue({ label: "", value: "" });
    }
  };

  return isCustomMotifClicked ? (
    <Container isCustomMotifClicked={isCustomMotifClicked}>
      <Label variant="caption1-Medium" color="textBaseSubdued">
        Ajouter un motif
      </Label>
      <Input
        placeholder="Indiquer un motif"
        onChange={(event) =>
          setCauseInputValue({ label: event.target.value, value: event.target.value })
        }
        value={causeInputValue.label}
      />
      <Button
        fluid
        variant="secondary"
        size="sm"
        onClick={handleAddCauseClick}
        disabled={causeInputValue.label.length === 0}
        style={{ marginTop: "1rem" }}
      >
        Ajouter le motif
      </Button>
    </Container>
  ) : (
    <SearchEmptyResultContainer>
      <Illustration
        src="/assets/media/illu-dog-running.svg"
        alt="Illustration d'un chien qui court"
      />
      <NoResultText variant="paragraph2-Medium" color="textBaseSubdued">
        Oops, nous n’avons pas trouvé ce que vous cherchez. Pensez à vérifier l’orthographe.
      </NoResultText>
      <Button variant="secondary" size="sm" onClick={() => setIsCustomMotifClicked(true)}>
        Entrer manuellement
      </Button>
    </SearchEmptyResultContainer>
  );
};

const Container = styled("div", {
  variants: {
    isCustomMotifClicked: {
      true: {
        paddingTop: "3.625rem",
        paddingBottom: "2rem",
        paddingLeft: "0.3rem",
        paddingRight: "0.3rem",
      },
      false: {
        padding: 0,
      },
    },
  },
});

const SearchEmptyResultContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingHorizontal: theme.spacing.spacing28px,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "2rem 0",
});

const NoResultText = styled(Text, {
  marginBottom: theme.spacing.spacing12px,
});

const Illustration = styled("img", {
  width: "10rem",
  height: "10rem",
});

const Label = styled(Text, {
  marginBottom: theme.spacing.spacing8px,
});
