import { useEffect } from "react";
import { Spinner } from "src/components/01_atoms/spinner";
import { useGetContract } from "src/hooks/data";
import { claimSteps, useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { styled } from "src/styles/stitches/theme";
import { reportError } from "src/utils/error";

const SpinnerWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

export function InitStep() {
  const { data: contracts, isLoading } = useGetContract();
  const { setCurrentStep, setSelectedPetToContext } = useRefundSidebar();

  useEffect(() => {
    if (!isLoading && !contracts) {
      reportError("Error while loading contracts on claim sidesheet init step");
      return;
    }

    if (isLoading || !contracts) {
      return;
    }

    if (contracts.length > 1) {
      setCurrentStep(claimSteps.PET_SELECTION);
    } else if (contracts.length === 1) {
      setSelectedPetToContext(contracts[0].pet);
      setCurrentStep(claimSteps.CONSULTATION_TYPES);
    }
  }, [contracts, isLoading, setCurrentStep, setSelectedPetToContext]);

  return (
    <SpinnerWrapper>
      <Spinner></Spinner>
    </SpinnerWrapper>
  );
}
