import { Outlet } from "src/components/01_atoms/outlet";
import { Claim } from "src/components/02_molecules/claim";
import { Claim_v2 } from "src/types";

type Props = {
  claims: Claim_v2[];
};

export function ClaimsList({ claims }: Props) {
  return (
    <Outlet vertical="0" fluid>
      {claims.map((claim) => (
        <Claim key={claim.claim_id} claim={claim} />
      ))}
    </Outlet>
  );
}
