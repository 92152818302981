import { ComponentProps, forwardRef, MouseEvent, ReactElement } from "react";
import { Icon, IconProps } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { VisuallyHidden } from "src/components/01_atoms/visually-hidden";
import { baseColors, rgba, styled, theme } from "src/styles/stitches/theme";
import { Avatar } from "../avatar";
import { Alert } from "./_alert";
import { BottomCard, Card, PetIcon, TopCard } from "./_utils";

const SpanCheckbox = styled("span", {
  transition: "all .15s ease-out",
  // add more radius to the checkbox for visual alignment with the svg
  borderRadius: `calc(${theme.radii[4]} + 0.04rem)`,
  width: "1.5rem",
  height: "1.5rem",
  border: `2px solid ${theme.colors.borderBaseSubdued}`,
  appearance: "none",
  display: "block",
  position: "relative",
  flex: "0 0 auto",
  marginLeft: "auto",

  "& svg": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },

  variants: {
    checked: {
      false: {
        "& svg": {
          opacity: 0,
        },
      },
      true: {
        "& svg": {
          opacity: 1,
        },
      },
    },
  },
});

const Wrapper = styled("label", {
  display: "block",
  border: `1.5px solid ${theme.colors.borderSecondary}`,
  borderRadius: theme.radii["24"],
  overflow: "hidden",
  cursor: "pointer",
  transition: "border-color .15s ease-out",

  "@withHover": {
    "&:hover": {
      [`& ${SpanCheckbox}`]: {
        boxShadow: theme.boxShadow.hovered,
      },
    },
  },

  variants: {
    disabled: {
      true: {
        backgroundColor: theme.colors.backgroundAccent,
        boxShadow: theme.elevation.elevation1Primary,
      },
    },
    checked: {
      true: {
        borderColor: theme.colors.borderSelected,
        backgroundColor: theme.colors.fillSecondary,
        boxShadow: theme.elevation.elevation1Primary,
      },
      false: {
        boxShadow: theme.elevation.elevation1Purple,
        // We fake a filter drop shadow with rbga background
        backgroundColor: rgba(baseColors.neutral100, 0.4),

        "@withHover": {
          "&:hover": {
            borderColor: theme.colors.borderBaseDisabled,
          },
        },
      },
    },
  },
});

const StyledInput = styled("input", VisuallyHidden, {
  "&:focus-visible": {
    [`& + ${Wrapper}`]: {
      boxShadow: theme.boxShadow.selected,
      [`& ${SpanCheckbox}`]: {
        boxShadow: theme.boxShadow.hovered,
      },
    },
  },
});

type CheckboxCardProps = Omit<ComponentProps<"input">, "type" | "value" | "ref" | "checked"> & {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconName: IconProps["name"];
  title: string;
  description?: string;
  value: string;
  alert?: string;
  onAlertClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  avatarBackgroundColor?: "base" | "secondary";
  infoDisabled?: ReactElement;
};

export const CheckboxCard = forwardRef<HTMLInputElement, CheckboxCardProps>(
  (
    {
      title,
      description,
      alert,
      onAlertClick,
      checked,
      iconName,
      avatarBackgroundColor = "secondary",
      disabled,
      infoDisabled,
      ...rest
    },
    ref
  ) => {
    return (
      <Card>
        <StyledInput
          {...rest}
          type="checkbox"
          id={rest.id ?? rest.value?.toString()}
          ref={ref}
          disabled={disabled}
        />
        <Wrapper htmlFor={rest.id ?? rest.value?.toString()} checked={checked} disabled={disabled}>
          <TopCard checked={checked} disabled={disabled}>
            <Outlet horizontal="spacing8px" fluid align="center" justify="spaceBetween">
              <Outlet horizontal="spacing8px" align="center">
                <Avatar color={avatarBackgroundColor} size="lg" withHover={false}>
                  <PetIcon name={iconName} size="24" color="iconBaseSubdued" />
                </Avatar>
                <Outlet vertical="spacing2px">
                  <Text variant="paragraph1-Bold" color="textBaseDefault">
                    {title}
                  </Text>
                  {description ? (
                    <Text variant="paragraph3-Medium" color="textBaseSubdued">
                      {description}
                    </Text>
                  ) : null}
                </Outlet>
              </Outlet>
              <SpanCheckbox checked={checked}>
                <Icon
                  name="filledCheckboxSquare"
                  color={disabled ? "iconPrimaryDisabled" : "iconPrimaryDefault"}
                  size="24"
                />
              </SpanCheckbox>
            </Outlet>
          </TopCard>
          {disabled ? <BottomCard> {infoDisabled} </BottomCard> : null}
          {alert ? <Alert onAlertClick={onAlertClick} text={alert} /> : null}
        </Wrapper>
      </Card>
    );
  }
);

CheckboxCard.displayName = "CheckboxCard";
