import { ApiUnpaidStatusResponse } from "src/backApi/get-unpaid-status";
import { displayPrice } from "src/components/cards/RefundCard";
import { styled, theme } from "src/styles/stitches/theme";
import { InlineAlert } from "../inline-alert";

const Container = styled("div", {
  padding: `0 ${theme.spacing.spacing24px}`,
  width: "100%",

  "@bp3": {
    padding: 0,
  },
});

const ContainerWithBorderRadius = styled("div", {
  borderRadius: theme.radii[16],
  overflow: "hidden",
});

type Props = {
  unpaidSubscriptions: ApiUnpaidStatusResponse[];
};

export function UnpaidInlineAlerts({ unpaidSubscriptions }: Props) {
  return (
    <Container>
      <ContainerWithBorderRadius>
        {unpaidSubscriptions.map((unpaidSubscription) => {
          const recoveryAmount = unpaidSubscription.body
            ? displayPrice(unpaidSubscription.body.recovery_amount / 100)
            : undefined;

          return (
            <a
              key={unpaidSubscription.body?.subscription_id}
              href={unpaidSubscription.body?.recovery_payment_link}
              target="_blank"
              rel="noreferrer noopener"
            >
              <InlineAlert
                iconName="filledWarning"
                text={`Une ou plusieurs de vos mensualités n’ont pas pu être prélevées pour un montant de ${recoveryAmount} €. Régularisez vos paiements pour continuer à profiter de Dalma.`}
                chevronRight
                background="fillCriticalAccent"
                color="textSecondaryDefault"
                iconColor="iconSecondaryDefault"
                borderRadius={0}
                withBorder={false}
              />
            </a>
          );
        })}
      </ContainerWithBorderRadius>
    </Container>
  );
}
