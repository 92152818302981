import { deprecatedStyled, deprecatedTheme } from "src/styles/stitches/deprecated-theme";

import { FileIcon } from "src/components/Icons/FileIcon";
import { FileDownloadButton } from "src/components/buttons/FileDownloadButton";
import { legal } from "src/configs/Legal";

const Container = deprecatedStyled("div", {
  borderTop: "1px solid #E0E0E0",
  marginTop: 18,
  padding: 48,
});

const TitleWrapper = deprecatedStyled("div", {
  display: "flex",
  alignItems: "center",
  marginBottom: 18,
});

const Title = deprecatedStyled("div", {
  fontWeight: 700,
  fontSize: 16,
  color: deprecatedTheme.colors.gray900,
  display: "flex",
  marginLeft: 10,
});

type DocumentsRelatedToContractProps = {
  legal_version: string;
};

export const DocumentsRelatedToContract = ({ legal_version }: DocumentsRelatedToContractProps) => {
  //RVE-193 We have to version the FIC for our clients.
  legal_version = legal_version == null ? "v4" : legal_version;
  const niLink = legal[legal_version].fic;
  const ipidLink = legal[legal_version].ipid;
  //@ts-ignore
  // hack to tranform a string in number
  const niName = legal_version.replace("v", "") * 1 >= 5 ? "FIC + NI" : "Fiche info conseil";
  return (
    <Container>
      <TitleWrapper>
        <FileIcon color="#585B66" />
        <Title>Fiche IPID</Title>
      </TitleWrapper>
      <a rel="noopener noreferrer" target="_blank" href={ipidLink}>
        <FileDownloadButton title={"Fiche IPID"} format={"PDF (230 ko)"} />
      </a>
      <TitleWrapper>
        <FileIcon color="#585B66" />
        <Title>{niName}</Title>
      </TitleWrapper>
      <a rel="noopener noreferrer" target="_blank" href={niLink}>
        <FileDownloadButton title={niName} format={"PDF (230 ko)"} />
      </a>
    </Container>
  );
};
