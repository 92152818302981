import { Auth } from "aws-amplify";
import { ClaimConsultationType, ClaimFile } from "src/types";
import { generateChecksum } from "./utils/generate-checksum";
import config from "src/config";
/**
 * THE DOC ABOUT THE FILE AND CLAIM API IS HERE: http://developers.dalma.dev.s3-website.eu-west-3.amazonaws.com/
 * Ping Firdaws or Armand if you have any questions
 */

export type SucessRegisterFile = {
  file_id: string;
  filename: string;
  upload_url: string;
};

export type RegisterFile = SucessRegisterFile & {
  checksum: string;
};

export type RegisterFilesResponse = RegisterFile[];

export type APIError = {
  code: string;
  message: string;
  stacktrace?: string;
};

async function createAuthorizationHeader() {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().getJwtToken();

  const headers = new Headers({
    Authorization: "Bearer " + idToken,
    "content-type": "application/json",
  });

  return headers;
}

type CreateClaimResponse = { claim_id: string; customer_uuid: string };
async function createClaimId(
  contract_id: string,
  contract_uuid: string,
  amount: string,
  consultation_type: ClaimConsultationType[],
  selectedAccidentDate: Date | undefined,
  selectedDiseaseDate: Date | undefined,
  visitMotivesAccident: string[] | undefined,
  visitMotivesDisease: string[] | undefined
) {
  const headers = await createAuthorizationHeader();
  const body = {
    contract_id,
    contract_uuid,
    amount: +amount,
    consultation_type,
    claim_creation_date: new Date().toISOString(),
    accident_date: selectedAccidentDate,
    first_symptom_date: selectedDiseaseDate,
    visit_motives_keywords: [...(visitMotivesAccident || []), ...(visitMotivesDisease || [])],
    created_by: "web",
  };

  const res = await fetch(`${config.apiClaimsGateway.URL}/createClaim`, {
    method: "POST",
    body: JSON.stringify(body),
    headers,
  });

  const jsonData = await res.json();

  if (!res.ok) {
    throw new Error(jsonData.message);
  }

  return jsonData as CreateClaimResponse;
}

export async function addFilesToClaim(
  customer_uuid: string,
  claim_id: string,
  registeredFiles: RegisterFilesResponse,
  reopen: boolean
) {
  const headers = await createAuthorizationHeader();

  const body = {
    type: reopen ? "claim.reopen" : "claim.update",
    file_ids: registeredFiles.map((file) => file.file_id),
  };

  const response = await fetch(
    `${config.apiClaimsGateway.URL}/addFilesToClaim/${customer_uuid}?claim_id=${claim_id}`,
    {
      method: "PUT",
      body: JSON.stringify(body),
      headers,
    }
  );

  if (!response.ok) {
    const jsonData = await response.json();
    throw new Error(jsonData.message);
  }
}

export async function registerFiles(files: ClaimFile[], claimId: string, clientId: string) {
  const buildFiles = await Promise.all(
    files.map(async (file) => ({
      filename: file.filename,
      mime_type: file.mime_type,
      checksum: await generateChecksum(file.file),
      type: file.kind,
    }))
  );

  const body = {
    upload_id: claimId,
    owner_id: clientId,
    service: "dalma.claim",
    files: buildFiles,
  };

  const headers = await createAuthorizationHeader();

  const rawResponse = await fetch(`${config.apiFilesGateway.URL}/registerFiles`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });

  const response = await rawResponse.json();

  if (!rawResponse.ok) {
    throw new Error(response.message);
  }

  // Add checksum to response
  const registerFiles = response.map((file: SucessRegisterFile) => {
    return {
      ...file,
      checksum: buildFiles.find((f) => f.filename === file.filename)?.checksum,
    };
  });

  return registerFiles as RegisterFilesResponse;
}

export async function uploadFiles(files: ClaimFile[], registeredFiles: RegisterFile[]) {
  // This is for uploading all files in parallel, because async functions in a .map doesn't work out of the box
  await Promise.all(
    registeredFiles.map(async (uploadResponse) => {
      const file = files.find((file) => file.filename === uploadResponse.filename)?.file;

      if (!file) {
        throw new Error("File not found before upload");
      }

      await fetch(uploadResponse.upload_url, {
        method: "PUT",
        headers: {
          // This is for the S3 bucket to accept the file with a check on the checksum
          "x-amz-checksum-sha256": uploadResponse.checksum,
        },
        body: file,
      });
    })
  ).catch((err) => {
    throw new Error(err.message);
  });
}

/**
 * Rename files to avoid duplicate names
 *
 * @param files Files of the claim
 * @returns Files with renamed filenames
 *
 * @example
 * const files = [
 * { filename: "blip-blop", kind: "CARE_RECORD", ... },
 * { filename: "blip-blop", kind: "CARE_RECORD", ... },
 * ]
 * const renamedFiles = getRenamedFiles(files)
 * // 👇
 * [
 * { filename: "care-record-1", kind: "CARE_RECORD", ... },
 * { filename: "care-record-2", kind: "CARE_RECORD", ... }
 * ]
 */
export function getRenamedFiles(files: ClaimFile[]) {
  const counters = { CARE_RECORD: 1, INVOICE: 1, PRESCRIPTION: 1 };
  return files.map((file) => ({
    ...file,
    filename: `${file.kind.toLowerCase().replace("_", "-")}-${counters[file.kind]++}`,
  }));
}

export async function createNewClaimWithNewApi(
  files: ClaimFile[],
  contractId: string,
  contractUuid: string,
  amount: string,
  consultation_type: ClaimConsultationType[],
  selectedAccidentDate: Date | undefined,
  selectedDiseaseDate: Date | undefined,
  visitMotivesAccident: string[] | undefined,
  visitMotivesDisease: string[] | undefined
) {
  const user = await Auth.currentAuthenticatedUser();
  const clientId = user.pool.clientId;

  const { claim_id, customer_uuid } = await createClaimId(
    contractId,
    contractUuid,
    amount,
    consultation_type,
    selectedAccidentDate,
    selectedDiseaseDate,
    visitMotivesAccident,
    visitMotivesDisease
  );

  const renamedFiles = getRenamedFiles(files);
  const registeredFiles = await registerFiles(renamedFiles, claim_id, clientId);
  await uploadFiles(renamedFiles, registeredFiles);

  await addFilesToClaim(customer_uuid, claim_id, registeredFiles, false);
}
