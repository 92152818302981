export const arrowsIcons = {
  chevronDown: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.79289 8.54289C5.18342 8.15237 5.81658 8.15237 6.20711 8.54289L12 14.3358L17.7929 8.54289C18.1834 8.15237 18.8166 8.15237 19.2071 8.54289C19.5976 8.93341 19.5976 9.56658 19.2071 9.9571L13.4142 15.75C12.6332 16.531 11.3668 16.531 10.5858 15.75L4.79289 9.95711C4.40237 9.56658 4.40237 8.93342 4.79289 8.54289Z"
      fill="inherit"
    />
  ),
  chevronRight: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z"
      fill="inherit"
    />
  ),
  chevronUp: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.79289 15.4571C5.18342 15.8476 5.81658 15.8476 6.20711 15.4571L12 9.66422L17.7929 15.4571C18.1834 15.8476 18.8166 15.8476 19.2071 15.4571C19.5976 15.0666 19.5976 14.4334 19.2071 14.0429L13.4142 8.25C12.6332 7.46895 11.3668 7.46895 10.5858 8.25L4.79289 14.0429C4.40237 14.4334 4.40237 15.0666 4.79289 15.4571Z"
      fill="inherit"
    />
  ),
  chevronLeft: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7071 19.2071C16.0976 18.8166 16.0976 18.1834 15.7071 17.7929L9.91421 12L15.7071 6.20711C16.0976 5.81658 16.0976 5.18342 15.7071 4.79289C15.3166 4.40237 14.6834 4.40237 14.2929 4.79289L8.5 10.5858C7.71895 11.3668 7.71895 12.6332 8.5 13.4142L14.2929 19.2071C14.6834 19.5976 15.3166 19.5976 15.7071 19.2071Z"
      fill="inherit"
    />
  ),
  arrowDown: (
    <path
      d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V18.0858L6.20711 13.2929C5.81658 12.9024 5.18342 12.9024 4.79289 13.2929C4.40237 13.6834 4.40237 14.3166 4.79289 14.7071L10.5858 20.5C11.3668 21.281 12.6332 21.281 13.4142 20.5L19.2071 14.7071C19.5976 14.3166 19.5976 13.6834 19.2071 13.2929C18.8166 12.9024 18.1834 12.9024 17.7929 13.2929L13 18.0858V4Z"
      fill="inherit"
    />
  ),
  arrowRight: (
    <path
      d="M13.2929 17.7929C12.9024 18.1834 12.9024 18.8166 13.2929 19.2071C13.6834 19.5976 14.3166 19.5976 14.7071 19.2071L20.5 13.4142C21.281 12.6332 21.281 11.3668 20.5 10.5858L14.7071 4.79289C14.3166 4.40237 13.6834 4.40237 13.2929 4.79289C12.9024 5.18342 12.9024 5.81658 13.2929 6.20711L18.0858 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H18.0858L13.2929 17.7929Z"
      fill="inherit"
    />
  ),
  arrowUp: (
    <path
      d="M6.20711 10.7071C5.81658 11.0976 5.18342 11.0976 4.79289 10.7071C4.40237 10.3166 4.40237 9.68339 4.79289 9.29286L10.5858 3.49997C11.3668 2.71892 12.6332 2.71892 13.4142 3.49997L19.2071 9.29286C19.5976 9.68339 19.5976 10.3166 19.2071 10.7071C18.8166 11.0976 18.1834 11.0976 17.7929 10.7071L13 5.91419V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V5.91418L6.20711 10.7071Z"
      fill="inherit"
    />
  ),
  arrowLeft: (
    <path
      d="M10.7071 17.7929C11.0976 18.1834 11.0976 18.8166 10.7071 19.2071C10.3166 19.5976 9.68339 19.5976 9.29286 19.2071L3.49997 13.4142C2.71892 12.6332 2.71892 11.3668 3.49997 10.5858L9.29286 4.79289C9.68339 4.40237 10.3166 4.40237 10.7071 4.79289C11.0976 5.18342 11.0976 5.81658 10.7071 6.20711L5.91419 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H5.91418L10.7071 17.7929Z"
      fill="inherit"
    />
  ),
  chevronAltDown: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9933 14.5368C12.4764 15.1544 11.5236 15.1544 11.0067 14.5368L7.30217 10.1113C6.60034 9.2729 7.19922 8 8.29551 8L15.7045 8C16.8008 8 17.3997 9.27291 16.6978 10.1113L12.9933 14.5368Z"
      fill="inherit"
    />
  ),
  chevronAltRight: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0368 11.0067C15.6544 11.5236 15.6544 12.4764 15.0368 12.9933L10.6113 16.6978C9.7729 17.3997 8.5 16.8008 8.5 15.7045L8.5 8.29551C8.5 7.19922 9.77291 6.60035 10.6113 7.30217L15.0368 11.0067Z"
      fill="inherit"
    />
  ),
  chevronRightSmall: (
    <path
      d="M9.21913 16.3753C8.87412 16.8066 8.94404 17.4359 9.3753 17.7809C9.80657 18.1259 10.4359 18.056 10.7809 17.6247L9.21913 16.3753ZM14 12L14.7809 12.6247C15.073 12.2595 15.073 11.7405 14.7809 11.3753L14 12ZM10.7809 6.37531C10.4359 5.94404 9.80657 5.87412 9.3753 6.21913C8.94404 6.56414 8.87412 7.19343 9.21913 7.62469L10.7809 6.37531ZM10.7809 17.6247L14.7809 12.6247L13.2191 11.3753L9.21913 16.3753L10.7809 17.6247ZM14.7809 11.3753L10.7809 6.37531L9.21913 7.62469L13.2191 12.6247L14.7809 11.3753Z"
      fill="inherit"
    />
  ),
};
