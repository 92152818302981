import { ForgotPasswordForm } from "src/components/03_organisms/login/forgot-password-form";
import { LoginLayout } from "src/components/04_layouts/login-layout";

type ForgotPasswordProps = {
  nextPage: string;
};

export function ForgotPasswordNew({ nextPage }: ForgotPasswordProps) {
  return (
    <LoginLayout>
      <ForgotPasswordForm nextPage={nextPage} />
    </LoginLayout>
  );
}
