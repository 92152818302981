import { Flex } from "@chakra-ui/react";
import Arrow from "@iconscout/react-unicons/icons/uil-angle-right";
import { Texts } from "../texts/Texts";
import { useHistory } from "react-router-dom";

type ProfileMenuProps = {
  profileState: "profile" | "contracts";
};

export const ProfileMenu = ({ profileState }: ProfileMenuProps) => {
  const history = useHistory();

  return (
    <Flex
      flex={1}
      align="center"
      mt="5px"
      mr="50px"
      display={["none", "none", "none", "initial"]}
      direction="column"
      maxW={["300px"]}
    >
      <Flex direction="column" w="100%">
        <Flex direction="column" w="100%">
          <Flex cursor="pointer" mb={"0px"} w="100%" onClick={() => history.push("/settings")}>
            <Flex>
              <Texts
                fontWeight="500"
                color={profileState === "profile" ? "#4E68F6" : "#616161"}
                fontSize="16px"
              >
                Mon profil
              </Texts>
            </Flex>
            {profileState === "profile" && (
              <Flex flex={1} justify="flex-end">
                <Arrow color="#4E68F6" />
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex direction="column" w="100%">
          <Flex
            cursor="pointer"
            mt="24px"
            mb={"0px"}
            w="100%"
            onClick={() => history.push("/contracts")}
          >
            <Flex cursor="pointer" minW="90px">
              <Texts
                w="100%"
                color={profileState === "contracts" ? "#4E68F6" : "#616161"}
                fontWeight="500"
                fontSize="16px"
              >
                Contrats et versements
              </Texts>
            </Flex>
            {profileState === "contracts" && (
              <Flex flex={1} justify="flex-end">
                <Arrow color="#4E68F6" />
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex direction="column" w="100%">
          <Flex cursor="pointer" mt="24px" w="100%" onClick={() => history.push("/guarantees")}>
            <Flex>
              <Texts fontWeight="500" color="#616161" fontSize="16px">
                Nos garanties
              </Texts>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
