import { useAnimation } from "framer-motion";
import { motion } from "framer-motion";
import { styled, theme } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";

const Container = styled("div", {
  width: "100vw",

  "@bp3": {
    display: "none",
  },
});

const Overlay = styled(motion.div, {
  position: "fixed",
  height: "100%",
  width: "100vw",
  right: 0,
  top: 0,
  backgroundColor: theme.colors.fillOverlay,
  cursor: "pointer",
  zIndex: 1000,
});

const Drawer = styled(motion.div, {
  zIndex: 1000,
  position: "fixed",
  // HACK : we need this to overflow to prevent spring from showing a gap.
  bottom: "-10rem",
  // We offset this with extra padding at the bottom.
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing32px} ${theme.spacing.spacing216px}`,
  width: "100vw",
  backgroundColor: theme.colors.fillSecondary,
  borderRadius: `${theme.radii[32]} ${theme.radii[32]} 0 0`,
  right: 0,

  "@bp3": {
    display: "none",
  },
});

const Content = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "center",
});

const PullBar = styled(motion.div, {
  zIndex: 999,
  position: "absolute",
  top: "-2.65rem",
  width: "3.5rem",
  height: "0.31rem",
  backgroundColor: theme.colors.fillSecondary,
  borderRadius: theme.radii[4],
});

type Props = WithChildren<{
  isVisible: boolean;
  onClose: () => void;
}>;

export function MobileDrawerLayout({ children, isVisible, onClose }: Props) {
  const controls = useAnimation();

  if (isVisible) {
    controls.start("visible");
  } else {
    controls.start("hidden");
  }

  const handleClose = () => {
    controls.start("hidden");
    onClose();
  };

  return (
    <Container>
      <Overlay
        animate={controls}
        onClick={handleClose}
        initial="hidden"
        variants={{
          visible: { opacity: 1, visibility: "visible" },
          hidden: { opacity: 0, visibility: "hidden" },
        }}
      />
      <Drawer
        animate={controls}
        drag="y"
        onDragEnd={handleClose}
        initial="hidden"
        transition={{
          type: "spring",
          damping: 20,
          stiffness: 200,
        }}
        variants={{
          visible: { y: 0 },
          hidden: { y: "100%" },
        }}
        dragConstraints={{ top: 0 }}
        dragElastic={0.2}
      >
        <Content>
          <PullBar />
          {children}
        </Content>
      </Drawer>
    </Container>
  );
}
