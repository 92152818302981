import { API, Auth } from "aws-amplify";
import { ReferrerType } from "src/types";

type updatesProps = {
  marketing: {
    vet_phone_number: string;
    referrer_type: ReferrerType[];
    referrer_details: string;
  };
};

export function updateContact(updates: updatesProps, cb: () => void) {
  const path = "/contact";
  const apiName = "apiMySpaceGateway";

  Auth.currentAuthenticatedUser()
    .then((user) => {
      const token = user.signInUserSession.idToken.jwtToken;
      const myInit = {
        headers: { Authorization: token },
        response: false,
        body: updates,
      };

      API.post(apiName, path, myInit)
        .then(() => {
          cb();
        })
        .catch(() => {
          // console.log(error.response);
        });
    })
    .catch(() => {
      // console.log("err", err);
    });
}
