import { useActor } from "@xstate/react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { routes } from "src/routes";
import { claimsService } from "src/store/claims/machine";

export function useLogOut() {
  const [, send] = useActor(claimsService);

  const history = useHistory();

  async function handleLogout() {
    await Auth.signOut().then(() => {
      send("RESET_CLAIMS");
      history.push(routes.login);
    });
  }

  return handleLogout;
}
