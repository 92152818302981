import { AccessibleLink } from "src/components/01_atoms/link";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { Avatar } from "src/components/02_molecules/avatar";
import { useGetContact } from "src/hooks/data";
import { routes } from "src/routes";
import { baseColors, styled, theme } from "src/styles/stitches/theme";

const Capitalize = styled("span", {
  textTransform: "capitalize",
});

const Wrapper = styled(AccessibleLink, {
  color: theme.colors.textBaseSubdued,

  [`${Text}`]: {
    transition: "color 0.15s ease-out",
  },

  "&:hover": {
    // Need to trigger the hover manually because the avatar is in the link
    [`${Avatar}`]: {
      boxShadow: `0px 0px 0px 1px rgba(${baseColors.neutral400}, 1)`,
    },
    color: theme.colors.textBaseDefault,
  },

  variants: {
    isMobileMenu: {
      true: {
        width: "100%",
      },
    },
  },
});

function getFirstUpperLetter(s?: string) {
  return s ? s.charAt(0).toUpperCase() : "";
}

type ProfileAvatarProps = {
  isMobileMenu?: boolean;
};

export function ProfileAvatar({ isMobileMenu = false }: ProfileAvatarProps) {
  const { data } = useGetContact();

  const initials = `${getFirstUpperLetter(data?.firstname)}${getFirstUpperLetter(data?.lastname)}`;

  const ProfileName = (
    <>
      <Text variant="paragraph1-Bold">
        <Capitalize>{data?.firstname}</Capitalize>
      </Text>
      <Text variant="caption1-Bold" color="currentColor">
        Voir le profil
      </Text>
    </>
  );

  return (
    <Wrapper to={routes.profil} isMobileMenu={isMobileMenu}>
      <Outlet horizontal="spacing8px" align="center" justify="start" fluid={isMobileMenu}>
        <Avatar size="lg">
          <Text variant="paragraph1-Bold">{initials}</Text>
        </Avatar>
        {isMobileMenu ? (
          <Outlet horizontal="0" align="center" justify="spaceBetween" fluid>
            {ProfileName}
          </Outlet>
        ) : (
          <Outlet vertical="spacing2px" align="start" justify="start">
            {ProfileName}
          </Outlet>
        )}
      </Outlet>
    </Wrapper>
  );
}
