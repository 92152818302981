import { Outlet } from "src/components/01_atoms/outlet";
import { useBanner } from "src/hooks/use-banner";
import { styled, theme } from "src/styles/stitches/theme";
import { PetList } from "./pet-list";
import { ProfileAvatar } from "./profile-avatar";
import { RightSidebarLinks } from "./right-sidebar-links";
import { claimsService } from "src/store/claims/machine";
import { useActor } from "@xstate/react";
import { PetListLoading } from "./pet-list-loading";

const RightSide = styled("div", {
  position: "fixed",
  right: 0,
  top: 0,
  bottom: 0,
  display: "none",
  padding: "4rem 2rem 2rem 2rem",
  backgroundColor: theme.colors.backgroundPrimary,

  "@bp3": {
    width: "13.5rem",
    flex: "0 0 13.5rem",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    overflowY: "auto",
  },

  "@bp4": {
    width: "18.25rem",
    flex: "0 0 18.25rem",
  },

  variants: {
    isBannerVisible: {
      true: {
        "@bp3": {
          top: `${theme.spacing.spacing64px}`, // banner height
        },
      },
    },
  },
});

export function RightSidebar() {
  const { isClosed } = useBanner();
  const [state] = useActor(claimsService);

  return (
    <RightSide isBannerVisible={!isClosed}>
      <Outlet vertical="spacing48px">
        <ProfileAvatar />
        {state.matches("loading") ? <PetListLoading /> : <PetList />}
        <RightSidebarLinks />
      </Outlet>
    </RightSide>
  );
}
