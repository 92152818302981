import { ReactElement } from "react";
import { Icon, IconProps } from "src/components/01_atoms/icon";
import { Text } from "src/components/01_atoms/text";
import { Tooltip } from "src/components/tooltip";
import { styled, theme } from "src/styles/stitches/theme";

const StepContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  padding: `${theme.spacing.spacing20px} 0`,

  "@bp3": {
    padding: `${theme.spacing.spacing24px} 0`,
  },

  variants: {
    withBorderBottom: {
      true: {
        borderBottom: `1px solid ${theme.colors.borderBaseSubdued}`,
      },
    },
  },

  defaultVariants: {
    withBorderBottom: true,
  },
});

const Img = styled("img", {
  width: "1.5rem",
  height: "1.5rem",
});

const EmptyDiv = styled("div", {
  width: "1.5rem",
  height: "1.5rem",
});

type PossibleIcons = Extract<IconProps["name"], "filledCheckCircle">;

type PossibleColors = Extract<IconProps["color"], "iconAccent">;

const iconColors: Record<PossibleIcons, PossibleColors> = {
  filledCheckCircle: "iconAccent",
};

type StepProps = {
  iconName?: PossibleIcons;
  spinner?: boolean;
  text: string;
  withBorderBottom?: boolean;
  tooltipMessage?: ReactElement;
};

export function Step({ iconName, spinner, text, withBorderBottom, tooltipMessage }: StepProps) {
  return (
    <StepContainer withBorderBottom={withBorderBottom}>
      {iconName ? (
        <Icon name={iconName} color={iconColors[iconName]} />
      ) : spinner ? (
        <Img src="/assets/media/spinner-on-secondary.png" alt="Spinner" />
      ) : (
        <EmptyDiv />
      )}
      <Text variant="paragraph2-Bold" color="textBaseDefault">
        {text}
      </Text>
      {tooltipMessage ? (
        <Tooltip size={14} enabled={true} message={tooltipMessage}>
          <Icon name="outlinedInfoCircle" color="iconBaseSubdued" />
        </Tooltip>
      ) : null}
    </StepContainer>
  );
}
