/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getVisitMotives } from "src/backApi/getVisitMotives";
import { useState, useCallback, useEffect } from "react";
import { VisitMotiveTranslation } from "src/types";

const useVisitMotives = () => {
  const [visitMotives, setVisitMotives] = useState([]);
  const [visitMotiveTranslation, setVisitMotivesTranslation] = useState<VisitMotiveTranslation>({
    fr: {},
  });

  const fetchData = useCallback(async () => {
    const response: any = await getVisitMotives();
    setVisitMotives(response.motives);
    setVisitMotivesTranslation(response.localization);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { visitMotives, visitMotiveTranslation };
};

export default useVisitMotives;
