import { SANDBOX } from "./config";

export const routes = Object.freeze({
  login: "/login",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password",
  firstConnection: "/first-connection",
  firstConnectionAlt: "/firstConnection",

  welcome: "/welcome",

  home: "/",
  profil: "/settings",
  contracts: "/contracts",
  guarantees: "/guarantees",
  liveChat: "/liveChat",
  sponsorhip: "/sponsorship",
});

export const externalRoutes = Object.freeze({
  subscriptionFlow: SANDBOX ? "https://signup.dev.dalma.co" : "https://signup.dalma.co",
  marketingSite: "https://dalma.co",
});
