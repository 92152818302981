import { Outlet } from "src/components/01_atoms/outlet";
import { styled, theme } from "src/styles/stitches/theme";

export const StyledImg = styled("img", {
  width: "10rem",
  height: "10rem",
});

export const StyledOutlet = styled(Outlet, {
  paddingTop: theme.spacing.spacing24px,
});
