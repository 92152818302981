import React from "react";

import { Flex } from "@chakra-ui/react";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";

import { Texts } from "../texts/Texts";

type InformationCardProps = {
  title?: string | null;
  informations?: {
    name: string;
    value: any;
  }[];
  minW: React.ComponentProps<typeof Flex>["minW"];
  onEdit?: () => void;
};

export function InformationsCard({
  title = null,
  informations = [],
  minW = "400px",
  onEdit,
}: InformationCardProps) {
  return (
    <Flex w="100%">
      <Flex
        p="25px"
        borderWidth="1px"
        borderColor="#E0E0E0"
        bg="white"
        rounded="8px"
        minW={minW}
        minH="200px"
        direction="column"
      >
        <Flex direction="row" mb="22px">
          <Flex>
            <Texts color="#212121" fontWeight="500">
              {title}
            </Texts>
          </Flex>

          {onEdit ? (
            <Flex
              flex={1}
              justifyContent="flex-end"
              h="100%"
              cursor="pointer"
              onClick={onEdit}
              align="center"
            >
              <EditIcon size="19px" color={"#144283"} />
            </Flex>
          ) : null}
        </Flex>

        {informations &&
          informations.map((e, index) => (
            <Flex mb="6px" key={index} direction="row">
              <Flex>
                <Texts color="#616161" fontWeight="400">
                  {e.name}
                </Texts>
              </Flex>

              <Flex flex={1} align="flex-end">
                <Texts w="100%" textAlign="right" color="#212121" fontWeight="400">
                  {e.value}
                </Texts>
              </Flex>
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
}
