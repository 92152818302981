import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { updateContact } from "src/backApi/updateContact";
import { matchIsAllContractsDeffered } from "src/helpers";
import { useGetContract } from "src/hooks/data";
import { ReferrerType } from "src/types";
import { send } from "xstate/lib/actions";
import { z } from "zod";
import { StyledForm, StyledOutlet } from "./form.styled";
import { HowDidYouKnowDalma } from "./how-did-you-know-dalma";
import { VetClinic } from "./vet-clinic";

const PHONE_NUMBER_REGEX =
  /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;

const onboardingFormSchema = z.object({
  referrer_type: z
    .string()
    .array()
    .nonempty({ message: "Veuillez sélectionner au moins une réponse." }),
  vet_phone_number: z
    .string()
    .regex(PHONE_NUMBER_REGEX, "Le format du numéro de téléphone est incorrect."),
});

export type OnboardingForm = {
  vet_phone_number: string;
  referrer_type: ReferrerType[];
  referrer_details: string;
};

type Props = {
  currentStep: string;
  setIsDownloadAppModalOrDrawerOpen: (isDownloadAppModalOrDrawerOpen: boolean) => void;
};

export function OnboardingForm({ currentStep, setIsDownloadAppModalOrDrawerOpen }: Props) {
  const [selectedReferrerTypes, setSelectedReferrerTypes] = useState<ReferrerType[]>([]);
  const [referrerDetails, setReferrerDetails] = useState("");
  const [vetClinicPhoneNumber, setVetClinicPhoneNumber] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<OnboardingForm>({
    resolver: zodResolver(onboardingFormSchema),
    defaultValues: {
      vet_phone_number: vetClinicPhoneNumber,
      referrer_type: selectedReferrerTypes,
      referrer_details: referrerDetails,
    },
  });
  const { data: contracts } = useGetContract();
  const onSubmit: SubmitHandler<FieldValues> = () => {
    if (!errors.vet_phone_number || vetClinicPhoneNumber === "") {
      updateContact(
        {
          marketing: {
            vet_phone_number: vetClinicPhoneNumber,
            referrer_type: selectedReferrerTypes,
            referrer_details: referrerDetails,
          },
        },
        () => {
          if (matchIsAllContractsDeffered(contracts || [])) {
            send("NEXT_STEP");
          } else {
            setIsDownloadAppModalOrDrawerOpen(true);
          }
        }
      );
    }
  };

  const methods = useForm();

  if (currentStep === "defered") {
    setIsDownloadAppModalOrDrawerOpen(false);
  }

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <StyledOutlet vertical={{ "@initial": "spacing32px", "@bp3": "0" }} fullHeight>
          {currentStep === "howDidYouKnowDalma" ? (
            <HowDidYouKnowDalma
              setSelectedReferrerTypes={setSelectedReferrerTypes}
              setReferrerDetails={setReferrerDetails}
              register={register}
              watch={watch}
            />
          ) : null}
          {currentStep === "vetClinic" ? (
            <VetClinic
              setVetClinicPhoneNumber={setVetClinicPhoneNumber}
              register={register}
              watch={watch}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
            />
          ) : null}
        </StyledOutlet>
      </StyledForm>
    </FormProvider>
  );
}
