import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";

const fonts = {
  ...chakraTheme.fonts,
  // We need a sans-serif fallback font here so that Firefox renders select options correctly
  body: "Grotesk, sans-serif",
  header: "Roslindale",
  mono: "Grotesk",
};

const colors = {
  // default colors
  transparent: "transparent",
  black: "#000",
  white: "#FFF",

  // backgrounds
  background: "#FAFAFA",
  secondBackground: "#FAFAFA",
  paperBackground: "#F5F5F5",
  sidebar: "white",
  sidebarCards: "#DBEBFF",
  radioCards: "white",
  lists: "white",
  progressBar: "#F5F5F5",
  activeProgressBar: "#FE6F18",
  warningBackground: "#FAE6E7;",
  heartBackground: "#FAEBF7",
  refundState: "#DDF0EA",
  inProgressState: "#DBEBFF",
  missingInformationState: "#FAE6E7",

  // button backgrounds
  buttons: "#FE6F18",
  backButton: "#DBEBFF",
  questionButton: "#4E68F6",
  activeButton: "#FE6F18",
  disabledButton: "#DBEBFF",
  textRadio: "white",
  textCheckbox: "white",
  radio: "#F5F5F5",
  newCareButton: "#FE6F18",
  veterinaryChatButton: "#4E68F6",
  refundButton: "#FAF7F0",
  sterilisationButton: "#DBEBFF",

  // fonts colors
  backButtonFont: "#144283",
  disabledFont: "#144283",
  activeFont: "#FE6F18",
  activeFontCheckbox: "#212121",
  sidebarFont: "#144283",
  textfieldLabelFont: "#9E9E9E",
  textfieldFont: "#212121",
  selectedItemFont: "#212121",
  checkboxFont: "#5E5E5E",
  radioTextFont: "#212121",
  inputFont: "#9E9E9E",
  secondTitleFont: "#616161",

  // borderColors
  borderColor: "#DDDDDD",
  activeBorderColor: "#FE6F18",
  selectItemBorder: "#E0E0E0",
  radioTextBorder: "#E0E0E0",
  sidebarButtonBorder: "#E0E0E0",

  // icons
  backIcon: "#144283",
  editIcon: "#4E68F6",
  editClearIcon: "#4E68F6",
};

const borderRadius = {
  none: "0",
  button: "0.5rem",
  input: "5rem",
  card: "0.5rem",
  max: 9999,
};

const overrides = {
  ...chakraTheme,
  radii: borderRadius,
  colors,
  fonts,
  fontWeights: { normal: 300, medium: 600, bold: 700 },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "32px",
    "5xl": "36px",
    "6xl": "48px",
    "7xl": "64px",
  },
};

export const dalmaTheme = extendTheme(overrides);
