import { Flex, Center } from "@chakra-ui/react";

type DalmaLogoProps = {
  size: string;
};

export function DalmaLogo({ size }: DalmaLogoProps) {
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      fill="currentColor"
    >
      <path d="M240.3,569.75v-1.22c0-35.17,36.25-45.15,65-45.15h14.42c8.08,0,11.59-4.17,11.59-10.11,0-9.56-7.68-18.32-23.32-18.32-13.07,0-23.18,7.68-26.41,20.08l-37.74-8.49c5-31.41,35.31-46.5,66.17-46.5,36.66,0,63.62,19.81,63.62,59.43v67.66a199.07,199.07,0,0,0,1.21,23.72H334.64c-.95-5.66-.95-12.67-.95-18.33l-.4-.13c-11.19,15.76-27.9,21.29-43.94,21.29C263.75,613.68,240.3,597,240.3,569.75ZM331,553.84v-3.37H305.26c-8.9,0-21.43,5.26-21.43,16.85,0,10.51,8.76,15,17.92,15C320.62,582.28,331,568.53,331,553.84Z" />
      <path d="M443.93,385.91V610.85H400.8V385.91Z" />
      <path d="M608.89,528.5v82.35H566V529c0-15.9-6.34-28.57-24-28.57S515.63,512.2,515.63,529v81.81H472.77V463.54H515v19.28h.4c8.63-14.83,26.42-22.65,43.13-22.65,17.66,0,34.64,7.28,43,23.45,13.88-15.5,27.76-23.45,49.06-23.45,36.12,0,51.75,25.07,51.75,58.63v92.05h-43V528.5c0-16-6.34-28-24-28C617.92,500.47,608.89,511.79,608.89,528.5Z" />
      <path d="M721.14,569.75v-1.22c0-35.17,36.25-45.15,65-45.15h14.42c8.09,0,11.59-4.17,11.59-10.11,0-9.56-7.68-18.32-23.32-18.32-13.07,0-23.18,7.68-26.41,20.08l-37.74-8.49c5-31.41,35.31-46.5,66.18-46.5,36.65,0,63.61,19.81,63.61,59.43v67.66a199.07,199.07,0,0,0,1.21,23.72H815.48c-.94-5.66-.94-12.67-.94-18.33l-.41-.13c-11.18,15.76-27.9,21.29-43.93,21.29C744.59,613.68,721.14,597,721.14,569.75Zm90.7-15.91v-3.37H786.1c-8.9,0-21.43,5.26-21.43,16.85,0,10.51,8.76,15,17.92,15C801.46,582.28,811.84,568.53,811.84,553.84Z" />
      <path d="M875.18,584.43A29.85,29.85,0,1,1,905,614.09,29.94,29.94,0,0,1,875.18,584.43Z" />
      <path d="M177,386.05v149c.72,22.57-13.16,39.55-35.32,39.55-20.08,0-36.38-15.23-36.38-37.61s16.3-37.46,36.52-37.46l5,.05a18.23,18.23,0,0,0,10.82-33.05c-.39-.28-.77-.52-1.16-.78a19.45,19.45,0,0,0-4.14-1.9l-.51-.17a62.23,62.23,0,0,0-19.26-3.1C90,460.58,61.6,499.66,61.6,537c0,39.76,27.49,76.69,70.22,76.69,9.83,0,32.07-1.35,45.82-21.56h.54v18.73H220V386.05Z" />
    </svg>
  );
}

type LogoProps = {
  boxSize?: string;
  size?: string;
  logoHref?: string;
};

export function Logo({
  boxSize = "100px",
  size = "100px",
  logoHref = "https://www.dalma.co/",
}: LogoProps) {
  return (
    <a href={logoHref} target="_blank" rel="noopener noreferrer">
      <Flex h={boxSize} overflow="hidden">
        <Center>
          <DalmaLogo size={size} />
        </Center>
      </Flex>
    </a>
  );
}
