export async function generateChecksum(claimFile: File) {
  const arrayBuffer = await claimFile.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer); // hash the message
  return arrayBufferToBase64(hashBuffer);
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
