/* eslint-disable no-restricted-imports */
import { QueryClient, QueryKey, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
// eslint-disable-next-line no-restricted-imports
import { useQuery as useBaseQuery } from "@tanstack/react-query";

export * from "@tanstack/react-query";
export class RequestError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    // default staleTime is 0
  },
});

export const useQuery = <T>(
  queryId: QueryKey,
  query: () => Promise<T>,
  options?: UseQueryOptions<T>
) => {
  const result = useBaseQuery<T>(queryId, query, options);

  // We need this `as` to keep TS discriminated unions from react-query
  return result as UseQueryResult<T, RequestError>;
};
