// Handle banner display with local storage
const getDate24HoursFromNow = () => {
  return new Date().getTime() + 60 * 60 * 24 * 1000; // now + 60 seconds * 60 minutes * 24 hours * 1000 milliseconds
};

export const setBannerClosedLocalStorage = () => {
  const bannerClosedFromLocalStorage = localStorage.getItem("bannerClosed");

  if (!bannerClosedFromLocalStorage) {
    localStorage.setItem(
      "bannerClosed",
      JSON.stringify({
        numberOfTimes: 1,
        expirationDate: getDate24HoursFromNow(),
      })
    );

    return;
  }

  const parsedBannerClosedFromLocalStorage = JSON.parse(bannerClosedFromLocalStorage);

  if (
    typeof parsedBannerClosedFromLocalStorage.numberOfTimes === "number" &&
    typeof parsedBannerClosedFromLocalStorage.expirationDate === "number"
  ) {
    localStorage.setItem(
      "bannerClosed",
      JSON.stringify({
        numberOfTimes: parsedBannerClosedFromLocalStorage.numberOfTimes + 1,
        expirationDate: getDate24HoursFromNow(),
      })
    );
  } else {
    localStorage.setItem(
      "bannerClosed",
      JSON.stringify({
        numberOfTimes: 1,
        expirationDate: getDate24HoursFromNow(),
      })
    );
  }
};

export const shouldBannerStayClosed = () => {
  const bannerClosedFromLocalStorage = localStorage.getItem("bannerClosed");

  if (!bannerClosedFromLocalStorage) {
    return false;
  }

  const parsedBannerClosedFromLocalStorage = JSON.parse(bannerClosedFromLocalStorage);

  // Hide the banner for 24 hours if the user closes it.
  if (parsedBannerClosedFromLocalStorage.expirationDate >= new Date().getTime()) {
    return true;
  }

  // If the user closes the banner 3 times, we don't show it anymore.
  if (
    typeof parsedBannerClosedFromLocalStorage.numberOfTimes === "number" &&
    parsedBannerClosedFromLocalStorage.numberOfTimes >= 3
  ) {
    return true;
  }

  return false;
};
