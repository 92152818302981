import { API, Auth } from "aws-amplify";
import { AllClaims } from "src/types";
import { SetContracts } from "./fetchContracts";
import { SetProfile } from "./fetchProfile";

export function fetchMySpace(
  path: string,
  setFieldValue:
    | SetContracts
    | SetProfile
    | React.Dispatch<React.SetStateAction<AllClaims | undefined>>
) {
  const apiName = "apiMySpaceGateway";

  Auth.currentAuthenticatedUser()
    .then((user) => {
      const token = user.signInUserSession.idToken.jwtToken;
      const myInit = {
        headers: { Authorization: token },
        response: false,
      };

      API.get(apiName, path, myInit)
        .then((response) => {
          // console.log( "API :: ", path, response );
          if (response.success) {
            setFieldValue(response.data);
          } else {
            // alert(response)
          }
        })
        .catch(() => {
          // console.log(error.response);
        });
    })
    .catch(() => {
      // console.log(err);
    });
}
