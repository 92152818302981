import { Icon } from "src/components/01_atoms/icon";
import { Text } from "src/components/01_atoms/text";
import { Tag } from "src/components/02_molecules/tag";
import { formatClaimActCategories, getClaimIcon } from "src/components/03_organisms/claims/_utils";
import { styled, theme } from "src/styles/stitches/theme";
import { Claim_v2 } from "src/types";
import { getFormattedDateWithoutYear } from "src/utils/format";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { useGetContract } from "src/hooks/data";
import { getClaimTag, getContractByPetName } from "./_utils";

const StyledButton = styled("button", {
  textAlign: "initial",
  width: "100%",
  lineHeight: 1,
  transition: "background-color 0.15s ease-out",

  "&:focus": {
    outline: "none",
    backgroundColor: theme.colors.fillBaseHovered,
  },

  "&:hover": {
    backgroundColor: theme.colors.fillBaseHovered,
  },

  "&:active": {
    backgroundColor: theme.colors.fillBasePressed,
  },
});

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 1.5rem",
  cursor: "pointer",
  position: "relative",

  "@bp3": {
    padding: "1rem 1.75rem",
  },

  "&::after": {
    content: "",
    position: "absolute",
    bottom: 0,
    left: "1.5rem",
    right: "1.5rem",
    width: "calc(100% - 3rem)",
    height: 1,
    backgroundColor: theme.colors.borderBaseSubdued,
  },
});

const TextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  marginLeft: "1rem",
  marginRight: "0.75rem",
  minWidth: 0,
});

const StyledTruncatedText = styled(Text, {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const StyledTag = styled(Tag, {
  marginLeft: "auto",
  marginRight: "1rem",
});

type Props = {
  claim: Claim_v2;
};

export function Claim({ claim }: Props) {
  const { openClaimStatusSidebar } = useClaimStatusSidebar();
  const { data } = useGetContract();

  const { claim_acts, date, pet_name } = claim;

  if (!data) return null;

  const contract = getContractByPetName(data, pet_name);

  const petType = contract?.pet.pet_type;

  if (!petType) return null;

  const categories = claim_acts.map(({ actCategory }) => actCategory);

  const tag = getClaimTag(claim);

  return (
    <StyledButton
      type="button"
      onClick={() => {
        openClaimStatusSidebar({
          formattedCategories: formatClaimActCategories(categories),
          petType,
          claim,
        });
      }}
    >
      <Container>
        <Icon name={getClaimIcon(categories)} color="iconBaseSubdued" />
        <TextContainer>
          <StyledTruncatedText variant="paragraph2-Bold" color="textBaseDefault">
            {formatClaimActCategories(categories)}
          </StyledTruncatedText>
          <StyledTruncatedText variant="paragraph2-Medium" color="textBaseSubdued">
            Le {getFormattedDateWithoutYear(date)} pour {pet_name}
          </StyledTruncatedText>
        </TextContainer>
        <StyledTag variant={tag.variant}>{tag.label}</StyledTag>
        <Icon name="chevronRight" color="iconBaseSubdued" />
      </Container>
    </StyledButton>
  );
}
