import { API, Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useQuery } from "src/queryClient";
import { routes } from "src/routes";
import { Contract } from "src/types";
import { reportError } from "src/utils/error";
import { toast } from "src/components/01_atoms/toast";
import { useLogOut } from "../signin/_use-log-out";

async function fetchContracts() {
  const apiName = "apiMySpaceGateway";
  const path = "/contracts";

  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: { Authorization: token },
    response: false,
  };

  const response = await API.get(apiName, path, myInit);

  if (response.success) {
    return response.data as Contract[];
  }

  throw new Error(response.errorMessage);
}

export function useGetContracts() {
  const history = useHistory();
  const logout = useLogOut();
  return useQuery<Contract[] | undefined>(["contract"], fetchContracts, {
    onError: async (err) => {
      reportError(`useContract : ${err}`);
      await logout();
      toast.error(
        "Une erreur est survenue lors du chargement de votre contrat, veuillez vous reconnecter"
      );
      history.push(routes.login);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
}
