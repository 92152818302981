import { useBanner } from "src/hooks/use-banner";
import { styled } from "src/styles/stitches/theme";
import { setBannerClosedLocalStorage } from "src/utils/local-storage";

// We don't use theme nor rem here because we want to be able to use this component in other projects
const Container = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 12,
  borderBottom: "1px solid #DBEBFF",
  background: "#FFFFFF",
  zIndex: 1,
  position: "sticky",
  top: 0,
  width: "100%",

  "@bp3": {
    display: "none",
  },
});

const LeftWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const CloseIconButton = styled("button", {
  cursor: "pointer",
});

const AppIconContainer = styled("div", {
  marginLeft: 4,
  marginRight: 12,
});

const Title = styled("p", {
  fontWeight: 700,
  fontSize: 14,
  lineHeight: 1.43,
  color: "#101531",
});

const Description = styled("p", {
  fontWeight: 500,
  fontSize: 13,
  lineHeight: 1.23,
  color: "#678AB8",
});

const StarsContainer = styled("div", { display: "flex", gap: 1, marginTop: 2 });

const Link = styled("a", {
  fontWeight: 500,
  fontSize: 16,
  lineHeight: 1.25,
  color: "#0074FF",
});

function CloseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5863 12.0001L7.79072 9.20893C7.6028 9.02101 7.49695 8.76585 7.49695 8.50009C7.49695 8.23433 7.60252 7.97946 7.79044 7.79154C7.97836 7.60362 8.23323 7.49805 8.49899 7.49805C8.76475 7.49805 9.01963 7.60362 9.20755 7.79154L11.999 10.5874L14.7902 7.79182C14.9781 7.6039 15.2332 7.49805 15.499 7.49805C15.7648 7.49805 16.0196 7.60362 16.2075 7.79154C16.3955 7.97946 16.501 8.23433 16.501 8.50009C16.501 8.76585 16.3955 9.02073 16.2075 9.20865L13.4117 12.0001L16.2061 14.7901L16.2069 14.7909C16.3002 14.8837 16.3744 14.994 16.425 15.1155C16.4758 15.2374 16.5019 15.3681 16.5019 15.5001C16.5019 15.6321 16.4758 15.7628 16.425 15.8847C16.3745 16.0059 16.3006 16.116 16.2075 16.2086C16.1149 16.3017 16.0048 16.3756 15.8836 16.4261C15.7617 16.4769 15.631 16.503 15.499 16.503C15.367 16.503 15.2363 16.4769 15.1144 16.4261C14.9928 16.3754 14.8824 16.3012 14.7896 16.2078L11.999 13.4128L9.20899 16.2072C9.11615 16.3007 9.00519 16.3754 8.88357 16.4261C8.76171 16.4769 8.631 16.503 8.49899 16.503C8.36698 16.503 8.23628 16.4769 8.11442 16.4261C7.99318 16.3756 7.8831 16.3017 7.79044 16.2086C7.69739 16.116 7.62351 16.0059 7.573 15.8847C7.52223 15.7628 7.49609 15.6321 7.49609 15.5001C7.49609 15.3681 7.52223 15.2374 7.573 15.1155C7.62364 14.994 7.6978 14.8836 7.79119 14.7908L10.5863 12.0001Z"
        fill="#678AB8"
      />
    </svg>
  );
}

function AppIcon() {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2943_14067)">
        <rect width="70" height="70" fill="url(#paint0_radial_2943_14067)" />
        <path
          d="M44.872 46.3547C44.8663 45.4733 45.1232 44.6101 45.6101 43.8745C46.0969 43.139 46.7917 42.5643 47.6064 42.2233C48.4211 41.8823 49.319 41.7904 50.1861 41.9592C51.0532 42.1281 51.8506 42.55 52.477 43.1716C53.1034 43.7932 53.5306 44.5864 53.7045 45.4506C53.8784 46.3148 53.7911 47.2111 53.4537 48.0257C53.1163 48.8403 52.544 49.5366 51.8094 50.0263C51.0747 50.5159 50.2109 50.7769 49.3274 50.776C48.1513 50.7713 47.0244 50.3048 46.1905 49.4773C45.3566 48.6498 44.883 47.528 44.872 46.3547Z"
          fill="url(#paint1_radial_2943_14067)"
        />
        <path
          d="M35.3475 38.9938V16.7832H41.7722V50.2944H35.5239V47.5024H35.4432C33.3888 50.515 30.0659 50.7162 28.5971 50.7162C22.2128 50.7162 18.1055 45.2113 18.1055 39.2845C18.1055 33.7184 22.3488 27.893 28.7092 27.893C29.6868 27.8951 30.658 28.0511 31.5869 28.3551L31.6631 28.3804C31.8797 28.4503 32.0873 28.5454 32.2816 28.6636C32.2983 28.6748 32.3149 28.6856 32.3315 28.6965C32.3726 28.7234 32.4134 28.7501 32.4549 28.7799C32.926 29.1165 33.2772 29.5942 33.4575 30.1435C33.6379 30.6929 33.6379 31.2853 33.4576 31.8347C33.2773 32.3841 32.9262 32.8618 32.4551 33.1984C31.9841 33.535 31.4177 33.713 30.8383 33.7065L30.0913 33.699C27.0702 33.699 24.6347 35.9469 24.6347 39.283C24.6347 42.6191 27.0702 44.8893 30.0703 44.8893C33.3813 44.8893 35.4551 42.3582 35.3475 38.9938Z"
          fill="url(#paint2_radial_2943_14067)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_2943_14067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35) rotate(90) scale(70)"
        >
          <stop stopColor="#4E68F6" />
          <stop offset="1" stopColor="#0B2AD6" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2943_14067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.5007 39.0836) rotate(-90) scale(22.75 23.8834)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_2943_14067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.5007 39.0836) rotate(-90) scale(22.75 23.8834)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </radialGradient>
        <clipPath id="clip0_2943_14067">
          <rect width="70" height="70" rx="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function StarIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0007 4.83483C10.9691 4.74337 10.9117 4.66304 10.8354 4.60358C10.7591 4.54412 10.6671 4.50808 10.5707 4.49983L7.72572 4.08483L6.45072 1.49983C6.40978 1.41529 6.34585 1.344 6.26627 1.29412C6.18668 1.24423 6.09465 1.21777 6.00072 1.21777C5.90679 1.21777 5.81476 1.24423 5.73518 1.29412C5.65559 1.344 5.59166 1.41529 5.55072 1.49983L4.27572 4.07983L1.43072 4.49983C1.33818 4.51298 1.25118 4.55181 1.1796 4.61191C1.10801 4.67201 1.0547 4.75097 1.02572 4.83983C0.999194 4.92667 0.996813 5.01909 1.01884 5.10717C1.04086 5.19526 1.08645 5.27569 1.15072 5.33983L3.21572 7.33983L2.71572 10.1798C2.69787 10.2736 2.70722 10.3705 2.74266 10.4591C2.7781 10.5477 2.83815 10.6243 2.91572 10.6798C2.99133 10.7339 3.08051 10.7658 3.17324 10.772C3.26598 10.7782 3.35861 10.7584 3.44072 10.7148L6.00072 9.37983L8.55072 10.7198C8.62089 10.7594 8.70015 10.7801 8.78072 10.7798C8.88663 10.7802 8.98993 10.7469 9.07572 10.6848C9.15329 10.6293 9.21335 10.5527 9.24879 10.4641C9.28422 10.3755 9.29357 10.2786 9.27572 10.1848L8.77572 7.34483L10.8407 5.34483C10.9129 5.28367 10.9663 5.20328 10.9946 5.113C11.0229 5.02273 11.025 4.92627 11.0007 4.83483Z"
        fill="#FE6F18"
      />
    </svg>
  );
}

export function SmartBanner() {
  const { isClosed, setIsClosed } = useBanner();

  if (isClosed) {
    return null;
  }

  return (
    <Container>
      <LeftWrapper>
        <CloseIconButton
          onClick={() => {
            setIsClosed(true);
            setBannerClosedLocalStorage();
          }}
        >
          <CloseIcon />
        </CloseIconButton>
        <AppIconContainer>
          <AppIcon />
        </AppIconContainer>
        <div>
          <Title>Dalma</Title>
          <Description>La super app...</Description>
          <StarsContainer>
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </StarsContainer>
        </div>
      </LeftWrapper>
      <Link href="https://dalmobile.page.link/fMNh" target="_blank" rel="noreferrer noopener">
        Afficher
      </Link>
    </Container>
  );
}
