import { Button } from "src/components/01_atoms/button";
import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { useGetContact } from "src/hooks/data";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";

const Container = styled(Outlet, {
  padding: theme.spacing.spacing20px,
  background: theme.colors.fillCriticalAccent,
  borderRadius: `0 0 ${theme.radii[24]} ${theme.radii[24]}`,

  "@bp3": {
    padding: theme.spacing.spacing24px,
  },
});

const Title = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",

  "@bp3": {
    flexDirection: "row",
  },
});

const StyledText = styled(Text, {
  textAlign: "center",

  "@bp3": {
    textAlign: "left",
  },
});

const StyledButton = styled(Button, {
  "@bp3": {
    alignSelf: "start",
  },
});

export function RefusedCardSection() {
  const { data } = useGetContact();
  const { setIsRefusedDetailSidebarOpen } = useClaimStatusSidebar();

  if (!data) {
    return null;
  }

  return (
    <Container vertical="spacing8px" align="center">
      <Title>
        <Icon name="filledWarning" color="iconSecondaryDefault" />
        <StyledText variant="paragraph2-Bold" color="textSecondaryDefault">
          Votre demande a bien été traitée : nous ne pouvons malheureusement pas couvrir vos frais
        </StyledText>
      </Title>
      <StyledText variant="paragraph2-Medium" color="textSecondaryDefault">
        Vous avez reçu un mail à {data.email} avec les raisons du refus
      </StyledText>
      <StyledButton
        type="button"
        variant="smallActionSecondary"
        iconRight="arrowRight"
        onClick={() => setIsRefusedDetailSidebarOpen(true)}
      >
        En savoir plus
      </StyledButton>
    </Container>
  );
}
