import { useState, useEffect } from "react";

import { Flex, VStack } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import { Spinner, Center } from "@chakra-ui/react";

import FileIcon from "@iconscout/react-unicons/icons/uil-file-alt";

import { DefaultButton } from "src/components/buttons/Button";
import { Logo } from "src/components/designElements/Logo";
import { Title } from "src/components/texts/Title";
import { Texts } from "src/components/texts/Texts";
import { FilesButton } from "src/components/buttons/FilesButton";
import { PriceCard } from "src/components/cards/PriceCard";
import { InformationsCard } from "src/components/cards/InformationsCard";

import { useParams } from "react-router-dom";

import { fetchQuoteInfo } from "src/backApi/fetchQuoteInfo";

import { petType as petTypeList } from "src/configs/petTypeList";
import { petVarietyList } from "src/configs/petVarietyList";

import { Error503 } from "../Error503";
import dog from "../Error503/dog.png";
import { pWait, track } from "src/utils/utils";
import { clearStorage } from "../Redirect/Hyperassur";
import * as Sentry from "@sentry/minimal";
import { SimpleReferCard } from "src/components/cards/SimpleReferCard";
import { Severity } from "@sentry/react";
import { Contract, QuoteInfo } from "src/types";
import { legal } from "src/configs/Legal";
import { trackWelcomeData } from "src/utils/tracking";
import { useRafOperation } from "src/hooks/use-raf-operation";
import { toast } from "react-toastify";
import { useChristmasCampaign } from "src/hooks/use-christmas-campaign";

const NOT_FOUND = { status: "NOT_FOUND" };
const MAX_HOURS_PAGE_ACCESSIBLE = 6;

function getHeaderWhenPaid(values: QuoteInfo[]) {
  return (
    <>
      <Flex mb="5">
        <Texts textAlign="center">{`Nous sommes ravis de vous accueillir parmi nous !`}</Texts>
      </Flex>
      <Flex mb="5">
        <Texts textAlign="center">
          {`Comptez sur nous pour vous aider à prendre soin de ${getPetNames(
            values
          )} au quotidien.`}
        </Texts>
      </Flex>
      <Flex mb="40px" direction="column">
        <Texts textAlign="center">
          Nous vous avons envoyé un email récapitulatif avec votre contrat en pièce jointe et un
          lien de connexion vers votre espace client.
        </Texts>
        <Texts textAlign="center" mt="25px" color="orange">
          <b>💡 Pas de trace de mail ? Pensez à vérifier dans vos spams.</b>
        </Texts>
      </Flex>
    </>
  );
}

function getHeaderWhenDeferred(values: QuoteInfo[]) {
  return (
    <>
      <Flex mb="5">
        <Texts textAlign="center">{`Nous vous avons envoyé un mail récapitulatif avec un lien de connexion vers votre espace personnel.`}</Texts>
      </Flex>
      <Flex mb="5" direction="column">
        <Texts textAlign="center">
          {`⚠️ Attention, votre inscription n’est pas encore terminée.`}
        </Texts>
        <Texts textAlign="center">
          {`Vous disposez de 1 mois pour renseigner le numéro d’identification de ${getPetNames(
            values
          )} une fois connecté pour que votre contrat prenne effet.`}
        </Texts>
        <Texts textAlign="center">Passé ce délai, votre inscription sera annulée sans frais.</Texts>
      </Flex>
    </>
  );
}

export type OptionsCard = {
  optionName: string;
  optionPrice: string;
};

export function FinalOverview() {
  const petIdx = 0;
  const [values, setValues] = useState<QuoteInfo[]>([]);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [error, setError] = useState<typeof NOT_FOUND | unknown>(null);
  const { isDuringCampaign } = useRafOperation();

  const { quoteId: rawQuoteId } = useParams<{ quoteId?: string }>();
  // Remove the session ID from the quote ID
  const quoteId = rawQuoteId?.split("&")[0];

  useEffect(() => {
    const toastId = toast.loading(
      "Nous préparons votre contrat. Cela peut prendre quelques secondes",
      {
        toastId: "loading",
      }
    );
    clearStorage();
    (async () => {
      try {
        let hasData = false;
        let hasContract = false;
        let retries = 15;
        while (retries-- && !hasContract) {
          if (!quoteId) continue;
          const { data, success }: { data: any; success: boolean } = await new Promise(
            (resolve, reject) => {
              fetchQuoteInfo(quoteId, resolve, reject);
            }
          );
          if (success) {
            setValues(data);
            trackWelcomeData(data);
            if (!hasData) {
              hasData = true;
              sendPaymentStatusToAmplitude(data);
            }

            if (data[0]?.contract_pdf) {
              hasContract = true;
              const acceptDate = new Date(data[0].accept_date);
              const toDayDate = new Date();
              const diffTime = (toDayDate as any) - (acceptDate as any);
              const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

              if (diffHours > MAX_HOURS_PAGE_ACCESSIBLE) {
                setError(NOT_FOUND);
              }
            }
          }
          await pWait(2000);
        }
        if (!hasData) {
          Sentry.captureMessage("No quote found before timeout", { level: Severity.Info });
          setError(NOT_FOUND);
        }
      } catch (error: unknown) {
        setError(error);
      } finally {
        setPdfLoading(false);
        toast.dismiss(toastId);
      }
    })();
  }, [quoteId]);

  function sendPaymentStatusToAmplitude(contracts: Contract["contract"][]) {
    for (const contract of contracts) {
      if (contract.payment_status === "PAID") {
        track({
          event: "amplitude_paid_quote",
          eventType: "Quote is paid",
          eventProperties: {
            quote_id: contract.quote_id,
            pet_idx: contract.pet_idx,
            payment_status: contract.payment_status,
          },
        });
      }
    }
  }

  if (error) {
    if (error === NOT_FOUND) {
      return (
        <Flex alignItems="center" justifyContent="center">
          <VStack
            minHeight="100vh"
            width="1110px"
            maxW="90vw"
            spacing="30px"
            padding={["20px 0 100px", "100px 0"]}
          >
            <Image w="400px" maxW="80%" src={dog} margin="-15% 0 -5%" />
            <Title fontSize="56px" lineHeight="56px">
              Woops !
            </Title>
            <Texts fontSize="18px" lineHeight="28px">
              Impossible de trouver cette souscription
            </Texts>
          </VStack>
        </Flex>
      );
    }
    return <Error503 />;
  }

  return (
    <Flex
      zIndex="9"
      overflow={"auto"}
      position="relative"
      maxW={["100vw", "100vw", "100vw", "100vw"]}
      pt={["50px", "50px", "50px", "80px", "80px"]}
      w={["100%", "100%", "100%", "100vw", "100vw"]}
    >
      <Flex
        display={["none", "none", "none", "flex"]}
        left="25"
        top="-30"
        position="absolute"
        zIndex="999999"
      >
        <Logo boxSize="90px" size="130px" />
      </Flex>

      {!values.length && (
        <Center position="fixed" opacity="0.8" zIndex="999999999" bg="white" h="100vh" w="100vw">
          <Spinner thickness="6px" speed="0.5s" color="#FE6F18" size="xl" />
        </Center>
      )}

      <Flex h="100%" bg="#F5F5F5" position="absolute" w="100%" top={0} zIndex={0} />

      <Flex
        bgImage="url(/assets/media/onboarding.svg)"
        backgroundSize="cover"
        w={["100%", "100%", "100%", "100vw", "100vw"]}
        direction="column"
        zIndex={2}
        h="100%"
        align="center"
      >
        <Flex
          h={["880px", "800px"]}
          bg="white"
          w="100%"
          position="absolute"
          top={0}
          zIndex={1}
        ></Flex>

        <Flex
          mb="110px"
          p="10px"
          mt="42"
          maxW="552px"
          top={0}
          zIndex={1}
          direction="column"
          align="center"
        >
          <Flex mb="5">
            <Title textAlign="center" fontSize={"32px"} color="#FE6F18">
              Bienvenue chez Dalma !
            </Title>
          </Flex>
          {values && values[0] && values[0].payment_status === "PAID" && getHeaderWhenPaid(values)}
          {values &&
            values[0] &&
            values[0].payment_status === "DEFERRED_SUBSCRIPTION" &&
            getHeaderWhenDeferred(values)}
          <Flex>
            <DefaultButton
              onClick={() => window.open("https://app.dalma.co/firstConnection", "_blank")}
              bg="#FE6F18"
              borderWidth={"1px"}
              borderColor={"#E0E0E0"}
              minW={["218px", "218px", "300px", "300px", "300px"]}
              py="15px"
            >
              <Flex minW="100%" align="center" justify="center">
                <Texts color="white" fontWeight="400">
                  {"Me connecter à mon espace client"}
                </Texts>
              </Flex>
            </DefaultButton>
          </Flex>

          <Flex mt="12">
            <SimpleReferCard
              color="secondary"
              title={
                isDuringCampaign
                  ? "50€ pour vous, 1 mois offert pour votre filleul·le."
                  : "25€ pour vous, 1 mois offert pour votre filleul·le."
              }
              description="Connectez vous à votre espace client pour parrainer vos proches etc."
              direction={{
                "@bp1": "row",
                "@initial": "column",
              }}
            />
          </Flex>

          {values?.[petIdx]?.contract_pdf ? (
            <Flex mt="24px">
              <a href={values[petIdx].contract_pdf} target="_blank" rel="noopener noreferrer">
                <Texts textDecoration="underline" color="#9E9E9E" textAlign="center">
                  Télécharger mon contrat
                </Texts>
              </a>
            </Flex>
          ) : values && values[0] && values[0].payment_status === "PAID" && pdfLoading ? (
            <Flex mt="24px">
              <Spinner thickness="2px" speed="0.5s" color="#9E9E9E" size="lg" />
            </Flex>
          ) : null}
        </Flex>

        <Flex p={["10px", "40px", "20px"]} maxW="900px" h="100%" direction="column" zIndex={2}>
          <Flex justify="center" w="100%">
            <Flex align="center" mt="25px" mb="51px" direction="row">
              <Title textAlign="center" fontSize="32px" mr="1">
                {"Récapitulatif de l'offre"}
              </Title>
            </Flex>
          </Flex>

          <Flex direction={["column", "column", "row"]}>
            <Flex direction="column">
              {values.map((value, petIdx) => (
                <Flex key={petIdx}>
                  <PriceCard
                    zIndex="1"
                    minW={["100%", "100%", "300px", "360px", "360px"]}
                    mr={["5", "5", "5", "10", "10"]}
                    cardType="basket"
                    formulaName={
                      value.accepted_formula === "formula_dalma"
                        ? "DALMA"
                        : value.accepted_formula === "accident"
                        ? "Essentielle"
                        : "Intégrale"
                    }
                    price={value.price}
                    strikeOutPrice={value.original_price}
                    promotionApplicated={value.discount_value}
                    formulaPrice={value.price}
                    options={value.optionsCard}
                    rate={value.options.rate}
                    health_limit={value.coverage.health_limit}
                    cardTitle={`Votre prix pour ${value.pet_name}`}
                    mx="0"
                  />
                </Flex>
              ))}
            </Flex>

            <Flex flex={1} minW="300px" maxW="600px" direction="column">
              <Flex mb="16px">
                <InformationsCard
                  minW={["100%", "100%", "300px", "360px", "360px"]}
                  title={"Vos informations"}
                  informations={[
                    {
                      name: "Nom",
                      value:
                        values &&
                        values[petIdx] &&
                        [values[petIdx].owner_firstname, values[petIdx].owner_lastname].join(" "),
                    },
                    {
                      name: "Email",
                      value: values && values[petIdx] && values[petIdx].owner_email,
                    },
                    {
                      name: "Téléphone",
                      value: values && values[petIdx] && values[petIdx].owner_phone,
                    },
                    {
                      name: "Date de naissance",
                      value: values && values[petIdx] && values[petIdx].owner_birthday,
                    },
                    {
                      name: "Adresse",
                      value:
                        values &&
                        values[petIdx] &&
                        values[petIdx].address &&
                        [values[petIdx].address.street_nb, values[petIdx].address.street_name].join(
                          " "
                        ),
                    },
                    {
                      name: "Ville",
                      value:
                        values &&
                        values[petIdx] &&
                        values[petIdx].address &&
                        values[petIdx].address.city,
                    },
                    {
                      name: "Code postal",
                      value:
                        values &&
                        values[petIdx] &&
                        values[petIdx].address &&
                        values[petIdx].address.zipcode,
                    },
                  ]}
                />
              </Flex>
              {values.map((value, petIdx) => (
                <Flex mb="16px" key={petIdx}>
                  <InformationsCard
                    minW={["100%", "100%", "300px", "360px", "360px"]}
                    title={"Informations de votre animal"}
                    informations={[
                      { name: "Espèce", value: petType(value) },
                      { name: "Nom", value: value.pet_name },
                      { name: "Race", value: petVariety(value) },
                      { name: "Date de naissance", value: value.pet_birthday },
                    ]}
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          p={["10px", "40px", "20px"]}
          bg="#FAFAFA"
          direction="column"
          w="100%"
          h="436px"
          pb="0"
        >
          <Flex mt="85px" justify="center">
            <Flex>
              <Title textAlign="center" fontSize={"32px"} color="#212121">
                Autre documents
              </Title>
            </Flex>
          </Flex>

          <Flex
            mt="51px"
            justifyContent="center"
            align="center"
            direction={["column", "column", "row", "row"]}
          >
            <a download rel="noopener noreferrer" target="_blank" href={legal.v8.ipid}>
              <Flex mr="5px" minW={["100%", "100%", "300px", "360px", "360px"]}>
                <FilesButton
                  downloadable
                  title="Fiche IPID"
                  description="PDF - 232ko"
                  icon={<FileIcon color="#4E68F6" />}
                  iconBackground="#DBEBFF"
                />
              </Flex>
            </a>

            <a download rel="noopener noreferrer" target="_blank" href={legal.v8.fic}>
              <Flex ml="5px" minW={["100%", "100%", "300px", "360px", "360px"]}>
                <FilesButton
                  downloadable
                  title="FIC + NI"
                  description="PDF - 171ko"
                  icon={<FileIcon color="#4E68F6" />}
                  iconBackground="#DBEBFF"
                />
              </Flex>
            </a>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function getPetNames(values: QuoteInfo[]) {
  const petNames = values.map((v) => v.pet_name);
  const last = petNames.slice(-1)[0];
  const rest = petNames.slice(0, -1).join(", ");
  return [rest, last].filter(Boolean).join(" et ");
}

function petType(value: QuoteInfo) {
  if (value.pet_type)
    return (
      value.pet_type && petTypeList && petTypeList.find((p) => p.value === value.pet_type)?.label
    );

  return null;
}

function petVariety(value: QuoteInfo) {
  if (value.pet_race)
    return (
      value.pet_race &&
      petVarietyList &&
      petVarietyList[value.pet_type]?.find((p) => p.key === value.pet_race)?.label
    );
  return null;
}
