import { Icon } from "src/components/01_atoms/icon";
import { Skeleton } from "src/components/01_atoms/skeleton/skeleton";
import { styled, theme } from "src/styles/stitches/theme";

const Container = styled("div", {
  width: "100%",
  padding: `0 ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: "0",
  },
});

const Claim = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  paddingLeft: theme.spacing.spacing24px,
});

const ClaimText = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.spacing.spacing24px,
  flex: 1,
});

const ClaimBadge = styled("div", {
  display: "none",
  gap: "0.75rem",
  paddingRight: theme.spacing.spacing12px,

  "@bp3": {
    display: "flex",
  },
});

const ClaimLoading = () => {
  const textGradient =
    "linear-gradient(146deg, rgba(79, 104, 246, 0.4) 0%, rgba(79, 104, 246, 0.2) 20%, rgba(255, 255, 255, 0.5) 110%)";

  return (
    <Container>
      <Skeleton width="100%" height="4.5rem" borderRadius="0.375rem" margin="0px 0px 1.25rem 0px">
        <Claim>
          <Skeleton
            width="2.5rem"
            height="2.5rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <ClaimText>
            <Skeleton
              width="7.5rem"
              height="1.25rem"
              borderRadius="0.375rem"
              margin="0px 0px 0.625rem 0px"
              backgroundImage={textGradient}
            />
            <Skeleton
              width="14.75rem"
              height="1.25rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </ClaimText>
          <ClaimBadge>
            <Skeleton
              width="4.125rem"
              height="1.563rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
            <Icon name="chevronRight" color="iconBaseSubdued" />
          </ClaimBadge>
        </Claim>
      </Skeleton>
      <Skeleton width="100%" height="4.5rem" borderRadius="0.375rem" margin="0px 0px 1.25rem 0px">
        <Claim>
          <Skeleton
            width="2.5rem"
            height="2.5rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <ClaimText>
            <Skeleton
              width="7.5rem"
              height="1.25rem"
              borderRadius="0.375rem"
              margin="0px 0px 0.625rem 0px"
              backgroundImage={textGradient}
            />
            <Skeleton
              width="14.75rem"
              height="1.25rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </ClaimText>
          <ClaimBadge>
            <Skeleton
              width="4.125rem"
              height="1.563rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
            <Icon name="chevronRight" color="iconBaseSubdued" />
          </ClaimBadge>
        </Claim>
      </Skeleton>
      <Skeleton width="100%" height="4.5rem" borderRadius="0.375rem" margin="0px 0px 1.25rem 0px">
        <Claim>
          <Skeleton
            width="2.5rem"
            height="2.5rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <ClaimText>
            <Skeleton
              width="7.5rem"
              height="1.25rem"
              borderRadius="0.375rem"
              margin="0px 0px 0.625rem 0px"
              backgroundImage={textGradient}
            />
            <Skeleton
              width="14.75rem"
              height="1.25rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </ClaimText>
          <ClaimBadge>
            <Skeleton
              width="4.125rem"
              height="1.563rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
            <Icon name="chevronRight" color="iconBaseSubdued" />
          </ClaimBadge>
        </Claim>
      </Skeleton>
      <Skeleton width="100%" height="4.5rem" borderRadius="0.375rem">
        <Claim>
          <Skeleton
            width="2.5rem"
            height="2.5rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <ClaimText>
            <Skeleton
              width="7.5rem"
              height="1.25rem"
              borderRadius="0.375rem"
              margin="0px 0px 0.625rem 0px"
              backgroundImage={textGradient}
            />
            <Skeleton
              width="14.75rem"
              height="1.25rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </ClaimText>
          <ClaimBadge>
            <Skeleton
              width="4.125rem"
              height="1.563rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
            <Icon name="chevronRight" color="iconBaseSubdued" />
          </ClaimBadge>
        </Claim>
      </Skeleton>
    </Container>
  );
};

export { ClaimLoading };
