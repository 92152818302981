import { AnchorButton, Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { MobileDrawerLayout } from "src/components/04_layouts/mobile-drawer-layout";
import { track } from "src/utils/utils";
import { StyledImg, StyledOutlet } from "./index.styled";
import { appDownloadLink } from "src/configs/external-links";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  description: string;
  withSecondButton?: boolean;
};

export function DownloadAppDrawer({
  isVisible,
  onClose,
  title,
  description,
  withSecondButton,
}: Props) {
  return (
    <MobileDrawerLayout isVisible={isVisible} onClose={onClose}>
      <Outlet vertical="spacing16px" align="center">
        <StyledImg
          src="/assets/media/vet-advice.svg"
          alt="Illustration d'une vétérinaire dans un téléphone"
        />
        <Outlet vertical="spacing8px" align="center">
          <Text variant="display4-Bold" color="textBaseDefault" textAlign="center">
            {title}
          </Text>
          <Text variant="paragraph2-Medium" color="textBaseSubdued" textAlign="center">
            {description}
          </Text>
        </Outlet>
        <StyledOutlet vertical="spacing16px" fluid>
          <AnchorButton
            variant="accent"
            size={{ "@initial": "md", "@bp3": "lg" }}
            fluid
            href={appDownloadLink}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
              onClose();
              track({
                event: "amplitude_click_download_app",
                eventType: "clickDownloadApp",
                eventProperties: {
                  url: window.location.href,
                },
              });
            }}
          >
            Télécharger l’application
          </AnchorButton>
          {withSecondButton ? (
            <Button
              variant="secondary"
              size={{ "@initial": "md", "@bp3": "lg" }}
              fluid
              onClick={onClose}
            >
              Non merci
            </Button>
          ) : null}
        </StyledOutlet>
      </Outlet>
    </MobileDrawerLayout>
  );
}
