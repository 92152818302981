import { VariantProps } from "@stitches/react";
import { deprecatedStyled } from "src/styles/stitches/deprecated-theme";
import { WithChildren } from "src/types";

const StyledTitle = deprecatedStyled("h1", {
  fontFamily: "Roslindale",
  fontWeight: 700,
  margin: 0,

  "& .block": {
    display: "block",
  },

  variants: {
    level: {
      h1: {
        fontSize: 36,
        lineHeight: 1.22,
      },
      h2: {
        fontSize: 24,
        lineHeight: 1.33,
      },
      h3: {
        fontSize: 20,
        lineHeight: 1.5,
      },
    },
    center: {
      true: {
        textAlign: "center",
      },
      false: {
        textAlign: "left",
      },
    },
  },
  defaultVariants: {
    level: "h1",
  },
});

type HLevel = "h1" | "h2" | "h3";

type Props = WithChildren<
  {
    as: HLevel;
  } & VariantProps<typeof StyledTitle>
>;

export function Title({ as, level, children, ...rest }: Props) {
  return (
    <StyledTitle as={as} level={level} {...rest}>
      {children}
    </StyledTitle>
  );
}
