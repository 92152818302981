import { SubmitHandler, useForm } from "react-hook-form";
import { Outlet } from "src/components/01_atoms/outlet";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { styled, theme } from "src/styles/stitches/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { CheckboxCard } from "src/components/02_molecules/selectable-card";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { Button } from "src/components/01_atoms/button";
import { FilledNames, Icon } from "src/components/01_atoms/icon";
import { ClaimConsultationType } from "src/types";
import { getContractByPetName } from "src/components/02_molecules/claim/_utils";
import { useGetContract } from "src/hooks/data";
import { Text } from "src/components/01_atoms/text";
import { AlertDrawer } from "src/components/02_molecules/alert-drawer";
import { AlertModal } from "src/components/02_molecules/alert-modal";
import { useState } from "react";

const FBE_URL = "https://intercom.help/dalma/fr/articles/6145270-le-forfait-bien-etre-c-est-quoi";

const Form = styled("form", {
  flex: 1,
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing36px}`,
  },
});

const consultationTypes: { value: ClaimConsultationType; label: string; iconName: FilledNames }[] =
  [
    { value: "ACCIDENT", label: "Accident", iconName: "filledAccident" },
    { value: "SICKNESS", label: "Maladie", iconName: "filledMaladie" },
    { value: "PREVENTION", label: "Prévention", iconName: "filledPrevention" },
  ];

const consultationTypesSchema = z.object({
  consultation_type: z
    .string()
    .array()
    .nonempty({ message: "Veuillez sélectionner au moins un type de soin." }),
});

export function ConsultationTypesForm() {
  const {
    currentStep,
    selectedPetToContext,
    setCurrentStep,
    selectedConsultationTypes,
    setSelectedConsultationTypes,
  } = useRefundSidebar();

  type ConsultationTypes = {
    consultation_type: ClaimConsultationType[];
  };
  const [isFBEModalOpen, setIsFBEModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<ConsultationTypes>({
    resolver: zodResolver(consultationTypesSchema),
    mode: "onChange",
    defaultValues: {
      consultation_type: selectedConsultationTypes,
    },
  });

  const { data } = useGetContract();
  if (!data || !selectedPetToContext) return null;

  const contract = getContractByPetName(data, selectedPetToContext.pet_name);
  const hasPrevention = Number.parseInt(contract?.coverage.prevention_limit ?? "0") > 0;

  const onSubmit: SubmitHandler<ConsultationTypes> = (values) => {
    setSelectedConsultationTypes(values.consultation_type);
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledOutlet vertical={{ "@initial": "spacing32px", "@bp3": "0" }} fullHeight>
          <SidesheetContentHeader
            title="Quelle est la nature du soin&nbsp;?"
            subtitle="Demande de remboursement"
            description="Cette information se trouve sur votre facture et feuille de soins. Vous pouvez choisir plusieurs types de soins en même temps&nbsp;!"
          />
          <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
            <Outlet vertical="spacing16px" fluid>
              {consultationTypes.map((type) => (
                <CheckboxCard
                  key={type.value}
                  title={type.label}
                  iconName={type.iconName}
                  value={type.value}
                  disabled={type.value === "PREVENTION" && !hasPrevention}
                  checked={watch("consultation_type").includes(type.value)}
                  {...register("consultation_type")}
                  infoDisabled={
                    <div
                      onClick={() => setIsFBEModalOpen(true)}
                      onKeyPress={() => setIsFBEModalOpen(true)}
                      role="button"
                      tabIndex={0}
                    >
                      <Outlet horizontal="spacing8px" fluid align="center" justify="spaceBetween">
                        <Outlet horizontal="spacing4px">
                          <Icon name="filledInfo" color={"iconPrimaryMuted"} />
                        </Outlet>
                        <Outlet vertical="spacing2px">
                          <Text variant="paragraph3-Bold" color="textBaseDefault">
                            Vous ne disposez pas du forfait bien-être
                          </Text>
                        </Outlet>
                        <Icon name="chevronRight" color={"iconPrimaryMuted"} />
                      </Outlet>{" "}
                    </div>
                  }
                />
              ))}
            </Outlet>
            <Button type="submit" variant="accent" size="lg" fluid disabled={!isValid}>
              Continuer
            </Button>
          </Outlet>
        </StyledOutlet>
      </Form>
      <AlertModal
        isOpen={isFBEModalOpen}
        label="Modale d'alerte du forfait bien-être"
        title="Vous ne disposez pas du forfait bien-être"
        description="Les frais de prévention sont uniquement remboursés dans le cadre du forfait bien-être, auquel vous n'avez pas souscrit. Vous pourrez ajouter cette option avant le renouvellement de votre contrat en contactant notre service client."
        onDismiss={() => {
          setIsFBEModalOpen(false);
        }}
        buttonText1="J'ai compris"
        onDismissButton1={() => {
          setIsFBEModalOpen(false);
        }}
        buttonText2="Qu'est-ce que le forfait bien-être ?"
        onDismissButton2={() => {
          window.open(FBE_URL, "_blank", "noreferrer")?.focus();
          setIsFBEModalOpen(false);
        }}
      />
      <AlertDrawer
        isOpen={isFBEModalOpen}
        title="Vous ne disposez pas du forfait bien-être"
        description="Les frais de prévention sont uniquement remboursés dans le cadre du forfait bien-être, auquel vous n'avez pas souscrit. Vous pourrez ajouter cette option avant le renouvellement de votre contrat en contactant notre service client."
        onClose={() => {
          setIsFBEModalOpen(false);
        }}
        buttonText1="J'ai compris"
        onCloseButton1={() => {
          setIsFBEModalOpen(false);
        }}
        buttonText2="Qu'est-ce que le forfait bien-être ?"
        onCloseButton2={() => {
          window.open(FBE_URL, "_blank", "noreferrer")?.focus();
          setIsFBEModalOpen(false);
        }}
      />
    </>
  );
}
