import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "src/components/01_atoms/button";
import { Input } from "src/components/01_atoms/input";
import { Link } from "src/components/01_atoms/link";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { toast } from "src/components/01_atoms/toast";
import { useForgotPassword } from "src/hooks/signin/use-forgot-password";
import { z } from "zod";

interface ForgotPassword {
  email: string;
}

const forgotPasswordSchema = z.object({
  email: z.string().email("Email invalide"),
});

type ForgotPasswordFormProps = {
  nextPage: string;
};

export function ForgotPasswordForm({ nextPage }: ForgotPasswordFormProps) {
  const {
    handleSubmit,
    register,
    setFocus,
    formState: { errors },
  } = useForm<ForgotPassword>({
    resolver: zodResolver(forgotPasswordSchema),
    reValidateMode: "onSubmit",
  });

  const { sendForgotPasswordEmail, isLoading } = useForgotPassword({ nextPage });
  const onSubmit: SubmitHandler<ForgotPassword> = async (data) => {
    sendForgotPasswordEmail(data.email);
  };

  // Show error toast if there is an error with the validation
  useEffect(() => {
    if (errors?.email) {
      toast.error(errors?.email?.message);
    }
  }, [errors]);

  // Set the email input focus on first mount
  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Outlet vertical="spacing48px" fluid>
        <Outlet vertical="spacing32px" fluid>
          <Outlet vertical="spacing8px" fluid>
            <Text as="h1" variant="display4-Bold">
              Mot de passe oublié
            </Text>
            <Text variant="paragraph1-Medium" color="textBaseSubdued">
              Vous avez oublié votre mot de passe ? Aucun problème, indiquez nous l’adresse à
              laquelle envoyer un code de vérification.
            </Text>
            <Link
              size="sm"
              color="accent"
              title="Article de la FAQ pour comprendre comment se connecter"
              href="https://intercom.help/dalma/fr/articles/6224498-comment-me-connecter"
              target="_blank"
            >
              Comment se connecter ?
            </Link>
          </Outlet>
          <Input
            type="email"
            placeholder="Email"
            critical={!!errors.email}
            errorMessage={errors.email?.message}
            {...register("email")}
          />
        </Outlet>
        <Button
          variant="accent"
          size="lg"
          fluid
          disabled={isLoading}
          loading={isLoading}
          type="submit"
        >
          Envoyer le code
        </Button>
      </Outlet>
    </form>
  );
}
