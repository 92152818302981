import { Contract } from "src/types";

export const matchIsAccidentAndDiseaseCategory = (categories: Array<string>) => {
  return categories.includes("ACCIDENT") && categories.includes("SICKNESS");
};

export const matchIsAccidentAndPreventionCategory = (categories: Array<string>) => {
  return categories.includes("ACCIDENT") && categories.includes("PREVENTION");
};

export const matchIsDiseaseAndPreventionCategory = (categories: Array<string>) => {
  return categories.every((categories) => categories === "SICKNESS" || categories === "PREVENTION");
};
export const matchIsPreventionCategory = (categories: Array<string>) => {
  return categories.length > 0
    ? categories.every((categories) => categories === "PREVENTION")
    : false;
};
export const matchIsAccidentCategory = (categories: Array<string>) => {
  return categories.length > 0
    ? categories.every((categories) => categories === "ACCIDENT")
    : false;
};

export const matchIsDiseaseCategory = (categories: Array<string>) => {
  return categories.length > 0
    ? categories.every((categories) => categories === "SICKNESS")
    : false;
};

export const matchIsAllCategories = (category: Array<string>) => {
  return (
    category.includes("ACCIDENT") &&
    category.includes("SICKNESS") &&
    category.includes("PREVENTION")
  );
};

export const matchIsAccidentClaimCategory = (claimCategory: string) => claimCategory === "ACCIDENT";

export const matchIsAllContractsDeffered = (contracts: Contract[]): boolean => {
  return contracts.every((contract) => contract.contract.status === "pending_activation");
};

export const matchIsContractDeffered = (contract: Contract): boolean => {
  return contract.contract.status === "pending_activation";
};

export const anyContractDeffered = (contracts: Contract[]): boolean => {
  contracts.some((contract) => contract.contract.status === "pending_activation");
  return true;
};
