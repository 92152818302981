import { ComponentProps } from "@stitches/react";
import { styled } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";
import { Icon, IconProps } from "../icon";
import { _Button } from "./_button";

export const Span = styled("span", {
  display: "block",
  position: "relative",
  marginTop: "1px",
});

type InnerButtonProps = WithChildren<Pick<_Button, "iconLeft" | "iconRight" | "size">>;

export function InnerButton({ iconLeft, iconRight, size, children }: InnerButtonProps) {
  const iconSize = getIconSize<_Button["size"]>(size, 24, (value) => {
    return value === "xs" ? 16 : value === "sm" ? 20 : 24;
  });

  return (
    <>
      {iconLeft && <Icon name={iconLeft} size={iconSize} />}
      <Span>{children}</Span>
      {iconRight && <Icon name={iconRight} />}
    </>
  );
}

// This is a workaround for transforming a size "sm" | "md" | "lg" into a size "16" | "20" | "24" with stitches responisve styling object
type StitchesType = string | number | Object | undefined;
type Extract<T, U> = T extends U ? T : never;
type IconSize = Extract<ComponentProps<typeof Icon>["size"], number | string>;
type ValueOf<V extends StitchesType> = Extract<V, number | string> | undefined;

export function getIconSize<T extends StitchesType>(
  size: T,
  defaultSize: IconSize,
  refine: (v: ValueOf<T>) => IconSize
): IconProps["size"] {
  let iconSize: ComponentProps<typeof Icon>["size"] = defaultSize;

  if (typeof size === "string" || typeof size === "number") {
    iconSize = refine(size as ValueOf<T>);
  }

  if (typeof size === "object") {
    Object.entries(size).forEach(([key, value]) => {
      iconSize =
        typeof iconSize === "object"
          ? { ...iconSize, [key]: refine(value) }
          : { [key]: refine(value) };
    });
  }

  return iconSize;
}
