import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../01_atoms/button";
import { Input } from "../../01_atoms/input";
import { Outlet } from "../../01_atoms/outlet";
import { InputPasswordWithCriteria } from "./input-password-with-criterias";
import { Text } from "src/components/01_atoms/text";
import { useFirstSignIn } from "src/hooks/signin";
import {
  newPasswordCriterias,
  PasswordCheck,
  passwordCriteriaErrors,
  PasswordCriteriasChecker,
} from "./_utils";
import { toast } from "src/components/01_atoms/toast";

// zod
interface FirstConnection {
  email: string;
  password: string;
  newPassword: string;
}

const firstConnectionSchema = z.object({
  email: z.string().email("Email invalide"),
  password: z.string().min(8, passwordCriteriaErrors.tooShort),
  // Refine in Zod to check if the password is valid and return an error message if not
  newPassword: z.string().refine(
    (val) => PasswordCheck(val).length === 0,
    (val) => ({ message: PasswordCheck(val)[0] })
  ),
});

// for prefilled fields
type HistoryState = {
  email?: string;
  tmpPassword?: string;
};

type FirstConnectionFormProps = {
  nextPage: string;
};

export function FirstConnectionForm({ nextPage }: FirstConnectionFormProps) {
  const { isLoading, signIn } = useFirstSignIn({ nextPage });
  const history = useHistory<HistoryState>();
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FirstConnection>({
    resolver: zodResolver(firstConnectionSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      newPassword: "",
    },
  });

  // Get URL params
  // Waiting for React router V6 to give us a hook
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email") || history.location.state?.email || "";
  const tmpPwd = searchParams.get("tmpPwd") || history.location.state?.tmpPassword || "";

  const onSubmit: SubmitHandler<FirstConnection> = async (data) => {
    const error = await signIn(data);
    // Make the inputs in critical state if there is an error from the server
    if (error) {
      error.fields.forEach((field) => {
        setError(field, {
          type: "manual",
          message: error.message,
        });
      });
    }
  };

  // Show error toast if there is an error with the validation
  useEffect(() => {
    if (errors?.email || errors?.password || errors?.newPassword) {
      toast.error(
        errors?.email?.message || errors?.password?.message || errors?.newPassword?.message
      );
    }
  }, [errors]);

  // Set the email input focus on first mount
  useEffect(() => {
    setFocus("newPassword");
  }, [setFocus]);

  useEffect(() => {
    email && setValue("email", email);
    tmpPwd && setValue("password", tmpPwd);
  }, [email, tmpPwd, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Outlet vertical="spacing48px">
        <Outlet vertical="spacing32px">
          <Outlet vertical="spacing8px">
            <Text variant="display4-Bold">Nouveau mot de passe</Text>
            <Text variant="paragraph1-Medium" color="textBaseSubdued">
              Créez un nouveau mot de passe, il remplacera le mot de passe temporaire.
            </Text>
          </Outlet>
          <Outlet vertical="spacing12px" fluid>
            <Input
              placeholder="Email"
              type="email"
              critical={!!errors.email}
              errorMessage={errors.email?.message}
              {...register("email")}
            />
            <Input
              placeholder="Mot de passe temporaire"
              type="password"
              critical={!!errors.password}
              errorMessage={errors.password?.message}
              {...register("password")}
            />
            <InputPasswordWithCriteria
              criterias={newPasswordCriterias}
              criteriasChecker={PasswordCheck as PasswordCriteriasChecker}
              placeholder="Nouveau mot de passe"
              type="password"
              critical={!!errors.newPassword}
              errorMessage={errors.newPassword?.message}
              {...register("newPassword")}
              value={watch("newPassword")}
            />
          </Outlet>
        </Outlet>
        <Button size="lg" variant="accent" fluid disabled={isLoading} type="submit">
          C&apos;est parti !
        </Button>
      </Outlet>
    </form>
  );
}
