import { baseColors, styled, theme } from "src/styles/stitches/theme";

export const Avatar = styled("div", {
  borderRadius: theme.radii[12],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "box-shadow 0.15s ease-out",
  boxShadow: `0px 0px 0px 1px rgba(${baseColors.neutral400}, 0)`,
  flex: "0 0 auto",

  variants: {
    size: {
      md: {
        width: "2rem",
        height: "2rem",
      },
      lg: {
        width: "2.5rem",
        height: "2.5rem",
      },
      xl: {
        width: "3.5rem",
        height: "3.5rem",
      },
    },
    color: {
      base: {
        backgroundColor: theme.colors.fillBaseSubdued,
      },
      secondary: {
        backgroundColor: theme.colors.fillSecondary,
      },
    },
    withHover: {
      true: {
        "&:hover": {
          boxShadow: `0px 0px 0px 1px rgba(${baseColors.neutral400}, 1)`,
        },
      },
    },
  },

  defaultVariants: {
    size: "lg",
    color: "base",
    withHover: true,
  },
});
