import { Divider, Flex, Center } from "@chakra-ui/react";
import Times from "@iconscout/react-unicons/icons/uil-times";
import { Button } from "src/components/01_atoms/button";
import { Input } from "src/components/01_atoms/input";
import { Outlet } from "src/components/01_atoms/outlet";
import { SmallInformationsCard } from "../../components/cards/SmallInformationsCard";
import { Title } from "../../components/texts/Title";
import { z } from "zod";
import { ProfileMenu } from "../../components/menus/ProfileMenu";

import { useProfile } from "src/hooks/useProfile";
import { useHistory } from "react-router-dom";
import { PaymentResponse, Sepa } from "src/backApi/fetchPaymentMethods";
import { Texts } from "src/components/texts/Texts";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateIban } from "src/backApi/updateIban";
import { styled } from "src/styles/stitches/theme";
import { Text } from "src/components/01_atoms/text";
import { toast } from "src/components/01_atoms/toast";
import { Icon } from "src/components/01_atoms/icon";
import { InlineHint } from "src/components/01_atoms/inline-hint";
import { IBAN_REGEX } from "src/utils/regexp";

export function Profile() {
  const history = useHistory();

  const { profile, paymentMethod, initializePaymentMethods } = useProfile();

  return (
    <Center minH="100vh" mt={["50px", "50px", 0]} p={["0", "0", "100px", "100px", "156px"]}>
      <Center
        cursor="pointer"
        onClick={() => history.replace("/")}
        bg="#DBEBFF"
        w="44px"
        h="44px"
        position="absolute"
        right="5vw"
        top="5vh"
        rounded="full"
      >
        <Times color="#4E68F6" size="25px" />
      </Center>

      <Flex w={["90%", "90%", "100%"]}>
        <ProfileMenu profileState="profile" />

        <Flex flex={1} maxW="90vw" direction="column">
          <Flex mb="32px">
            <Title size="24px">
              {profile &&
                [
                  profile.firstname.charAt(0).toUpperCase() + profile.firstname.slice(1),
                  profile.lastname.charAt(0).toUpperCase() + profile.lastname.slice(1),
                ].join(" ")}{" "}
            </Title>
          </Flex>

          <Flex direction="column">
            <Flex direction={["column", "row"]} mb="24px">
              <SmallInformationsCard title={"N° de téléphone"} value={profile && profile.phone} />
              <SmallInformationsCard
                mt={["24px", 0]}
                title={"Email"}
                value={profile && profile.email}
              />
            </Flex>

            <Flex direction={["column"]}>
              <Flex direction={["column", "row"]} mb="24px">
                <SmallInformationsCard
                  title={"Date de naissance"}
                  value={profile && profile.owner_birthday}
                />
                <SmallInformationsCard
                  mt={["24px", 0]}
                  title={"Lieu de naissance"}
                  value={profile && profile.owner_birthcity}
                />
              </Flex>
              <SmallInformationsCard
                title={"Adresse"}
                value={
                  profile &&
                  profile.address &&
                  profile.address.street_nb +
                    " " +
                    profile.address.street_name +
                    ", " +
                    profile.address.zipcode +
                    " " +
                    profile.address.city +
                    ", " +
                    profile.address.country
                }
              />
            </Flex>
          </Flex>
          {paymentMethod ? (
            <PaymentMethod
              paymentMethod={paymentMethod}
              initializePaymentMethods={initializePaymentMethods}
              email={profile?.email}
            />
          ) : null}
          <Divider mt={["32px", "53px"]} mb={["32px", "51px"]} />
        </Flex>
      </Flex>
    </Center>
  );
}

type PaymentMethodProps = {
  paymentMethod: PaymentResponse;
  initializePaymentMethods: () => void;
  email: string | undefined;
};

export const formatIban = (iban: Sepa) => {
  return `•••• ${iban.last4}`;
};

type Iban = {
  iban: string;
};

const ibanSchema = z.object({
  iban: z.string().refine((iban) => {
    return IBAN_REGEX.test(iban.replaceAll(" ", ""));
  }),
});

export const StyledIbanInputLabel = styled(Text, {
  display: "none",

  "@bp3": {
    display: "block",
  },
});

function PaymentMethod({ paymentMethod, initializePaymentMethods, email }: PaymentMethodProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<Iban>({
    resolver: zodResolver(ibanSchema),
    defaultValues: {
      iban: "",
    },
  });

  const onSubmit: SubmitHandler<Iban> = async (values) => {
    setIsLoading(true);
    await updateIban(values.iban.replaceAll(" ", ""), () => {
      setTimeout(async () => {
        await initializePaymentMethods();
        setIsLoading(false);
        toast.success(
          `La modification a bien été enregistrée.\n Nous vous avons envoyé un mail de confirmation à l’adresse ${email}`
        );
        setIsEditing(false);
      }, 1000);
    });
  };

  return (
    <>
      <Divider mt={["32px", "53px"]} mb={["32px", "51px"]} />
      <Flex mb="32px" alignItems="center">
        <Title size="24px">Mes IBAN</Title>
        {isEditing ? null : (
          <Texts
            fontSize="16px"
            color="#4E68F6"
            cursor="pointer"
            ml="8px"
            onClick={() => setIsEditing(true)}
          >
            Modifier
          </Texts>
        )}
      </Flex>
      <Flex direction="column">
        <Flex mb="22px" direction="column">
          {isEditing ? (
            <Flex mb="2px" direction="column">
              <Texts color="#9E9E9E">
                La modification s’appliquera aux prélèvements et remboursements.
              </Texts>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Outlet
                  vertical="spacing32px"
                  justify={{ "@bp3": "spaceBetween" }}
                  fullHeight
                  fluid
                >
                  <Outlet vertical="spacing8px" fluid>
                    <StyledIbanInputLabel variant="caption1-Medium" color="textBaseSubdued">
                      IBAN
                    </StyledIbanInputLabel>
                    <Input
                      placeholder="FR7500000000000000000000000"
                      {...register("iban")}
                      value={watch("iban")}
                      onChange={(event) => setValue("iban", event.target.value)}
                      critical={Boolean(errors.iban)}
                    />
                  </Outlet>
                  {errors.iban ? (
                    <InlineHint color="textCritical" leftIcon={<Icon name="filledClose" />}>
                      Le format de l’IBAN n’est pas correct
                    </InlineHint>
                  ) : null}
                  <Outlet vertical="spacing16px" fluid>
                    <Button
                      type="submit"
                      variant="accent"
                      size={{ "@initial": "md", "@bp3": "lg" }}
                      loading={isLoading}
                      fluid
                    >
                      Enregistrer les modifications
                    </Button>
                  </Outlet>
                </Outlet>
              </form>
            </Flex>
          ) : (
            <Flex mb="2px" direction="column">
              {paymentMethod.selected_payment.default ? (
                <SmallInformationsCard
                  title={"Prélèvements"}
                  value={formatIban(paymentMethod.selected_payment.default)}
                />
              ) : null}
              {paymentMethod.selected_payment.refund ? (
                <SmallInformationsCard
                  title={"Remboursements"}
                  mt="16px"
                  value={formatIban(paymentMethod.selected_payment.refund)}
                />
              ) : null}
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
}
