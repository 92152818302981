import { useActor } from "@xstate/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toastClose } from "src/components/01_atoms/toast";
import { AlertDrawer } from "src/components/02_molecules/alert-drawer";
import { AlertModal } from "src/components/02_molecules/alert-modal";
import { MenuButton } from "src/components/02_molecules/menu-button";
import { FDS } from "src/configs/Legal";
import { matchIsAllContractsDeffered } from "src/helpers";
import { useGetContract } from "src/hooks/data";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { useGetUnpaidStatus } from "src/hooks/use-get-unpaid-status";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { routes } from "src/routes";
import { claimsService, filterNeedInfoClaims } from "src/store/claims/machine";
import { onboardingStepsService } from "src/store/onboarding/machine";
import { styled, theme } from "src/styles/stitches/theme";
import { ClaimCategory } from "src/types";
import { getFormattedDateWithYear } from "src/utils/format";
import { openInNewTab } from "src/utils/utils";
import { formatClaimActCategories } from "../claims/_utils";
import { DownloadAppDrawer } from "../download-app-drawer";
import { DownloadAppModal } from "../download-app-modal";
import { IbanSidebar } from "../iban-sidebar";
import { PetUuidForm } from "../refund-sidebar/pet-uuid-form";

const Wrapper = styled("div", {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "repeat(2, 1fr)",
  gridColumnGap: "1rem",
  gridRowGap: "1rem",
  padding: `0 ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: "2rem 1.5rem",
    display: "flex",
    flexDirection: "column",

    gridTemplateColumns: "unset",
    gridTemplateRows: "unset",
    gridColumnGap: "unset",
    gridRowGap: "unset",

    "& > * + *": {
      marginTop: "0.5rem",
    },
  },
});

export function MenuItems() {
  const [isDownloadAppModalOrDrawerOpen, setIsDownloadAppModalOrDrawerOpen] = useState(false);
  const [
    isSingleNeedInfoClaimAlertModalOrDrawerOpen,
    setIsSingleNeedInfoClaimAlertModalOrDrawerOpen,
  ] = useState(false);
  const [
    isMultipleNeedInfoClaimsAlertModalOrDrawerOpen,
    setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen,
  ] = useState(false);
  const [isIbanAlertModalOrDrawerOpen, setIsIbanAlertModalOrDrawerOpen] = useState(false);
  const [isIbanSidebarOpen, setIsIbanSidebarOpen] = useState(false);
  const [isUnpaidAlertModalOrDrawerOpen, setIsUnpaidAlertModalOrDrawerOpen] = useState(false);
  const [isDefaultPetModalOpen, setIsDefaultPetModalOpen] = useState(false);
  const [isDeferredAlertModalOrDrawerOpen, setIsDeferredAlertModalOrDrawerOpen] = useState(false);

  const { openRefundSidebar } = useRefundSidebar();
  const { data: contracts } = useGetContract();

  const { openClaimStatusSidebar } = useClaimStatusSidebar();
  const {
    recoveryAmount,
    recoveryPaymentLink,
    hasUnpaidSubscription,
    hasMultipleUnpaidSubscriptions,
  } = useGetUnpaidStatus();

  const history = useHistory();

  const [claimsState, sendClaims] = useActor(claimsService);
  const [onboardingStepsState] = useActor(onboardingStepsService);

  const claims = claimsState.context.claims;

  const iban = onboardingStepsState.context.iban;

  if (!claims || !contracts) {
    return null;
  }

  const hasSinglePet = contracts.length === 1;

  const invalidPetUuid = contracts.find((contract) => {
    return contract.pet.is_pet_uuid_default === true;
  });

  const hasSingleNeedInfoClaim = filterNeedInfoClaims(claims).length === 1;
  const hasMultipleNeedInfoClaims = filterNeedInfoClaims(claims).length > 1;
  const singleNeedInfoClaim = filterNeedInfoClaims(claims)[0];

  let categories: ClaimCategory[] = [];
  let petType: "cat" | "dog" | undefined = undefined;

  if (hasSinglePet && hasSingleNeedInfoClaim) {
    const contract = contracts[0];

    petType = contract?.pet.pet_type;

    if (!petType) return null;

    categories = singleNeedInfoClaim.claim_acts.map(({ actCategory }) => actCategory);
  }

  return (
    <>
      <Wrapper>
        <MenuButton
          label="Me faire rembourser"
          iconName="filledSpark"
          lockIcon={matchIsAllContractsDeffered(contracts)}
          onClick={() => {
            toastClose();

            if (matchIsAllContractsDeffered(contracts)) {
              setIsDeferredAlertModalOrDrawerOpen(true);
              return;
            }

            if (hasSinglePet && Boolean(invalidPetUuid)) {
              setIsDefaultPetModalOpen(true);
              return;
            }

            if (hasUnpaidSubscription) {
              setIsUnpaidAlertModalOrDrawerOpen(true);
              return;
            }

            if (!iban) {
              setIsIbanAlertModalOrDrawerOpen(true);
              return;
            }

            if (hasSinglePet && hasSingleNeedInfoClaim) {
              setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(true);
              return;
            }

            if (hasSinglePet && hasMultipleNeedInfoClaims) {
              setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(true);
              return;
            }

            openRefundSidebar();
          }}
        />
        <MenuButton
          label="Feuille de soins"
          iconName="filledDownload"
          onClick={() => {
            openInNewTab(FDS);
          }}
        />
        <MenuButton
          label="Consulter mes contrats"
          iconName="filledFile"
          onClick={() => {
            history.push(routes.contracts);
          }}
        />

        <MenuButton
          label="Parler à un vétérinaire"
          iconName="filledVeto"
          onClick={() => {
            toastClose();
            setIsDownloadAppModalOrDrawerOpen(true);
          }}
          lockIcon
        />
      </Wrapper>
      <DownloadAppModal
        isOpen={isDownloadAppModalOrDrawerOpen}
        onClose={() => {
          setIsDownloadAppModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        title="Profitez désormais de cette expérience sur l’app mobile&nbsp;!"
        description="La fonctionnalité d’échange avec un vétérinaire est désormais uniquement accessible depuis l’app Dalma. Utilisez la caméra de votre téléphone pour scanner ce QR Code et téléchargez l’app gratuite Dalma."
      />
      <DownloadAppDrawer
        isVisible={isDownloadAppModalOrDrawerOpen}
        onClose={() => {
          setIsDownloadAppModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        title="Profitez désormais de cette expérience sur l’app mobile&nbsp;!"
        description="La fonctionnalité d’échange avec un vétérinaire est désormais uniquement accessible depuis l’app Dalma. Téléchargez l’app gratuite Dalma."
      />
      <AlertModal
        isOpen={isUnpaidAlertModalOrDrawerOpen}
        label="Modale d'alerte de régularisation des paiements"
        title="Vous devez régulariser vos paiements"
        description={
          hasMultipleUnpaidSubscriptions
            ? "Nous ne sommes pas parvenus à prélever vos mensualités sur plusieurs de vos contrats. Chacun d’entre eux nécessite une régularisation individuelle.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti."
            : `Nous ne sommes pas parvenus à prélever vos mensualités pour un montant de ${recoveryAmount}€. Régularisez votre situation au plus vite.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti.`
        }
        onDismiss={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
        }}
        buttonText1="Régulariser mes paiements"
        onDismissButton1={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
          window.open(recoveryPaymentLink, "_blank", "noreferrer");
        }}
        buttonText2="Créer une demande de remboursement"
        onDismissButton2={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);

          if (!iban) {
            setIsIbanAlertModalOrDrawerOpen(true);
            return;
          }

          if (hasSinglePet && hasSingleNeedInfoClaim) {
            setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(true);
            return;
          }

          if (hasSinglePet && hasMultipleNeedInfoClaims) {
            setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(true);
            return;
          }

          openRefundSidebar();
        }}
      />
      <AlertDrawer
        isOpen={isUnpaidAlertModalOrDrawerOpen}
        title="Vous devez régulariser vos paiements"
        description={
          hasMultipleUnpaidSubscriptions
            ? "Nous ne sommes pas parvenus à prélever vos mensualités sur plusieurs de vos contrats. Chacun d’entre eux nécessite une régularisation individuelle.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti."
            : `Nous ne sommes pas parvenus à prélever vos mensualités pour un montant de ${recoveryAmount}€. Régularisez votre situation au plus vite.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti.`
        }
        onClose={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
        }}
        buttonText1="Régulariser mes paiements"
        onCloseButton1={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
          window.open(recoveryPaymentLink, "_blank", "noreferrer");
        }}
        buttonText2="Créer une demande de remboursement"
        onCloseButton2={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);

          if (!iban) {
            setIsIbanAlertModalOrDrawerOpen(true);
            return;
          }

          if (hasSinglePet && hasSingleNeedInfoClaim) {
            setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(true);
            return;
          }

          if (hasSinglePet && hasMultipleNeedInfoClaims) {
            setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(true);
            return;
          }

          openRefundSidebar();
        }}
      />
      <AlertModal
        isOpen={isIbanAlertModalOrDrawerOpen}
        label="Modale d'alerte de demande des informations bancaires"
        title="Nous avons besoin de vos informations bancaires"
        description="Vos informations bancaires nous sont utiles pour rembourser vos soins vétérinaires. Cela ne vous prendra qu’une petite minute et vous pourrez faire votre première demande de remboursement"
        onDismiss={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText1="Compléter mes informations"
        onDismissButton1={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          setIsIbanSidebarOpen(true);
        }}
        buttonText2="Annuler"
        onDismissButton2={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
      />
      <AlertDrawer
        isOpen={isIbanAlertModalOrDrawerOpen}
        title="Nous avons besoin de vos informations bancaires"
        description="Vos informations bancaires nous sont utiles pour rembourser vos soins vétérinaires. Cela ne vous prendra qu’une petite minute et vous pourrez faire votre première demande de remboursement"
        onClose={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText1="Compléter mes informations"
        onCloseButton1={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          setIsIbanSidebarOpen(true);
        }}
        buttonText2="Annuler"
        onCloseButton2={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
      />
      <IbanSidebar isOpen={isIbanSidebarOpen} setIsOpen={setIsIbanSidebarOpen} />
      <AlertModal
        isOpen={isSingleNeedInfoClaimAlertModalOrDrawerOpen}
        label="Modale d'alerte d'une demande de remboursement à compléter"
        title="Des informations sont à compléter dans l’une de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onDismiss={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText1="Voir la demande concernée"
        onDismissButton1={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          openClaimStatusSidebar({
            formattedCategories: formatClaimActCategories(categories),
            petType,
            claim: singleNeedInfoClaim,
          });
        }}
        buttonText2="Créer une nouvelle demande"
        onDismissButton2={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          openRefundSidebar();
        }}
      />
      <AlertDrawer
        isOpen={isSingleNeedInfoClaimAlertModalOrDrawerOpen}
        title="Des informations sont à compléter dans l’une de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onClose={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText1="Voir la demande concernée"
        onCloseButton1={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          openClaimStatusSidebar({
            formattedCategories: formatClaimActCategories(categories),
            petType,
            claim: singleNeedInfoClaim,
          });
        }}
        buttonText2="Créer une nouvelle demande"
        onCloseButton2={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          openRefundSidebar();
        }}
      />
      <AlertModal
        isOpen={isMultipleNeedInfoClaimsAlertModalOrDrawerOpen}
        label="Modale d'alerte de plusieurs demandes de remboursement à compléter"
        title="Des informations sont à compléter dans plusieurs de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onDismiss={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText1="Voir les demandes concernées"
        onDismissButton1={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText2="Créer une nouvelle demande"
        onDismissButton2={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          openRefundSidebar();
        }}
      />
      <AlertDrawer
        isOpen={isMultipleNeedInfoClaimsAlertModalOrDrawerOpen}
        title="Des informations sont à compléter dans plusieurs de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onClose={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText1="Voir les demandes concernées"
        onCloseButton1={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        buttonText2="Créer une nouvelle demande"
        onCloseButton2={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          openRefundSidebar();
        }}
      />
      <AlertModal
        isOpen={isDefaultPetModalOpen}
        label="Modale d'alerte du numéro d'identification à compléter"
        title={`Renseignez le numéro d'identification de ${invalidPetUuid?.pet.pet_name}`}
        description="Afin de pouvoir vous rembourser, merci de renseigner le numéro de puce ou tatouage de votre animal. Sans cette information nous ne pourrons pas traiter votre demande."
        onDismiss={() => setIsDefaultPetModalOpen(false)}
      >
        {invalidPetUuid && (
          <PetUuidForm
            selectedPetContract={{
              stripe_contract_id: invalidPetUuid.contract.stripe_contract_id,
              pet_idx: Number(invalidPetUuid.pet.pet_idx),
              pet_uuid_type: invalidPetUuid.pet.pet_uuid_type,
            }}
            setIsDefaultPetModalOpen={setIsDefaultPetModalOpen}
          />
        )}
      </AlertModal>
      <AlertModal
        isOpen={isDeferredAlertModalOrDrawerOpen}
        label="Modale d'alerte de contrat différé"
        title="Votre contrat n’est pas encore actif."
        description={`Les demandes de remboursement ne seront disponibles qu’une fois votre contrat actif. 
  
        Pour rappel, celui-ci débute le ${getFormattedDateWithYear(
          contracts[0].contract.start_date
        )}.`}
        onDismiss={() => setIsDeferredAlertModalOrDrawerOpen(false)}
        buttonText1="J'ai compris"
        onDismissButton1={() => setIsDeferredAlertModalOrDrawerOpen(false)}
      />
      <AlertDrawer
        isOpen={isDeferredAlertModalOrDrawerOpen}
        title="Votre contrat n’est pas encore actif."
        description={`Les demandes de remboursement ne seront disponibles qu’une fois votre contrat actif. 
  
        Pour rappel, celui-ci débute le ${getFormattedDateWithYear(
          contracts[0].contract.start_date
        )}.`}
        onClose={() => setIsDeferredAlertModalOrDrawerOpen(false)}
        buttonText1="J'ai compris"
        onCloseButton1={() => setIsDeferredAlertModalOrDrawerOpen(false)}
      />
    </>
  );
}
