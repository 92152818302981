import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line no-restricted-imports
import { Id, toast as _toast, ToastOptions } from "react-toastify";
import { styled, theme } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";
import { Icon } from "../icon";
import { Outlet } from "../outlet";
import { Button } from "../button";
import { ComponentProps, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

// The Toast himself, but colors/styles are defined in toast-rules
const StyledDiv = styled("div", {
  marginLeft: theme.spacing.spacing16px,
  marginRight: theme.spacing.spacing16px,
});

const StyledIcon = styled(Icon, {
  flexShrink: 0,
});

const StyledActionDiv = styled("div", {
  marginLeft: "auto",
  marginRight: "1.125rem",
});

type DalmaToastProps = WithChildren<{
  action?: () => any;
  actionLabel?: string;
  iconName?: ComponentProps<typeof Icon>["name"];
  iconColor?: ComponentProps<typeof Icon>["color"];
  isInfoToast?: boolean;
  showToastOnlyOnHomePage?: boolean;
}>;

export function DalmaToast({
  children,
  iconName,
  iconColor,
  action,
  actionLabel,
  isInfoToast,
  showToastOnlyOnHomePage,
}: DalmaToastProps) {
  const location = useLocation();

  const icon = iconName ? iconName : "outlinedHourglass";
  const buttonVariant = isInfoToast ? "smallActionAccent" : "smallActionWhite";

  useEffect(() => {
    if (showToastOnlyOnHomePage && location.pathname !== "/") {
      toastClose();
    }
  }, [showToastOnlyOnHomePage, location.pathname]);

  return (
    <Outlet fluid align="center">
      <StyledIcon name={icon} color={iconColor} />
      <StyledDiv>{children}</StyledDiv>
      {action && (
        <StyledActionDiv>
          <Button onClick={action} variant={buttonVariant} size="sm">
            {actionLabel}
          </Button>
        </StyledActionDiv>
      )}
    </Outlet>
  );
}

// Toast factory
type ToastFunc = (
  content?: ReactNode,
  options?: ToastOptions,
  showToastOnlyOnHomePage?: boolean
) => Id;
type ToastProps = ToastFunc & {
  info: ToastFunc;
  success: ToastFunc;
  warning: ToastFunc;
  error: ToastFunc;
  copy: ToastFunc;
};

export const toastClose = (id?: number | string) => _toast.dismiss(id);

export const toast: ToastProps = (content, options) =>
  _toast(<DalmaToast>{content}</DalmaToast>, options);
toast.info = (content, options) =>
  _toast.info(
    <DalmaToast iconName="filledInfo" isInfoToast>
      {content}
    </DalmaToast>,
    options
  );
toast.success = (content, options) =>
  _toast.success(<DalmaToast iconName="filledCheckCircle">{content}</DalmaToast>, options);
toast.warning = (content, options, showToastOnlyOnHomePage) =>
  _toast.warning(
    <DalmaToast
      iconName="filledWarning"
      iconColor="iconWarning"
      showToastOnlyOnHomePage={showToastOnlyOnHomePage}
    >
      {content}
    </DalmaToast>,
    options
  );
toast.error = (content, options) =>
  _toast.error(<DalmaToast iconName="filledWarning">{content}</DalmaToast>, options);
toast.copy = (content, options) =>
  _toast.success(<DalmaToast iconName="filledCopy">{content}</DalmaToast>, options);

// ActionToast le plus fort de tous les hérooos
type ActionToastFunc = (
  content?: ReactNode,
  action?: () => any,
  actionLabel?: string,
  options?: ToastOptions
) => Id;

type ActionToastProps = ActionToastFunc & {
  info: ActionToastFunc;
  success: ActionToastFunc;
  warning: ActionToastFunc;
  error: ActionToastFunc;
};

export const actionToast: ActionToastProps = (content, action, actionLabel, options) =>
  _toast(
    <DalmaToast action={action} actionLabel={actionLabel}>
      {content}
    </DalmaToast>,
    options
  );
actionToast.info = (content, action, actionLabel, options) =>
  _toast.info(
    <DalmaToast iconName="filledInfo" action={action} actionLabel={actionLabel} isInfoToast>
      {content}
    </DalmaToast>,
    options
  );
actionToast.success = (content, action, actionLabel, options) =>
  _toast.success(
    <DalmaToast iconName="filledCheckCircle" action={action} actionLabel={actionLabel}>
      {content}
    </DalmaToast>,
    options
  );
actionToast.warning = (content, action, actionLabel, options) =>
  _toast.warning(
    <DalmaToast
      iconName="filledWarning"
      iconColor="iconWarning"
      action={action}
      actionLabel={actionLabel}
    >
      {content}
    </DalmaToast>,
    options
  );
actionToast.error = (content, action, actionLabel, options) =>
  _toast.error(
    <DalmaToast iconName="filledWarning" action={action} actionLabel={actionLabel}>
      {content}
    </DalmaToast>,
    options
  );
