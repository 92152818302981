import { useActor } from "@xstate/react";
import * as React from "react";
import { Outlet } from "src/components/01_atoms/outlet";
import { ClaimLoading } from "src/components/02_molecules/claim-loading/claim-loading";
import { ClaimsEmptyState } from "src/components/02_molecules/claims-empty-state";
import { ClaimsListByYear } from "src/components/02_molecules/claims-list-by-year";
import { matchIsAllContractsDeffered } from "src/helpers";
import { useGetContract } from "src/hooks/data";
import { claimsService } from "src/store/claims/machine";
import { getClaimsByYear } from "./_utils";
import { StyledText } from "./claims.styled";

export function Claims() {
  const [state, send] = useActor(claimsService);
  const { data: contracts } = useGetContract();
  React.useEffect(() => {
    if (state.matches("initialized")) {
      send("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
      return;
    }

    send("GET_CLAIMS");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const claimsByYear = state.context.claims ? getClaimsByYear(state.context.claims) : null;

  return (
    <Outlet vertical="spacing32px" fluid>
      <StyledText variant="display5-Bold" color="textBaseDefault">
        Vos remboursements
      </StyledText>

      {contracts && matchIsAllContractsDeffered(contracts) ? <></> : null}
      {state.matches("loading") ? (
        <ClaimLoading />
      ) : claimsByYear && claimsByYear.length > 0 ? (
        claimsByYear.map((claims) => (
          <ClaimsListByYear key={claims.year} year={claims.year} claims={claims.claims} />
        ))
      ) : (
        <ClaimsEmptyState />
      )}
    </Outlet>
  );
}
