import { isWithinInterval } from "date-fns";
import { useState, useEffect } from "react";

export function useRafOperation() {
  const [isDuringCampaign, setIsDuringCampaign] = useState(false);
  const isOperation200Enabled = true;

  useEffect(() => {
    const currentDate = new Date();
    const campaignPeriod = {
      start: new Date("2024-01-15"),
      end: new Date("2030-01-01"),
    };

    setIsDuringCampaign(isWithinInterval(currentDate, campaignPeriod));
  }, []);

  return { isDuringCampaign, isOperation200Enabled };
}
