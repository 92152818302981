import { Flex, Box, Tooltip } from "@chakra-ui/react";
import { Texts } from "../texts/Texts";
import { Title } from "../texts/Title";
import InfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";

function formatValue(value: number, valueType: string) {
  if (valueType === "€" && value > 0) {
    return value.toFixed(2).replace(".", ",");
  }
  return value;
}

type Props = {
  value?: number | string;
  totalValue?: number | string;
  valueType?: string;
  title: string;
  description: string;
  label?: string;
  w?: string;
  color?: string;
};

export const PetGuaranteeCard = ({
  value = 0,
  totalValue = 0,
  valueType = "%",
  title,
  description,
  label = "",
  w = "100%",
  color = "#4E68F6",
}: Props) => {
  return (
    <Flex bg="#FAFAFA" minW={["90vw", "300px"]} w={w} minH="120px" direction="column" p="24px">
      <Flex>
        <Flex>
          <Texts>{title}</Texts>
        </Flex>

        <Flex align="center" justify="center">
          <Tooltip label={label} aria-label="A tooltip" bg="#FFFFFF">
            <Flex mb="1" ml="1">
              <InfoCircle size="14px" color="#757575" />
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex>
        <Flex align="flex-end" mr="8px">
          <Title color={color} fontSize="24px">
            {`${formatValue(Number(value), valueType)}${valueType}`}
          </Title>
        </Flex>

        <Flex align="flex-end" mb="5px">
          <Texts fontSize="12px" color="#616161">
            {description}
          </Texts>
        </Flex>
      </Flex>

      <Flex pt="12px" pb="12px">
        <Flex w="100%">
          <Box bg="#DDF0EA" w="100%" h="1" rounded="full" mb={["2", "2", "0", "0", "0"]}>
            <Box
              bg={color}
              w={`${(100 * Number(value)) / Number(totalValue)}%`}
              h="100%"
              rounded="full"
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
