import { RefundSidebarHeader } from "src/components/03_organisms/refund-sidebar/header";
import { SidesheetLayout } from "src/components/04_layouts/sidesheet-layout";
import { claimSteps, useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { PetSelectionForm } from "./pet-selection-form";
import { ConsultationTypesForm } from "./consultation-types-form";
import { AmountForm } from "./amount-form";
import { DocumentsForm } from "./documents-form";
import { SidesheetScreenWithImage } from "src/components/02_molecules/sidesheet-screen-with-image";
import { InitStep } from "./init";
import { styled } from "src/styles/stitches/theme";
import { BoostAppDownloadLogin } from "../login/boost-app-download";
import { AccidentCausesForm } from "./accident-causes-form";
import { DiseaseCausesForm } from "./disease-causes-form";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowY: "auto",
});

export function RefundSidebar() {
  const { isOpen, currentStep, closeRefundSidebar, clearNewClaim, setCurrentStep } =
    useRefundSidebar();

  const sidebarByStep: {
    [step: number]: JSX.Element;
  } = {
    [claimSteps.INIT]: <InitStep />,
    [claimSteps.PET_SELECTION]: <PetSelectionForm />,
    [claimSteps.CONSULTATION_TYPES]: <ConsultationTypesForm />,
    [claimSteps.AMOUNT]: <AmountForm />,
    [claimSteps.ACCIDENT_CAUSES]: <AccidentCausesForm />,
    [claimSteps.DISEASE_CAUSES]: <DiseaseCausesForm />,
    [claimSteps.DOCUMENTS]: <DocumentsForm />,
    [claimSteps.CLAIM_SUBMITTED]: (
      <SidesheetScreenWithImage
        title="Demande validée"
        description="Votre dossier sera traité dans les 2 jours ouvrés."
        image="/assets/media/paper-plane.svg"
        imageAlt="Illustration d'un avion en papier"
        onClickButton1={() => setCurrentStep(currentStep + 1)}
        buttonLabel1="J'ai compris"
      />
    ),
    [claimSteps.PUSH_APP_MODAL]: <BoostAppDownloadLogin isFromRefundSidebar />,
  };

  const getCurrentStepPage = (currentStep: number) => {
    return sidebarByStep[currentStep];
  };

  return (
    <SidesheetLayout
      isOpen={isOpen}
      label="Demande de remboursement"
      onDismiss={closeRefundSidebar}
      onExitComplete={clearNewClaim}
    >
      {currentStep !== claimSteps.PUSH_APP_MODAL ? (
        <Container>
          <RefundSidebarHeader />
          {getCurrentStepPage(currentStep)}
        </Container>
      ) : (
        getCurrentStepPage(claimSteps.PUSH_APP_MODAL)
      )}
    </SidesheetLayout>
  );
}
