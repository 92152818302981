import { Step } from "src/components/02_molecules/step";
import { styled, theme } from "src/styles/stitches/theme";

const Container = styled("div", {
  padding: `0 ${theme.spacing.spacing24px}`,
  background: theme.colors.fillSecondary,
  borderRadius: `0 0 ${theme.radii[24]} ${theme.radii[24]}`,
});

type ToBeCompletedCardSectionProps = {
  title: string;
  showTooltip?: boolean;
};

export function ToBeCompletedCardSection({
  title,
  showTooltip = false,
}: ToBeCompletedCardSectionProps) {
  return (
    <Container>
      <Step iconName="filledCheckCircle" text="Envoi de la demande" />
      <Step spinner text={title} />
      <Step
        text={"Traitement sous 2 jours ouvrés par Dalma"}
        tooltipMessage={
          showTooltip ? (
            <>
              <b>Nous traitons votre demande sous 2 jours ouvrés</b>
              <br />
              <>
                Votre demande est en cours de traitement. Celle-ci sera traitée dans un délai de 2
                jours ouvrés, comprenant les jours du Lundi au Vendredi, hors week-end et jours
                fériés.
              </>
            </>
          ) : undefined
        }
      />
      <Step text="Remboursement" withBorderBottom={false} />
    </Container>
  );
}
