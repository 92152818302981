import { toast, toastClose } from "src/components/01_atoms/toast";
import { createContext, useContext, useState } from "react";
import { ClaimConsultationType, Contract, WithChildren } from "src/types";
import { reportError } from "src/utils/error";
import { useActor } from "@xstate/react";
import { claimsService } from "src/store/claims/machine";

export const claimSteps = {
  INIT: 0,
  PET_SELECTION: 1,
  CONSULTATION_TYPES: 2,
  AMOUNT: 3,
  ACCIDENT_CAUSES: 4,
  DISEASE_CAUSES: 5,
  DOCUMENTS: 6,
  CLAIM_SUBMITTED: 7,
  PUSH_APP_MODAL: 8,
} as const;

type RefundSidebarContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  selectedPetToContext: Contract["pet"] | undefined;
  setSelectedPetToContext: (selectedPet: Contract["pet"] | undefined) => void;
  selectedConsultationTypes: ClaimConsultationType[];
  setSelectedConsultationTypes: (selectedConsultationTypes: ClaimConsultationType[]) => void;
  amount: string | undefined;
  setAmount: (amount: string | undefined) => void;
  selectedAccidentDate: Date | undefined;
  setSelectedAccidentDate: (date: Date | undefined) => void;
  selectedDiseaseDate: Date | undefined;
  setSelectedDiseaseDate: (date: Date | undefined) => void;
  visitMotivesAccident: string[] | undefined;
  setVisitMotivesAccident: (visitMotives: string[] | undefined) => void;
  visitMotivesDisease: string[] | undefined;
  setVisitMotivesDisease: (visitMotives: string[] | undefined) => void;
  clearNewClaim: () => void;
  checkDocumentStep: () => void;
  openRefundSidebar(): void;
  closeRefundSidebar(): void;
};

export const RefundSidebarContext = createContext<RefundSidebarContextType>({
  isOpen: false,
  setIsOpen: () => undefined,
  currentStep: claimSteps.INIT,
  setCurrentStep: () => undefined,
  selectedPetToContext: undefined,
  setSelectedPetToContext: () => undefined,
  selectedConsultationTypes: [],
  setSelectedConsultationTypes: () => undefined,
  amount: undefined,
  setAmount: () => undefined,
  selectedAccidentDate: undefined,
  setSelectedAccidentDate: () => undefined,
  selectedDiseaseDate: undefined,
  setSelectedDiseaseDate: () => undefined,
  visitMotivesAccident: undefined,
  setVisitMotivesAccident: () => undefined,
  visitMotivesDisease: undefined,
  setVisitMotivesDisease: () => undefined,
  clearNewClaim: () => undefined,

  checkDocumentStep: () => undefined,
  openRefundSidebar: () => undefined,
  closeRefundSidebar: () => undefined,
});

export const RefundSidebarContextProvider = ({ children }: WithChildren) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(claimSteps.INIT);
  const [selectedPetToContext, setSelectedPetToContext] = useState<Contract["pet"] | undefined>();
  const [selectedConsultationTypes, setSelectedConsultationTypes] = useState<
    ClaimConsultationType[]
  >([]);
  const [amount, setAmount] = useState<string | undefined>();
  const [selectedAccidentDate, setSelectedAccidentDate] = useState<Date | undefined>(undefined);
  const [selectedDiseaseDate, setSelectedDiseaseDate] = useState<Date | undefined>(undefined);
  const [visitMotivesAccident, setVisitMotivesAccident] = useState<string[] | undefined>(undefined);
  const [visitMotivesDisease, setVisitMotivesDisease] = useState<string[] | undefined>(undefined);

  const [, send] = useActor(claimsService);

  const clearNewClaim = () => {
    setCurrentStep(claimSteps.INIT);
    setSelectedPetToContext(undefined);
    setSelectedConsultationTypes([]);
    setAmount(undefined);
    setSelectedAccidentDate(undefined);
    setSelectedDiseaseDate(undefined);
    setVisitMotivesAccident(undefined);
    setVisitMotivesDisease(undefined);
  };

  // If the user comes back to the document step, we need to check if he has already filled the amount, petselection and consultation types
  function checkDocumentStep() {
    if (currentStep === claimSteps.DOCUMENTS) {
      if (!amount || !selectedPetToContext || !selectedConsultationTypes.length) {
        toast.error("Une erreur est survenue, des champs sont manquants. Veuillez recommencer");
        reportError("User tried to access document step without having filled the previous steps");
        setIsOpen(false);
      }
    }
  }

  function openRefundSidebar() {
    setIsOpen(true);
    setCurrentStep(claimSteps.INIT);
    toastClose();
  }

  function closeRefundSidebar() {
    setIsOpen(false);
    if (currentStep === claimSteps.CLAIM_SUBMITTED) {
      send({ type: "REFETCH_CLAIMS" });
      toastClose();
      toast.success("Votre demande a bien été envoyée !");
    }
    send("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
  }

  return (
    <RefundSidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        currentStep,
        setCurrentStep,
        selectedPetToContext,
        setSelectedPetToContext,
        selectedConsultationTypes,
        setSelectedConsultationTypes,
        amount,
        setAmount,
        selectedAccidentDate,
        setSelectedAccidentDate,
        selectedDiseaseDate,
        setSelectedDiseaseDate,
        visitMotivesAccident,
        setVisitMotivesAccident,
        visitMotivesDisease,
        setVisitMotivesDisease,
        clearNewClaim,
        checkDocumentStep,
        openRefundSidebar,
        closeRefundSidebar,
      }}
    >
      {children}
    </RefundSidebarContext.Provider>
  );
};

export const useRefundSidebar = (): RefundSidebarContextType => {
  const context = useContext(RefundSidebarContext);

  if (context === null) {
    throw new Error("useRefundSidebar must be used within a RefundSidebarContextProvider");
  }

  return context;
};
