import { styled, theme } from "src/styles/stitches/theme";

const Container = styled("div", {
  borderRadius: theme.radii[7],
  width: "100%",
  height: "0.5rem",

  variants: {
    background: {
      fillBaseSubdued: {
        background: theme.colors.fillBaseSubdued,
      },
    },
  },

  defaultVariants: {
    background: "fillBaseSubdued",
  },
});

const FilledPart = styled("div", {
  borderRadius: theme.radii[7],
  height: "0.5rem",

  variants: {
    background: {
      fillAccent: {
        background: theme.colors.fillAccent,
      },
    },
  },

  defaultVariants: {
    background: "fillAccent",
  },
});

type Props = {
  emptyColor?: "fillBaseSubdued";
  filledColor?: "fillAccent";
  filledPercentage: string;
};

export function ProgressBar({ emptyColor, filledColor, filledPercentage }: Props) {
  return (
    <Container background={emptyColor}>
      <FilledPart background={filledColor} css={{ width: filledPercentage }} />
    </Container>
  );
}
