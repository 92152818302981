import { VariantProps } from "@stitches/react";
import { ReactNode } from "react";
import { deprecatedStyled } from "src/styles/stitches/deprecated-theme";
import { Title } from "src/components/texts/NewTitle";

const Card = deprecatedStyled("div", {
  padding: 32,
  borderRadius: 8,
  display: "flex",
  width: "100%",

  variants: {
    direction: {
      column: {
        flexDirection: "column",
        maxWidth: 327,

        "& > * + *": {
          marginTop: 24,
        },
      },
      row: {
        flexDirection: "row",
        maxWidth: 571,

        "& > * + *": {
          marginLeft: 24,
        },
      },
    },
    color: {
      primary: {
        backgroundColor: "$electric",
        color: "$white",
      },
      secondary: {
        backgroundColor: "$blueBackGround",
        color: "$electric900",
      },
    },
    size: {
      regular: {},
      small: {
        padding: 16,
      },
    },
  },

  defaultVariants: {
    direction: "column",
    color: "primary",
  },
});

const ReferIllustration = deprecatedStyled("img", {
  height: 100,
  width: "auto",
  objectFit: "contain",
  objectPosition: "left",
});

const Content = deprecatedStyled("div", {
  "& > * + *": {
    marginTop: 8,
  },
});

const Description = deprecatedStyled("p", {
  fontSize: 14,
  lineHeight: 1.4,
  fontWeight: 500,
  letterSpacing: "-0.25px",

  variants: {
    color: {
      primary: {
        color: "$white",
      },
      secondary: {
        color: "$infoIconColor",
      },
    },
  },

  defaultVariants: {
    color: "primary",
  },
});

type SimpleReferCardProps = VariantProps<typeof Card> & {
  title: string | ReactNode;
  description: string;
};

export function SimpleReferCard({ title, description, ...rest }: SimpleReferCardProps) {
  const isTypeofTitleString = typeof title === "string";

  return (
    <Card {...rest}>
      <ReferIllustration
        src="/assets/media/illu-parrainage.png"
        alt="Illustration d'un chat avec un cadeau"
      />

      <Content>
        {isTypeofTitleString ? (
          <Title as="h2" level="h3">
            {title}
          </Title>
        ) : (
          title
        )}
        <Description color={rest.color}>{description}</Description>
      </Content>
    </Card>
  );
}
