import { CSS, VariantProps } from "@stitches/react";
import { ElementType } from "react";
import { styled, theme } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";

type Gaps = Record<keyof typeof theme.spacing, CSS>;

/**
 * Create the Stitches variant object for horizontal gaps, based on GAPS
 */
const horizontalGaps = Object.entries(theme.spacing).reduce<Gaps>((obj, [key, value]) => {
  return {
    ...obj,
    [key]: {
      flexDirection: "row",
      "& > * + *": {
        marginLeft: value,
      },
    },
  };
}, {} as any as Gaps);

/**
 * Create the Stitches variant object for vertical gaps, based on GAPS
 */
const verticalGaps = Object.entries(theme.spacing).reduce<Gaps>((obj, [key, value]) => {
  return {
    ...obj,
    [key]: {
      flexDirection: "column",
      "& > * + *": {
        marginTop: value,
      },
    },
  };
}, {} as any as Gaps);

export const StyledOutlet = styled("div", {
  display: "flex",
  position: "relative",
  variants: {
    horizontal: horizontalGaps,
    vertical: verticalGaps,
    justify: {
      center: {
        justifyContent: "center",
      },
      start: {
        justifyContent: "flex-start",
      },
      end: {
        justifyContent: "flex-end",
      },
      spaceBetween: {
        justifyContent: "space-between",
      },
      stretch: {
        justifyContent: "stretch",
      },
    },
    align: {
      center: {
        alignItems: "center",
      },
      start: {
        alignItems: "flex-start",
      },
      end: {
        alignItems: "flex-end",
      },
      stretch: {
        alignItems: "stretch",
      },
      baseline: {
        alignItems: "baseline",
      },
    },
    fluid: {
      true: {
        width: "100%",
      },
    },
    fullHeight: {
      true: {
        height: "100%",
      },
    },
  },
  defaultVariants: {
    align: "start",
    justify: "start",
  },
});

export type OutletProps = WithChildren<
  VariantProps<typeof StyledOutlet> & {
    as?: ElementType;
    css?: CSS;
  }
>;

export function Outlet({ children, ...rest }: OutletProps) {
  return <StyledOutlet {...rest}>{children}</StyledOutlet>;
}
