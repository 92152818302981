import { useQuery } from "src/queryClient";
import { getContact } from "src/backApi/getContact";
import { Contact } from "src/types";

export function useGetContact() {
  const res = useQuery<Contact>(["contact"], getContact, {
    onError: () => {
      // TODO sentry
    },
    refetchOnWindowFocus: false,
  });

  return res;
}
