import { QuoteInfo } from "src/types";

function capitalizeFirstLetter(string: string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function gtag(data: any) {
  window.dataLayer.push(data);
}

export function trackWelcomeData(welcome: QuoteInfo[] | undefined) {
  gtag({
    event: "subscription",
    quote_id: welcome?.[0]?.quote_id,
    items: welcome?.map((petContract) => ({
      type: petContract.pet_type,
      op: false,
      conversionEventId: capitalizeFirstLetter(petContract.pet_type),
    })),
  });
}
