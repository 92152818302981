import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// We use return type because the type History of react-router-dom is protected
async function isUserAuthenticated(history: ReturnType<typeof useHistory>) {
  try {
    await Auth.currentSession();
    history.push("/");
  } catch (e) {
    // silently ignore
  }
}

export function useRedirectAuthenticatedUser() {
  const history = useHistory();

  useEffect(() => {
    isUserAuthenticated(history);
  }, [history]);
}
