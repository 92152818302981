import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { WithChildren } from "src/types";

type MobileMenuContextProps = {
  isMobileMenuOpen: boolean;
  setMobileMenuOpen: (value: boolean) => void;
  toggleMobileMenu: () => void;
  closeMobileMenu: () => void;
  openMobileMenu: () => void;
};

// Mobile menu react context
export const MobileMenuContext = createContext<MobileMenuContextProps | null>(null);

export const MobileMenuContextProvider = ({ children }: WithChildren) => {
  const history = useHistory();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  // Close the mobile menu on each change of route
  // and make sure the menu is closed when a user navigates back to the page
  useEffect(() => {
    history.listen(() => {
      closeMobileMenu();
    });
  }, [history]);

  return (
    <MobileMenuContext.Provider
      value={{
        isMobileMenuOpen,
        setMobileMenuOpen,
        toggleMobileMenu,
        closeMobileMenu,
        openMobileMenu,
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
};

export const useMobileMenu = (): MobileMenuContextProps => {
  const context = useContext(MobileMenuContext);

  if (context === null) {
    throw new Error("useMobileMenu must be used within a MobileMenuContextProvider");
  }

  return context;
};
