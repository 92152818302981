import { Auth } from "aws-amplify";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "src/components/01_atoms/toast";
import { z } from "zod";

type ChangePasswordFields = "verificationCode" | "newPassword";

type UseChangePasswordProps = {
  nextPage: string;
};

type HandleChangePasswordErrorReturn = {
  fields: ChangePasswordFields[];
  message: string;
};

type ForgotPasswordSubmitValues = {
  email: string;
  verificationCode: string;
  newPassword: string;
};

const changePasswordErrorSchema = z.object({
  code: z.string(),
  message: z.string(),
  name: z.string(),
});

// TODO in the futur: use i18n
const errorMessages = Object.freeze({
  wrongVerificationCode: "Le code de vérification est incorrect.",
  alreadyUsedVerificationCode: "Le code de vérification a déjà été utilisé.",
  limitedExceeded:
    "Vous avez atteint le plafond de tentatives de réinitialisation du mot de passe. Veuillez patienter 1h ou écrire à contact@dalma.co",
  unsecuredPassword:
    "Le mot de passe n'est pas sécurisé. Il doit contenir au moins 8 lettres, majuscule et minuscule, caractères spéciaux.",
  verificationCodeError:
    "Une erreur est survenue. Vérifiez que vous avez saisi le bon 'code de vérification' qui vous a été envoyé par mail et renseigné un mot de passe contenant au moins 8 lettres, majuscule et minuscule, caractères spéciaux.",
  default: "Une erreur est survenue. Veuillez nous écrire à contact@dalma.co",
});

export const useChangePassword = ({ nextPage }: UseChangePasswordProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePasswordError = (error: unknown): HandleChangePasswordErrorReturn | void => {
    const defaultError = () => {
      toast.error(errorMessages.default);
      return {
        fields: ["verificationCode" as ChangePasswordFields, "newPassword" as ChangePasswordFields],
        message: errorMessages.default,
      };
    };

    try {
      const parsedError = changePasswordErrorSchema.parse(error);

      const errorHandlerDic: { [key: string]: () => any } = {
        CodeMismatchException: () => toast.error(errorMessages.wrongVerificationCode),
        ExpiredCodeException: () => toast.error(errorMessages.alreadyUsedVerificationCode),
        LimitExceededException: () => toast.error(errorMessages.limitedExceeded),
        InvalidParameterException: () => {
          toast.error(errorMessages.unsecuredPassword);
          return { fields: ["newPassword"], message: errorMessages.unsecuredPassword };
        },
        default: defaultError,
      };

      const errorHandler = errorHandlerDic[parsedError.code] || errorHandlerDic.default;

      return errorHandler();
    } catch (error) {
      return defaultError();
    }
  };

  const forgotPasswordSubmit = async (values: ForgotPasswordSubmitValues) => {
    setIsLoading(true);
    // HACK our new user pool is case sensitive, so we must lowercase emails
    const email = values.email.toLowerCase();

    try {
      await Auth.forgotPasswordSubmit(email, values.verificationCode, values.newPassword);
      await Auth.signIn(email, values.newPassword);
      history.push(nextPage);
      toast.success("Nouveau mot de passe enregistré");
    } catch (error) {
      return handleChangePasswordError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, forgotPasswordSubmit };
};
