import { useActor } from "@xstate/react";
import { Icon } from "src/components/01_atoms/icon";
import { Stepper } from "src/components/02_molecules/stepper";
import { getOnboardingStepperItems, onboardingStepsService } from "src/store/onboarding/machine";
import { StyledEmptyDiv, StyledOutlet } from "./header.styled";

export function OnboardingHeader() {
  const [state, send] = useActor(onboardingStepsService);

  const iban = state.context.iban;

  const stateValue = state.value;

  if (typeof stateValue !== "string") {
    return null;
  }

  const currentStep = stateValue;

  const isBackButtonDisplayed = currentStep === "howDidYouKnowDalma" || currentStep === "vetClinic";

  return (
    <StyledOutlet horizontal="spacing16px" align="center" justify="spaceBetween">
      {isBackButtonDisplayed ? (
        <button type="button" onClick={() => send("PREV_STEP")}>
          <Icon name="arrowLeft" />
        </button>
      ) : (
        <StyledEmptyDiv />
      )}
      <Stepper steps={getOnboardingStepperItems(iban)} currentStep={currentStep} />
      <StyledEmptyDiv />
    </StyledOutlet>
  );
}
