import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";
import { getFormattedDateWithYear } from "src/utils/format";

const Container = styled(Outlet, {
  padding: theme.spacing.spacing20px,
  background: theme.colors.backgroundPrimary,
  borderRadius: `0 0 ${theme.radii[24]} ${theme.radii[24]}`,

  "@bp3": {
    padding: theme.spacing.spacing24px,
  },
});

const Title = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",

  "@bp3": {
    flexDirection: "row",
  },
});

const StyledText = styled(Text, {
  textAlign: "center",

  "@bp3": {
    textAlign: "left",
  },
});

type Props = {
  startDate?: string;
  petName?: string;
};

export function InfoBottomCard({ startDate, petName = "votre animal" }: Props) {
  return (
    <Container vertical="spacing8px" align="center">
      <Title>
        <Icon name="filledInfo" color="iconWarning" />
        <StyledText variant="paragraph2-Bold" color={"textBaseDefault"}>
          La demande de remboursement pour {petName} sera disponible une fois votre contrat actif et
          les délais de carences écoulés.{" "}
          {startDate ? (
            <>Pour rappel, votre contrat débute le {getFormattedDateWithYear(startDate)}</>
          ) : null}
        </StyledText>
      </Title>
    </Container>
  );
}
