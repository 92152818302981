import { AnchorButton } from "src/components/01_atoms/button";
import { Text } from "src/components/01_atoms/text";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";
import { ClaimStatusSidebarContentHeader } from "../content-header";
import { ClaimStatusSidebarHeader } from "../header";

const Container = styled("div", {
  display: "none",

  "@bp3": {
    position: "fixed",
    backgroundColor: theme.colors.fillSecondary,
    height: "100%",
    width: "100%",

    maxWidth: "26.25rem",
    right: "-26.25rem",
    zIndex: 2,
    overflowY: "auto",
    transition: "right 0.3s ease-out",
    display: "flex",
    flexDirection: "column",
  },

  variants: {
    isOpen: {
      true: {
        right: 0,
      },
    },
  },
});

const Content = styled("div", {
  padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing32px}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
});

const ContentWrapper = styled("div", { paddingBottom: theme.spacing.spacing32px });

const Ul = styled("ul", {
  marginLeft: "1rem",
  fontSize: theme.fontSizes.textParagraph2,
  lineHeight: 1.285,
  letterSpacing: theme.letterSpacings.regular,
  fontWeight: theme.fontWeights.medium,
  color: theme.colors.textBaseSubdued,
});

export function NeedInfoDetailSidebar() {
  const { isNeedInfoDetailSidebarOpen, setIsNeedInfoDetailSidebarOpen } = useClaimStatusSidebar();

  return (
    <Container isOpen={isNeedInfoDetailSidebarOpen}>
      <ClaimStatusSidebarHeader onCloseButtonClick={() => setIsNeedInfoDetailSidebarOpen(false)} />
      <Content>
        <ContentWrapper>
          <ClaimStatusSidebarContentHeader
            title="Qu’est-ce qu’une demande incomplète&nbsp;?"
            withStatusTag={false}
          />
          <Text variant="paragraph2-Medium" color="textBaseSubdued">
            Il nous manque une information pour traiter votre demande. Voici la liste des manques
            les plus courants :
          </Text>
          <br />
          <Ul>
            <li>Absence des justificatifs de frais</li>
            <li>Absence de la feuille de soins Dalma</li>
            <li>Absence d’ordonnance s’il y a une prescription de médicaments</li>
            <li>Besoin de justificatifs médicaux supplémentaires</li>
          </Ul>
        </ContentWrapper>
        <AnchorButton
          type="button"
          variant="accent"
          size="lg"
          href="https://intercom.help/dalma/fr/articles/6221662-comment-completer-sa-demande-de-remboursement"
          target="_blank"
          rel="noopener noreferrer"
          fluid
        >
          Consulter notre article détaillé
        </AnchorButton>
      </Content>
    </Container>
  );
}
