import { Outlet } from "src/components/01_atoms/outlet";
import { styled, theme } from "src/styles/stitches/theme";

export const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing16px} ${theme.spacing.spacing24px}`,
  borderBottom: `1px solid ${theme.colors.borderBaseSubdued}`,

  "@bp3": {
    padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing32px} 0`,
    borderBottom: "none",
  },
});

export const StyledEmptyDiv = styled("div", {
  width: theme.spacing.spacing24px,
  height: theme.spacing.spacing24px,
});

export const StyledContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowY: "auto",
});
