import { useState } from "react";
import { Outlet } from "src/components/01_atoms/outlet";
import { AlertDrawer } from "src/components/02_molecules/alert-drawer";
import { AlertModal } from "src/components/02_molecules/alert-modal";
import { HaveAnAccount } from "src/components/02_molecules/have-an-account";
import { BoostAppDownloadLogin } from "src/components/03_organisms/login/boost-app-download";
import { Referral } from "src/components/03_organisms/login/referral";
import { SignUpForm } from "src/components/03_organisms/login/signup-form";
import { LoginLayout } from "src/components/04_layouts/login-layout";
import { appDownloadLink } from "src/configs/external-links";
import { useRafOperation } from "src/hooks/use-raf-operation";
import { styled } from "src/styles/stitches/theme";

type SignInNewProps = {
  nextPage: string;
  previousPage: string;
};

const SigninContainer = styled(Outlet, {
  "@bp1": {
    flexDirection: "row !important",
  },
});

export function SignIn({ nextPage }: SignInNewProps) {
  const [isBlockFreeUserAlertModalOrDrawerOpen, setIsBlockFreeUserAlertModalOrDrawerOpen] =
    useState(false);

  const { isDuringCampaign, isOperation200Enabled } = useRafOperation();

  return (
    <>
      <SigninContainer vertical="0" align="stretch">
        <LoginLayout>
          <Outlet vertical="spacing64px" fluid>
            <SignUpForm
              nextPage={nextPage}
              setIsBlockFreeUserAlertModalOrDrawerOpen={setIsBlockFreeUserAlertModalOrDrawerOpen}
            />
            <HaveAnAccount />
          </Outlet>
        </LoginLayout>
        {isDuringCampaign && !isOperation200Enabled ? <Referral /> : <BoostAppDownloadLogin />}
      </SigninContainer>
      <AlertModal
        isOpen={isBlockFreeUserAlertModalOrDrawerOpen}
        label="Modale d'alerte d'identifiants associés à un compte gratuit"
        title="Vos identifiants sont associés à un compte gratuit"
        description="Vous pouvez uniquement accéder au contenu gratuit disponible sur l’application mobile Dalma."
        onDismiss={() => setIsBlockFreeUserAlertModalOrDrawerOpen(false)}
        buttonText1="J’ai compris"
        onDismissButton1={() => {
          setIsBlockFreeUserAlertModalOrDrawerOpen(false);
        }}
        hasImage
      />
      <AlertDrawer
        isOpen={isBlockFreeUserAlertModalOrDrawerOpen}
        title="Vos identifiants sont associés à un compte gratuit"
        description="Vous pouvez uniquement accéder au contenu gratuit disponible sur l’application mobile Dalma."
        onClose={() => setIsBlockFreeUserAlertModalOrDrawerOpen(false)}
        buttonText1="J’ai compris"
        onCloseButton1={() => setIsBlockFreeUserAlertModalOrDrawerOpen(false)}
        buttonText2="Ouvrir l’app"
        onCloseButton2={() => {
          setIsBlockFreeUserAlertModalOrDrawerOpen(false);
          window.open(appDownloadLink, "_blank", "noreferrer");
        }}
        hasImage
      />
    </>
  );
}
