import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { routes } from "src/routes";
import { fetchResetPassword } from "src/backApi/fetchResetPassword";
import { z } from "zod";
import { toast } from "src/components/01_atoms/toast";
import { useState } from "react";

export type LoginFields = "email" | "password";

// z object so we can relay on it for form validation using zod
export const loginErrorSchema = z.object({
  code: z.string(),
  message: z.string(),
  name: z.string(),
});

// Used to type Cognito signIn
export type Credentials = {
  email: string;
  password: string;
};
// - TODO add types to Auth
// - Reference to this types : https://github.com/aws-amplify/amplify-js/blob/de0441b4fa67409ccbc630c42890e2c58ee779fb/packages/amazon-cognito-identity-js/index.d.ts
type ChallengeName =
  | "CUSTOM_CHALLENGE"
  | "MFA_SETUP"
  | "NEW_PASSWORD_REQUIRED"
  | "SELECT_MFA_TYPE"
  | "SMS_MFA"
  | "SOFTWARE_TOKEN_MFA";
export type CognitoUser = {
  challengeName?: ChallengeName;
};

// function return types
type handleLoginErrorReturn = {
  fields: LoginFields[];
  message: string;
};

export type LoginErrorMessages = {
  wrongLogin: string;
  wrongVerificationCode: string;
  alreadyUsedVerificationCode: string;
  limitedExceeded: string;
  unsecuredPassword: string;
  verificationCodeError: string;
  temporaryPasswordExpire: string;
  default: string;
};

// Props
type UseLoginProps = {
  errorMessages: LoginErrorMessages;
};

const defaultErrorMessage = "Une erreur est survenue. Veuillez nous écrire à contact@dalma.co";
const parsedErrorMessages = Object.freeze({
  expiredTemporaryPassword: "Temporary password has expired and must be reset by an administrator.",
});

export const useLogin = ({ errorMessages }: UseLoginProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  // Send confirmation code & redirect to change password page
  async function resendConfirmationCode(email: string) {
    try {
      await Auth.forgotPassword(email);
      history.push({
        pathname: routes.changePassword,
        state: { email },
      });
    } catch (err) {
      toast.error(defaultErrorMessage);
    }
  }

  // Handling errors
  const defaultError = () => {
    toast.error(errorMessages.default);
    return {
      fields: ["email" as LoginFields, "password" as LoginFields],
      message: errorMessages.default,
    };
  };

  const handleLoginError = (error: unknown, email: string): handleLoginErrorReturn | void => {
    try {
      const parsedError = loginErrorSchema.parse(error);

      const errorHandlerDic: { [key: string]: () => any } = {
        NotAuthorizedException: async () => {
          const tmpPasswordExpired =
            parsedError.message === parsedErrorMessages.expiredTemporaryPassword;
          if (tmpPasswordExpired) {
            await fetchResetPassword(email);
            setIsLoading(false);
            toast.error(errorMessages.temporaryPasswordExpire + email);
          } else {
            toast.error(errorMessages.wrongLogin);
            return { fields: ["email", "password"], message: errorMessages.wrongLogin };
          }
        },
        CodeMismatchException: () => toast.error(errorMessages.wrongVerificationCode),
        ExpiredCodeException: () => toast.error(errorMessages.alreadyUsedVerificationCode),
        LimitExceededException: () => toast.error(errorMessages.limitedExceeded),
        InvalidParameterException: () => {
          toast.error(errorMessages.unsecuredPassword);
          return { fields: ["password"], message: errorMessages.unsecuredPassword };
        },
        PasswordResetRequiredException: () => resendConfirmationCode(email),
        default: defaultError,
      };

      const errorHandler = errorHandlerDic[parsedError.code] || errorHandlerDic.default;
      return errorHandler();
    } catch (error) {
      return defaultError();
    }
  };

  // Loging in
  const loginHandler = async <T extends Credentials>(
    credentials: T,
    callback: (user: CognitoUser, credentials: T) => Promise<void> | void
  ) => {
    setIsLoading(true);
    // HACK our new user pool is case sensitive, so we must lowercase emails
    credentials.email = credentials.email.toLowerCase();
    try {
      const user: CognitoUser = await Auth.signIn(credentials.email, credentials.password);
      callback(user, credentials);
    } catch (error) {
      return handleLoginError(error, credentials.email);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, loginHandler };
};
