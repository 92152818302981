import { API, Auth } from "aws-amplify";
import { toast } from "src/components/01_atoms/toast";

function handleIbanError() {
  return toast.error(
    "Oups, une erreur est survenue, veuillez vérifier que le format de l’IBAN est correct"
  );
}

export async function updateIban(iban: string, cb: () => void) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const response = await API.post("apiMySpaceGateway", "/payment-method", {
      headers: { Authorization: token },
      body: {
        iban: iban,
      },
    });
    if (response.success) {
      cb();
    } else {
      handleIbanError();
    }
    // eslint-disable-next-line no-empty
  } catch (error) {
    handleIbanError();
  }
}

export async function postIban(iban: string, cb: () => void) {
  const path = "/payment-method";
  const apiName = "apiMySpaceGateway";

  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: { Authorization: token },
      body: {
        iban: iban,
      },
    };
    const response = await API.post(apiName, path, myInit);
    if (response.success) {
      cb();
    } else {
      handleIbanError();
    }
    // eslint-disable-next-line no-empty
  } catch {
    handleIbanError();
  }
}
