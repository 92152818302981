import { ChangeEvent, FocusEvent } from "react";

// GENERAL
export type PasswordCriteriasChecker = <T extends string>(password: string) => T[];

export interface PasswordCriteria<C extends string, E extends string> {
  criteria: C;
  errorMessage: E;
}

export const passwordCriteriaErrors = Object.freeze({
  tooShort: "Votre mot de passe doit contenir au moins 8 caractères",
});

// FIRST CONNECTION PASSWORD
// -- Wording
// TODO in the futur: use i18n
const newPasswordCriteriasLabels = Object.freeze({
  sizeMin: "Au moins 8 caractères",
  uppercase: "Au moins une majuscule",
  lowercase: "Au moins une minuscule",
  special: "Au moins un caractère spécial (!@#$%^*)",
  number: "Au moins un chiffre",
});

const newPasswordCriteriasErrors = Object.freeze({
  tooShort: "Votre mot de passe doit contenir au moins 8 caractères",
  missingNumber: "Votre mot de passe doit contenir au moins 1 chiffre",
  missingLowercase: "Votre mot de passe doit contenir au moins 1 minuscule",
  missingUppercase: "Votre mot de passe doit contenir au moins 1 majuscule",
  missingSpecialChar: "Votre mot de passe doit contenir au moins 1 caractère spécial",
});

const npcLabels = newPasswordCriteriasLabels;
const npcErrors = newPasswordCriteriasErrors;
// -- Types from wording
type CriteriasType = typeof npcLabels[keyof typeof npcLabels];
type CriteriasErrorsType = typeof npcErrors[keyof typeof npcErrors];
type NewPasswordCriteria = PasswordCriteria<CriteriasType, CriteriasErrorsType>;

export const newPasswordCriterias: NewPasswordCriteria[] = [
  { criteria: npcLabels.sizeMin, errorMessage: npcErrors.tooShort },
  { criteria: npcLabels.number, errorMessage: npcErrors.missingNumber },
  { criteria: npcLabels.lowercase, errorMessage: npcErrors.missingLowercase },
  { criteria: npcLabels.uppercase, errorMessage: npcErrors.missingUppercase },
  { criteria: npcLabels.special, errorMessage: npcErrors.missingSpecialChar },
];

export function PasswordCheck(password: string) {
  const errors: CriteriasErrorsType[] = [];
  if (password.length < 8) errors.push(npcErrors.tooShort);
  if (!/[0-9]/.test(password)) errors.push(npcErrors.missingNumber);
  if (!/[a-z]/.test(password)) errors.push(npcErrors.missingLowercase);
  if (!/[A-Z]/.test(password)) errors.push(npcErrors.missingUppercase);
  if (!/[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(password))
    errors.push(npcErrors.missingSpecialChar);
  return errors;
}

export function valueLength(e: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) {
  return e?.currentTarget?.value.length;
}
