import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { MobileDrawerLayout } from "src/components/04_layouts/mobile-drawer-layout";
import { styled } from "src/styles/stitches/theme";

const Img = styled("img", {
  width: "10rem",
  height: "10rem",
});

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  onClose: () => void;
  buttonText1: string;
  onCloseButton1: () => void;
  buttonText2?: string;
  onCloseButton2?: () => void;
  hasImage?: boolean;
};

export function AlertDrawer({
  isOpen,
  title,
  description,
  onClose,
  buttonText1,
  onCloseButton1,
  buttonText2,
  onCloseButton2,
  hasImage,
}: Props) {
  return (
    <MobileDrawerLayout isVisible={isOpen} onClose={onClose}>
      <Outlet vertical="spacing40px" fluid>
        <Outlet vertical="spacing16px" align="center" fluid>
          {hasImage ? (
            <Img
              src="/assets/media/humans-advice-cat.svg"
              alt="Illustration d'une femme avec un chat sur la tête qui regardent un écran de téléphone"
            />
          ) : null}
          <Outlet vertical="spacing8px" align="center" fluid>
            <Text variant="display4-Bold" color="textBaseDefault" textAlign="center">
              {title}
            </Text>
            <Text variant="paragraph2-Medium" color="textBaseSubdued" textAlign="center">
              {description}
            </Text>
          </Outlet>
        </Outlet>
        <Outlet vertical="spacing16px" fluid>
          <Button variant="accent" size="md" fluid onClick={onCloseButton1}>
            {buttonText1}
          </Button>
          {buttonText2 ? (
            <Button variant="secondary" size="md" fluid onClick={onCloseButton2}>
              {buttonText2}
            </Button>
          ) : null}
        </Outlet>
      </Outlet>
    </MobileDrawerLayout>
  );
}
