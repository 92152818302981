export const dayOptions = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: i + 1 }));
export const monthOptions = [
  { value: 1, label: "Janvier" },
  { value: 2, label: "Février" },
  { value: 3, label: "Mars" },
  { value: 4, label: "Avril" },
  { value: 5, label: "Mai" },
  { value: 6, label: "Juin" },
  { value: 7, label: "Juillet" },
  { value: 8, label: "Août" },
  { value: 9, label: "Septembre" },
  { value: 10, label: "Octobre" },
  { value: 11, label: "Novembre" },
  { value: 12, label: "Décembre" },
];
export const yearOptions = Array.from({ length: 100 }, (_, i) => {
  const year = new Date().getFullYear() - i;
  return { value: year, label: year };
});

export const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: "1.5px solid #DBEBFF",
    borderRadius: "16px",
    padding: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 0px 0px 4px #DBEBFF",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#678AB8",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor: state.isSelected ? "#4d68f5" : provided.backgroundColor,
    color: state.isSelected ? "white" : provided.color,
    "&:hover": {
      backgroundColor: "#415ef2",
      color: "white",
    },
  }),
};
