import { API, Auth } from "aws-amplify";

export async function getVisitMotives() {
  const apiName = "apiClaimsGateway";
  const path = "/visit_motives";

  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: { Authorization: token },
      response: false,
    };

    const response = await API.get(apiName, path, myInit);
    return response;
  } catch (error) {
    // TODO error handling
    //@ts-expect-error message may not be property of error
    const message = error && typeof error === "object" ? error.message : undefined;
    const formattedError = new Error(message);
    throw formattedError;
  }
}
