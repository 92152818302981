import { zodResolver } from "@hookform/resolvers/zod";
import { useActor } from "@xstate/react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Spinner } from "src/components/01_atoms/spinner";
import { AlertDrawer } from "src/components/02_molecules/alert-drawer";
import { AlertModal } from "src/components/02_molecules/alert-modal";
import { RadioCard } from "src/components/02_molecules/selectable-card";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { useGetContract } from "src/hooks/data";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { claimsService, filterNeedInfoClaims } from "src/store/claims/machine";
import { styled, theme } from "src/styles/stitches/theme";
import { ClaimCategory, Claim_v2 } from "src/types";
import { matchIsContractDeffered } from "src/utils/data";
import { z } from "zod";
import { formatClaimActCategories } from "../../claims/_utils";
import { PetUuidForm } from "../pet-uuid-form";

const Form = styled("form", {
  flex: 1,
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing36px}`,
  },
});

const SpinnerWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const petSchema = z.object({
  pet: z.string({ required_error: "Veuillez sélectionner un animal." }),
});

export function PetSelectionForm() {
  const [isDefaultPetModalOpen, setIsDefaultPetModalOpen] = useState(false);

  const [
    isSingleNeedInfoClaimAlertModalOrDrawerOpen,
    setIsSingleNeedInfoClaimAlertModalOrDrawerOpen,
  ] = useState(false);
  const [
    isMultipleNeedInfoClaimsAlertModalOrDrawerOpen,
    setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen,
  ] = useState(false);
  const [singleNeedInfoClaim, setSingleNeedInfoClaim] = useState<Claim_v2>({
    claim_acts: [],
    claim_id: "",
    contract_id: "",
    date: "",
    status: "NEED_INFO",
    title: "",
    pet_name: "",
  });

  const {
    selectedPetToContext,
    setSelectedPetToContext,
    currentStep,
    setCurrentStep,
    closeRefundSidebar,
  } = useRefundSidebar();
  const { data: contracts, isLoading } = useGetContract();
  const { openClaimStatusSidebar } = useClaimStatusSidebar();

  const [state] = useActor(claimsService);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<Pet>({
    resolver: zodResolver(petSchema),
    mode: "onChange",
    defaultValues: {
      pet: selectedPetToContext?.contract_uuid,
    },
  });

  const claims = state.context.claims;

  if (!claims || !contracts) {
    return null;
  }

  let categories: ClaimCategory[] = [];
  let petType: "cat" | "dog" | undefined = undefined;

  type Pet = {
    pet: string;
  };

  const onSubmit: SubmitHandler<Pet> = (values) => {
    const selectedPetContract = contracts.find((contract) => {
      return contract.contract.contract_uuid === values.pet;
    });

    if (!selectedPetContract) return null;

    setSelectedPetToContext(selectedPetContract.pet);

    if (selectedPetContract.pet.is_pet_uuid_default) {
      setIsDefaultPetModalOpen(true);
      return;
    }

    petType = selectedPetContract.pet.pet_type;

    if (!petType) return null;

    const selectedPetClaims = claims.filter(
      (claim) => claim.pet_name === selectedPetContract.pet.pet_name
    );

    const selectedPetNeedInfoClaims = filterNeedInfoClaims(selectedPetClaims);

    const hasSingleNeedInfoClaim = selectedPetNeedInfoClaims.length === 1;
    setSingleNeedInfoClaim(selectedPetNeedInfoClaims[0]);

    const hasMultipleNeedInfoClaims = selectedPetNeedInfoClaims.length > 1;

    categories = singleNeedInfoClaim.claim_acts.map(({ actCategory }) => actCategory);

    if (hasSingleNeedInfoClaim) {
      setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(true);
      return;
    }

    if (hasMultipleNeedInfoClaims) {
      setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(true);
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledOutlet vertical={{ "@initial": "spacing32px", "@bp3": "0" }} fullHeight>
          <SidesheetContentHeader
            title="Pour quel animal&nbsp;?"
            subtitle="Demande de remboursement"
          />
          <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
            <Outlet vertical="spacing16px" fluid>
              {contracts.map((contract) => (
                <RadioCard
                  key={contract.contract.contract_uuid}
                  title={contract.pet.pet_name}
                  petType={contract.pet.pet_type}
                  petName={contract.pet.pet_name}
                  startDate={contract.contract.start_date}
                  value={contract.contract.contract_uuid}
                  checked={watch("pet") === contract.contract.contract_uuid}
                  {...register("pet")}
                  disabled={matchIsContractDeffered(contract)}
                />
              ))}
            </Outlet>
            <Button type="submit" variant="accent" size="lg" fluid disabled={!isValid}>
              Continuer
            </Button>
          </Outlet>
        </StyledOutlet>
      </Form>
      <AlertModal
        isOpen={isSingleNeedInfoClaimAlertModalOrDrawerOpen}
        label="Modale d'alerte d'une demande de remboursement à compléter"
        title="Des informations sont à compléter dans l’une de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onDismiss={() => setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false)}
        buttonText1="Voir la demande concernée"
        onDismissButton1={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          closeRefundSidebar();
          openClaimStatusSidebar({
            formattedCategories: formatClaimActCategories(categories),
            petType,
            claim: singleNeedInfoClaim,
          });
        }}
        buttonText2="Créer une nouvelle demande"
        onDismissButton2={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          setCurrentStep(currentStep + 1);
        }}
      />
      <AlertDrawer
        isOpen={isSingleNeedInfoClaimAlertModalOrDrawerOpen}
        title="Des informations sont à compléter dans l’une de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onClose={() => setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false)}
        buttonText1="Voir la demande concernée"
        onCloseButton1={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          closeRefundSidebar();
          openClaimStatusSidebar({
            formattedCategories: formatClaimActCategories(categories),
            petType,
            claim: singleNeedInfoClaim,
          });
        }}
        buttonText2="Créer une nouvelle demande"
        onCloseButton2={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          setCurrentStep(currentStep + 1);
        }}
      />
      <AlertModal
        isOpen={isMultipleNeedInfoClaimsAlertModalOrDrawerOpen}
        label="Modale d'alerte de plusieurs demandes de remboursement à compléter"
        title="Des informations sont à compléter dans plusieurs de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onDismiss={() => setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false)}
        buttonText1="Voir les demandes concernées"
        onDismissButton1={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          closeRefundSidebar();
        }}
        buttonText2="Créer une nouvelle demande"
        onDismissButton2={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          setCurrentStep(currentStep + 1);
        }}
      />
      <AlertDrawer
        isOpen={isMultipleNeedInfoClaimsAlertModalOrDrawerOpen}
        title="Des informations sont à compléter dans plusieurs de vos demandes"
        description="Si vous souhaitez compléter un remboursement en cours, repartez du remboursement en question"
        onClose={() => setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false)}
        buttonText1="Voir les demandes concernées"
        onCloseButton1={() => {
          setIsMultipleNeedInfoClaimsAlertModalOrDrawerOpen(false);
          closeRefundSidebar();
        }}
        buttonText2="Créer une nouvelle demande"
        onCloseButton2={() => {
          setIsSingleNeedInfoClaimAlertModalOrDrawerOpen(false);
          setCurrentStep(currentStep + 1);
        }}
      />
      <AlertModal
        isOpen={isDefaultPetModalOpen}
        label="Modale d'alerte du numéro d'identification à compléter"
        title={`Renseignez le numéro d'identification de ${selectedPetToContext?.pet_name}`}
        description="Afin de pouvoir vous rembourser, merci de renseigner le numéro de puce ou tatouage de votre animal. Sans cette information nous ne pourrons pas traiter votre demande."
        onDismiss={() => setIsDefaultPetModalOpen(false)}
      >
        {selectedPetToContext && (
          <PetUuidForm
            selectedPetContract={{
              stripe_contract_id: selectedPetToContext.stripe_contract_id,
              pet_idx: Number(selectedPetToContext.pet_idx),
              pet_uuid_type: selectedPetToContext.pet_uuid_type,
            }}
            setIsDefaultPetModalOpen={setIsDefaultPetModalOpen}
          />
        )}
      </AlertModal>
    </>
  );
}
