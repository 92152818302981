import { forwardRef } from "react";
import { AnchorButton } from "src/components/01_atoms/button";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

const A = styled("a", {
  borderRadius: theme.radii[20],

  "&:focus": {
    outline: "none",
    boxShadow: theme.boxShadow.focus,
  },
});

const Button = styled("button", {
  textAlign: "initial",
  borderRadius: theme.radii[20],

  "&:focus": {
    outline: "none",
    boxShadow: theme.boxShadow.focus,
  },
  "&:disabled": {
    opacity: "0.3",
  },
});

const Container = styled("div", {
  width: "14.5rem",
  height: "8.25rem",
  borderRadius: theme.radii[20],
  padding: theme.spacing.spacing24px,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
  overflow: "hidden",
  flexShrink: 0,
  cursor: "pointer",
  scrollSnapAlign: "center",
  transition: "all 0.15s ease-in",

  "@bp3": {
    height: "9.88rem",
  },

  variants: {
    background: {
      fillAccent: {
        background: theme.colors.fillAccent,
      },
      fillDecorativeYellow: {
        background: theme.colors.fillDecorativeYellow,
      },
      fillDecorativePurple: {
        background: theme.colors.fillDecorativePurple,
      },
    },
    hover: {
      blue: {
        boxShadow: `${theme.colors.borderSelected} 0px 0px 0px 0px`,
        "&:hover": {
          boxShadow: `${theme.colors.borderSelected} 0px 0px 0px 4px`,
        },
        "&:active": {
          boxShadow: `${theme.colors.borderSecondaryPressed} 0px 0px 0px 4px`,
          opacity: 0.8,
        },
      },
      yellow: {
        boxShadow: `${theme.colors.yellowDecorativeHover} 0px 0px 0px 0px`,
        "&:hover": {
          boxShadow: `${theme.colors.yellowDecorativeHover} 0px 0px 0px 4px`,
        },
        "&:active": {
          boxShadow: `${theme.colors.borderSecondaryPressed} 0px 0px 0px 4px`,
          opacity: 0.8,
        },
      },
      purple: {
        boxShadow: `${theme.colors.purpleDecorativeHover} 0px 0px 0px 0px`,
        "&:hover": {
          boxShadow: `${theme.colors.purpleDecorativeHover} 0px 0px 0px 4px`,
        },
        "&:active": {
          boxShadow: `${theme.colors.borderSecondaryPressed} 0px 0px 0px 4px`,
          opacity: 0.8,
        },
      },
    },
  },
});

const SubtitleWrapper = styled("div", {
  display: "none",

  "@bp3": {
    display: "flex",
  },
});

const Image = styled("img", {
  position: "absolute",
  width: "5rem",
  height: "5rem",
  bottom: 0,
  right: "1rem",
  objectFit: "contain",

  "@bp3": {
    width: "7.5rem",
    height: "7.5rem",
    right: "0.56rem",
  },

  variants: {
    imagePosition: {
      raf: {
        right: 0,
        bottom: 0,

        "@bp3": {
          width: "6.25rem",
          bottom: "-0.628rem",
        },
      },
    },
  },
});

type Props = {
  title: string;
  background: "fillAccent" | "fillDecorativeYellow" | "fillDecorativePurple";
  image: string;
  imageDescription: string;
  link?: string;
  onClick?: () => void;
  hover: "blue" | "yellow" | "purple";
  imagePosition?: "raf";
  isDisabled?: boolean;
};

export const MediaCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      background,
      image,
      imageDescription,
      link,
      onClick,
      hover,
      imagePosition,
      isDisabled,
      ...rest
    },
    ref
  ) => {
    return link ? (
      <A href={link} target="_blank" rel="noopener noreferrer" title={title} {...rest}>
        <Container background={background} hover={hover} ref={ref}>
          <Text variant="display5-Bold" color="textSecondaryDefault">
            {title}
          </Text>
          <SubtitleWrapper>
            <AnchorButton as="div" variant="smallActionSecondary" size="md" inline>
              Découvrir
            </AnchorButton>
          </SubtitleWrapper>
          <Image src={image} alt={imageDescription} imagePosition={imagePosition} />
        </Container>
      </A>
    ) : (
      <Button onClick={onClick} disabled={isDisabled} {...rest}>
        <Container background={background} hover={hover} ref={ref}>
          <Text variant="display5-Bold" color="textSecondaryDefault">
            {title}
          </Text>
          <SubtitleWrapper>
            <AnchorButton as="div" variant="smallActionSecondary" size="md" inline>
              Découvrir
            </AnchorButton>
          </SubtitleWrapper>
          <Image src={image} alt={imageDescription} imagePosition={imagePosition} />
        </Container>
      </Button>
    );
  }
);

MediaCard.displayName = "MediaCard";
