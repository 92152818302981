import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { toast } from "src/components/01_atoms/toast";
import { routes } from "src/routes";
import { CognitoUser, Credentials, LoginErrorMessages, useLogin } from "./_useLogin";

interface FirstSignin extends Credentials {
  newPassword: string;
}

type UseSignInProps = {
  firstConnectionLink?: string;
  nextPage: string;
};

// TODO: i18n
const FirstSigninErrorMessages: LoginErrorMessages = Object.freeze({
  wrongLogin: "Email ou mot de passe temporaire incorrect.",
  wrongVerificationCode: "Le code de vérification est incorrect.",
  alreadyUsedVerificationCode: "Le code de vérification a déjà été utilisé.",
  limitedExceeded:
    "Vous avez atteint le plafond de tentatives de réinitialisation du mot de passe. Veuillez patienter 1h ou écrire à contact@dalma.co",
  unsecuredPassword:
    "Le mot de passe n'est pas sécurisé. Il doit contenir au moins 8 lettres, majuscule et minuscule, caractères spéciaux.",
  verificationCodeError:
    "Le mot de passe a expiré. Un code de vérification vous a été envoyé par mail pour modifier votre mot de passe",
  temporaryPasswordExpire:
    "Votre mot de passe temporaire a expiré.\n Un nouveau mot de passe temporaire vous a été envoyé à : ",
  default:
    "Une erreur est survenue. Verifiez que vous saisi le 'mot de passe temporaire' qui vous a été envoyé par mail et renseigné un mot de passe contenant au moins 8 lettres, majuscule et miniscule, caractères spéciaux.",
});

export const useFirstSignIn = ({ nextPage }: UseSignInProps) => {
  const history = useHistory();
  const { isLoading, loginHandler } = useLogin({ errorMessages: FirstSigninErrorMessages });

  const handleFirstSignIn = async (user: CognitoUser, values: FirstSignin) => {
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      // if it fails, errors will be handled in the _useLogin/login function
      await Auth.completeNewPassword(user, values.newPassword);
      toast.success("Votre mot de passe à bien été modifié");
      return history.push(nextPage);
    }

    // User tried to setup the password but it was already done
    toast.error("Votre mot de passe n'a pas pu être modifié");
    return history.push(routes.login);
  };

  const signIn = async (values: FirstSignin) => loginHandler(values, handleFirstSignIn);
  return { isLoading, signIn };
};
