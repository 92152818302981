import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { getClaimTag } from "src/components/02_molecules/claim/_utils";
import { Tag } from "src/components/02_molecules/tag";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";

const StyledOutlet = styled(Outlet, {
  "@bp3": {
    paddingBottom: `${theme.spacing.spacing16px}`,
  },
});

const StyledText = styled(Text, {
  display: "none",

  "@bp3": {
    display: "block",
    padding: `${theme.spacing.spacing4px} 0`,
  },
});

type Props = {
  withStatusTag?: boolean;
  title: string | undefined;
};

export function ClaimStatusSidebarContentHeader({ withStatusTag = true, title }: Props) {
  const { selectedClaim } = useClaimStatusSidebar();

  if (!selectedClaim) return null;

  const tag = getClaimTag(selectedClaim);

  return (
    <StyledOutlet justify="spaceBetween" align={{ "@initial": "center", "@bp3": "start" }} fluid>
      <Outlet vertical={{ "@initial": 0, "@bp3": "spacing8px" }}>
        <StyledText variant="paragraph2-Bold" color="textBaseSubdued">
          Mes remboursements
        </StyledText>
        <Text
          variant={{ "@initial": "display4-Bold", "@bp3": "display3-Bold" }}
          color="textBaseDefault"
        >
          {title}
        </Text>
      </Outlet>
      {withStatusTag ? <Tag variant={tag.variant}>{tag.label}</Tag> : null}
    </StyledOutlet>
  );
}
