export const catVarietyList = [
  {
    key: "alley_cat",
    label: "Gouttière",
  },
  {
    key: "unknown",
    label: "Inconnu",
  },
  {
    key: "mixed_breed",
    label: "Croisé",
  },
  {
    key: "abyssin",
    label: "Abyssin",
  },
  {
    key: "american_bobtail",
    label: "American bobtail",
  },
  {
    key: "american_bobtail_a_poil_court",
    label: "American Bobtail à poil court",
  },
  {
    key: "american_bobtail_a_poil_long",
    label: "American Bobtail à poil long",
  },
  {
    key: "american_curl",
    label: "American Curl",
  },
  {
    key: "american_curl_a_poil_court",
    label: "American Curl à poil court",
  },
  {
    key: "american_curl_a_poil_long",
    label: "American Curl à poil long",
  },
  {
    key: "american_shorthair",
    label: "American Shorthair",
  },
  {
    key: "american_whitehair",
    label: "American Whitehair",
  },
  {
    key: "american_wirehair",
    label: "American Wirehair",
  },
  {
    key: "angora_turc",
    label: "Angora Turc",
  },
  {
    key: "asian",
    label: "Asian",
  },
  {
    key: "asian_longhair",
    label: "Asian Longhair",
  },
  {
    key: "asian_shorthair",
    label: "Asian Shorthair",
  },
  {
    key: "australian_mist",
    label: "Australian Mist",
  },
  {
    key: "balinais",
    label: "Balinais",
  },
  {
    key: "bengal",
    label: "Bengal",
  },
  {
    key: "birman",
    label: "Birman",
  },
  {
    key: "bleu_russe",
    label: "Bleu russe",
  },
  {
    key: "bobtail_des_kouriles",
    label: "Bobtail des Kouriles",
  },
  {
    key: "bombay",
    label: "Bombay",
  },
  {
    key: "brazilian_shorthair",
    label: "Brazilian Shorthair",
  },
  {
    key: "british_longhair",
    label: "British Longhair",
  },
  {
    key: "british_shorthair",
    label: "British Shorthair",
  },
  {
    key: "burmese",
    label: "Burmese",
  },
  {
    key: "burmese_americain",
    label: "Burmese Américain",
  },
  {
    key: "burmese_anglais",
    label: "Burmese anglais",
  },
  {
    key: "burmilla",
    label: "Burmilla",
  },
  {
    key: "california_spangled",
    label: "California Spangled",
  },
  {
    key: "california_toyger",
    label: "California Toyger",
  },
  {
    key: "californian_rex",
    label: "Californian Rex",
  },
  {
    key: "caracat",
    label: "Caracat",
  },
  {
    key: "ceylan",
    label: "Ceylan",
  },
  {
    key: "chartreux",
    label: "Chartreux",
  },
  {
    key: "chat_caniche",
    label: "Chat Caniche",
  },
  {
    key: "chat_chinois",
    label: "Chat chinois",
  },
  {
    key: "chat_de_cafrerie",
    label: "Chat de Cafrerie",
  },
  {
    key: "chat_de_pallas",
    label: "Chat de Pallas",
  },
  {
    key: "chat_des_forets_norvegiennes",
    label: "Chat des Forêts Norvégiennes",
  },
  {
    key: "chat_du_sri-lanka",
    label: "Chat du Sri-Lanka",
  },
  {
    key: "chat_haret",
    label: "Chat Haret",
  },
  {
    key: "chat_jaune",
    label: "Chat Jaune",
  },
  {
    key: "chat_nu",
    label: "Chat nu",
  },
  {
    key: "chat_rubigineux",
    label: "Chat rubigineux",
  },
  {
    key: "chat_sauvage_d_europe",
    label: "Chat Sauvage d'Europe",
  },
  {
    key: "chat_tigre",
    label: "Chat Tigre",
  },
  {
    key: "chausie",
    label: "Chausie",
  },
  {
    key: "colourpoint_shorthair",
    label: "Colourpoint Shorthair",
  },
  {
    key: "cornish_rex",
    label: "Cornish Rex",
  },
  {
    key: "cymric",
    label: "Cymric",
  },
  {
    key: "devon_rex",
    label: "Devon Rex",
  },
  {
    key: "donskoy",
    label: "Donskoy",
  },
  {
    key: "european_shorthair",
    label: "European Shorthair",
  },
  {
    key: "europeen",
    label: "Européen",
  },
  {
    key: "exotic_shorthair",
    label: "Exotic Shorthair",
  },
  {
    key: "foldex",
    label: "Foldex",
  },
  {
    key: "german_rex",
    label: "German Rex",
  },
  {
    key: "havana",
    label: "Havana",
  },
  {
    key: "havana_brown",
    label: "Havana Brown",
  },
  {
    key: "highland",
    label: "Highland",
  },
  {
    key: "highland_fold",
    label: "Highland Fold",
  },
  {
    key: "highland_straight",
    label: "Highland Straight",
  },
  {
    key: "highlander",
    label: "Highlander",
  },
  {
    key: "highlander_lynx",
    label: "Highlander Lynx",
  },
  {
    key: "himalayen",
    label: "Himalayen",
  },
  {
    key: "japanese_bobtail",
    label: "Japanese Bobtail",
  },
  {
    key: "japanese_bobtail_a_poil_court",
    label: "Japanese Bobtail à poil court",
  },
  {
    key: "japanese_bobtail_a_poil_long",
    label: "Japanese Bobtail à poil long",
  },
  {
    key: "javanais",
    label: "Javanais",
  },
  {
    key: "kanaani",
    label: "Kanaani",
  },
  {
    key: "karelian_bobtail",
    label: "Karelian Bobtail",
  },
  {
    key: "khao_manee",
    label: "Khao Manee",
  },
  {
    key: "korat",
    label: "Korat",
  },
  {
    key: "kurilian_bobtail",
    label: "Kurilian Bobtail",
  },
  {
    key: "kurilian_bobtail_a_poil_court",
    label: "Kurilian Bobtail à poil court",
  },
  {
    key: "kurilian_bobtail_a_poil_long",
    label: "Kurilian Bobtail à poil long",
  },
  {
    key: "laperm",
    label: "Laperm",
  },
  {
    key: "laperm_a_poil_court",
    label: "Laperm à poil court",
  },
  {
    key: "laperm_a_poil_long",
    label: "Laperm à poil long",
  },
  {
    key: "lykoi",
    label: "Lykoï",
  },
  {
    key: "lynx_domestique",
    label: "Lynx Domestique",
  },
  {
    key: "maine_coon",
    label: "Maine Coon",
  },
  {
    key: "malayen",
    label: "Malayen",
  },
  {
    key: "mandarin",
    label: "Mandarin",
  },
  {
    key: "manul",
    label: "Manul",
  },
  {
    key: "manx",
    label: "Manx",
  },
  {
    key: "margay",
    label: "Margay",
  },
  {
    key: "mau_egyptien",
    label: "Mau Egyptien",
  },
  {
    key: "mekong_bobtail",
    label: "Mekong Bobtail",
  },
  {
    key: "minskin",
    label: "Minskin",
  },
  {
    key: "munchkin",
    label: "Munchkin",
  },
  {
    key: "munchkin_a_poil_court",
    label: "Munchkin à poil court",
  },
  {
    key: "munchkin_a_poil_long",
    label: "Munchkin à poil long",
  },
  {
    key: "nebelung",
    label: "Nebelung",
  },
  {
    key: "norvegien",
    label: "Norvégien",
  },
  {
    key: "ocicat",
    label: "Ocicat",
  },
  {
    key: "ojos_azules",
    label: "Ojos Azules",
  },
  {
    key: "oriental",
    label: "Oriental",
  },
  {
    key: "persan",
    label: "Persan",
  },
  {
    key: "peterbald",
    label: "Peterbald",
  },
  {
    key: "pixie_bob",
    label: "Pixie bob",
  },
  {
    key: "pixiebob_a_poil_court",
    label: "Pixiebob à poil court",
  },
  {
    key: "pixiebob_a_poil_long",
    label: "Pixiebob à poil long",
  },
  {
    key: "poodle_cat_(pudelkatze)",
    label: "Poodle Cat (Pudelkatze)",
  },
  {
    key: "ragamuffin",
    label: "Ragamuffin",
  },
  {
    key: "ragdoll",
    label: "Ragdoll",
  },
  {
    key: "rex_devon",
    label: "Rex Devon",
  },
  {
    key: "russe",
    label: "Russe",
  },
  {
    key: "russian_bobtail",
    label: "Russian Bobtail",
  },
  {
    key: "sacre_de_birmanie",
    label: "Sacré de Birmanie",
  },
  {
    key: "safari",
    label: "Safari",
  },
  {
    key: "savannah",
    label: "Savannah",
  },
  {
    key: "scottish_fold",
    label: "Scottish Fold",
  },
  {
    key: "scottish_fold_long_hair",
    label: "Scottish Fold Long Hair",
  },
  {
    key: "scottish_straight",
    label: "Scottish Straight",
  },
  {
    key: "selkirk_rex_a_poil_court",
    label: "Selkirk Rex à poil court",
  },
  {
    key: "selkirk_rex_a_poil_long",
    label: "Selkirk Rex à poil long",
  },
  {
    key: "selkirk_straight_a_poil_court",
    label: "Selkirk Straight à poil court",
  },
  {
    key: "selkirk_straight_a_poil_long",
    label: "Selkirk Straight à poil long",
  },
  {
    key: "selkrirk_rex",
    label: "Selkrirk Rex",
  },
  {
    key: "serengeti",
    label: "Serengeti",
  },
  {
    key: "seychellois",
    label: "Seychellois",
  },
  {
    key: "siamois",
    label: "Siamois",
  },
  {
    key: "siberien",
    label: "Sibérien",
  },
  {
    key: "singapura",
    label: "Singapura",
  },
  {
    key: "skogkatt",
    label: "Skogkatt",
  },
  {
    key: "skookum",
    label: "Skookum",
  },
  {
    key: "snowshoe",
    label: "Snowshoe",
  },
  {
    key: "sokoke",
    label: "Sokoké",
  },
  {
    key: "somali",
    label: "Somali",
  },
  {
    key: "sphynx",
    label: "Sphynx",
  },
  {
    key: "spotted_mist",
    label: "Spotted Mist",
  },
  {
    key: "thai",
    label: "Thaï",
  },
  {
    key: "tiffany",
    label: "Tiffany",
  },
  {
    key: "tiffany_americain",
    label: "Tiffany Américain",
  },
  {
    key: "tonkinois",
    label: "Tonkinois",
  },
  {
    key: "tonkinois_a_poil_court",
    label: "Tonkinois à poil court",
  },
  {
    key: "tonkinois_a_poil_long",
    label: "Tonkinois à poil long",
  },
  {
    key: "toyger",
    label: "Toyger",
  },
  {
    key: "turc_du_lac_de_van",
    label: "Turc du lac de van",
  },
  {
    key: "type_oriental",
    label: "Type oriental",
  },
  {
    key: "ural_rex",
    label: "Ural Rex",
  },
  {
    key: "york_chocolate",
    label: "York Chocolate",
  },
  {
    key: "american_shorthair_wirehair",
    label: "American shorthair et wirehair",
  },
  {
    key: "balinais_mandarin",
    label: "Balinais et Mandarin",
  },
  {
    key: "bombay_burmese_americain",
    label: "Bombay et Burmese americain",
  },
  {
    key: "british",
    label: "British",
  },
  {
    key: "burmilla_asian",
    label: "Burmilla et Asian",
  },
  {
    key: "manx_cymric",
    label: "Manx et Cymric",
  },
  {
    key: "russe_nebelung",
    label: "Russe et Nebelung",
  },
  {
    key: "scottish_highland",
    label: "Scottish et Highland",
  },
  {
    key: "selkirk_rex",
    label: "Selkirk Rex",
  },
  {
    key: "york_chocolat",
    label: "York chocolat",
  },
  {
    key: "chat_de_gouttiere",
    label: "Chat de Gouttière",
  },
  {
    key: "chat_de_maison",
    label: "Chat de Maison",
  },
  {
    key: "croise",
    label: "Croisé",
  },
  {
    key: "batard",
    label: "Bâtard",
  },
  {
    key: "bunny_cat",
    label: "Bunny Cat",
  },
  {
    key: "aby",
    label: "Aby",
  },
  {
    key: "anatoli",
    label: "Anatoli",
  },
  {
    key: "anatolian_shorthair",
    label: "Anatolian Shorthair",
  },
  {
    key: "turkish_shorthair",
    label: "Turkish Shorthair",
  },
  {
    key: "bobtail_americain",
    label: "Bobtail Américain",
  },
  {
    key: "bobtail_russe",
    label: "Bobtail Russe",
  },
  {
    key: "kurile_islands_bobtail",
    label: "Kurile Islands Bobtail",
  },
  {
    key: "californian_spangled",
    label: "Californian Spangled",
  },
  {
    key: "spangled_californien",
    label: "Spangled Californien",
  },
  {
    key: "chantilly/tiffany",
    label: "Chantilly/Tiffany",
  },
  {
    key: "chat_des_bois_norvegien",
    label: "Chat des Bois Norvégien",
  },
  {
    key: "norsk_skogkatt",
    label: "Norsk Skogkatt",
  },
  {
    key: "colorpoint_shorthair",
    label: "Colorpoint shorthair",
  },
  {
    key: "rex_de_cornouailles",
    label: "Rex de Cornouailles",
  },
  {
    key: "don_bald_cat",
    label: "Don Bald Cat",
  },
  {
    key: "don_hairless",
    label: "Don Hairless",
  },
  {
    key: "sphynx_du_don",
    label: "Sphynx du Don",
  },
  {
    key: "don_sphynx",
    label: "Don Sphynx",
  },
  {
    key: "celtic_shorthair",
    label: "Celtic Shorthair",
  },
  {
    key: "chat_europeen",
    label: "Chat Européen",
  },
  {
    key: "colourpoint",
    label: "Colourpoint",
  },
  {
    key: "colorpoint",
    label: "Colorpoint",
  },
  {
    key: "oriental_longhair",
    label: "Oriental longhair",
  },
  {
    key: "mau_arabe",
    label: "Mau Arabe",
  },
  {
    key: "oriental_shorthair",
    label: "Oriental Shorthair",
  },
  {
    key: "pixie-bob",
    label: "Pixie-bob",
  },
  {
    key: "pixiebob",
    label: "Pixiebob",
  },
  {
    key: "turc_de_van",
    label: "Turc de Van",
  },
  {
    key: "chat_turc_de_l_ile_de_van",
    label: "Chat Turc de l'Ile de Van",
  },
  {
    key: "turc_van",
    label: "Turc Van",
  },
  {
    key: "turkish_van",
    label: "Turkish Van",
  },
  {
    key: "chat_turc_du_lac_de_van",
    label: "Chat Turc du Lac de Van",
  },
];
