import { API, Auth } from "aws-amplify";
import { Contract, Pet } from "src/types";

import { fetchMySpace } from "./fetchMySpace";
import { Contracts } from "src/pages/Account/Contracts";

export type SetContracts =
  | React.Dispatch<React.SetStateAction<Contract[] | null>>
  | React.Dispatch<React.SetStateAction<Pet[] | null>>;

/**
 * @deprecated
 */
export function _deprecated_fetchContracts(setContracts: SetContracts) {
  const path = "/contracts";
  fetchMySpace(path, setContracts);
}

export type ContractsResponse = {
  success: boolean;
  data: Contract[];
};

export async function fetchContractsPromise(): Promise<ContractsResponse> {
  const apiName = "apiMySpaceGateway";
  const path = "/contracts";

  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: { Authorization: token },
    response: false,
  };

  return API.get(apiName, path, myInit);
}
