import { AnchorButton } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { MobileDrawerLayout } from "src/components/04_layouts/mobile-drawer-layout";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";

const Ul = styled("ul", {
  marginLeft: "1rem",
  fontSize: theme.fontSizes.textParagraph2,
  lineHeight: 1.285,
  letterSpacing: theme.letterSpacings.regular,
  fontWeight: theme.fontWeights.medium,
  color: theme.colors.textBaseSubdued,
});

export function NeedInfoDetailMobileDrawer() {
  const { isNeedInfoDetailSidebarOpen, setIsNeedInfoDetailSidebarOpen } = useClaimStatusSidebar();
  return (
    <MobileDrawerLayout
      isVisible={isNeedInfoDetailSidebarOpen}
      onClose={() => setIsNeedInfoDetailSidebarOpen(false)}
    >
      <Outlet vertical="spacing32px">
        <Outlet vertical="spacing8px">
          <Text variant="display4-Bold" color="textBaseDefault">
            Qu’est-ce qu’une demande incomplète&nbsp;?
          </Text>
          <Text variant="paragraph2-Medium" color="textBaseSubdued">
            Il nous manque une information pour traiter votre demande. Voici la liste des manques
            les plus courants :
          </Text>
          <br />
          <Ul>
            <li>Absence des justificatifs de frais</li>
            <li>Absence de la feuille de soins Dalma</li>
            <li>Absence d’ordonnance s’il y a une prescription de médicaments</li>
            <li>Besoin de justificatifs médicaux supplémentaires</li>
          </Ul>
        </Outlet>
        <AnchorButton
          type="button"
          variant="accent"
          size="md"
          href="https://intercom.help/dalma/fr/articles/6221662-comment-completer-sa-demande-de-remboursement"
          target="_blank"
          rel="noopener noreferrer"
          fluid
        >
          Consulter notre article détaillé
        </AnchorButton>
      </Outlet>
    </MobileDrawerLayout>
  );
}
