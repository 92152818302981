import React, { MouseEventHandler } from "react";
import { Flex, Divider, Box, Button } from "@chakra-ui/react";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import CheckCircle from "@iconscout/react-unicons/icons/uil-check-circle";
import { Title } from "../texts/Title";
import { Texts } from "../texts/Texts";
import { OptionsCard } from "src/pages/Login/FinalOverview";
import { WithChildren } from "src/types";

type PriceCardProps = WithChildren<{
  small?: boolean;
  onPress?: MouseEventHandler<HTMLButtonElement>;
  w?: string;
  m?: string;
  bg?: string;
  boxShadow?: string;
  rounded?: string;
  color?: string;
  iconColor?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  cardType?: string;
  editable?: boolean;
  options?: OptionsCard[];
  price?: number;
  formulaPrice?: number;
  rate?: string;
  health_limit?: string;
  cardTitle?: string;
  packageOptions?: React.ReactNode;
  isLoading?: boolean;
  formulaName?: string;
  strikeOutPrice?: number;
  promotionApplicated?: number;
  zIndex?: React.ComponentProps<typeof Flex>["zIndex"];
  minW?: React.ComponentProps<typeof Flex>["minW"];
  minH?: React.ComponentProps<typeof Flex>["minH"];
  mr?: React.ComponentProps<typeof Flex>["mr"];
  mx?: React.ComponentProps<typeof Flex>["mx"];
}>;

export const PriceCard = ({
  formulaName = "",
  onPress,
  small = false,
  w = "300px",
  minW = "270px",
  minH = "52px",
  rounded = "card",
  boxShadow = "",
  bg = "#144283",
  iconColor = "white",
  cardType = "basket",
  editable = false,
  price = 0,
  formulaPrice = 0,
  health_limit = "1500",
  rate = "80%",
  options = [],
  cardTitle = "Votre prix",
  strikeOutPrice = 0,
  promotionApplicated = 0,
  ...props
}: PriceCardProps) => (
  <Flex
    minH={minH}
    minW={minW}
    w={w}
    mx={25}
    mb="4"
    flex={1}
    borderRadius={rounded}
    boxShadow={boxShadow}
    bg={bg}
    rounded={rounded}
    {...props}
  >
    <Flex flex={1} direction="column">
      <Flex pl="7" pt="4" pr="3">
        <Flex pt="4" pb="3">
          <Texts color="white">{cardTitle}</Texts>
        </Flex>
        {editable && (
          <Flex flex={1} justifyContent="flex-end" h="100%" align="center">
            <Button bg="transparent" borderColor="transparent" onClick={onPress}>
              <EditIcon size="19px" color={iconColor} />
            </Button>
          </Flex>
        )}
      </Flex>

      <Flex px="7" justifyContent="flex-end">
        <Title fontSize="50px" color="white" lineHeight="10">
          {Math.trunc(price)}
        </Title>
        <Flex flex={1} direction="column" pl="1">
          <Flex flex={1} alignItems="flex-end" align="flex-end" mb="5px">
            <Title fontSize="16px" color="white" textAlign="left">
              {`,${(price - Math.trunc(price)).toFixed(2).split(".")[1]}€`}
            </Title>
            <Texts ml="1" fontSize="15px" fontWeight="200" color="#F0F0F0" textAlign="left">
              TTC/mois
            </Texts>
          </Flex>
        </Flex>
        {promotionApplicated > 0 && (
          <Flex textAlign="right" direction="column" align="flex-end" flex={1}>
            {promotionApplicated && (
              <Flex>
                <Title fontSize="16px" color="#FFB2EF">
                  -{promotionApplicated}%
                </Title>
              </Flex>
            )}
            {strikeOutPrice && (
              <Flex>
                <Title color="#FFB2EF" fontSize="16px" textDecoration="line-through">
                  {strikeOutPrice}€
                </Title>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      <Flex px="7">
        <Divider my="3" />
      </Flex>

      <Box px="7" w="100%" zIndex="100">
        <Flex flex={1} w="100%">
          <Flex w="100%" flex={1} direction="column">
            {cardType === "basket" ? (
              <Flex flex={1} direction="column">
                <Flex flex={1}>
                  <Flex flex={4} align="center">
                    {cardType !== "basket" && !small && (
                      <Flex mr={["2", "2", "2", "1", "2", "2"]}>
                        <CheckCircle size="18px" color="white" />
                      </Flex>
                    )}
                    <Title fontSize={small ? "14px" : "16px"} color="white" textAlign="left">
                      Formule {formulaName}
                    </Title>
                  </Flex>
                  <Flex flex={1} justifyContent="flex-end">
                    {cardType === "basket" && (
                      <Title fontSize="14px" color="white">
                        {formulaPrice}€
                      </Title>
                    )}
                  </Flex>
                </Flex>

                <Flex flex={1} w="100%" mt="2">
                  <Flex flex={4}>
                    <Texts fontSize={small ? "12px" : "md"} color="white">
                      Taux de couverture
                    </Texts>
                  </Flex>
                  <Flex flex={1} justifyContent="flex-end">
                    <Texts fontSize={small ? "12px" : "md"} color="white">{`${rate}`}</Texts>
                  </Flex>
                </Flex>

                <Flex flex={1} w="100%" mt="2">
                  <Flex flex={4}>
                    <Texts fontSize={small ? "12px" : "md"} color="white">
                      Plafond annuel
                    </Texts>
                  </Flex>
                  <Flex flex={1} justifyContent="flex-end">
                    <Texts
                      fontSize={small ? "12px" : "md"}
                      color="white"
                    >{`${health_limit}€`}</Texts>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <>
                <Flex flex={1} h="25px" mt="3" mb="2" alignItems="center">
                  <CheckCircle size="16px" color="white" />
                  <Texts ml="8px" color="white">
                    Pack accident
                  </Texts>
                </Flex>
                <Flex flex={1} mb="2">
                  <CheckCircle size="16px" color="white" />
                  <Texts ml="8px" color="white">
                    Pack maladie
                  </Texts>
                </Flex>
                <Flex flex={1} mb="3">
                  <CheckCircle size="16px" color="white" />
                  <Texts ml="8px" color="white">
                    Responsabilité civile
                  </Texts>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Box>

      {small || cardType === "basket" ? (
        <Box px="7" my={!options || options.length === 0 ? "3" : ""} w="100%" zIndex="100">
          {options && options.length > 0 && (
            <>
              <Divider my="3" />
              <Flex flex={1}>
                <Flex flex={1} direction="column">
                  <Flex flex={1} h="25px" mt="1" mb="2" alignItems="center">
                    <Flex flex={4}>
                      <Texts fontWeight="700" fontSize="12px" color="#688AB8">
                        Mes options
                      </Texts>
                    </Flex>
                  </Flex>
                  {options.map((e, index) => (
                    <Flex
                      flex={1}
                      mb={index === options.length - 1 ? "3" : ""}
                      key={e.optionName + index.toString()}
                    >
                      <Flex flex={4} mb="1" align="center">
                        <Flex>
                          <CheckCircle size="16px" color="white" />
                        </Flex>
                        <Flex h="100%">
                          <Title ml="8px" fontSize="14px" color="white">
                            {e.optionName}
                          </Title>
                        </Flex>
                      </Flex>
                      <Flex flex={1} justifyContent="flex-end">
                        <Title fontSize="14px" color="white">{`+${e.optionPrice}€`}</Title>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </>
          )}
        </Box>
      ) : (
        <Box px="7" w="100%" zIndex="100">
          <Divider my="2" w="100%" justifyContent="center" />
          <Flex flex={1}>
            <Flex flex={1} direction="column">
              <Flex flex={1} h="25px" mt="3" mb="2" alignItems="center">
                <Flex flex={4}>
                  <Texts color="white">Taux de couverture</Texts>
                </Flex>
                <Flex flex={1} justifyContent="flex-end">
                  <Texts color="white">80%</Texts>
                </Flex>
              </Flex>
              <Flex flex={1} mb="2">
                <Flex flex={4}>
                  <Texts color="white">Plafond annuel</Texts>
                </Flex>
                <Flex flex={1} justifyContent="flex-end">
                  <Texts color="white">1500€</Texts>
                </Flex>
              </Flex>
              <Flex flex={1} h="25px" mb="3">
                <Flex flex={4}>
                  <Texts color="white">Franchise annuelle</Texts>
                </Flex>
                <Flex flex={1} justifyContent="flex-end">
                  <Texts color="white">0€</Texts>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </Flex>
  </Flex>
);
