import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";
import { Claim_v2 } from "src/types";
import { ClaimsList } from "../claims-list";

const StyledText = styled(Text, {
  padding: `0 ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: 0,
  },
});

type Props = {
  year: number;
  claims: Claim_v2[];
};

export function ClaimsListByYear({ year, claims }: Props) {
  const displayYear = year !== new Date().getFullYear();

  return (
    <Outlet vertical="spacing16px" fluid css={{ marginTop: theme.spacing.spacing16px }}>
      {displayYear && (
        <StyledText variant="paragraph3-Bold" color="textBaseSubdued">
          {year}
        </StyledText>
      )}
      {claims && <ClaimsList claims={claims} />}
    </Outlet>
  );
}
