import { useHistory } from "react-router-dom";
import { AnchorButton, Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { useLogOut } from "src/hooks/signin/_use-log-out";
import { routes } from "src/routes";
import { Tooltip } from "src/components/tooltip";
import { ReferralModalV3 } from "src/components/02_molecules/referral-modal-v3/referral-modal-v3";
import { useState } from "react";
import { useGetContact } from "src/hooks/data"

export function RightSidebarLinks() {
  const history = useHistory();
  const handleLogout = useLogOut();
  const [isRafFModalOpen, setIsRAFModalOpen] = useState(false);
  const { data: contact } = useGetContact();

  return (
    <Outlet vertical="spacing16px">
      <AnchorButton
        variant="smallActionBase"
        size="sm"
        href="https://intercom.help/dalma/fr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Aide, Support
      </AnchorButton>
      <Tooltip size={14} message={"Le parrainage sera disponible une fois votre contrat actif."}>
        <Button variant="smallActionBase" size="sm" onClick={() => setIsRAFModalOpen(true)}>
          Parrainer un proche
        </Button>
      </Tooltip>
      <Button variant="smallActionCritical" size="sm" onClick={handleLogout}>
        Se déconnecter
      </Button>
      <ReferralModalV3
        isRafFModalOpen={isRafFModalOpen}
        setIsRAFModalOpen={setIsRAFModalOpen}
        rafCode={contact?.raf_code}
      />
    </Outlet>
  );
}
