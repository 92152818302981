import { useState, useEffect } from "react";

type ShareConfig = {
  title: string;
  text: string;
  url: string;
};

function shareContent(onSuccess?: () => void, onError?: (err?: unknown) => void) {
  return async function ({ text, title, url }: ShareConfig) {
    try {
      await window.navigator.share({ text, title, url });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
    }
  };
}

export function useWebShare(onSuccess?: () => void, onError?: () => void) {
  const [loading, setLoading] = useState(true);
  const [isSupported, setSupport] = useState(false);

  useEffect(() => {
    if ("share" in window.navigator) {
      setSupport(true);
    } else {
      setSupport(false);
    }
    setLoading(false);
  }, [onSuccess, onError]);

  return {
    loading,
    isSupported,
    share: shareContent(onSuccess, onError),
  };
}
