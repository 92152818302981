import { CSS } from "@stitches/react";
import { styled, textColors, theme } from "src/styles/stitches/theme";

const display1CSS: CSS = {
  fontSize: theme.fontSizes.textDisplay1,
  lineHeight: 1.112,
  letterSpacing: theme.letterSpacings.regular,
};

const display2CSS: CSS = {
  fontSize: theme.fontSizes.textDisplay2,
  lineHeight: 1.1875,
  letterSpacing: theme.letterSpacings.regular,
};

const display3CSS: CSS = {
  fontSize: theme.fontSizes.textDisplay3,
  lineHeight: 1.153,
  letterSpacing: theme.letterSpacings.regular,
};

const display4CSS: CSS = {
  fontSize: theme.fontSizes.textDisplay4,
  lineHeight: 1.166,
  letterSpacing: theme.letterSpacings.regular,
};

export const display5CSS: CSS = {
  fontSize: theme.fontSizes.textDisplay5,
  lineHeight: 1.2,
  letterSpacing: theme.letterSpacings.regular,
};

export const paragraph1CSS: CSS = {
  fontSize: theme.fontSizes.textParagraph1,
  lineHeight: 1.125,
  letterSpacing: theme.letterSpacings.regular,
};

export const paragraph2CSS: CSS = {
  fontSize: theme.fontSizes.textParagraph2,
  lineHeight: 1.285,
  letterSpacing: theme.letterSpacings.regular,
};

const paragraph3CSS: CSS = {
  fontSize: theme.fontSizes.textParagraph3,
  lineHeight: 1.23,
  letterSpacing: theme.letterSpacings.regular,
};

const caption1CSS: CSS = {
  fontSize: theme.fontSizes.textCaption1,
  lineHeight: 1.16,
  letterSpacing: theme.letterSpacings.regular,
};

const caption2CSS: CSS = {
  fontSize: theme.fontSizes.textCaption2,
  lineHeight: 1,
  letterSpacing: theme.letterSpacings.regular,
};

const caption3CSS: CSS = {
  fontSize: theme.fontSizes.textCaption3,
  lineHeight: 1,
  letterSpacing: theme.letterSpacings.regular,
};

// This code is a bit of a hack to get the typescript compiler to accept the type of the `variant` prop
type ColorVariant = Record<keyof typeof textColors, CSS>;
const colorsVariant = Object.entries(textColors).reduce<ColorVariant>(
  (previousValue, [key, value]) => {
    return {
      ...previousValue,
      [key]: {
        color: value,
      },
    };
  },
  {} as any as ColorVariant
);

export const Text = styled("p", {
  variants: {
    variant: {
      // Display 1
      [`display1-Regular`]: {
        ...display1CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`display1-Medium`]: {
        ...display1CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`display1-Bold`]: {
        ...display1CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Display 2
      [`display2-Regular`]: {
        ...display2CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`display2-Medium`]: {
        ...display2CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`display2-Bold`]: {
        ...display2CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Display 3
      [`display3-Regular`]: {
        ...display3CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`display3-Medium`]: {
        ...display3CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`display3-Bold`]: {
        ...display3CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Display 4
      [`display4-Regular`]: {
        ...display4CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`display4-Medium`]: {
        ...display4CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`display4-Bold`]: {
        ...display4CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Display 5
      [`display5-Regular`]: {
        ...display5CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`display5-Medium`]: {
        ...display5CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`display5-Bold`]: {
        ...display5CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Paragraph 1
      [`paragraph1-Regular`]: {
        ...paragraph1CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`paragraph1-Medium`]: {
        ...paragraph1CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`paragraph1-Bold`]: {
        ...paragraph1CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Paragraph 2
      [`paragraph2-Regular`]: {
        ...paragraph2CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`paragraph2-Medium`]: {
        ...paragraph2CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`paragraph2-Bold`]: {
        ...paragraph2CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Paragraph 3
      [`paragraph3-Regular`]: {
        ...paragraph3CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`paragraph3-Medium`]: {
        ...paragraph3CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`paragraph3-Bold`]: {
        ...paragraph3CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Caption 1
      [`caption1-Regular`]: {
        ...caption1CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`caption1-Medium`]: {
        ...caption1CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`caption1-Bold`]: {
        ...caption1CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Caption 2
      [`caption2-Regular`]: {
        ...caption2CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`caption2-Medium`]: {
        ...caption2CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`caption2-Bold`]: {
        ...caption2CSS,
        fontWeight: theme.fontWeights.bold,
      },
      // Caption 3
      [`caption3-Regular`]: {
        ...caption3CSS,
        fontWeight: theme.fontWeights.regular,
      },
      [`caption3-Medium`]: {
        ...caption3CSS,
        fontWeight: theme.fontWeights.medium,
      },
      [`caption3-Bold`]: {
        ...caption3CSS,
        fontWeight: theme.fontWeights.bold,
      },
    },
    color: {
      ...colorsVariant,
      currentColor: {
        color: "currentColor",
      },
    },
    textAlign: {
      center: {
        textAlign: "center",
      },
      start: {
        textAlign: "start",
      },
    },
  },

  defaultVariants: {
    variant: "paragraph1-Regular",
    color: "textBaseDefault",
  },
});
