import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { Stepper } from "src/components/03_organisms/refund-sidebar/stepper";
import {
  matchIsAccidentAndDiseaseCategory,
  matchIsAccidentAndPreventionCategory,
  matchIsAccidentCategory,
  matchIsAllCategories,
  matchIsDiseaseAndPreventionCategory,
  matchIsDiseaseCategory,
  matchIsPreventionCategory,
} from "src/helpers";
import { useGetContract } from "src/hooks/data";
import { claimSteps, useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { styled, theme } from "src/styles/stitches/theme";

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing16px} ${theme.spacing.spacing24px}`,
  borderBottom: `1px solid ${theme.colors.borderBaseSubdued}`,

  "@bp3": {
    padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing32px} ${theme.spacing.spacing36px} ${theme.spacing.spacing32px}`,
    borderBottom: "none",
  },
});

const EmptyDiv = styled("div", {
  width: theme.spacing.spacing24px,
  height: theme.spacing.spacing24px,
});

const StyledText = styled(Text, {
  "@bp3": {
    display: "none",
  },
});

export function RefundSidebarHeader() {
  const { data } = useGetContract();
  const contracts = data || [];

  const { currentStep, setCurrentStep, setIsOpen, selectedConsultationTypes } = useRefundSidebar();

  const isBackButtonDisplayed =
    (contracts.length > 1 && currentStep !== claimSteps.PET_SELECTION) ||
    (contracts.length === 1 && currentStep !== claimSteps.CONSULTATION_TYPES);

  function goBack() {
    const isOnDocumentStep = currentStep === 6;
    const isOnDiseaseStep = currentStep === 5;
    const isAmountStep = currentStep === 3;

    const isAccidentCategory = matchIsAccidentCategory(selectedConsultationTypes);
    const isDiseaseCategory = matchIsDiseaseCategory(selectedConsultationTypes);
    const isPreventionCategory = matchIsPreventionCategory(selectedConsultationTypes);
    const isAccidentAndPreventionCategory =
      matchIsAccidentAndPreventionCategory(selectedConsultationTypes);
    const isDiseaseAndPreventionCategory =
      matchIsDiseaseAndPreventionCategory(selectedConsultationTypes);
    const isAllCategories = matchIsAllCategories(selectedConsultationTypes);
    if (isPreventionCategory && currentStep <= 4) {
      setCurrentStep(currentStep - 1);
    } else if (isPreventionCategory && isOnDocumentStep) {
      setCurrentStep(currentStep - 3);
    } else if (
      isOnDocumentStep &&
      (isAllCategories || isDiseaseCategory || isDiseaseAndPreventionCategory)
    ) {
      setCurrentStep(currentStep - 1);
    } else if (isOnDocumentStep && (isAccidentCategory || isAccidentAndPreventionCategory)) {
      setCurrentStep(currentStep - 2);
    } else if (isOnDiseaseStep && (isDiseaseCategory || isDiseaseAndPreventionCategory)) {
      setCurrentStep(currentStep - 2);
    } else if (isPreventionCategory && !isAmountStep) {
      setCurrentStep(currentStep - 3);
    } else {
      setCurrentStep(currentStep - 1);
    }
  }

  const numberOfSteps = () => {
    if (
      contracts.length > 1 &&
      (matchIsAccidentCategory(selectedConsultationTypes) ||
        matchIsDiseaseCategory(selectedConsultationTypes))
    ) {
      console.log("1");
      return 5;
    } else if (
      contracts.length > 1 &&
      matchIsAccidentAndDiseaseCategory(selectedConsultationTypes)
    ) {
      return 6;
    } else {
      return 4;
    }
  };

  return (
    <StyledOutlet
      horizontal={{ "@initial": "spacing32px", "@bp3": "spacing96px" }}
      align="center"
      justify="spaceBetween"
    >
      {isBackButtonDisplayed ? (
        <button type="button" onClick={goBack}>
          <Icon name="arrowLeft" />
        </button>
      ) : (
        <EmptyDiv />
      )}
      <StyledText variant="paragraph1-Bold" color="textBaseDefault">
        Demande de remboursement
      </StyledText>
      <Stepper
        currentStep={currentStep}
        numberOfSteps={numberOfSteps()}
        css={{ display: "none", "@bp3": { display: "flex" } }}
        selectedConsultationTypes={selectedConsultationTypes}
      />
      <button type="button" onClick={() => setIsOpen(false)}>
        <Icon name="outlinedClose" />
      </button>
    </StyledOutlet>
  );
}
