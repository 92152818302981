import { Text } from "@chakra-ui/react";
import { WithChildren } from "src/types";

/**
 * TODO refactor this component
 * @param {any} props
 * @returns
 */

type TextsProps = React.ComponentProps<typeof Text> & WithChildren<{}>;

export const Texts = ({
  fontSize = "md",
  fontWeight = "400",
  color = "#212121",
  children,
  ...props
}: TextsProps) => (
  <>
    <Text
      fontSize={fontSize}
      color={color}
      fontFamily={fontWeight === "500" ? "GroteskBold" : "Grotesk "}
      {...props}
    >
      {children}
    </Text>
  </>
);
