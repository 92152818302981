import { API, Auth } from "aws-amplify";

export async function updatePetUuid(
  body: { pet_uuid: string; pet_uuid_type: string },
  stripeContractId: string,
  petIdx: number
) {
  const apiName = "apiMySpaceGateway";
  const path = `/${stripeContractId}/${petIdx}/pet_uuid`;

  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: { Authorization: token },
      response: false,
      body,
    };

    const response = await API.patch(apiName, path, myInit);
    return response;
  } catch (error) {
    // TODO error handling
    //@ts-expect-error message may not be property of error
    const message = error && typeof error === "object" ? error.message : undefined;
    const formattedError = new Error(message);
    throw formattedError;
  }
}
