import { ReactNode } from "react";
import { ArrowsNames, FilledNames, Icon, OutlinedNames } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

const StyledButton = styled("button", {
  textAlign: "initial",
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing16px} ${theme.spacing.spacing12px}`,

  variants: {
    background: {
      fillSecondary: {
        background: theme.colors.fillSecondary,
      },
      fillCriticalAccent: {
        background: theme.colors.fillCriticalAccent,
      },
      fillBlueAccent: {
        background: theme.colors.fillAccent,
      },
    },
    borderRadius: {
      16: {
        borderRadius: theme.radii[16],
      },
      0: {
        borderRadius: 0,
      },
    },
    withBorder: {
      true: {
        border: `1px solid ${theme.colors.borderBaseSubdued}`,
      },
    },
  },

  defaultVariants: {
    background: "fillSecondary",
    borderRadius: 16,
    withBorder: true,
  },
});

type Props = {
  iconName: ArrowsNames | FilledNames | OutlinedNames;
  text: string | ReactNode;
  chevronRight?: boolean;
  onClick?: () => void;
  background?: "fillSecondary" | "fillCriticalAccent" | "fillBlueAccent";
  color?: "textBaseSubdued" | "textSecondaryDefault";
  iconColor?: "iconBaseSubdued" | "iconSecondaryDefault";
  borderRadius?: 16 | 0;
  withBorder?: boolean;
};

export function InlineAlert({
  iconName,
  text,
  chevronRight,
  onClick,
  background,
  color = "textBaseSubdued",
  iconColor = "iconBaseSubdued",
  borderRadius,
  withBorder,
}: Props) {
  return (
    <>
      {onClick ? (
        <StyledButton type="button" onClick={onClick}>
          <StyledOutlet
            horizontal="spacing8px"
            align="center"
            background={background}
            borderRadius={borderRadius}
            withBorder={withBorder}
          >
            <Icon name={iconName} color={iconColor} />
            <Text variant="caption1-Medium" color={color}>
              {text}
            </Text>
            {chevronRight ? <Icon name="chevronRight" color={iconColor} /> : null}
          </StyledOutlet>
        </StyledButton>
      ) : (
        <StyledOutlet
          horizontal="spacing8px"
          align="center"
          background={background}
          borderRadius={borderRadius}
          withBorder={withBorder}
        >
          <Icon name={iconName} color={iconColor} />
          <Text variant="caption1-Medium" color={color}>
            {text}
          </Text>
          {chevronRight ? <Icon name="chevronRight" color={iconColor} /> : null}
        </StyledOutlet>
      )}
    </>
  );
}
