import { Contract } from "src/types";

export function isContractActive(contract: Contract) {
  const now = new Date();
  return new Date(contract.contract.start_date) < now && new Date(contract.contract.end_date) > now;
}

export function hasAnActiveContract(contracts?: Contract[]) {
  if (contracts === undefined) return false;

  return contracts.some(
    (contract) => isContractActive(contract) || matchIsContractDeffered(contract)
  );
}

export function matchIsContractDeffered(contract: Contract) {
  return contract.contract.status === "pending_activation";
}
