import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Outlet } from "src/components/01_atoms/outlet";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { styled, theme } from "src/styles/stitches/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { Button } from "src/components/01_atoms/button";
import { PriceInput } from "./_price-input";
import { useGetContract } from "src/hooks/data";
import { AlertModal } from "src/components/02_molecules/alert-modal";
import { AlertDrawer } from "src/components/02_molecules/alert-drawer";
import { amountRegex } from "src/backApi/newClaim/utils/parse-amount";
import { reportError } from "src/utils/error";
import {
  matchIsAccidentAndDiseaseCategory,
  matchIsAccidentAndPreventionCategory,
  matchIsAccidentCategory,
  matchIsPreventionCategory,
} from "src/helpers";

const Form = styled("form", {
  flex: 1,
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing36px}`,
  },
});

const amountSchema = z.object({
  amount: z.string().regex(amountRegex),
});

type Amount = {
  amount: string;
};

export function AmountForm() {
  const [isUpperLimitReachedModalOpen, setIsUpperLimitReachedModalOpen] = useState(false);

  const {
    currentStep,
    setCurrentStep,
    amount,
    setAmount,
    selectedPetToContext,
    selectedConsultationTypes,
  } = useRefundSidebar();
  const { data: contracts } = useGetContract();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    setFocus,
  } = useForm<Amount>({
    resolver: zodResolver(amountSchema),
    mode: "onChange",
    defaultValues: {
      amount,
    },
  });

  // Set the email input focus on first mount
  useEffect(() => {
    setFocus("amount");
  }, [setFocus]);

  if (!contracts) {
    return null;
  }

  const currentPetContract = contracts.find(
    (contract) => contract.contract.contract_uuid === selectedPetToContext?.contract_uuid
  );

  // Sentry
  if (!currentPetContract) {
    reportError(
      `No current pet contract found on the amount form. selectedPet = ${selectedPetToContext?.contract_uuid}`
    );
    return null;
  }

  const healthLimitLeft =
    Number(currentPetContract.coverage.health_limit) -
    Number(currentPetContract.coverage.health_conso);

  const preventionLimitLeft =
    Number(currentPetContract.coverage.prevention_limit) -
    Number(currentPetContract.coverage.prevention_conso);

  function goToNextStep(selectedConsultationTypes: string[]) {
    if (
      matchIsAccidentCategory(selectedConsultationTypes) ||
      matchIsAccidentAndDiseaseCategory(selectedConsultationTypes)
    ) {
      return setCurrentStep(currentStep + 1);
    }
    if (matchIsAccidentAndPreventionCategory(selectedConsultationTypes)) {
      return setCurrentStep(currentStep + 1);
    }
    if (matchIsPreventionCategory(selectedConsultationTypes)) {
      return setCurrentStep(currentStep + 3);
    }
    return setCurrentStep(currentStep + 2);
  }

  const onSubmit: SubmitHandler<Amount> = (values) => {
    setAmount(values.amount);

    if (
      (selectedConsultationTypes.includes("ACCIDENT") ||
        selectedConsultationTypes.includes("SICKNESS")) &&
      !selectedConsultationTypes.includes("PREVENTION")
    ) {
      if (Number(values.amount) > healthLimitLeft) {
        setIsUpperLimitReachedModalOpen(true);
        return;
      }
    }

    if (
      selectedConsultationTypes.length === 1 &&
      selectedConsultationTypes.includes("PREVENTION")
    ) {
      if (Number(values.amount) > preventionLimitLeft) {
        setIsUpperLimitReachedModalOpen(true);
        return;
      }
    }

    goToNextStep(selectedConsultationTypes);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledOutlet vertical={{ "@initial": "spacing32px", "@bp3": "0" }} fullHeight>
          <SidesheetContentHeader
            title="Quel était le montant&nbsp;?"
            subtitle="Demande de remboursement"
            alertIconName="filledInfo"
            alertText="Cette information se trouve sur votre facture et feuille de soins"
          />
          <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
            <PriceInput {...register("amount")} error={Boolean(errors.amount)} />
            <Button type="submit" variant="accent" size="lg" fluid disabled={!isValid}>
              Continuer
            </Button>
          </Outlet>
        </StyledOutlet>
      </Form>
      <AlertModal
        isOpen={isUpperLimitReachedModalOpen}
        label="Modale d'alerte de dépassement du plafond de remboursement"
        title="Le montant dépasse votre plafond de remboursement"
        description="Pas d’inquiétude ! Vous serez remboursé·e à hauteur de ce qu’il reste sur votre plafond de remboursement"
        onDismiss={() => {
          setIsUpperLimitReachedModalOpen(false);
        }}
        buttonText1="J'ai compris"
        onDismissButton1={() => {
          setIsUpperLimitReachedModalOpen(false);
          goToNextStep(selectedConsultationTypes);
        }}
      />
      <AlertDrawer
        isOpen={isUpperLimitReachedModalOpen}
        title="Le montant dépasse votre plafond de remboursement"
        description="Pas d’inquiétude ! Vous serez remboursé·e à hauteur de ce qu’il reste sur votre plafond de remboursement"
        onClose={() => {
          setIsUpperLimitReachedModalOpen(false);
        }}
        buttonText1="J'ai compris"
        onCloseButton1={() => {
          setIsUpperLimitReachedModalOpen(false);
          goToNextStep(selectedConsultationTypes);
        }}
      />
    </>
  );
}
