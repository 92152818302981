import { ClaimAct, ClaimStatus, Claim_v2, Contract } from "src/types";
import { getFormattedPrice } from "src/utils/format";

type TagType = {
  label: string;
  variant: "warning" | "progress" | "critical" | "success" | "secondary";
};

export const getClaimPaidAmount = (claimActs: ClaimAct[]) => {
  return claimActs.reduce((accumulator, { paidAmount }) => accumulator + paidAmount, 0);
};

export const getClaimRefundAmount = (claimActs: ClaimAct[]) => {
  return claimActs.reduce((accumulator, { refundAmount }) => accumulator + refundAmount, 0);
};

export const getClaimTag = (claim: Claim_v2) => {
  const { claim_acts, status } = claim;

  const tags: { [T in ClaimStatus]: TagType } = {
    NEED_INFO: {
      label: "À compléter",
      variant: "warning",
    },
    IN_ANALYSIS: {
      label: "En cours",
      variant: "progress",
    },
    TO_BE_COMPLETED: {
      label: "À compléter par votre vétérinaire",
      variant: "progress",
    },
    CLOSE:
      getClaimRefundAmount(claim_acts) === 0
        ? {
            label: "Refusé",
            variant: "critical",
          }
        : {
            label: getFormattedPrice(getClaimRefundAmount(claim_acts)),
            variant: "success",
          },
  };

  return tags[status];
};

/**
 * On a un bug qui est revenu sur la webapp qui fait
 * qu'on n'affiche plus les anciens claims des utilisateurs
 * qui ont un contrat renouvelé (voir capture).
 * Ceci est dû au fait qu'on utilise contract_id pour afficher les claims
 * alors que le précédent contract_id n'est plus récupéré.
 * On avait fix ça en utilisant le pet_name il y a quelques temps (voici le ticket).
 * @param contracts
 * @param petName
 * @returns
 */
export const getContractByPetName = (
  contracts: Contract[],
  petName: string
): Contract | undefined => {
  return contracts.find((contract) => {
    return contract.pet.pet_name === petName;
  });
};
