import { CSS, VariantProps } from "@stitches/react";
import { Outlet, OutletProps } from "../outlet";
import { Text } from "../text";
import { textColors, styled } from "src/styles/stitches/theme";
import { Icon, IconProps } from "../icon";
import { cloneElement, ReactElement } from "react";
import { getChildByType } from "react-nanny";
import { WithChildren } from "src/types";

type ColorVariant = Record<keyof typeof textColors, CSS>;
const colorsVariant = Object.entries(textColors).reduce<ColorVariant>(
  (previousValue, [key, value]) => {
    return {
      ...previousValue,
      [key]: {
        color: value,
      },
    };
  },
  {} as any as ColorVariant
);

const LabelWrapper = styled(Outlet, {
  flexGrow: 1,
});

const StyledOutlet = styled(Outlet, {
  variants: {
    color: {
      ...colorsVariant,
    },
  },
});

type InlineHintProps = WithChildren<{
  rightIcon?: ReactElement<IconProps>;
  leftIcon?: ReactElement<IconProps>;
  justify?: OutletProps["justify"];
  color?: VariantProps<typeof StyledOutlet>["color"];
}>;

export function InlineHint({ children, color, rightIcon, leftIcon, justify }: InlineHintProps) {
  return (
    <StyledOutlet horizontal="spacing4px" align="center" color={color}>
      {/* getChildByType allows us to not render the component if it's not an Icon */}
      {leftIcon && getChildByType(leftIcon, [Icon]) && cloneElement(leftIcon, { size: 16 })}

      <LabelWrapper horizontal="0" justify={justify}>
        <Text variant="caption1-Medium" color={color}>
          {children}
        </Text>
      </LabelWrapper>

      {/* getChildByType allows us to not render the component if it's not an Icon */}

      {rightIcon && getChildByType(rightIcon, [Icon]) && cloneElement(rightIcon, { size: 16 })}
    </StyledOutlet>
  );
}
