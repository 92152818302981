import { keyframes } from "src/styles/stitches/theme";
import { styled } from "src/styles/stitches/theme";

const loadingAnimation = keyframes({
  "0%": {
    backgroundPosition: "100% 50%",
  },
  "50%": {
    backgroundPosition: "0% 50%",
  },
  "100%": {
    backgroundPosition: "100% 50%",
  },
});

export const SkeletonWrapper = styled("div", {
  backgroundImage:
    "linear-gradient(146deg, rgba(0, 85, 251, 0.3) 0%, rgba(0, 85, 251, 0.2) 20%, rgba(255, 255, 255, 0.7) 110%)",
  backgroundSize: "200% 100%",
  animation: `${loadingAnimation} 1.5s infinite`,
  borderRadius: "4px",
});
