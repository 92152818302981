import { ReactNode, useEffect } from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

import { Guarantee } from "./pages/Guarantee/Guarantee";
import { Sponsorship } from "./pages/Account/Sponsorship";
import { LiveChat } from "./pages/Account/LiveChat";
import { Profile } from "./pages/Account/Profile";
import { Contracts } from "./pages/Account/Contracts";

import { FinalOverview as Welcome } from "./pages/Login/FinalOverview";
import { Home } from "./pages/Account/home";

import { SANDBOX, SIGNUP_DOMAIN } from "./config";
import { Error503 } from "./pages/Error503";
import { DesignSystem } from "./pages/design-system";
import { SignIn } from "./pages/Login/sign-in";
import { externalRoutes, routes } from "./routes";
import { ChangePassword } from "./pages/Login/change-password";
import { FirstConnection } from "./pages/Login/first-connection";
import { ForgotPasswordNew } from "./pages/Login/forgot-password";
import { useAuth } from "./hooks/useAuth";

type RedirectToNewFlowProps = {
  path: string;
};

function RedirectToNewFlow({ path }: RedirectToNewFlowProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const quoteId = searchParams.get("quote_id") || "";
  useEffect(() => {
    window.location.href = `${SIGNUP_DOMAIN}${path}?quote_id=${quoteId}`;
  }, [path, quoteId]);
  return null;
}

export function Routes() {
  return (
    <Switch>
      <Route exact path={`${routes.welcome}/:quoteId`}>
        <Welcome />
      </Route>
      <Route exact path="/redirect/marketing">
        <RedirectToNewFlow path="/redirect/marketing" />
      </Route>
      <Route exact path="/redirect/meilleurtaux">
        <RedirectToNewFlow path="/redirect/meilleurtaux" />
      </Route>
      <Route exact path="/redirect/hyperassur">
        <RedirectToNewFlow path="/redirect/hyperassur" />
      </Route>
      <Route exact path="/redirect/email">
        <RedirectToNewFlow path="/redirect/email" />
      </Route>
      <Route path="/quote">
        <RedirectToNewFlow path="/" />
      </Route>
      <Route key="first-connection" exact path={routes.firstConnection}>
        <FirstConnection />
      </Route>
      <Route key="first-connection" exact path={routes.firstConnectionAlt}>
        <FirstConnection />
      </Route>
      <Route key="login" exact path={routes.login}>
        <SignIn nextPage={routes.home} previousPage={externalRoutes.marketingSite} />
      </Route>
      <Route key="change-password" exact path={routes.changePassword}>
        <ChangePassword nextPage={routes.home} />
      </Route>
      <Route key="forgot-password" exact path={routes.forgotPassword}>
        <ForgotPasswordNew nextPage={routes.changePassword} />
      </Route>
      <Route>
        <RequireAuth>
          <Switch>
            <Route exact path={routes.home}>
              <Home />
            </Route>
            <Route key="guarantees" exact path={routes.guarantees}>
              <Guarantee />
            </Route>
            <Route key="referral" exact path={routes.sponsorhip}>
              <Sponsorship />
            </Route>
            <Route key="settings" exact path={routes.profil}>
              <Profile />
            </Route>
            <Route key="contracts" exact path={routes.contracts}>
              <Contracts />
            </Route>
            <Route key="liveChat" exact path={routes.liveChat}>
              <LiveChat />
            </Route>
            {SANDBOX ? (
              <Route path="/errors/network">
                <Error503 />
              </Route>
            ) : null}
            {SANDBOX ? (
              <Route path="/design-system">
                <DesignSystem />
              </Route>
            ) : null}
            <Route>
              <Redirect to={routes.home} />
            </Route>
          </Switch>
        </RequireAuth>
      </Route>
    </Switch>
  );
}

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (auth === null) {
      history.push(routes.login);
    }
  }, [auth, history]);

  if (auth == "signIn") {
    return <>{children}</>;
  }

  return null;
};
