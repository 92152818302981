import { ComponentProps, forwardRef, MouseEvent } from "react";
import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { VisuallyHidden } from "src/components/01_atoms/visually-hidden";
import { baseColors, rgba, styled, theme } from "src/styles/stitches/theme";
import { Pet } from "src/types";
import { Avatar } from "../avatar";
import { Alert } from "./_alert";
import { InfoBottomCard } from "./_info-bottom-card";
import { Card, PetIcon, TopCard } from "./_utils";

const SpanRadio = styled("span", {
  transition: "all .15s ease-out",
  borderRadius: "100%",
  width: "1.5rem",
  height: "1.5rem",
  border: `2px solid ${theme.colors.borderBaseSubdued}`,
  appearance: "none",
  display: "block",
  position: "relative",
  flex: "0 0 auto",
  marginLeft: "auto",

  "& svg": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },

  variants: {
    checked: {
      false: {
        "& svg": {
          opacity: 0,
        },
      },
      true: {
        "& svg": {
          opacity: 1,
        },
      },
    },
  },
});

const Wrapper = styled("label", {
  display: "block",
  border: `1.5px solid ${theme.colors.borderSecondary}`,
  borderRadius: theme.radii["24"],
  overflow: "hidden",
  cursor: "pointer",
  transition: "border-color .15s ease-out",

  "@withHover": {
    "&:hover": {
      [`& ${SpanRadio}`]: {
        boxShadow: theme.boxShadow.hovered,
      },
    },
  },

  variants: {
    checked: {
      true: {
        borderColor: theme.colors.borderSelected,
        backgroundColor: theme.colors.fillSecondary,
        boxShadow: theme.elevation.elevation1Primary,
      },
      false: {
        boxShadow: theme.elevation.elevation1Purple,
        // We fake a filter drop shadow with rbga background
        backgroundColor: rgba(baseColors.neutral100, 0.4),

        "@withHover": {
          "&:hover": {
            borderColor: theme.colors.borderBaseDisabled,
          },
        },
      },
    },
  },
});

const StyledInput = styled("input", VisuallyHidden, {
  "&:focus-visible": {
    [`& + ${Wrapper}`]: {
      boxShadow: theme.boxShadow.selected,
      [`& ${SpanRadio}`]: {
        boxShadow: theme.boxShadow.hovered,
      },
    },
  },
});

type RadioCardProps = Omit<ComponentProps<"input">, "type" | "value" | "ref" | "checked"> & {
  title: string;
  description?: string;
  petType: Pet["pet"]["pet_type"];
  petName?: string;
  startDate?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  alert?: string;
  onAlertClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
};

export const RadioCard = forwardRef<HTMLInputElement, RadioCardProps>(
  (
    { title, description, petType, startDate, petName, alert, onAlertClick, checked, ...rest },
    ref
  ) => {
    return (
      <Card>
        <StyledInput {...rest} type="radio" id={rest.id ?? rest.value?.toString()} ref={ref} />
        <Wrapper htmlFor={rest.id ?? rest.value?.toString()} checked={checked}>
          <TopCard checked={checked}>
            <Outlet horizontal="spacing8px" fluid align="center" justify="spaceBetween">
              <Outlet horizontal="spacing8px" fluid align="center">
                <Avatar color="secondary" size="lg" withHover={false}>
                  <PetIcon
                    name={petType === "cat" ? "outlinedCat" : "outlinedDog"}
                    size="24"
                    color={checked ? "iconAccent" : "iconBaseSubdued"}
                  />
                </Avatar>
                <Outlet vertical="spacing2px">
                  <Text variant="paragraph1-Bold" color="textBaseDefault">
                    {title}
                  </Text>
                  {description ? (
                    <Text variant="paragraph3-Medium" color="textBaseSubdued">
                      {description}
                    </Text>
                  ) : null}
                </Outlet>
              </Outlet>
              <SpanRadio checked={checked}>
                <Icon
                  name="filledCheckboxCircle"
                  color={rest.disabled ? "iconPrimaryDisabled" : "iconPrimaryDefault"}
                  size="24"
                />
              </SpanRadio>
            </Outlet>
          </TopCard>
          {rest.disabled ? <InfoBottomCard startDate={startDate} petName={petName} /> : null}
          {alert ? <Alert onAlertClick={onAlertClick} text={alert} /> : null}
        </Wrapper>
      </Card>
    );
  }
);

RadioCard.displayName = "RadioCard";
