import { OLD_CLAIM_SERVICE_IS_ONLINE } from "src/config";
import { ClaimConsultationType, Files } from "src/types";
import { createNewClaimWithNewApi } from "./newClaim/new-claim-api";
import { createClaimWithOldAPi } from "./newClaim/old-claim-api";
import { AmountError, parseAmount } from "./newClaim/utils/parse-amount";

export async function newClaim(
  files: Files,
  contractId: string,
  contractUuid: string,
  amount: string,
  consultation_type: ClaimConsultationType[],
  selectedAccidentDate: Date | undefined,
  selectedDiseaseDate: Date | undefined,
  visitMotivesAccident: string[] | undefined,
  visitMotivesDisease: string[] | undefined
) {
  const validAmount = parseAmount(amount);
  if (!validAmount) {
    throw new AmountError("Le montant de la consultation est invalide");
  }

  if (OLD_CLAIM_SERVICE_IS_ONLINE) {
    await createClaimWithOldAPi(files, validAmount, contractId, contractUuid, consultation_type);
    return;
  }

  await createNewClaimWithNewApi(
    files,
    contractId,
    contractUuid,
    validAmount,
    consultation_type,
    selectedAccidentDate,
    selectedDiseaseDate,
    visitMotivesAccident,
    visitMotivesDisease
  );
}
