import { useActor } from "@xstate/react";
import { useEffect, useState } from "react";
import { Outlet } from "src/components/01_atoms/outlet";
import { toastClose } from "src/components/01_atoms/toast";
import { Banner } from "src/components/02_molecules/banner";
import { HomeHeader } from "src/components/02_molecules/home-header";
import { MediaCardSponsorship } from "src/components/02_molecules/media-card-sponsorship/media-card-sponsorship";
import { ReferralModal } from "src/components/02_molecules/referral-modal/referral-modal";
import { UnpaidInlineAlerts } from "src/components/02_molecules/unpaid-inline-alerts";
import { Claims } from "src/components/03_organisms/claims";
import { DownloadAppDrawer } from "src/components/03_organisms/download-app-drawer";
import { DownloadAppModal } from "src/components/03_organisms/download-app-modal";
import { MenuItems } from "src/components/03_organisms/home-navbar/menu-items";
import { MediaCards } from "src/components/03_organisms/media-cards";
import { Onboarding } from "src/components/03_organisms/onboarding";
import { SmartBanner } from "src/components/03_organisms/smart-banner";
import { HomeLayout } from "src/components/04_layouts/home-layout";
import { useGetContact } from "src/hooks/data";
import { useRafOperation } from "src/hooks/use-raf-operation";
import { useGetUnpaidStatus } from "src/hooks/use-get-unpaid-status";
import { claimsService } from "src/store/claims/machine";
import { onboardingStepsService } from "src/store/onboarding/machine";
import { styled } from "src/styles/stitches/theme";
import { track } from "src/utils/utils";
import { ReferralModalV3 } from "src/components/02_molecules/referral-modal-v3/referral-modal-v3";

const HideOnDesktop = styled("div", {
  // This is a hack to get the 32px of spacing due to the padding of the cards for the scrollbar
  marginTop: "calc(2rem - 0.87rem) !important",
  width: "100%",

  "@bp3": {
    display: "none",
  },
});

const WrapperMediaCard = styled("div", {
  padding: "0px 1.5rem",
  width: "100%",

  "@bp3": {
    padding: "0",
  },
});

export function Home() {
  const [isDownloadAppModalOrDrawerOpen, setIsDownloadAppModalOrDrawerOpen] = useState(false);
  const [isModalFromBannerOpen, setIsModalFromBannerOpen] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [isRafFModalOpen, setIsRAFModalOpen] = useState(false);

  const { data: contact } = useGetContact();
  const { hasUnpaidSubscription, unpaidSubscriptions, isUnpaidSubscriptionsLoading, isFetched } =
    useGetUnpaidStatus();

  const [state, sendOnboardingSteps] = useActor(onboardingStepsService);
  const [claimsState, sendClaims] = useActor(claimsService);
  const { isDuringCampaign } = useRafOperation();

  useEffect(() => {
    if (
      isFetched &&
      !isUnpaidSubscriptionsLoading &&
      claimsState.value === "checkingUnpaidSubscription"
    ) {
      sendClaims({ type: "SET_HAS_UNPAID_SUBSCRIPTION", value: hasUnpaidSubscription });
    }
  }, [
    hasUnpaidSubscription,
    isUnpaidSubscriptionsLoading,
    sendClaims,
    claimsState.value,
    isFetched,
  ]);

  useEffect(() => {
    sendOnboardingSteps("FETCH_PAYMENT_METHODS");
  }, [sendOnboardingSteps]);

  useEffect(() => {
    if (isOnboardingOpen) {
      toastClose();
    }
  }, [isOnboardingOpen]);

  useEffect(() => {
    if (!contact || state.value !== "welcome") return;

    if (!contact.marketing) {
      setIsOnboardingOpen(true);
      return;
    }
  }, [contact, state]);
  return (
    <>
      <Onboarding
        isOpen={isOnboardingOpen}
        setIsOnboardingOpen={setIsOnboardingOpen}
        setIsDownloadAppModalOrDrawerOpen={setIsDownloadAppModalOrDrawerOpen}
      />
      <DownloadAppModal
        isOpen={isDownloadAppModalOrDrawerOpen}
        onClose={() => {
          setIsDownloadAppModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        title="Pour une meilleure expérience, téléchargez l’app Dalma !"
        description="Elle vous permettra de faire rapidement vos demandes de remboursement et d’accéder à des conseils vétérinaires en illimité ! Scannez simplement ce QR Code"
        qrCodeWithImage
      />
      <DownloadAppDrawer
        isVisible={isDownloadAppModalOrDrawerOpen}
        onClose={() => {
          setIsDownloadAppModalOrDrawerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        title="Pour une meilleure expérience, téléchargez l’app Dalma !"
        description="Elle vous permettra de faire rapidement vos demandes de remboursement et d’accéder à des conseils vétérinaires en illimité !"
        withSecondButton
      />
      <SmartBanner />
      <Banner
        image="/assets/media/app-icon.svg"
        imageAlt="Logo de l'App"
        text="📣 Nouveauté dans votre app ! Des conseils vidéos d’experts gratuits pour devenir le meilleur parent pour votre boule de poils."
        linkText="Télécharger l’app !"
        isClosable
        onClick={() => {
          toastClose();
          setIsModalFromBannerOpen(true);
          track({
            event: "amplitude_click_top_banner",
            eventType: "clickTopBanner",
            eventProperties: {
              url: window.location.href,
            },
          });
        }}
      />
      <DownloadAppModal
        isOpen={isModalFromBannerOpen}
        onClose={() => {
          setIsModalFromBannerOpen(false);
          sendClaims("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
        title="Téléchargez l’app Dalma"
        description="Utilisez la caméra de votre téléphone pour scanner et télécharger l’app gratuite Dalma."
      />
      <HomeLayout>
        <Outlet vertical={{ "@initial": "spacing32px", "@bp3": "spacing48px" }} fluid>
          <Outlet vertical="spacing32px" fluid>
            <HomeHeader />
            {hasUnpaidSubscription && !isUnpaidSubscriptionsLoading ? (
              <UnpaidInlineAlerts unpaidSubscriptions={unpaidSubscriptions} />
            ) : null}
            {isDuringCampaign ? (
              <WrapperMediaCard>
                <MediaCardSponsorship setIsRAFModalOpen={setIsRAFModalOpen} />
              </WrapperMediaCard>
            ) : (
              <MediaCards />
            )}
            <HideOnDesktop>
              <MenuItems />
            </HideOnDesktop>
          </Outlet>
          <Claims />
        </Outlet>
        <ReferralModalV3
          isRafFModalOpen={isRafFModalOpen}
          setIsRAFModalOpen={setIsRAFModalOpen}
          rafCode={contact?.raf_code}
        />
      </HomeLayout>
    </>
  );
}
