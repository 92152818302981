import { useContext, useEffect, useRef, useState, createContext } from "react";
import { WithChildren } from "src/types";

type OptimizeContextProps = { [key: string]: unknown };
const OptimizeContext = createContext<OptimizeContextProps>({});

export function useABTest(experimentId: string) {
  const ctx = useContext(OptimizeContext);

  return ctx[experimentId] ?? "0";
}

export function OptimizeProvider({ children }: WithChildren) {
  const experimentsRef = useRef<OptimizeContextProps>({});
  const [experiments, setExperiments] = useState(experimentsRef.current);

  useEffect(() => {
    let timeout: number;
    function gtag(...args: any) {
      window.dataLayer.push(args);
    }

    // Since this will be called once for every experiment, we'll need to debounce setting the state
    function implementManyExperiments(value: string, name: string) {
      const exp = experimentsRef.current;

      // Early exit if value has not changed
      if (exp[name] === value) {
        return;
      }

      exp[name] = value;
      clearTimeout(timeout);
      // Call setState in next task (setTimeout) to batch updates if
      // there are multiple experiments
      timeout = window.setTimeout(() => {
        // In order for react to detect a State change, the object has to be different
        // As the ref is mutated, we shallow clone it here
        setExperiments({ ...exp });
      }, 0);
    }

    gtag("event", "optimize.callback", {
      callback: implementManyExperiments,
    });

    return () => clearTimeout(timeout);
  }, []);

  return <OptimizeContext.Provider value={experiments}>{children}</OptimizeContext.Provider>;
}
