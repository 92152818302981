import { createContext, useContext, useEffect, useState } from "react";
import { WithChildren } from "src/types";
import { shouldBannerStayClosed } from "src/utils/local-storage";

type BannerContextType = {
  isClosed: boolean;
  setIsClosed: (isClosed: boolean) => void;
};
export const BannerContext = createContext<BannerContextType>({
  isClosed: false,
  setIsClosed: () => undefined,
});

export const BannerContextProvider = ({ children }: WithChildren) => {
  const [isClosed, setIsClosed] = useState<boolean>(true);

  useEffect(() => {
    setIsClosed(shouldBannerStayClosed());
  }, []);

  return (
    <BannerContext.Provider value={{ isClosed, setIsClosed }}>{children}</BannerContext.Provider>
  );
};

export const useBanner = (): BannerContextType => {
  const context = useContext(BannerContext);

  if (context === null) {
    throw new Error("useBanner must be used within a BannerContextProvider");
  }

  return context;
};
