import { AnchorButton } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { MobileDrawerLayout } from "src/components/04_layouts/mobile-drawer-layout";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";

const Ul = styled("ul", {
  marginLeft: "1rem",
  fontSize: theme.fontSizes.textParagraph2,
  lineHeight: 1.285,
  letterSpacing: theme.letterSpacings.regular,
  fontWeight: theme.fontWeights.medium,
  color: theme.colors.textBaseSubdued,
});

export function RefusedDetailMobileDrawer() {
  const { isRefusedDetailSidebarOpen, setIsRefusedDetailSidebarOpen, refundAmount } =
    useClaimStatusSidebar();

  return (
    <MobileDrawerLayout
      isVisible={isRefusedDetailSidebarOpen}
      onClose={() => setIsRefusedDetailSidebarOpen(false)}
    >
      <Outlet vertical="spacing32px">
        <Outlet vertical="spacing8px">
          <Text variant="display4-Bold" color="textBaseDefault">
            {refundAmount === 0
              ? "Pourquoi ma demande de remboursement peut-elle être refusée ?"
              : "Pourquoi une partie de ma demande de remboursement peut-elle être refusée ?"}
          </Text>
          <Text variant="paragraph2-Medium" color="textBaseSubdued">
            Le pépin de santé de votre animal n&apos;est pas pris en charge :
          </Text>
          <br />
          <Ul>
            <li>S’il survient pendant les délais de carence</li>
            <li>S’il survient avant votre date de souscription</li>
            <li>Si le problème de santé fait partie de notre liste d’exclusions</li>
            <li>
              Si vous n&apos;avez pas souscrit au forfait bien-être et qu&apos;il s&apos;agit
              d&apos;une dépense de prévention
            </li>
            <li>Si votre plafond de remboursement a été atteint</li>
          </Ul>
        </Outlet>
        <AnchorButton
          type="button"
          variant="accent"
          size="md"
          href="https://intercom.help/dalma/fr/articles/6154870-les-conditions-de-prise-en-charge-dalma"
          target="_blank"
          rel="noopener noreferrer"
          fluid
        >
          Consulter notre article détaillé
        </AnchorButton>
      </Outlet>
    </MobileDrawerLayout>
  );
}
