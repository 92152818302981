import { useState, useEffect } from "react";
import { Center, Divider, Flex } from "@chakra-ui/react";
import Times from "@iconscout/react-unicons/icons/uil-times";
import { Texts } from "../../components/texts/Texts";
import { Title } from "../../components/texts/Title";
import { RefundInformations } from "../../components/cards/RefundInformations";
import { PetGuaranteeCard } from "../../components/cards/PetGuaranteeCard";
import { SelectPet } from "../../components/inputs/SelectPet";

import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import { _deprecated_fetchContracts } from "../../backApi/fetchContracts";
import { Contract } from "src/types";
import { Icon } from "src/components/01_atoms/icon";

export const Guarantee = () => {
  const history = useHistory();

  const [petIdx, setPetIdx] = useState(0);
  const [contracts, setContracts] = useState<Contract[] | null>([]);

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentAuthenticatedUser();
        _deprecated_fetchContracts(setContracts);
      } catch (e) {
        // TODO sentry
      }
    }
    onLoad();
  }, [history]);

  const preventionConso =
    (contracts &&
      contracts.length > 0 &&
      contracts[petIdx].coverage &&
      contracts[petIdx].coverage.prevention_conso) ||
    0;

  const healthConso =
    (contracts &&
      contracts.length > 0 &&
      contracts[petIdx].coverage &&
      contracts[petIdx].coverage.health_conso) ||
    0;

  const contract = contracts && contracts.length > 0 && contracts[petIdx];

  return contracts && contracts.length > 0 ? (
    <Flex minH="100vh" alignItems="center" justifyContent="center">
      <Flex align="center" alignItems="center" justifyContent="center" direction="column">
        <Center
          cursor="pointer"
          onClick={() => history.replace("/")}
          bg="#DBEBFF"
          w="44px"
          h="44px"
          position="absolute"
          right="5vw"
          top="5vh"
          rounded="full"
          zIndex="2"
        >
          <Times color="#4E68F6" size="25px" />
        </Center>
        <Flex
          h={["350px", "280px", "280px"]}
          position="absolute"
          top="0"
          left="0"
          backgroundSize="cover"
          bgImage="url(/assets/media/onboarding.svg)"
          zIndex="1"
          flex={1}
          w="100%"
        />
        <Flex
          h={["350px", "280px", "280px"]}
          position="absolute"
          top="0"
          left="0"
          bg="#F5F5F5"
          zIndex="0"
          flex={1}
          w="100%"
        />

        <Flex w="90%" zIndex="1">
          <Flex zIndex="1" direction="column" pt={["250px", "180px", "180px"]}>
            <Flex>
              <Title size="32px">Quels soins sont remboursés ?</Title>
            </Flex>
            <Flex>
              <Texts color="#616161" fontSize="14px">
                Découvrez en détails la liste des soins remboursés par Dalma en fonction de vos
                options.
              </Texts>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction={["column", "row", "row", "row", "row", "row"]} pt="100px" w="90%">
          <Flex direction={["column", "row"]} w={["100%"]} align="center">
            <Flex minW={["100%", "170px", "230px"]}>
              <Title fontSize={["18px", "18px", "24px"]}>Les couvertures de </Title>
            </Flex>
            <Flex align="flex-start" ml={[0, "10px"]} w="100%" mt={["10px", 0]}>
              <SelectPet
                pets={contracts.map((p) => ({ petName: p.pet.pet_name }))}
                setSelectedPet={setPetIdx}
                selectedPet={petIdx}
              />
            </Flex>
          </Flex>
        </Flex>

        <Texts px={["3vw", "5vw"]} mt="30px" w="100%">
          Dans le cadre de vos garanties essentielles
        </Texts>
        <Flex
          px={["3vw", "5vw"]}
          direction={["column", "column", "row"]}
          w="100%"
          rounded="8px"
          mt="30px"
        >
          <Flex mr={[0, 0, "20px"]}>
            <PetGuaranteeCard
              label={"Ce taux correspond au % de remboursement de vos futurs frais vétérinaires"}
              value={
                (contracts &&
                  contracts.length > 0 &&
                  contracts[petIdx].options &&
                  contracts[petIdx].options.rate.replace("%", "")) ||
                0
              }
              valueType="%"
              totalValue={100}
              title={"Taux de couverture"}
              description={"des frais couverts par Dalma"}
            />
          </Flex>
          <Flex mt={["24px", "24px", 0]}>
            <PetGuaranteeCard
              label={"Ce montant correspond à la limite maximale de vos remboursements sur un an"}
              value={healthConso}
              valueType="€"
              title={"Plafond annuel"}
              totalValue={
                (contracts &&
                  contracts.length > 0 &&
                  contracts[petIdx].coverage &&
                  contracts[petIdx].coverage.health_limit) ||
                0
              }
              description={
                (contracts &&
                  contracts.length > 0 &&
                  contracts[petIdx].coverage &&
                  "sur " + parseInt(contracts[petIdx].coverage.health_limit) + "€/an") ||
                ""
              }
            />
          </Flex>
        </Flex>

        {contracts[petIdx]?.coverage?.prevention_limit ? (
          <>
            <Texts px={["3vw", "5vw"]} mt="30px" w="100%">
              Dans le cadre de votre forfait bien-être
            </Texts>
            <Flex px={["3vw", "5vw"]} mt="10px" w="100%" align="flex-start" alignItems={"center"}>
              <Icon name="filledInfo" color="iconGreyDefault" />
              <Flex align="flex-start">
                <Texts
                  px={["0.5vw"]}
                  mt="0px"
                  w="100%"
                  fontSize={"13px"}
                  color={"grey"}
                  fontWeight={"500"}
                >
                  Quelque soit le plafond choisi lors de votre souscription,
                  <br /> les remboursements concernant la stérilisation ne pourront excéder 100 €.
                </Texts>
              </Flex>
            </Flex>
            <Flex
              px={["3vw", "5vw"]}
              direction={["column", "column", "row"]}
              w="100%"
              rounded="8px"
              mt="30px"
            >
              <Flex mr={[0, 0, "20px"]}>
                <PetGuaranteeCard
                  label={
                    "Ce taux correspond au % de remboursement de vos futurs frais vétérinaires"
                  }
                  value={100}
                  valueType="%"
                  totalValue={100}
                  title={"Taux de couverture"}
                  description={"des frais couverts par Dalma"}
                />
              </Flex>
              <Flex mt={["24px", "24px", 0]}>
                <PetGuaranteeCard
                  label={
                    "Ce montant correspond à la limite maximale de vos remboursements sur un an"
                  }
                  value={preventionConso}
                  valueType="€"
                  title={"Plafond annuel"}
                  totalValue={
                    (contracts &&
                      contracts.length > 0 &&
                      contracts[petIdx].coverage &&
                      contracts[petIdx].coverage.prevention_limit) ||
                    0
                  }
                  description={
                    (contracts &&
                      contracts.length > 0 &&
                      contracts[petIdx].coverage &&
                      "sur " + parseInt(contracts[petIdx].coverage.prevention_limit) + "€/an") ||
                    ""
                  }
                />
              </Flex>
            </Flex>
          </>
        ) : null}

        <Divider mt="30px" />
        {contract && (
          <Flex w="100%" justifyContent="center">
            <Flex w={["90vw", "90vw", "90vw", "90%", "90%", "90%"]}>
              <RefundInformations values={contract} fromPricing={false} bg="#F6FAFF" />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  ) : null;
};
