import { styled } from "src/styles/stitches/theme";

export const VisuallyHidden = styled("span", {
  position: "absolute !important",
  width: "1px !important",
  height: "1px !important",
  padding: "0 !important",
  margin: "-1px !important",
  overflow: "hidden !important",
  clip: "rect(0,0,0,0) !important",
  whiteSspace: "nowrap !important",
  border: "0 !important",
});
