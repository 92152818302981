import { DialogContent, DialogOverlay } from "@reach/dialog";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { Outlet } from "src/components/01_atoms/outlet";
import { baseColors, styled } from "src/styles/stitches/theme";
import { PetList } from "../home-navbar/pet-list";
import { RightSidebarLinks } from "../home-navbar/right-sidebar-links";
import { ProfileAvatar } from "../home-navbar/profile-avatar";
import { useMobileMenu } from "src/hooks/use-mobile-menu";
import { useBanner } from "src/hooks/use-banner";

const HEADER_MOBILE_HEIGHT = "5.95rem";

const Wrapper = styled("div", {
  "& div[data-reach-dialog-content]": {
    background: "DimGray",
  },
});

const StyledDialogOverlay = styled(DialogOverlay, {
  background: "transparent",
  height: `calc(100% - ${HEADER_MOBILE_HEIGHT})`,
  marginTop: HEADER_MOBILE_HEIGHT,

  "& div[data-reach-dialog-content]": {
    background: baseColors.neutral000,
    width: "100vw",
    height: `calc(100% - ${HEADER_MOBILE_HEIGHT})`,
    margin: 0,
    padding: "2rem 1.5rem",
    overflowY: "auto",
  },
});

const StyledContent = styled(DialogContent, {
  position: "sticky",
  top: HEADER_MOBILE_HEIGHT,
  left: 0,
  right: 0,
  bottom: 0,
  padding: 0,

  "& > div": {
    maxWidth: "23.5625rem",
    margin: "0 auto",
  },
});

// TODO, convert this to usePresence + CSS transitions to improve bundle size
const Content = motion(StyledContent);

export function MobileMenu() {
  const { isMobileMenuOpen, setMobileMenuOpen } = useMobileMenu();
  const { isClosed } = useBanner();

  // close the modal if the window is resized to desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && isMobileMenuOpen) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileMenuOpen, setMobileMenuOpen]);

  return (
    <AnimatePresence>
      {isMobileMenuOpen && (
        <Wrapper>
          <StyledDialogOverlay
            style={{ background: "transparent", transition: "none" }}
            onDismiss={() => setMobileMenuOpen(false)}
          >
            {/* @ts-ignore this works but TS get mad for a reason I can't explain right now */}
            <Content
              aria-label="menu"
              initial={{ transform: "translateX(100%)" }}
              animate={{ transform: "translateX(0%)" }}
              exit={{ transform: "translateX(100%)" }}
              transition={{ ease: "easeOut" }}
              style={{ height: isClosed ? "100%" : "" }}
            >
              <Outlet vertical="spacing64px" fluid>
                <ProfileAvatar isMobileMenu />
                <PetList />
                <RightSidebarLinks />
              </Outlet>
            </Content>
          </StyledDialogOverlay>
        </Wrapper>
      )}
    </AnimatePresence>
  );
}
