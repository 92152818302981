import { ComponentProps, ElementType, forwardRef } from "react";
import { styled } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";
import { Outlet } from "../outlet";
import { Spinner, SpinnerWrapper, StyledButton, _Button } from "./_button";
import { InnerButton } from "./_utils";

const StyledLink = styled("a", StyledButton);

type AnchorButtonProps = WithChildren<ComponentProps<"a"> & _Button & { as?: ElementType }>;

export const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  ({ iconLeft, iconRight, children, size, loading, ...rest }, ref) => {
    const baseInner = (
      <InnerButton iconLeft={iconLeft} iconRight={iconRight} size={size}>
        {children}
      </InnerButton>
    );

    return (
      <StyledLink size={size} {...rest} ref={ref}>
        <Outlet horizontal="spacing8px" justify="center" align="center">
          {loading ? (
            <SpinnerWrapper>
              <Outlet horizontal="spacing8px" justify="center" align="center">
                {baseInner}
              </Outlet>
              <Spinner size={size} />
            </SpinnerWrapper>
          ) : (
            baseInner
          )}
        </Outlet>
      </StyledLink>
    );
  }
);

AnchorButton.displayName = "AnchorButton";
