import { Icon } from "src/components/01_atoms/icon";
import { AccessibleLink } from "src/components/01_atoms/link";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { Avatar } from "src/components/02_molecules/avatar";
import { routes } from "src/routes";
import { baseColors, styled, theme } from "src/styles/stitches/theme";
import { Pet as _Pet } from "src/types";

const Pet = styled(AccessibleLink, {
  color: theme.colors.textBaseSubdued,

  [`${Text}`]: {
    transition: "color 0.15s ease-out",
  },

  "&:hover": {
    color: theme.colors.textBaseDefault,

    // Need to trigger the hover manually because the avatar is in the link
    [`${Avatar}`]: {
      boxShadow: `0px 0px 0px 1px rgba(${baseColors.neutral400}, 1)`,
    },
  },
});

type PetItemProps = {
  type: _Pet["pet"]["pet_type"];
  name: string;
};

export function PetItem({ type, name }: PetItemProps) {
  return (
    <Pet to={routes.contracts}>
      <Outlet horizontal="spacing8px" align="center" justify="start">
        <Avatar>
          <Icon name={type === "dog" ? "outlinedDog" : "outlinedCat"} color="iconBaseSubdued" />
        </Avatar>
        <Text variant="paragraph2-Bold" color="currentColor">
          {name}
        </Text>
      </Outlet>
    </Pet>
  );
}
