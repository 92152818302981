import { Text } from "src/components/01_atoms/text";
import { useGetContact } from "src/hooks/data";
import { styled, theme } from "src/styles/stitches/theme";
import { Outlet } from "src/components/01_atoms/outlet";

const StyledOutlet = styled(Outlet, {
  marginTop: theme.spacing.spacing32px,
  padding: `0 ${theme.spacing.spacing24px}`,

  "@bp3": {
    marginTop: theme.spacing.spacing64px,
    padding: "unset",
  },
});

export function HomeHeader() {
  const { data } = useGetContact();

  return (
    <StyledOutlet vertical="spacing4px">
      <Text
        variant={{ "@initial": "display3-Bold", "@bp3": "display2-Bold", "@bp4": "display1-Bold" }}
        color="textBaseDefault"
      >
        Hello {data?.firstname} !
      </Text>
      <Text
        variant={{ "@initial": "paragraph2-Medium", "@bp3": "paragraph1-Medium" }}
        color="textBaseSubdued"
      >
        Ravi de vous revoir 👋
      </Text>
    </StyledOutlet>
  );
}
