import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { Modal } from "src/components/04_layouts/modal-layout";
import { styled } from "src/styles/stitches/theme";
import { StyledOutlet } from "./alert-modal.styled";

const Img = styled("img", {
  width: "15rem",
  height: "15rem",
});

type Props = {
  isOpen: boolean;
  label: string;
  title: string;
  description: string;
  onDismiss: () => void;
  buttonText1?: string;
  onDismissButton1?: () => void;
  buttonText2?: string;
  onDismissButton2?: () => void;
  hasImage?: boolean;
  children?: React.ReactNode;
};

export function AlertModal({
  isOpen,
  label,
  title,
  description,
  onDismiss,
  buttonText1,
  onDismissButton1,
  buttonText2,
  onDismissButton2,
  hasImage,
  children,
}: Props) {
  return (
    <Modal isOpen={isOpen} label={label} onDismiss={onDismiss}>
      <StyledOutlet vertical="spacing48px" align="center">
        {hasImage ? (
          <Img
            src="/assets/media/humans-advice-cat.svg"
            alt="Illustration d'une femme avec un chat sur la tête qui regardent un écran de téléphone"
          />
        ) : null}
        <Outlet vertical="spacing16px">
          <Text variant="display3-Bold" color="textBaseDefault" textAlign="center">
            {title}
          </Text>
          <Text variant="paragraph1-Medium" color="textBaseSubdued" textAlign="center">
            {description}
          </Text>
        </Outlet>
        <Outlet vertical="spacing8px" fluid>
          {children}
        </Outlet>
        <Outlet vertical="spacing8px" fluid>
          {buttonText1 ? (
            <Button type="button" variant="accent" size="lg" fluid onClick={onDismissButton1}>
              {buttonText1}
            </Button>
          ) : null}
          {buttonText2 ? (
            <Button type="button" variant="secondary" size="lg" fluid onClick={onDismissButton2}>
              {buttonText2}
            </Button>
          ) : null}
        </Outlet>
      </StyledOutlet>
    </Modal>
  );
}
