import { ClaimCategory, Claim_v2 } from "src/types";
import { upperFirst } from "lodash";
import { ArrowsNames, FilledNames, OutlinedNames } from "src/components/01_atoms/icon";
import { getYear } from "src/utils/format";
import { getUniqueValues } from "src/utils/utils";

// GET CLAIMS ACT CATEGORIES
const categoriesByPriority = {
  ACCIDENT: 1,
  MALADIE: 2,
  PREVENTION: 3,
};

const sortCategories = (categories: ClaimCategory[]) =>
  categories.sort((a, b) => categoriesByPriority[a] - categoriesByPriority[b]);

const categories = {
  ACCIDENT: "accident",
  MALADIE: "maladie",
  PREVENTION: "prévention",
};

const concatenateClaimActCategories = (claimActCategories: ClaimCategory[]) => {
  switch (claimActCategories.length) {
    case 1:
      return categories[claimActCategories[0]];
    case 2:
      return `${categories[claimActCategories[0]]} et ${categories[claimActCategories[1]]}`;
    case 3:
      return `${categories[claimActCategories[0]]}, ${categories[claimActCategories[1]]} et ${
        categories[claimActCategories[2]]
      }`;
    default:
      return "soins vétérinaires";
  }
};

export const formatClaimActCategories = (categories: ClaimCategory[]) => {
  const uniqueCategories = getUniqueValues(categories);

  const sortedCategories = sortCategories(uniqueCategories);

  return upperFirst(concatenateClaimActCategories(sortedCategories));
};

// GET CLAIM ICON

const categoriesIconNames: {
  ACCIDENT: FilledNames;
  MALADIE: FilledNames;
  PREVENTION: FilledNames;
  DEFAULT: OutlinedNames;
} = {
  ACCIDENT: "filledAccident",
  MALADIE: "filledMaladie",
  PREVENTION: "filledPrevention",
  DEFAULT: "outlinedHospital",
};

export const getClaimIcon = (
  categories: ClaimCategory[]
): ArrowsNames | FilledNames | OutlinedNames => {
  const uniqueCategories = getUniqueValues(categories);

  const sortedCategories = sortCategories(uniqueCategories);

  if (categories.length === 1) return categoriesIconNames[sortedCategories[0]];

  if (categories.length > 1) return categoriesIconNames[sortedCategories[0]];

  return "outlinedHospital";
};

// GET CLAIMS BY YEAR

export const getClaimsByYear = (claims: Claim_v2[]) => {
  const claimsCopy = [...claims];
  const sortedClaims = claimsCopy?.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });

  const years = sortedClaims?.map((claim) => getYear(claim.date));

  const uniqueYears = getUniqueValues(years);

  return uniqueYears.map((year) => ({
    year,
    claims: sortedClaims?.filter((claim) => getYear(claim.date) === year),
  }));
};
