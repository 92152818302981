/* eslint-disable no-nested-ternary */
import { ReactNode, useState } from "react";
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  Switch,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";

import Lock from "@iconscout/react-unicons/icons/uil-lock";
import { Texts } from "../texts/Texts";
import { Title } from "../texts/Title";

type Information = {
  name: string;
  color: string;
  bg: string;
};

type Option = {
  icon: ReactNode;
  description: string;
};

type Props = {
  bg?: string;
  bgOptionIcons?: string;
  optionIcon?: ReactNode;
  optionDescription?: ReactNode;
  optionTitle?: ReactNode;
  optionPrice?: number | null;
  selected?: boolean;
  disabled?: boolean;
  optionsIncluded?: Array<Option> | null;
  informations?: Array<Information> | null;
};

export const OptionsDetails = ({
  optionIcon = null,
  optionTitle = null,
  bgOptionIcons = "#DBEBFF",
  optionDescription = null,
  optionPrice = null,
  selected = false,
  disabled = false,
  optionsIncluded = null,
  informations = null,
  bg = "white",
}: Props) => {
  const [accordionOpened, setAccordionOpened] = useState(false);

  return (
    <Flex boxShadow="0px 4px 8px rgba(0, 0, 0, 0.08)" direction="column" rounded="8px" flex={1}>
      <Flex bg={bg} roundedTop="8px" minH="115px" w="100%" justifyContent="center">
        <Flex
          minH="130px"
          alignItems={["flex-start", "flex-start", "center"]}
          alignContent="center"
          w="90%"
          direction={["column", "column", "row"]}
        >
          <Flex minW="400px" w="100%" display={["none", "none", "flex"]}>
            <Flex mr={["20px"]} align="flex-start" mt="1">
              <Center>{optionIcon}</Center>
            </Flex>
            <Flex
              direction="column"
              align="flex-start"
              justifyContent="flex-start"
              minH="70px"
              w="100%"
            >
              <Flex mb="1" w="100%">
                <Title
                  minW="320px"
                  textAlign="left"
                  color={disabled ? "#616161" : selected ? "#FE6F18" : "#212121"}
                  fontSize="20px"
                >
                  {optionTitle}
                </Title>
              </Flex>
              <Flex w="100%">
                <Texts textAlign="left" color="#616161" fontSize="14px">
                  {optionDescription}
                </Texts>
              </Flex>
            </Flex>
          </Flex>

          <Flex direction="row" align="center" justifyContent="flex-start" w="100%">
            <Flex mt="-5" display={["flex", "none", "none"]}>
              <Center w="100%">{optionIcon}</Center>
            </Flex>
            <Flex
              flex={1}
              minH={["70px", "50px", "70px"]}
              mt={["30px", "30px", 0]}
              w="100%"
              justifyContent="flex-end"
            >
              {optionPrice && !disabled ? (
                <>
                  <Flex
                    h="32px"
                    px="2"
                    alignItems="center"
                    rounded="full"
                    bg={selected ? "#FAFAFA" : "#DBEBFF"}
                  >
                    <Texts fontSize="12" color={selected ? "#FE6F18" : "#4E68F6"}>
                      {`+${optionPrice}€/mois`}
                    </Texts>
                  </Flex>
                  <Flex>
                    <Switch colorScheme="orange" size="lg" ml="16px" isChecked={selected} />
                  </Flex>
                </>
              ) : disabled ? (
                <Flex
                  minW={[0, "160px"]}
                  ml="3"
                  h="32px"
                  px="3"
                  align="center"
                  justifyContent="center"
                  rounded="full"
                  bg="#EEEEEE"
                >
                  <Flex mt="-0.5">
                    <Lock size="13px" />
                  </Flex>
                  <Flex display={["none", "flex", "flex", "none", "flex"]}>
                    <Texts textAlign="left" ml="2" fontWeight="500" fontSize="12" color="#616161">
                      Disponible avec la formule Intégrale
                    </Texts>
                  </Flex>
                  <Flex display={["flex", "none", "none", "flex", "none"]}>
                    <Texts textAlign="left" ml="2" fontWeight="500" fontSize="12" color="#616161">
                      Formule Intégrale
                    </Texts>
                  </Flex>
                </Flex>
              ) : (
                <>
                  {informations &&
                    informations.map((item) => (
                      <>
                        <Flex
                          ml="3"
                          h="32px"
                          px="3"
                          alignItems="center"
                          rounded="full"
                          bg={item.bg}
                        >
                          <Texts textAlign="left" fontSize="12" color={item.color}>
                            {item.name}
                          </Texts>
                        </Flex>
                      </>
                    ))}
                </>
              )}
            </Flex>
          </Flex>

          <Flex mb="30px" display={["flex", "flex", "none"]}>
            <Flex mr="20px" mt="-5" display={["none", "flex", "none"]}>
              <Center w="100%">{optionIcon}</Center>
            </Flex>
            <Flex direction="column" justifyContent="center" minH="70px">
              <Flex mb="1">
                <Title
                  color={disabled ? "#616161" : selected ? "#FE6F18" : "#212121"}
                  fontSize="20px"
                >
                  {optionTitle}
                </Title>
              </Flex>
              <Flex mb="5">
                <Texts color="#616161" textAlign="left" fontSize="14px">
                  {optionDescription}
                </Texts>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex roundedBottom="8px" align="flex-end" justifyContent="center" w="100%">
        <Accordion
          onChange={() => {
            setAccordionOpened(!accordionOpened);
          }}
          bg={bg}
          roundedBottom="8px"
          allowToggle
          w="100%"
        >
          <Flex justifyContent="center" flex={1}>
            <Divider w="90%" />
          </Flex>
          <AccordionItem borderColor="transparent" w="100%">
            <AccordionPanel p="0" pb={4} mt="6" mb="10">
              <SimpleGrid spacing="24px" columns={[1, 1, 2]} w="90%">
                {optionsIncluded &&
                  optionsIncluded.map((e, i) => (
                    <Flex key={e.description + i.toString()}>
                      <Flex alignItems="center">
                        <Center bg={bgOptionIcons} h="56px" w="56px" rounded="8px">
                          {e.icon}
                        </Center>
                      </Flex>

                      <Flex alignItems="center" ml="20px">
                        <Texts textAlign="left" fontSize="12px" color="#212121">
                          {e.description}
                        </Texts>
                      </Flex>
                    </Flex>
                  ))}
              </SimpleGrid>
            </AccordionPanel>
            <AccordionButton>
              <Flex
                flex="1"
                align="center"
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              >
                <Texts color="#616161">{!accordionOpened ? "Plus de détails" : "Voir moins"}</Texts>

                <AccordionIcon ml="1" />
              </Flex>
            </AccordionButton>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Flex>
  );
};
