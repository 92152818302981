import { SANDBOX } from "src/config";

const LEAD_LOGIN_API_URL = SANDBOX
  ? "https://free-users-authentication.dalma.dev/dalma-latest/signin"
  : "https://free-users-authentication.dalma.tech/dalma/signin";

export async function leadLoginApi(
  email: string,
  password: string
): Promise<{ token: string; refresh_token: string }> {
  const response = await fetch(LEAD_LOGIN_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (!response.ok) {
    throw new Error(`${response.status}`);
  }

  return await response.json();
}
