import { AccessibleLink } from "src/components/01_atoms/link";
import { Logo } from "src/components/01_atoms/logo";
import { Outlet } from "src/components/01_atoms/outlet";
import { styled } from "src/styles/stitches/theme";

const LogoWrapper = styled(Outlet, {
  width: "100%",
  padding: "4rem 2rem 1rem 2rem",
});

export function HomeNavbarHeader() {
  return (
    <LogoWrapper horizontal="0" justify="start">
      <AccessibleLink
        to="/"
        title="Revenir à votre espace client"
        aria-label="Revenir à votre espace client"
      >
        <Logo color="fillBaseAccent" size="xs" space />
      </AccessibleLink>
    </LogoWrapper>
  );
}
