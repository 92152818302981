import config from "src/config";

export interface ApiUnpaidStatusResponse {
  statusCode: number;
  body?: {
    customer_id: string;
    subscription_id: string;
    unpaid_invoice_id: string;
    recovery_invoice_id: string;
    invoice_status: string;
    recovery_amount: number;
    unpaid_type: string;
    email_created_at: string;
    recovery_payment_link: string;
  };
}

export async function getUnpaidStatus(subscriptionId: string): Promise<ApiUnpaidStatusResponse> {
  const response = await fetch(config.UNPAID_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `{"subscription_id":"${subscriptionId}"}`,
  });

  return await response.json();
}
