export const dogVarietyList = [
  {
    key: "american_bully_pocket",
    label: "American Bully Pocket",
  },
  {
    key: "affenpinscher",
    label: "Affenpinscher",
  },
  {
    key: "african_lion_hound",
    label: "African Lion Hound",
  },
  {
    key: "aidi",
    label: "Aïdi",
  },
  {
    key: "airedale",
    label: "Airedale",
  },
  {
    key: "akita_americain",
    label: "Akita Americain",
  },
  {
    key: "akita_inu",
    label: "Akita Inu",
  },
  {
    key: "alano_espagnol",
    label: "Alano espagnol",
  },
  {
    key: "alaskan_klee_kai",
    label: "Alaskan Klee Kai",
  },
  {
    key: "new_english_bulldog",
    label: "New English Bulldog",
  },
  {
    key: "alaskan_malamute",
    label: "Alaskan Malamute",
  },
  {
    key: "altdeutsche_schaferhunde",
    label: "Altdeutsche Schaferhunde",
  },
  {
    key: "americain_foxhound",
    label: "Américain Foxhound",
  },
  {
    key: "americain_staffordshire_terrier",
    label: "Americain Staffordshire Terrier",
  },
  {
    key: "american_bully",
    label: "American Bully",
  },
  {
    key: "american_hairless_terrier",
    label: "American Hairless Terrier",
  },
  {
    key: "american_shepherd",
    label: "American shepherd",
  },
  {
    key: "american-staffordshire-terrier",
    label: "American-Staffordshire-Terrier",
  },
  {
    key: "american-water-spaniel",
    label: "American-Water-Spaniel",
  },
  {
    key: "ancien_chien_d_arret_danois",
    label: "Ancien Chien D'arrêt Danois",
  },
  {
    key: "anglo-français_de_petite_venerie",
    label: "Anglo-Français de Petite Vènerie",
  },
  {
    key: "anglo-français_tricolore",
    label: "Anglo-Français Tricolore",
  },
  {
    key: "ariegeois",
    label: "Ariegeois",
  },
  {
    key: "aussie",
    label: "Aussie",
  },
  {
    key: "aussie_bulldog",
    label: "Aussie Bulldog",
  },
  {
    key: "australian_cobberdog",
    label: "Australian cobberdog",
  },
  {
    key: "australian_kelpie",
    label: "Australian Kelpie",
  },
  {
    key: "australian_silky_terrier",
    label: "Australian Silky Terrier",
  },
  {
    key: "azawakh",
    label: "Azawakh",
  },
  {
    key: "bangkaew_de_thailande",
    label: "Bangkaew De Thailande",
  },
  {
    key: "barak",
    label: "Barak",
  },
  {
    key: "barbet",
    label: "Barbet",
  },
  {
    key: "barbu_tcheque",
    label: "Barbu Tcheque",
  },
  {
    key: "barzoi",
    label: "Barzoï",
  },
  {
    key: "bas-rouge",
    label: "Bas-Rouge",
  },
  {
    key: "basset_artesien-normand",
    label: "Basset Artésien-Normand",
  },
  {
    key: "basset_bleu_de_gascogne",
    label: "Basset Bleu De Gascogne",
  },
  {
    key: "basset_de_westphalie",
    label: "Basset De Westphalie",
  },
  {
    key: "basset_des_alpes",
    label: "Basset Des Alpes",
  },
  {
    key: "basset_fauve_de_bretagne",
    label: "Basset Fauve De Bretagne",
  },
  {
    key: "basset_hound",
    label: "Basset Hound",
  },
  {
    key: "basset_suedois",
    label: "Basset Suedois",
  },
  {
    key: "beagle",
    label: "Beagle",
  },
  {
    key: "beagle-harrier",
    label: "Beagle-Harrier",
  },
  {
    key: "bearded_collie",
    label: "Bearded Collie",
  },
  {
    key: "beauceron",
    label: "Beauceron",
  },
  {
    key: "bedlington-terrier",
    label: "Bedlington-Terrier",
  },
  {
    key: "bergamasque",
    label: "Bergamasque",
  },
  {
    key: "berger_allemand",
    label: "Berger Allemand",
  },
  {
    key: "berger_americain",
    label: "Berger Américain",
  },
  {
    key: "berger_americain_miniature",
    label: "Berger americain miniature (American sheperd miniature)",
  },
  {
    key: "berger_australien",
    label: "Berger Australien",
  },
  {
    key: "berger_australien_miniature",
    label: "Berger australien miniature",
  },
  {
    key: "berger_belge",
    label: "Berger Belge",
  },
  {
    key: "berger_belge_groenendal",
    label: "Berger Belge Groenendal",
  },
  {
    key: "berger_belge_laekenois",
    label: "Berger belge laekenois",
  },
  {
    key: "berger_belge_malinois",
    label: "Berger Belge Malinois",
  },
  {
    key: "berger_belge_tervueren",
    label: "Berger Belge Tervueren",
  },
  {
    key: "berger_bergamasque",
    label: "Berger Bergamasque",
  },
  {
    key: "berger_blanc_suisse",
    label: "Berger Blanc Suisse",
  },
  {
    key: "berger_canadien",
    label: "Berger Canadien",
  },
  {
    key: "berger_catalan",
    label: "Berger Catalan",
  },
  {
    key: "berger_creole",
    label: "Berger Créole",
  },
  {
    key: "berger_croate",
    label: "Berger Croate",
  },
  {
    key: "berger_d_alsace",
    label: "Berger D'Alsace",
  },
  {
    key: "berger_d_anatolie",
    label: "Berger d'Anatolie",
  },
  {
    key: "berger_d_asie_centrale",
    label: "Berger D'asie Centrale",
  },
  {
    key: "berger_d_atura",
    label: "Berger d'Atura",
  },
  {
    key: "berger_d_islande",
    label: "Berger d'Islande",
  },
  {
    key: "berger_de_beauce",
    label: "Berger De Beauce",
  },
  {
    key: "berger_de_bergame",
    label: "Berger De Bergame",
  },
  {
    key: "berger_de_boheme",
    label: "Berger De Boheme",
  },
  {
    key: "berger_de_bosnie_herzegovine_et_de_croatie",
    label: "Berger de Bosnie Herzegovine et de Croatie",
  },
  {
    key: "berger_de_brie",
    label: "Berger De Brie",
  },
  {
    key: "berger_de_crau",
    label: "Berger De Crau",
  },
  {
    key: "berger_de_croatie",
    label: "Berger de Croatie",
  },
  {
    key: "berger_de_hollande",
    label: "Berger de Hollande",
  },
  {
    key: "berger_de_karst",
    label: "Berger de Karst",
  },
  {
    key: "berger_de_l_europe_du_sud-est",
    label: "Berger de l'Europe du Sud-Est",
  },
  {
    key: "berger_de_la_serra_de_aires",
    label: "Berger De La Serra De Aires",
  },
  {
    key: "berger_de_maremme_et_abruzzes",
    label: "Berger de Maremme et abruzzes",
  },
  {
    key: "berger_de_picardie",
    label: "Berger De Picardie",
  },
  {
    key: "berger_de_podhale",
    label: "Berger de Podhale",
  },
  {
    key: "berger_de_russie_meridionale",
    label: "Berger De Russie Meridionale",
  },
  {
    key: "berger_de_savoie",
    label: "Berger De Savoie",
  },
  {
    key: "berger_des_abruzzes_et_maremme",
    label: "Berger des Abruzzes et Maremme",
  },
  {
    key: "berger_des_alpes",
    label: "Berger des Alpes",
  },
  {
    key: "berger_des_pyrenees",
    label: "Berger des Pyrénées",
  },
  {
    key: "berger_des_shetland",
    label: "Berger des Shetland",
  },
  {
    key: "berger_du_bassin_de_karst",
    label: "Berger du bassin de Karst",
  },
  {
    key: "berger_du_caucase",
    label: "Berger Du Caucase",
  },
  {
    key: "berger_du_karst",
    label: "Berger Du Karst",
  },
  {
    key: "berger_ecossais",
    label: "Berger écossais",
  },
  {
    key: "berger_espagnol_de_majorque",
    label: "Berger Espagnol de Majorque",
  },
  {
    key: "berger_finnois_de_laponie",
    label: "Berger Finnois De Laponie",
  },
  {
    key: "berger_hollandais",
    label: "Berger Hollandais",
  },
  {
    key: "berger_hongrois",
    label: "Berger Hongrois",
  },
  {
    key: "berger_islandais",
    label: "Berger Islandais",
  },
  {
    key: "berger_polonais",
    label: "Berger Polonais",
  },
  {
    key: "berger_polonais_de_plaine",
    label: "Berger Polonais De Plaine",
  },
  {
    key: "berger_polonais_de_podhale",
    label: "Berger polonais de Podhale",
  },
  {
    key: "berger_portugais",
    label: "Berger Portugais",
  },
  {
    key: "berger_roumain_des_carpates",
    label: "Berger Roumain des Carpates",
  },
  {
    key: "berger_yougoslave",
    label: "Berger Yougoslave",
  },
  {
    key: "bichon_a_poil_droit",
    label: "Bichon à poil droit",
  },
  {
    key: "bichon_a_poil_frise",
    label: "Bichon A Poil Frise",
  },
  {
    key: "bichon_bolognais",
    label: "Bichon Bolognais",
  },
  {
    key: "bichon_havanais",
    label: "Bichon Havanais",
  },
  {
    key: "bichon_maltais",
    label: "Bichon Maltais",
  },
  {
    key: "biewer_yorkshire",
    label: "Biewer Yorkshire",
  },
  {
    key: "billy",
    label: "Billy",
  },
  {
    key: "bloodhound",
    label: "Bloodhound",
  },
  {
    key: "bobtail",
    label: "Bobtail",
  },
  {
    key: "bodeguero",
    label: "Bodeguero",
  },
  {
    key: "boerbull",
    label: "Boerbull",
  },
  {
    key: "boerenfox",
    label: "Boerenfox",
  },
  {
    key: "bolonka",
    label: "Bolonka",
  },
  {
    key: "boomer",
    label: "Boomer",
  },
  {
    key: "border_collie",
    label: "Border Collie",
  },
  {
    key: "border-terrier",
    label: "Border-Terrier",
  },
  {
    key: "boston-terrier",
    label: "Boston-Terrier",
  },
  {
    key: "bouledogue_americain",
    label: "Bouledogue Americain",
  },
  {
    key: "bouledogue_anglais",
    label: "Bouledogue Anglais",
  },
  {
    key: "bouledogue_français",
    label: "Bouledogue Français",
  },
  {
    key: "bouvier_australien",
    label: "Bouvier Australien",
  },
  {
    key: "bouvier_australien_courte_queue",
    label: "Bouvier Australien Courte Queue",
  },
  {
    key: "bouvier_bernois",
    label: "Bouvier Bernois",
  },
  {
    key: "bouvier_d_appenzell",
    label: "Bouvier d'Appenzell",
  },
  {
    key: "bouvier_de_l_entlebuch",
    label: "Bouvier De L'Entlebuch",
  },
  {
    key: "bouvier_de_l_alentejo",
    label: "Bouvier de l'alentejo",
  },
  {
    key: "bouvier_des_alpes",
    label: "Bouvier des Alpes",
  },
  {
    key: "bouvier_des_ardennes",
    label: "Bouvier Des Ardennes",
  },
  {
    key: "bouvier_des_flandres",
    label: "Bouvier Des Flandres",
  },
  {
    key: "boxer",
    label: "Boxer",
  },
  {
    key: "boxer_nain",
    label: "Boxer nain",
  },
  {
    key: "brabançon",
    label: "Brabançon",
  },
  {
    key: "brachet_allemand",
    label: "Brachet Allemand",
  },
  {
    key: "brachet_de_styrie_a_poil_dur",
    label: "Brachet De Styrie A Poil Dur",
  },
  {
    key: "brachet_hongrois",
    label: "Brachet Hongrois",
  },
  {
    key: "brachet_noir_et_feu",
    label: "Brachet Noir Et Feu",
  },
  {
    key: "brachet_polonais",
    label: "Brachet Polonais",
  },
  {
    key: "brachet_tyrolien",
    label: "Brachet Tyrolien",
  },
  {
    key: "braque_allemand",
    label: "Braque Allemand",
  },
  {
    key: "braque_allemand_a_poil_court",
    label: "Braque Allemand A Poil Court",
  },
  {
    key: "braque_d_auvergne",
    label: "Braque D Auvergne",
  },
  {
    key: "braque_de_burgos",
    label: "Braque De Burgos",
  },
  {
    key: "braque_de_l_ariege",
    label: "Braque De L Ariege",
  },
  {
    key: "braque_de_weimar",
    label: "Braque De Weimar",
  },
  {
    key: "braque_du_bourbonnais",
    label: "Braque Du Bourbonnais",
  },
  {
    key: "braque_dupuy",
    label: "Braque Dupuy",
  },
  {
    key: "braque_français",
    label: "Braque Français",
  },
  {
    key: "braque_francais_type_gascogne",
    label: "Braque Francais Type Gascogne",
  },
  {
    key: "braque_francais_type_pyrenees",
    label: "Braque Francais Type Pyrenees",
  },
  {
    key: "braque_hongrois",
    label: "Braque Hongrois",
  },
  {
    key: "braque_hongrois_a_poil_court",
    label: "Braque Hongrois A Poil Court",
  },
  {
    key: "braque_hongrois_a_poil_dur",
    label: "Braque Hongrois A Poil Dur",
  },
  {
    key: "braque_italien",
    label: "Braque Italien",
  },
  {
    key: "braque_saint-germain",
    label: "Braque Saint-Germain",
  },
  {
    key: "braque_slovaque_a_poil_dur",
    label: "Braque Slovaque A Poil Dur",
  },
  {
    key: "braque_suedois",
    label: "Braque Suedois",
  },
  {
    key: "briard",
    label: "Briard",
  },
  {
    key: "briquet_d_artois",
    label: "Briquet d'Artois",
  },
  {
    key: "briquet_de_provence",
    label: "Briquet De Provence",
  },
  {
    key: "briquet_griffon_vendeen",
    label: "Briquet Griffon Vendeen",
  },
  {
    key: "broholmer",
    label: "Broholmer",
  },
  {
    key: "bruno_du_jura",
    label: "Bruno Du Jura",
  },
  {
    key: "buhund_norvegien",
    label: "Buhund Norvegien",
  },
  {
    key: "bull_terrier",
    label: "Bull Terrier",
  },
  {
    key: "bull_terrier_miniature",
    label: "Bull Terrier Miniature",
  },
  {
    key: "bulldog_francais",
    label: "Bulldog français",
  },
  {
    key: "bulldog_anglais",
    label: "Bulldog Anglais",
  },
  {
    key: "bullmastiff",
    label: "Bullmastiff",
  },
  {
    key: "ca_de_bestiar",
    label: "Ca de bestiar",
  },
  {
    key: "ca_de_bou",
    label: "Ca de Bou",
  },
  {
    key: "cairn_terrier",
    label: "Cairn Terrier",
  },
  {
    key: "cane_corso",
    label: "Cane Corso",
  },
  {
    key: "caniche",
    label: "Caniche",
  },
  {
    key: "caniche_moyen",
    label: "Caniche moyen",
  },
  {
    key: "caniche_nain",
    label: "Caniche nain",
  },
  {
    key: "caniche_toy",
    label: "Caniche toy",
  },
  {
    key: "cans_bou",
    label: "Cans Bou",
  },
  {
    key: "cao_da_serra_de_aires",
    label: "Cão da serra de Aires",
  },
  {
    key: "carlin",
    label: "Carlin",
  },
  {
    key: "cavalier",
    label: "Cavalier",
  },
  {
    key: "cavalier_king_charles",
    label: "Cavalier King Charles",
  },
  {
    key: "cavalier_king_charles_spaniel",
    label: "Cavalier King Charles Spaniel",
  },
  {
    key: "cesky_terrier",
    label: "Cesky Terrier",
  },
  {
    key: "charplaninatz",
    label: "Charplaninatz",
  },
  {
    key: "chien_a_loutre",
    label: "Chien A Loutre",
  },
  {
    key: "chien_a_peau_nue",
    label: "Chien à Peau Nue",
  },
  {
    key: "chien_chinois",
    label: "Chien Chinois",
  },
  {
    key: "chien_chinois_a_crete",
    label: "Chien Chinois A Crete",
  },
  {
    key: "chien_corse",
    label: "Chien Corse",
  },
  {
    key: "chien_courant",
    label: "Chien Courant",
  },
  {
    key: "chien_courant_d_illyrie",
    label: "Chien Courant D'Illyrie",
  },
  {
    key: "chien_courant_d_istrie_a_poil_dur",
    label: "Chien Courant D'Istrie A Poil Dur",
  },
  {
    key: "chien_courant_d_istrie_a_poil_ras",
    label: "Chien Courant D'istrie A Poil Ras",
  },
  {
    key: "chien_courant_de_bosnie",
    label: "Chien courant de Bosnie",
  },
  {
    key: "chien_courant_de_halden",
    label: "Chien Courant De Halden",
  },
  {
    key: "chien_courant_de_hamilton",
    label: "Chien Courant De Hamilton",
  },
  {
    key: "chien_courant_de_hygen",
    label: "Chien Courant De Hygen",
  },
  {
    key: "chien_courant_de_la_vallee_de_la_save",
    label: "Chien courant de la vallee de la save",
  },
  {
    key: "chien_courant_de_montagne_du_montenegr",
    label: "Chien courant de montagne du montenegr",
  },
  {
    key: "chien_courant_de_posavatz",
    label: "Chien Courant De Posavatz",
  },
  {
    key: "chien_courant_de_schiller",
    label: "Chien Courant De Schiller",
  },
  {
    key: "chien_courant_de_transylvanie",
    label: "Chien Courant De Transylvanie",
  },
  {
    key: "chien_courant_des_balkans",
    label: "Chien courant des Balkans",
  },
  {
    key: "chien_courant_du_smaland",
    label: "Chien Courant Du Smaland",
  },
  {
    key: "chien_courant_espagnol",
    label: "Chien Courant Espagnol",
  },
  {
    key: "chien_courant_finlandais",
    label: "Chien Courant Finlandais",
  },
  {
    key: "chien_courant_grec",
    label: "Chien Courant Grec",
  },
  {
    key: "chien_courant_hongrois",
    label: "Chien Courant Hongrois",
  },
  {
    key: "chien_courant_italien",
    label: "Chien courant Italien",
  },
  {
    key: "chien_courant_italien_a_poil_ras",
    label: "Chien Courant Italien A Poil Ras",
  },
  {
    key: "chien_courant_norvegien",
    label: "Chien Courant Norvegien",
  },
  {
    key: "chien_courant_polonais",
    label: "Chien Courant Polonais",
  },
  {
    key: "chien_courant_portugais",
    label: "Chien courant Portugais",
  },
  {
    key: "chien_courant_serbe",
    label: "Chien Courant Serbe",
  },
  {
    key: "chien_courant_slovaque",
    label: "Chien Courant Slovaque",
  },
  {
    key: "chien_courant_slovene_de_montagne",
    label: "Chien courant Slovene de montagne",
  },
  {
    key: "chien_courant_suisse",
    label: "Chien Courant Suisse",
  },
  {
    key: "chien_courant_tricolore_serbe",
    label: "Chien Courant Tricolore Serbe",
  },
  {
    key: "chien_courant_yougoslave",
    label: "Chien courant yougoslave",
  },
  {
    key: "chien_creole",
    label: "Chien créole",
  },
  {
    key: "chien_d_arret_allemand",
    label: "Chien d'arrêt Allemand",
  },
  {
    key: "chien_d_arret_allemand_a_poil_dur",
    label: "Chien D'arret Allemand A Poil Dur",
  },
  {
    key: "chien_d_arret_allemand_a_poil_raide",
    label: "Chien D'arret Allemand A Poil Raide",
  },
  {
    key: "chien_d_arret_danois_ancestral",
    label: "Chien D'arret Danois Ancestral",
  },
  {
    key: "chien_d_arret_frison",
    label: "Chien D'arret Frison",
  },
  {
    key: "chien_d_arret_portugais",
    label: "Chien D'arret Portugais",
  },
  {
    key: "chien_d_artois",
    label: "Chien D'artois",
  },
  {
    key: "chien_d_eau_americain",
    label: "Chien D'eau Americain",
  },
  {
    key: "chien_d_eau_espagnol",
    label: "Chien D'eau Espagnol",
  },
  {
    key: "chien_d_eau_frison",
    label: "Chien D'eau Frison",
  },
  {
    key: "chien_d_eau_irlandais",
    label: "Chien d'eau irlandais",
  },
  {
    key: "chien_d_eau_italien",
    label: "Chien d'eau Italien",
  },
  {
    key: "chien_d_eau_portugais",
    label: "Chien D'eau Portugais",
  },
  {
    key: "chien_d_eau_romagnol",
    label: "Chien D'eau Romagnol",
  },
  {
    key: "chien_d_elan_gris_mineur",
    label: "Chien d'Elan Gris Mineur",
  },
  {
    key: "chien_d_elan_norvegien_gris",
    label: "Chien D'elan Norvegien Gris",
  },
  {
    key: "chien_d_elan_norvegien_noir",
    label: "Chien D'Elan Norvegien Noir",
  },
  {
    key: "chien_d_elan_suedois",
    label: "Chien D'Elan Suedois",
  },
  {
    key: "chien_d_ours_de_carelie",
    label: "Chien D'ours De Carelie",
  },
  {
    key: "chien_d_oysel_allemand",
    label: "Chien D'oysel Allemand",
  },
  {
    key: "chien_de_berge_croate",
    label: "Chien de berge Croate",
  },
  {
    key: "chien_de_berger_anglais_ancestral",
    label: "Chien De Berger Anglais Ancestral",
  },
  {
    key: "chien_de_berger_belge",
    label: "Chien de berger belge",
  },
  {
    key: "chien_de_berger_britannique",
    label: "Chien de Berger Britannique",
  },
  {
    key: "chien_de_berger_catalan",
    label: "Chien De Berger Catalan",
  },
  {
    key: "chien_de_berger_d_anatolie",
    label: "Chien De Berger D Anatolie",
  },
  {
    key: "chien_de_berger_de_majorque",
    label: "Chien De Berger De Majorque",
  },
  {
    key: "chien_de_berger_des_pyrenees_a_face_rase",
    label: "Chien de berger des Pyrénées à face rase",
  },
  {
    key: "chien_de_berger_des_pyrenees_a_poil_long",
    label: "Chien de berger des Pyrénées à poil long",
  },
  {
    key: "chien_de_berger_des_shetland",
    label: "Chien De Berger Des Shetland",
  },
  {
    key: "chien_de_berger_des_tatras",
    label: "Chien De Berger Des Tatras",
  },
  {
    key: "chien_de_berger_des_tatras_/_podhale",
    label: "Chien de berger des Tatras / Podhale",
  },
  {
    key: "chien_de_berger_du_caucase",
    label: "Chien de berger du Caucase",
  },
  {
    key: "chien_de_berger_d_auvergne",
    label: "Chien de Berger d'Auvergne",
  },
  {
    key: "chien_de_berger_islandais",
    label: "Chien De Berger Islandais",
  },
  {
    key: "chien_de_berger_roumain_de_bucovine",
    label: "Chien de Berger roumain de Bucovine",
  },
  {
    key: "chien_de_berger_roumain_de_mioritza",
    label: "Chien De Berger Roumain De Mioritza",
  },
  {
    key: "chien_de_berger_roumain_des_carpathes",
    label: "Chien De Berger Roumain Des Carpathes",
  },
  {
    key: "chien_de_berger_yougoslave_de_charplan",
    label: "Chien de berger yougoslave de charplan",
  },
  {
    key: "chien_de_canaan",
    label: "Chien De Canaan",
  },
  {
    key: "chien_de_castro_laboreiro",
    label: "Chien de Castro Laboreiro",
  },
  {
    key: "chien_de_combat_japonais",
    label: "Chien de combat Japonais",
  },
  {
    key: "chien_de_combat_majorquin",
    label: "Chien de combat majorquin",
  },
  {
    key: "chien_de_cour_italien",
    label: "Chien De Cour Italien",
  },
  {
    key: "chien_de_ferme_dano-suedois",
    label: "Chien De Ferme Dano-Suedois",
  },
  {
    key: "chien_de_franche-comte",
    label: "Chien de Franche-Comté",
  },
  {
    key: "chien_de_garde_turc",
    label: "Chien de garde Turc",
  },
  {
    key: "chien_de_garenne_des_canaries",
    label: "Chien De Garenne Des Canaries",
  },
  {
    key: "chien_de_garenne_portugais",
    label: "Chien De Garenne Portugais",
  },
  {
    key: "chien_de_groenland",
    label: "Chien de Groenland",
  },
  {
    key: "chien_de_kheops",
    label: "Chien de Kheops",
  },
  {
    key: "chien_de_l_atlas_marocain",
    label: "Chien de l Atlas Marocain",
  },
  {
    key: "chien_de_l_ile_de_la_reunion",
    label: "Chien de l'Ile de la Reunion",
  },
  {
    key: "chien_de_la_serra_da_estrela",
    label: "Chien De La Serra Da Estrela",
  },
  {
    key: "chien_de_montagne_de_l_atlas",
    label: "Chien De Montagne De L'atlas",
  },
  {
    key: "chien_de_montagne_des_pyrenees",
    label: "Chien De Montagne Des Pyrenees",
  },
  {
    key: "chien_de_montagne_du_tibet",
    label: "Chien de montagne du Tibet",
  },
  {
    key: "chien_de_montagne_portugais",
    label: "Chien de montagne portugais",
  },
  {
    key: "chien_de_recherche_au_sang_de_hanovre",
    label: "Chien de recherche au sang de Hanovre",
  },
  {
    key: "chien_de_rhodesie_a_crete_dorsale",
    label: "Chien De Rhodesie A Crete Dorsale",
  },
  {
    key: "chien_de_rouge_de_baviere",
    label: "Chien De Rouge De Baviere",
  },
  {
    key: "chien_de_rouge_du_hanovre",
    label: "Chien De Rouge Du Hanovre",
  },
  {
    key: "chien_de_saint_hubert",
    label: "Chien De Saint Hubert",
  },
  {
    key: "chien_de_taiwan",
    label: "Chien De Taïwan",
  },
  {
    key: "chien_de_teneriffe",
    label: "Chien de Teneriffe",
  },
  {
    key: "chien_de_terre-neuve",
    label: "Chien de Terre-Neuve",
  },
  {
    key: "chien_des_canaries",
    label: "Chien des Canaries",
  },
  {
    key: "chien_des_goths",
    label: "Chien des goths",
  },
  {
    key: "chien_des_pharaons",
    label: "Chien des pharaons",
  },
  {
    key: "chien_du_groenland",
    label: "Chien Du Groenland",
  },
  {
    key: "chien_du_perou",
    label: "Chien du perou",
  },
  {
    key: "chien_du_pharaon",
    label: "Chien Du Pharaon",
  },
  {
    key: "chien_finnois_de_laponie",
    label: "Chien Finnois De Laponie",
  },
  {
    key: "chien_japonais_d_akita",
    label: "Chien Japonais d'Akita",
  },
  {
    key: "chien_leopard_catahoula",
    label: "Chien Léopard Catahoula",
  },
  {
    key: "chien_loup_de_saarloos",
    label: "Chien Loup De Saarloos",
  },
  {
    key: "chien_loup_de_saarlos",
    label: "Chien loup de saarlos",
  },
  {
    key: "chien_loup_tchecoslovaque",
    label: "Chien Loup Tchecoslovaque",
  },
  {
    key: "chien_n._et_f._pour_la_chasse_au_raton",
    label: "Chien n. et f. pour la chasse au raton",
  },
  {
    key: "chien_noir_et_feu_pour_la_chasse_au_raton_laveur",
    label: "Chien noir et feu pour la chasse au raton laveur",
  },
  {
    key: "chien_norvegien_de_macareux",
    label: "Chien Norvegien De Macareux",
  },
  {
    key: "chien_nu_argentin",
    label: "Chien nu argentin",
  },
  {
    key: "chien_nu_du_perou",
    label: "Chien Nu Du Perou",
  },
  {
    key: "chien_nu_mexicain",
    label: "Chien nu Mexicain",
  },
  {
    key: "chien_rouge_de_baviere",
    label: "Chien Rouge de Bavière",
  },
  {
    key: "chien_rouge_de_hanovre",
    label: "Chien rouge de Hanovre",
  },
  {
    key: "chien_suedois_de_laponie",
    label: "Chien suedois de laponie",
  },
  {
    key: "chien_thailandais_a_crete_dorsale",
    label: "Chien Thailandais A Crete Dorsale",
  },
  {
    key: "chihuahua",
    label: "Chihuahua",
  },
  {
    key: "chow_chow",
    label: "Chow Chow",
  },
  {
    key: "cimarron_uruguayen",
    label: "Cimarron Uruguayen",
  },
  {
    key: "cirneco_de_l_etna",
    label: "Cirneco De L Etna",
  },
  {
    key: "clumber-spaniel",
    label: "Clumber-Spaniel",
  },
  {
    key: "coban_kopegi",
    label: "Coban Kopegi",
  },
  {
    key: "cocker",
    label: "Cocker",
  },
  {
    key: "cocker_americain",
    label: "Cocker Américain",
  },
  {
    key: "cocker_anglais",
    label: "Cocker Anglais",
  },
  {
    key: "cocker_spaniel",
    label: "Cocker Spaniel",
  },
  {
    key: "cocker_spaniel_americain",
    label: "Cocker Spaniel Americain",
  },
  {
    key: "cocker_spaniel_anglais",
    label: "Cocker Spaniel Anglais",
  },
  {
    key: "colley",
    label: "Colley",
  },
  {
    key: "colley_a_poil_court",
    label: "Colley a poil court",
  },
  {
    key: "colley_a_poil_long",
    label: "Colley à poil long",
  },
  {
    key: "colley_barbu",
    label: "Colley barbu",
  },
  {
    key: "colley_nain",
    label: "Colley nain",
  },
  {
    key: "colley_shetland",
    label: "Colley Shetland",
  },
  {
    key: "collie_a_poil_court",
    label: "Collie A Poil Court",
  },
  {
    key: "collie_a_poil_long",
    label: "Collie A Poil Long",
  },
  {
    key: "continental_bulldog",
    label: "Continental Bulldog",
  },
  {
    key: "coonhound_noir_et_feu",
    label: "Coonhound noir et feu",
  },
  {
    key: "coton_de_tulear",
    label: "Coton De Tulear",
  },
  {
    key: "mixed_breed",
    label: "Croisé",
  },
  {
    key: "curly_coated_retriever",
    label: "Curly Coated Retriever",
  },
  {
    key: "cursinu",
    label: "Cursinu",
  },
  {
    key: "dalmatien",
    label: "Dalmatien",
  },
  {
    key: "dandie-dinmont-terrier",
    label: "Dandie-Dinmont-Terrier",
  },
  {
    key: "danish_swedish_farmdog",
    label: "Danish Swedish farmdog",
  },
  {
    key: "danois",
    label: "Danois",
  },
  {
    key: "deerhound",
    label: "Deerhound",
  },
  {
    key: "deutsch_langhaar",
    label: "Deutsch langhaar",
  },
  {
    key: "doberman",
    label: "Doberman",
  },
  {
    key: "dobermann",
    label: "Dobermann",
  },
  {
    key: "dogo",
    label: "Dogo",
  },
  {
    key: "dogo_canario",
    label: "Dogo Canario",
  },
  {
    key: "dogue_allemand",
    label: "Dogue Allemand",
  },
  {
    key: "dogue_anglais",
    label: "Dogue Anglais",
  },
  {
    key: "dogue_argentin",
    label: "Dogue Argentin",
  },
  {
    key: "dogue_d_aquitaine",
    label: "Dogue d'Aquitaine",
  },
  {
    key: "dogue_de_bordeaux",
    label: "Dogue De Bordeaux",
  },
  {
    key: "dogue_de_majorque",
    label: "Dogue De Majorque",
  },
  {
    key: "dogue_des_canaries",
    label: "Dogue Des Canaries",
  },
  {
    key: "dogue_du_tibet",
    label: "Dogue Du Tibet",
  },
  {
    key: "drahthaar",
    label: "Drahthaar",
  },
  {
    key: "drever",
    label: "Drever",
  },
  {
    key: "dunker",
    label: "Dunker",
  },
  {
    key: "english_bull_terrier",
    label: "English Bull Terrier",
  },
  {
    key: "english_foxhound",
    label: "English foxhound",
  },
  {
    key: "english_toy_spaniel",
    label: "English Toy Spaniel",
  },
  {
    key: "english-springer-spaniel",
    label: "English-Springer-Spaniel",
  },
  {
    key: "epagneul_a_perdrix_de_drente",
    label: "Épagneul à Perdrix De Drente",
  },
  {
    key: "epagneul_allemand",
    label: "Epagneul allemand",
  },
  {
    key: "epagneul_bleu_de_picardie",
    label: "Epagneul Bleu De Picardie",
  },
  {
    key: "epagneul_breton",
    label: "Epagneul Breton",
  },
  {
    key: "epagneul_d_eau_irlandais",
    label: "Epagneul D'eau Irlandais",
  },
  {
    key: "epagneul_de_munster",
    label: "Epagneul de Munster",
  },
  {
    key: "epagneul_de_pont-audemer",
    label: "Epagneul de Pont-Audemer",
  },
  {
    key: "epagneul_de_saint-usuge",
    label: "Epagneul de Saint-Usuge",
  },
  {
    key: "epagneul_français",
    label: "Epagneul Français",
  },
  {
    key: "epagneul_hollandais_de_la_drenthe",
    label: "Epagneul hollandais de la Drenthe",
  },
  {
    key: "epagneul_japonais",
    label: "Epagneul Japonais",
  },
  {
    key: "epagneul_king_charles",
    label: "Epagneul King Charles",
  },
  {
    key: "epagneul_nain_continental",
    label: "Epagneul Nain Continental",
  },
  {
    key: "epagneul_nain_phalene",
    label: "Epagneul nain Phalène",
  },
  {
    key: "epagneul_papillon",
    label: "Epagneul Papillon",
  },
  {
    key: "epagneul_pekinois",
    label: "Epagneul Pekinois",
  },
  {
    key: "epagneul_phalene",
    label: "Epagneul Phalène",
  },
  {
    key: "epagneul_picard",
    label: "Epagneul Picard",
  },
  {
    key: "epagneul_tibetain",
    label: "Epagneul Tibétain",
  },
  {
    key: "esquimau_du_canada",
    label: "Esquimau Du Canada",
  },
  {
    key: "esquimau_du_groenland",
    label: "Esquimau du Groenland",
  },
  {
    key: "eurasier",
    label: "Eurasier",
  },
  {
    key: "exotic_bull_dog",
    label: "Exotic Bull Dog",
  },
  {
    key: "fauve_de_bretagne",
    label: "Fauve de Bretagne",
  },
  {
    key: "field-spaniel",
    label: "Field-Spaniel",
  },
  {
    key: "fila_brasileiro",
    label: "Fila Brasileiro",
  },
  {
    key: "fila_de_sao_miguel",
    label: "Fila de Sao Miguel",
  },
  {
    key: "flat_coated_retriever",
    label: "Flat coated retriever",
  },
  {
    key: "fox-hound_americain",
    label: "Fox-Hound Américain",
  },
  {
    key: "fox-hound_anglais",
    label: "Fox-Hound Anglais",
  },
  {
    key: "fox-terrier",
    label: "Fox-Terrier",
  },
  {
    key: "fox-terrier_a_poil_dur",
    label: "Fox-Terrier à poil dur",
  },
  {
    key: "fox-terrier_a_poil_lisse",
    label: "Fox-Terrier à poil lisse",
  },
  {
    key: "francais_blanc_et_noir",
    label: "Francais Blanc Et Noir",
  },
  {
    key: "francais_blanc_et_orange",
    label: "Francais Blanc Et Orange",
  },
  {
    key: "francais_tricolore",
    label: "Francais Tricolore",
  },
  {
    key: "galgo",
    label: "Galgo",
  },
  {
    key: "gardiaan_boerboel",
    label: "Gardiaan Boerboel",
  },
  {
    key: "gascon-saintongeais",
    label: "Gascon-Saintongeais",
  },
  {
    key: "glen_of_imaal-terrier",
    label: "Glen of Imaal-Terrier",
  },
  {
    key: "golden-retriever",
    label: "Golden-Retriever",
  },
  {
    key: "gos_d_atura",
    label: "Gos d'Atura",
  },
  {
    key: "grand_anglo_francais_blanc_et_noir",
    label: "Grand Anglo Francais Blanc Et Noir",
  },
  {
    key: "grand_anglo_francais_tricolore",
    label: "Grand anglo-francais tricolore (Grand anglo francais)",
  },
  {
    key: "grand_anglo-francais_blanc_et_orange",
    label: "Grand Anglo-Francais Blanc Et Orange",
  },
  {
    key: "grand_basset_griffon_vendeen",
    label: "Grand Basset Griffon Vendeen",
  },
  {
    key: "grand_bleu_de_gascogne",
    label: "Grand Bleu De Gascogne",
  },
  {
    key: "grand_bouvier_suisse",
    label: "Grand Bouvier Suisse",
  },
  {
    key: "grand_chien_japonais",
    label: "Grand Chien Japonais",
  },
  {
    key: "grand_danois",
    label: "Grand Danois",
  },
  {
    key: "grand_epagneul_de_munster",
    label: "Grand Epagneul De Munster",
  },
  {
    key: "grand_griffon_vendeen",
    label: "Grand Griffon Vendeen",
  },
  {
    key: "grand_munsterlander",
    label: "Grand münsterländer",
  },
  {
    key: "greyhound",
    label: "Greyhound",
  },
  {
    key: "griffon",
    label: "Griffon",
  },
  {
    key: "griffon_a_poil_dur_korthal",
    label: "Griffon à poil dur korthal",
  },
  {
    key: "griffon_a_poil_laineux",
    label: "Griffon a poil laineux",
  },
  {
    key: "griffon_belge",
    label: "Griffon Belge",
  },
  {
    key: "griffon_bleu_de_gascogne",
    label: "Griffon Bleu De Gascogne",
  },
  {
    key: "griffon_bruxellois",
    label: "Griffon Bruxellois",
  },
  {
    key: "griffon_d_arret_slovaque_a_poil_dur",
    label: "Griffon d'arret slovaque a poil dur",
  },
  {
    key: "griffon_fauve_de_bretagne",
    label: "Griffon Fauve De Bretagne",
  },
  {
    key: "griffon_korthals",
    label: "Griffon Korthals",
  },
  {
    key: "griffon_nivernais",
    label: "Griffon Nivernais",
  },
  {
    key: "griffon_vendeen",
    label: "Griffon Vendéen",
  },
  {
    key: "groenendael",
    label: "Groenendael",
  },
  {
    key: "harrier",
    label: "Harrier",
  },
  {
    key: "hokkaido",
    label: "Hokkaido",
  },
  {
    key: "hokkaido_inu",
    label: "Hokkaido Inu",
  },
  {
    key: "hovawart",
    label: "Hovawart",
  },
  {
    key: "hug",
    label: "Hug",
  },
  {
    key: "husky_de_siberie",
    label: "Husky De Siberie",
  },
  {
    key: "husky_siberien",
    label: "Husky Sibérien",
  },
  {
    key: "inuit_canadien",
    label: "Inuit Canadien",
  },
  {
    key: "irish_water_spaniel",
    label: "Irish water spaniel",
  },
  {
    key: "irish_wolfhound",
    label: "Irish Wolfhound",
  },
  {
    key: "irish-terrier",
    label: "Irish-Terrier",
  },
  {
    key: "jack-russell-terrier",
    label: "Jack-Russell-Terrier",
  },
  {
    key: "jagdterrier",
    label: "Jagdterrier",
  },
  {
    key: "jindo_coreen",
    label: "Jindo Coreen",
  },
  {
    key: "kai",
    label: "Kai",
  },
  {
    key: "kangal",
    label: "Kangal",
  },
  {
    key: "kelpie",
    label: "Kelpie",
  },
  {
    key: "kerry-blue-terrier",
    label: "Kerry-Blue-Terrier",
  },
  {
    key: "king_charles",
    label: "King Charles",
  },
  {
    key: "king_charles_spaniel",
    label: "King Charles Spaniel",
  },
  {
    key: "king_shepherd",
    label: "King Shepherd",
  },
  {
    key: "kishu",
    label: "Kishu",
  },
  {
    key: "kokoni",
    label: "Kokoni",
  },
  {
    key: "komondor",
    label: "Komondor",
  },
  {
    key: "kooikerhondje",
    label: "Kooikerhondje",
  },
  {
    key: "korea_jindo_dog",
    label: "Korea jindo dog",
  },
  {
    key: "kromfohrlander",
    label: "Kromfohrlander",
  },
  {
    key: "kuvasz",
    label: "Kuvasz",
  },
  {
    key: "kuwatz",
    label: "Kuwatz",
  },
  {
    key: "kyi_leo",
    label: "Kyi leo",
  },
  {
    key: "labernois",
    label: "Labernois",
  },
  {
    key: "labradoodle",
    label: "Labradoodle",
  },
  {
    key: "labrador-retriever",
    label: "Labrador-Retriever",
  },
  {
    key: "labrit",
    label: "Labrit",
  },
  {
    key: "lagotto_romagnolo",
    label: "Lagotto Romagnolo",
  },
  {
    key: "laika_de_iakoutie",
    label: "Laïka de Iakoutie",
  },
  {
    key: "laika_de_siberie_occidentale",
    label: "Laika De Siberie Occidentale",
  },
  {
    key: "laika_de_siberie_orientale",
    label: "Laika De Siberie Orientale",
  },
  {
    key: "laika_russo_europeen",
    label: "Laika Russo Europeen",
  },
  {
    key: "lakeland-terrier",
    label: "Lakeland-Terrier",
  },
  {
    key: "lancashire_heeler",
    label: "Lancashire heeler",
  },
  {
    key: "landseer",
    label: "Landseer",
  },
  {
    key: "langhaar",
    label: "Langhaar",
  },
  {
    key: "lapphund_suedois",
    label: "Lapphund Suedois",
  },
  {
    key: "leonberg",
    label: "Leonberg",
  },
  {
    key: "leonberger",
    label: "Léonberger",
  },
  {
    key: "levrier_afghan",
    label: "Levrier Afghan",
  },
  {
    key: "levrier_anglais",
    label: "Lévrier anglais",
  },
  {
    key: "levrier_arabe",
    label: "Lévrier Arabe",
  },
  {
    key: "levrier_azawakh",
    label: "Lévrier Azawakh",
  },
  {
    key: "levrier_ecossais",
    label: "Levrier Ecossais",
  },
  {
    key: "levrier_espagnol",
    label: "Levrier Espagnol",
  },
  {
    key: "levrier_hongrois",
    label: "Levrier Hongrois",
  },
  {
    key: "levrier_irlandais",
    label: "Levrier Irlandais",
  },
  {
    key: "levrier_polonais",
    label: "Levrier Polonais",
  },
  {
    key: "levrier_russe",
    label: "Lévrier russe",
  },
  {
    key: "lhassa_apso",
    label: "Lhassa Apso",
  },
  {
    key: "lion_d_occitanie",
    label: "Lion d'occitanie",
  },
  {
    key: "loulou_de_pomeranie",
    label: "Loulou de Poméranie",
  },
  {
    key: "lundehund",
    label: "Lundehund",
  },
  {
    key: "magyar_agar",
    label: "Magyar Agar",
  },
  {
    key: "malamute_d_alaska",
    label: "Malamute d'Alaska",
  },
  {
    key: "malinois",
    label: "Malinois",
  },
  {
    key: "manchester_terrier",
    label: "Manchester Terrier",
  },
  {
    key: "mastiff",
    label: "Mastiff",
  },
  {
    key: "mastiff_danois",
    label: "Mastiff Danois",
  },
  {
    key: "mastiff_du_tibet",
    label: "Mastiff du Tibet",
  },
  {
    key: "matin_de_l_alentejo",
    label: "Matin De L'alentejo",
  },
  {
    key: "matin_de_naples",
    label: "Mâtin de Naples",
  },
  {
    key: "matin_des_pyrenees",
    label: "Matin Des Pyrenees",
  },
  {
    key: "matin_espagnol",
    label: "Matin Espagnol",
  },
  {
    key: "matin_napolitain",
    label: "Matin Napolitain",
  },
  {
    key: "mini_aussie_bulldog",
    label: "Mini aussie Bulldog",
  },
  {
    key: "mini_berger_australien",
    label: "mini berger australien",
  },
  {
    key: "montagne_des_pyrenees",
    label: "Montagne des Pyrénées",
  },
  {
    key: "morkie",
    label: "Morkie",
  },
  {
    key: "mudi",
    label: "Mudi",
  },
  {
    key: "nederlandse_kooikerhondje",
    label: "Nederlandse kooikerhondje",
  },
  {
    key: "nihon_suppitsu",
    label: "Nihon suppitsu",
  },
  {
    key: "nihon_teria",
    label: "Nihon Teria",
  },
  {
    key: "nizinny",
    label: "Nizinny",
  },
  {
    key: "nordic_timber",
    label: "Nordic Timber",
  },
  {
    key: "norfolk-terrier",
    label: "Norfolk-Terrier",
  },
  {
    key: "norwich-terrier",
    label: "Norwich-Terrier",
  },
  {
    key: "old_english_bouledogue",
    label: "Old English Bouledogue",
  },
  {
    key: "old_inuit_dog",
    label: "Old Inuit Dog",
  },
  {
    key: "otterhound",
    label: "Otterhound",
  },
  {
    key: "owczarek_nizinny",
    label: "Owczarek Nizinny",
  },
  {
    key: "papillon",
    label: "Papillon",
  },
  {
    key: "parson_jack_russell",
    label: "Parson Jack Russell",
  },
  {
    key: "patou",
    label: "Patou",
  },
  {
    key: "patterdale-terrier",
    label: "Patterdale-Terrier",
  },
  {
    key: "pekinois",
    label: "Pékinois",
  },
  {
    key: "perdiguero_de_burgos",
    label: "Perdiguero De Burgos",
  },
  {
    key: "petit_basset_griffon_vendeen",
    label: "Petit Basset Griffon Vendeen",
  },
  {
    key: "petit_bleu_de_gascogne",
    label: "Petit Bleu De Gascogne",
  },
  {
    key: "petit_brabancon",
    label: "Petit Brabancon",
  },
  {
    key: "petit_chien_courant_bernois",
    label: "Petit chien courant Bernois",
  },
  {
    key: "petit_chien_courant_de_schwyz",
    label: "Petit chien courant de Schwyz",
  },
  {
    key: "petit_chien_courant_du_jura",
    label: "Petit chien courant du Jura",
  },
  {
    key: "petit_chien_courant_suisse",
    label: "Petit Chien Courant Suisse",
  },
  {
    key: "petit_chien_domestique_grecque",
    label: "Petit Chien domestique Grecque",
  },
  {
    key: "petit_chien_hollandais_de_chasse_a_gibier_d_eau",
    label: "Petit chien Hollandais de chasse à gibier d'eau",
  },
  {
    key: "petit_chien_lion",
    label: "Petit Chien Lion",
  },
  {
    key: "petit_chien_russe",
    label: "Petit Chien Russe",
  },
  {
    key: "petit_epagneul_de_munster",
    label: "Petit Epagneul De Munster",
  },
  {
    key: "petit_levrier_italien",
    label: "Petit Levrier Italien",
  },
  {
    key: "petit_spitz",
    label: "Petit Spitz",
  },
  {
    key: "pila",
    label: "Pila",
  },
  {
    key: "pinscher",
    label: "Pinscher",
  },
  {
    key: "pinscher_allemand",
    label: "Pinscher Allemand",
  },
  {
    key: "pinscher_australien",
    label: "Pinscher australien",
  },
  {
    key: "pinscher_autrichien",
    label: "Pinscher Autrichien",
  },
  {
    key: "pinscher_moyen",
    label: "Pinscher moyen",
  },
  {
    key: "pinscher_nain",
    label: "Pinscher Nain",
  },
  {
    key: "pit_bull_terrier",
    label: "Pit Bull Terrier",
  },
  {
    key: "pitbull",
    label: "Pitbull",
  },
  {
    key: "plott_hound",
    label: "Plott hound",
  },
  {
    key: "podenco",
    label: "Podenco",
  },
  {
    key: "podenco_canario",
    label: "Podenco canario",
  },
  {
    key: "podenco_d_ibiza",
    label: "Podenco D'ibiza",
  },
  {
    key: "podengo_portugais",
    label: "Podengo Portugais",
  },
  {
    key: "pointer",
    label: "Pointer",
  },
  {
    key: "pointer_anglais",
    label: "Pointer Anglais",
  },
  {
    key: "poitevin",
    label: "Poitevin",
  },
  {
    key: "pomsky",
    label: "Pomsky",
  },
  {
    key: "porcelaine",
    label: "Porcelaine",
  },
  {
    key: "pudelpointer",
    label: "Pudelpointer",
  },
  {
    key: "pug",
    label: "Pug",
  },
  {
    key: "puli",
    label: "Puli",
  },
  {
    key: "pumi",
    label: "Pumi",
  },
  {
    key: "qimmiq",
    label: "Qimmiq",
  },
  {
    key: "rafeiro_de_l_alentejo",
    label: "Rafeiro de l'Alentejo",
  },
  {
    key: "ratier_de_prague",
    label: "Ratier De Prague",
  },
  {
    key: "ratier_pragois",
    label: "Ratier pragois",
  },
  {
    key: "redbone_coonhound",
    label: "Redbone coonhound",
  },
  {
    key: "retriever_a_poil_boucles",
    label: "Retriever à poil bouclés",
  },
  {
    key: "retriever_a_poil_plat",
    label: "Retriever A Poil Plat",
  },
  {
    key: "retriever_de_la_baie_de_cheasapeake",
    label: "Retriever de la baie de cheasapeake",
  },
  {
    key: "retriever_de_nouvelle_ecosse",
    label: "Retriever de nouvelle écosse",
  },
  {
    key: "retriever_flat_coated",
    label: "Retriever Flat Coated",
  },
  {
    key: "rhodesian-ridgeback",
    label: "Rhodesian-Ridgeback",
  },
  {
    key: "rottweiler",
    label: "Rottweiler",
  },
  {
    key: "rottweiler_miniature",
    label: "Rottweiler miniature",
  },
  {
    key: "rouge_de_baviere",
    label: "Rouge de Baviere",
  },
  {
    key: "royal_bourbon",
    label: "Royal Bourbon",
  },
  {
    key: "russkiy_toy",
    label: "Russkiy Toy",
  },
  {
    key: "saint-bernard",
    label: "Saint-Bernard",
  },
  {
    key: "saint-pierre",
    label: "Saint-Pierre",
  },
  {
    key: "saluki",
    label: "Saluki",
  },
  {
    key: "samoyede",
    label: "Samoyede",
  },
  {
    key: "sarplaninac",
    label: "Sarplaninac",
  },
  {
    key: "schapendoes",
    label: "Schapendoes",
  },
  {
    key: "schapendoes_neerlandais",
    label: "Schapendoes Neerlandais",
  },
  {
    key: "schipperke",
    label: "Schipperke",
  },
  {
    key: "schnauzer_geant",
    label: "Schnauzer Geant",
  },
  {
    key: "schnauzer_moyen",
    label: "Schnauzer Moyen",
  },
  {
    key: "schnauzer_nain",
    label: "Schnauzer Nain",
  },
  {
    key: "scottish-terrier",
    label: "Scottish-Terrier",
  },
  {
    key: "sealyham-terrier",
    label: "Sealyham-Terrier",
  },
  {
    key: "setter_anglais",
    label: "Setter Anglais",
  },
  {
    key: "setter_gordon",
    label: "Setter Gordon",
  },
  {
    key: "setter_irlandais",
    label: "Setter Irlandais",
  },
  {
    key: "setter_irlandais_rouge",
    label: "Setter Irlandais Rouge",
  },
  {
    key: "setter_irlandais_rouge_et_blanc",
    label: "Setter Irlandais Rouge Et Blanc",
  },
  {
    key: "shar-pei",
    label: "Shar-Pei",
  },
  {
    key: "shiba-inu",
    label: "Shiba-Inu",
  },
  {
    key: "shikoku",
    label: "Shikoku",
  },
  {
    key: "shitzu",
    label: "Shitzu",
  },
  {
    key: "silky_terrier",
    label: "Silky Terrier",
  },
  {
    key: "skye",
    label: "Skye",
  },
  {
    key: "skye_terrier",
    label: "Skye Terrier",
  },
  {
    key: "sloughi",
    label: "Sloughi",
  },
  {
    key: "smous_des_pays_bas",
    label: "Smous Des Pays Bas",
  },
  {
    key: "spinone",
    label: "Spinone",
  },
  {
    key: "spitz",
    label: "Spitz",
  },
  {
    key: "spitz_allemand",
    label: "Spitz Allemand",
  },
  {
    key: "spitz_allemand_nain",
    label: "Spitz Allemand nain",
  },
  {
    key: "spitz_de_norrbotten",
    label: "Spitz De Norrbotten",
  },
  {
    key: "spitz_de_pomeranie",
    label: "Spitz de poméranie",
  },
  {
    key: "spitz_des_visigoths",
    label: "Spitz Des Visigoths",
  },
  {
    key: "spitz_finlandais",
    label: "Spitz Finlandais",
  },
  {
    key: "spitz_finnois_de_laponie",
    label: "Spitz finnois de Laponie",
  },
  {
    key: "spitz_français",
    label: "Spitz Français",
  },
  {
    key: "spitz_japonais",
    label: "Spitz Japonais",
  },
  {
    key: "spitz_loup",
    label: "Spitz loup",
  },
  {
    key: "spitz_moyen",
    label: "Spitz Moyen",
  },
  {
    key: "spitz_nain",
    label: "Spitz nain",
  },
  {
    key: "springer_anglais",
    label: "Springer Anglais",
  },
  {
    key: "springer_spaniel",
    label: "Springer Spaniel",
  },
  {
    key: "stabyhoun",
    label: "Stabyhoun",
  },
  {
    key: "staffordshire_bull_terrier",
    label: "Staffordshire Bull Terrier",
  },
  {
    key: "staffordshire_terrier_americain",
    label: "Staffordshire Terrier Americain",
  },
  {
    key: "sussex-spaniel",
    label: "Sussex-Spaniel",
  },
  {
    key: "syndney_terrier",
    label: "Syndney Terrier",
  },
  {
    key: "tchiorny_terrier",
    label: "Tchiorny Terrier",
  },
  {
    key: "tchouvatch_slovaque",
    label: "Tchouvatch Slovaque",
  },
  {
    key: "teckel",
    label: "Teckel",
  },
  {
    key: "teckel_a_poil_dur",
    label: "Teckel à poil dur",
  },
  {
    key: "teckel_a_poil_long",
    label: "Teckel à poil long",
  },
  {
    key: "teckel_a_poil_ras",
    label: "Teckel à poil ras",
  },
  {
    key: "teckel_kaninchen",
    label: "Teckel Kaninchen",
  },
  {
    key: "teckel_nain_a_poil_dur",
    label: "Teckel Nain à poil dur",
  },
  {
    key: "teckel_nain_a_poil_long",
    label: "Teckel Nain à poil long",
  },
  {
    key: "teckel_nain_a_poil_ras",
    label: "Teckel Nain à poil ras",
  },
  {
    key: "telomian",
    label: "Telomian",
  },
  {
    key: "terre_neuve",
    label: "Terre Neuve",
  },
  {
    key: "terrier_anglais_d_agrement_(noir_et_feu)",
    label: "Terrier anglais d'agrément (noir et feu)",
  },
  {
    key: "terrier_australien",
    label: "Terrier Australien",
  },
  {
    key: "terrier_australien_a_poil_soyeux",
    label: "Terrier Australien A Poil Soyeux",
  },
  {
    key: "terrier_bresilien",
    label: "Terrier Bresilien",
  },
  {
    key: "terrier_d_agrement_anglais_noir_et_feu",
    label: "Terrier D'agrement Anglais Noir Et Feu",
  },
  {
    key: "terrier_de_boston",
    label: "Terrier De Boston",
  },
  {
    key: "terrier_de_chasse_allemand",
    label: "Terrier De Chasse Allemand",
  },
  {
    key: "terrier_de_manchester",
    label: "Terrier de Manchester",
  },
  {
    key: "terrier_du_congo",
    label: "Terrier Du Congo",
  },
  {
    key: "terrier_du_reverend_russell",
    label: "Terrier Du Reverend Russell",
  },
  {
    key: "terrier_du_tibet",
    label: "Terrier du Tibet",
  },
  {
    key: "terrier_ecossais",
    label: "Terrier Ecossais",
  },
  {
    key: "terrier_gallois",
    label: "Terrier Gallois",
  },
  {
    key: "terrier_irlandais",
    label: "Terrier Irlandais",
  },
  {
    key: "terrier_irlandais_-_glen_of_imaal-terrier",
    label: "Terrier irlandais - Glen of Imaal-Terrier",
  },
  {
    key: "terrier_irlandais_a_poil_doux",
    label: "Terrier Irlandais A Poil Doux",
  },
  {
    key: "terrier_jack_russell",
    label: "Terrier Jack Russell",
  },
  {
    key: "terrier_japonais",
    label: "Terrier Japonais",
  },
  {
    key: "terrier_kerry_blue",
    label: "Terrier Kerry Blue",
  },
  {
    key: "terrier_noir_russe",
    label: "Terrier Noir Russe",
  },
  {
    key: "terrier_tcheque",
    label: "Terrier Tcheque",
  },
  {
    key: "terrier_tibetain",
    label: "Terrier Tibetain",
  },
  {
    key: "tervueren",
    label: "Tervueren",
  },
  {
    key: "timber_shepherd",
    label: "Timber Shepherd",
  },
  {
    key: "tornjak",
    label: "Tornjak",
  },
  {
    key: "tosa",
    label: "Tosa",
  },
  {
    key: "toy_terrier",
    label: "Toy terrier",
  },
  {
    key: "toy_terrier_noir_et_feu",
    label: "Toy terrier noir et feu",
  },
  {
    key: "u_cursinu",
    label: "U Cursinu",
  },
  {
    key: "vallhund_suedois_/_spitz_des_visigoths",
    label: "Vallhund Suédois / Spitz des Visigoths",
  },
  {
    key: "vastgotaspets",
    label: "Vastgotaspets",
  },
  {
    key: "vizla",
    label: "Vizla",
  },
  {
    key: "volpino_italien",
    label: "Volpino Italien",
  },
  {
    key: "welsh_corgi",
    label: "Welsh Corgi",
  },
  {
    key: "welsh_corgi_cardigan",
    label: "Welsh Corgi Cardigan",
  },
  {
    key: "welsh_corgi_pembroke",
    label: "Welsh Corgi Pembroke",
  },
  {
    key: "welsh_springer_spaniel",
    label: "Welsh Springer Spaniel",
  },
  {
    key: "welsh-terrier",
    label: "Welsh-Terrier",
  },
  {
    key: "west_highland_white_terrier",
    label: "West Highland White Terrier",
  },
  {
    key: "westhy",
    label: "Westhy",
  },
  {
    key: "westie",
    label: "Westie",
  },
  {
    key: "wheaten_terrier",
    label: "Wheaten Terrier",
  },
  {
    key: "whippet",
    label: "Whippet",
  },
  {
    key: "xoloitzcuintle",
    label: "Xoloitzcuintle",
  },
  {
    key: "yorkipoo",
    label: "Yorkipoo",
  },
  {
    key: "yorkshire_terrier",
    label: "Yorkshire Terrier",
  },
  {
    key: "ysterberg_mastiff",
    label: "Ysterberg Mastiff",
  },
  {
    key: "airedale_terrier",
    label: "Airedale Terrier",
  },
  {
    key: "akita",
    label: "Akita",
  },
  {
    key: "anglo_francais_petite_venerie",
    label: "Anglo francais de petite venerie",
  },
  {
    key: "bangkaew_thailande",
    label: "Bangkaew de thailande (Thai bangkaew dog)",
  },
  {
    key: "barbado_terceira",
    label: "Barbado de terceira (Bouvier portugais)",
  },
  {
    key: "basenji",
    label: "Basenji",
  },
  {
    key: "basset_artesien_normand",
    label: "Basset Artesien Normand",
  },
  {
    key: "basset_bleu_gascogne",
    label: "Basset bleu de gascogne",
  },
  {
    key: "basset_westphalie",
    label: "Basset de westphalie (Westfälische dachsbracke)",
  },
  {
    key: "basset_alpes",
    label: "Basset des alpes (Alpenländische dachsbracke)",
  },
  {
    key: "basset_fauve_bretagne",
    label: "Basset fauve de bretagne",
  },
  {
    key: "beagle_harrier",
    label: "Beagle Harrier",
  },
  {
    key: "bedlington_terrier",
    label: "Bedlington Terrier",
  },
  {
    key: "berger_asie_centrale",
    label: "Berger d'asie centrale (Sredneasiatskaïa ovtcharka)",
  },
  {
    key: "berger_beauce",
    label: "Berger de beauce (Beauceron",
  },
  {
    key: "berger_bosnie_herzegovine_croatie",
    label: "Berger de bosnie-herzegovine et de croatie (Tornjak)",
  },
  {
    key: "berger_brie",
    label: "Berger de brie (Briard)",
  },
  {
    key: "berger_europe_sud_est",
    label: "Berger de l'europe du sud-est (Ciobanesc romanesc de bucovina)",
  },
  {
    key: "berger_maremme_abruzzes",
    label:
      "Berger de la maremme et des abruzzes (Berger des abruzzes et de maremme / Cane da pastore maremmano-abruzzese)",
  },
  {
    key: "berger_serra_aires",
    label: "Berger de la serra de aires (Berger portugais / Cão da serra de aires)",
  },
  {
    key: "berger_picardie",
    label: "Berger de picardie (Berger picard)",
  },
  {
    key: "berger_russie_meridionale",
    label: "Berger de russie meridionale (Ioujnorousskaïa ovtcharka)",
  },
  {
    key: "berger_pyrenees_face_rase",
    label: "Berger des pyrenees a face rase (Labrit)",
  },
  {
    key: "berger_pyrenees_poil_long",
    label: "Berger des pyrenees a poil long (Labrit)",
  },
  {
    key: "berger_caucase",
    label: "Berger du caucase (Kavkazskaïa ovtcharka)",
  },
  {
    key: "berger_karst",
    label: "Berger du karst (Berger du bassin de kras / Kraszki ovcar)",
  },
  {
    key: "berger_finnois_laponie",
    label: "Berger finnois de laponie (Lapinporokoira)",
  },
  {
    key: "berger_polonais_plaine",
    label: "Berger polonais de plaine (Nizinny / Polski owczarek nizinny)",
  },
  {
    key: "bichon_poil_frise",
    label: "Bichon a poil frise (Bichon frise)",
  },
  {
    key: "bichon_bolonais",
    label: "Bichon Bolonais",
  },
  {
    key: "border_terrier",
    label: "Border Terrier",
  },
  {
    key: "bouledogue_francais",
    label: "Bouledogue Francais",
  },
  {
    key: "bouvier_appenzell",
    label: "Bouvier de l'appenzell (Appenzeller sennenhund)",
  },
  {
    key: "bouvier_entlebuch",
    label: "Bouvier de l'entlebuch (Entlebucher sennenhund)",
  },
  {
    key: "bouvier_ardennes",
    label: "Bouvier des ardennes",
  },
  {
    key: "bouvier_flandres",
    label: "Bouvier des flandres (Vlamse koehond)",
  },
  {
    key: "brachet_styrie_poil_dur",
    label:
      "Brachet de styrie a poil dur (Peintinger brachet de styrie a poil dur / Steirische rauhhaarbracke)",
  },
  {
    key: "brachet_noir_feu",
    label: "Brachet noir et feu (Brachet tirolien / Brandlbracke)",
  },
  {
    key: "braque_allemand_poil_court",
    label: "Braque allemand a poil court (Braque allemand / Deutsch kurzhaar)",
  },
  {
    key: "braque_auvergne",
    label: "Braque d'auvergne (Bleu d'auvergne / Braque d'auvergne)",
  },
  {
    key: "braque_burgos",
    label: "Braque de burgos (Perdiguero de burgos)",
  },
  {
    key: "braque_ariege",
    label: "Braque de l'ariege (Braque de l'ariege)",
  },
  {
    key: "braque_weimar",
    label: "Braque de weimar (Weimaraner)",
  },
  {
    key: "braque_bourbonnais",
    label: "Braque du bourbonnais",
  },
  {
    key: "braque_hongrois_poil_court",
    label: "Braque hongrois a poil court (Vizsla a poil court / Rövidszöru magyar vizsla)",
  },
  {
    key: "braque_hongrois_poil_dur",
    label: "Braque hongrois a poil dur (Vizsla / Drötzörü magyar vizsla)",
  },
  {
    key: "braque_saint_germain",
    label: "Braque Saint Germain",
  },
  {
    key: "braque_slovaque_poil_dur",
    label: "Braque slovaque a poil dur (Slovenski hrubosrsty stavac (ohar))",
  },
  {
    key: "briquet_provence",
    label: "Briquet de provence",
  },
  {
    key: "bruno_saint_hubert_francais",
    label: "Bruno saint-hubert francais (Bruno du jura type saint hubert / Jura laufhund",
  },
  {
    key: "bulldog",
    label: "Bulldog",
  },
  {
    key: "bulldog_continental",
    label: "Bulldog continental (Conti)",
  },
  {
    key: "cao_gado_transmontano",
    label: "Cao de gado transmontano",
  },
  {
    key: "chien_loutre",
    label: "Chien a loutre (Chien de loutre / Otterhound)",
  },
  {
    key: "chien_chinois_crete",
    label: "Chien chinois a crete (Chinese crested dog)",
  },
  {
    key: "chien_courant_istrie_poil_dur",
    label: "Chien courant d'istrie a poil dur (Istarski ostrodlaki gonic)",
  },
  {
    key: "chien_courant_istrie_poil_ras",
    label: "Chien courant d'istrie a poil ras (Istarski kratkodlaki gonic)",
  },
  {
    key: "chien_courant_bosnie_poil_dur",
    label: "Chien courant de bosnie a poil dur (Bosanski ostrodlaki gonic",
  },
  {
    key: "chien_courant_halden",
    label: "Chien courant de halden (Haldenstover)",
  },
  {
    key: "chien_courant_hamilton",
    label: "Chien courant de hamilton (Hamiltonstovare)",
  },
  {
    key: "chien_courant_hygen",
    label: "Chien courant de hygen (Hygenhund)",
  },
  {
    key: "chien_courant_valle_save",
    label: "Chien courant de la valle de la save (Chien courant de posavatz / Posavski gonic)",
  },
  {
    key: "chien_courant_montagne_montenegro",
    label:
      "Chien courant de montagne du montenegro (Chien courant yougoslave / Crnogorski planinski gonic)",
  },
  {
    key: "chien_courant_schiller",
    label: "Chien courant de schiller (Schillerstövare)",
  },
  {
    key: "chien_courant_transylvanie",
    label: "Chien courant de transylvanie (Erdelyi kopo)",
  },
  {
    key: "chien_courant_smaland",
    label: "Chien courant du smaland (Smalandsstovare)",
  },
  {
    key: "chien_courant_italien_poil_dur",
    label: "Chien courant italien a poil dur (Segugio a poil dur / Segugio italiano a pelo forte)",
  },
  {
    key: "chien_courant_italien_poil_ras",
    label: "Chien courant italien a poil ras (Segugio a poil ras / Segugio italiano a pelo razo)",
  },
  {
    key: "chien_arret_allemand_poil_dur",
    label: "Chien d'arret allemand a poil dur (Drahthaar / Deutsch drahthaar)",
  },
  {
    key: "chien_arret_allemand_poil_long",
    label: "Chien d'arret allemand a poil long (Langhaar / deutsch langhaar)",
  },
  {
    key: "chien_arret_allemand_poil_raide",
    label: "Chien d'arret allemand a poil raide (Stichelhaar / Deutsch stichelhaar)",
  },
  {
    key: "chien_arret_danois_ancestral",
    label: "Chien d'arret danois ancestral (Ancien chien d'arret danois / Gammel dansk honshund)",
  },
  {
    key: "chien_arret_frison",
    label: "Chien d'arret frison (Stabyhoun)",
  },
  {
    key: "chien_arret_portugais",
    label: "Chien d'arret portugais (Braque portugais / Perdigueiro portugues)",
  },
  {
    key: "chien_artois",
    label: "Chien d'artois",
  },
  {
    key: "chien_eau_americain",
    label: "Chien d'eau americain (American water spaniel)",
  },
  {
    key: "chien_eau_espagnol",
    label: "Chien d'eau espagnol (Perro de agua español)",
  },
  {
    key: "chien_eau_frison",
    label: "Chien d'eau frison (Wetterhoun)",
  },
  {
    key: "chien_eau_portugais",
    label: "Chien d'eau portugais (Cao de agua / Cão de agua português)",
  },
  {
    key: "chien_eau_romagnol",
    label: "Chien d'eau romagnol (Lagotto romagnolo)",
  },
  {
    key: "chien_elan_norvegien_gris",
    label: "Chien d'elan norvegien gris (Elkhound gris / Norsk elkhund gra)",
  },
  {
    key: "chien_elan_norvegien_noir",
    label: "Chien d'elan norvegien noir (Elkhound noir / Norsk elkhund sort)",
  },
  {
    key: "chien_elan_suedois",
    label: "Chien d'elan suedois / jämthund (Jamthund / Jämthund)",
  },
  {
    key: "chien_ours_carelie",
    label: "Chien d'ours de carelie (Karjalankarhukoira)",
  },
  {
    key: "chien_oysel_allemand",
    label: "Chien d'oysel allemand (Deutscher wachtelhund)",
  },
  {
    key: "chien_berger_anglais_ancestral",
    label: "Chien de berger anglais ancestral (Bobtail / Old english sheepdog)",
  },
  {
    key: "chien_berger_belge",
    label: "Chien de berger belge (Berger belge / Belgische herdershond)",
  },
  {
    key: "chien_berger_catalan",
    label: "Chien de berger catalan (Gos d'atura / Perro de pastor catalan",
  },
  {
    key: "chien_berger_croate",
    label: "Chien de berger croate (Berger de croatie / Hrvatski ovcar)",
  },
  {
    key: "chien_berger_anatolie",
    label: "Chien de berger d'anatolie (Berger d'anatolie / Coban kopegi)",
  },
  {
    key: "chien_berger_majorque",
    label: "Chien de berger de majorque (Ca de bestiar / Perro de pastor mallorquin",
  },
  {
    key: "chien_berger_shetland",
    label: "Chien de berger des shetland (Shetland",
  },
  {
    key: "chien_berger_tatras",
    label: "Chien de berger des tatras (Berger de podhale / Polski owczarek podhalanski)",
  },
  {
    key: "chien_berger_islandais",
    label: "Chien de berger islandais (Iceland sheepdog / Islenskur fjarhundur)",
  },
  {
    key: "chien_berger_roumain_mioritza",
    label: "Chien de berger roumain de mioritza (Ciobanesc românesc mioritic)",
  },
  {
    key: "chien_berger_roumain_carpathes",
    label: "Chien de berger roumain des carpathes (Carpatin / Ciobanesc românesc carpatin)",
  },
  {
    key: "chien_berger_yougoslave_charplanina",
    label: "Chien de berger yougoslave de charplanina (Sarplaninac / Jugoslovenski ovcarski pas)",
  },
  {
    key: "chien_canaan",
    label: "Chien de canaan (Canaan dog)",
  },
  {
    key: "chien_castro_laboreiro",
    label: "Chien de castro laboreiro (Cao de castro laboreiro)",
  },
  {
    key: "chien_cour_italien",
    label: "Chien de cour italien (Cane corso)",
  },
  {
    key: "chien_ferme_dano_suedois",
    label: "Chien de ferme dano-suedois (Dansk-svensk gardshund)",
  },
  {
    key: "chien_garenne_canaries",
    label: "Chien de garenne des canaries (Podenco canario)",
  },
  {
    key: "chien_garenne_portugais",
    label: "Chien de garenne portugais (Podengo portuguès)",
  },
  {
    key: "chien_serra_da_estrela",
    label: "Chien de la serra da estrela (Estrela / Cao da serra da estrela)",
  },
  {
    key: "chien_leonberg",
    label: "Chien de leonberg (Leonberg / Leonberger)",
  },
  {
    key: "chien_montagne_atlas",
    label: "Chien de montagne de l'atlas (Aïdi)",
  },
  {
    key: "chien_montagne_pyrenees",
    label: "Chien de montagne des pyrenees (Patou)",
  },
  {
    key: "chien_perdrix_drente",
    label: "Chien de perdrix de drente (Epagneul de drente / Drentsche patrijshond)",
  },
  {
    key: "chien_rhodesie_crete_dorsale",
    label: "Chien de rhodesie a crete dorsale (Rhodesian ridgeback)",
  },
  {
    key: "chien_rouge_baviere",
    label: "Chien de rouge de baviere (Rouge de baviere / Bayrischer gebirgsschweisshund)",
  },
  {
    key: "chien_rouge_hanovre",
    label: "Chien de rouge du hanovre (Rouge du hanovre / Hannoverscher schweisshund)",
  },
  {
    key: "chien_saint_hubert",
    label: "Chien de saint hubert (Saint hubert)",
  },
  {
    key: "chien_taiwan",
    label: "Chien de taïwan (Taïwan dog)",
  },
  {
    key: "chien_groenland",
    label: "Chien du groenland (Esquimau du groenland / Gronlandshund)",
  },
  {
    key: "chien_pharaon",
    label: "Chien du pharaon (Pharaon / Pharaoh hound)",
  },
  {
    key: "chien_finnois_laponie",
    label: "Chien finnois de laponie (Lapinkoira / Suomenlapinkoira)",
  },
  {
    key: "chien_fonnese",
    label: "Chien fonnese (Cane fonnese)",
  },
  {
    key: "chien_loup_saarloos",
    label: "Chien loup de saarloos (Saarloos / Saarloos wolfhond)",
  },
  {
    key: "chien_n_f_chasse_raton",
    label:
      "Chien n. et f. pour la chasse au raton (Coonhound noir et feu / Black and tan coonhound)",
  },
  {
    key: "chien_norvegien_macareux",
    label: "Chien norvegien de macareux (Norsk lundehund)",
  },
  {
    key: "chien_nu_mexique",
    label: "Chien nu du mexique (Xolo / Xoloitzcuintle)",
  },
  {
    key: "chien_nu_perou",
    label: "Chien nu du perou (Perro sin pelo del peru)",
  },
  {
    key: "chien_particolore_poil_frise",
    label: "Chien particolore a poil frise (Particolore a poil frise)",
  },
  {
    key: "chien_thailandais_crete_dorsale",
    label: "Chien thailandais a crete dorsale (Thai ridgeback / Thai ridgeback dog)",
  },
  {
    key: "cirneco_etna",
    label: "Cirneco de l'etna (Cirneco de l'etna / Cirneco dell'etna)",
  },
  {
    key: "clumber_spaniel",
    label: "Clumber Spaniel",
  },
  {
    key: "collie_poil_court",
    label: "Collie a poil court (Colley a poil court / Smooth colley)",
  },
  {
    key: "collie_poil_long",
    label: "Collie a poil long (Colley / Rough colley)",
  },
  {
    key: "coton_tulear",
    label: "Coton de tulear",
  },
  {
    key: "dandie_dinmont_terrier",
    label: "Dandie Dinmont Terrier",
  },
  {
    key: "dogue_bordeaux",
    label: "Dogue de bordeaux",
  },
  {
    key: "dogue_majorque",
    label: "Dogue de majorque (Ca de bou / Perro dogo mallorquin)",
  },
  {
    key: "dogue_canaries",
    label: "Dogue des canaries (Presa canario / Dogo canario)",
  },
  {
    key: "dogue_tibet",
    label: "Dogue du tibet (Do-khyi)",
  },
  {
    key: "english_springer_spaniel",
    label: "English Springer Spaniel",
  },
  {
    key: "epagneul_bleu_picardie",
    label: "Epagneul bleu de picardie (Bleu de picardie)",
  },
  {
    key: "epagneul_eau_irlandais",
    label: "Epagneul d'eau irlandais (Chien d'eau irlandais / Irish water spaniel)",
  },
  {
    key: "epagneul_pont_audemer",
    label: "Epagneul de pont audemer (Pont audemer)",
  },
  {
    key: "epagneul_saint_usuge",
    label: "Epagneul de saint usuge (Epagneul saint usuge)",
  },
  {
    key: "epagneul_francais",
    label: "Epagneul Francais",
  },
  {
    key: "field_spaniel",
    label: "Field Spaniel",
  },
  {
    key: "fila_saint_miguel",
    label: "Fila de saint miguel (Fila de sao miguel / Cao de fila de sao miguel)",
  },
  {
    key: "fox_hound_anglais",
    label: "Fox Hound Anglais",
  },
  {
    key: "fox_terrier_poil_dur",
    label: "Fox Terrier Poil Dur",
  },
  {
    key: "fox_terrier_poil_lisse",
    label: "Fox Terrier Poil Lisse",
  },
  {
    key: "foxhound_americain",
    label: "Foxhound Americain",
  },
  {
    key: "francais_blanc_noir",
    label: "Francais blanc et noir",
  },
  {
    key: "francais_blanc_orange",
    label: "Francais blanc et orange",
  },
  {
    key: "gascon_saintongeois",
    label: "Gascon Saintongeois",
  },
  {
    key: "golden_retriever",
    label: "Golden Retriever",
  },
  {
    key: "grand_anglo_francais_blanc_noir",
    label: "Grand anglo francais blanc et noir (Grand anglo francais)",
  },
  {
    key: "grand_anglo_francais_blanc_orange",
    label: "Grand anglo-francais blanc et orange (Grand anglo-francais)",
  },
  {
    key: "grand_bleu_gascogne",
    label: "Grand bleu de gascogne",
  },
  {
    key: "grand_epagneul_munster",
    label: "Grand epagneul de munster (Grand munsterlander / Grosser münsterländer vorstehhund)",
  },
  {
    key: "griffon_poil_dur_korthals",
    label: "Griffon a poil dur korthals (Korthals)",
  },
  {
    key: "griffon_bleu_gascogne",
    label: "Griffon bleu de gascogne",
  },
  {
    key: "griffon_fauve_bretagne",
    label: "Griffon fauve de bretagne",
  },
  {
    key: "husky_siberie",
    label: "Husky de siberie (Husky siberien / Siberian husky)",
  },
  {
    key: "kelpie_australien",
    label: "Kelpie Australien",
  },
  {
    key: "laika_siberie_occidentale",
    label: "Laika de siberie occidentale (Zapadno sibirskaïa laïka)",
  },
  {
    key: "laika_siberie_orientale",
    label: "Laika de siberie orientale (Vostotchno sibirskaïa laïka)",
  },
  {
    key: "lakeland_terrier",
    label: "Lakeland Terrier",
  },
  {
    key: "malamute_alaska",
    label: "Malamute de l'alaska (Alaskan malamute)",
  },
  {
    key: "matin_alentejo",
    label: "Matin de l'alentejo (Rafeiro do alentejo)",
  },
  {
    key: "matin_pyrenees",
    label: "Matin des pyrenees (Mastin del pirineo)",
  },
  {
    key: "norfolk_terrier",
    label: "Norfolk Terrier",
  },
  {
    key: "norwich_terrier",
    label: "Norwich Terrier",
  },
  {
    key: "petit_bleu_gascogne",
    label: "Petit bleu de gascogne",
  },
  {
    key: "petit_chien_hollandais_chasse_gibier_eau",
    label:
      "Petit chien hollandais de chasse au gibier d'eau (Chien hollandais de canardiere / Kooikerhondje)",
  },
  {
    key: "petit_epagneul_munster",
    label: "Petit epagneul de munster (Petit munsterlander / Kleiner münsterländer vorstehhund)",
  },
  {
    key: "podenco_ibiza",
    label: "Podenco d'ibiza (Podenco ibicenco)",
  },
  {
    key: "ratier_prague",
    label: "Ratier de prague (Prazsky krysarik)",
  },
  {
    key: "retriever_poil_boucle",
    label: "Retriever a poil boucle (Curly coated retriever)",
  },
  {
    key: "retriever_poil_plat",
    label: "Retriever a poil plat (Flat coated / Flat coated retriever)",
  },
  {
    key: "retriever_baie_chesapeake",
    label: "Retriever de la baie de chesapeake (Chesapeake bay retriever)",
  },
  {
    key: "retriever_nouvelle_ecosse",
    label: "Retriever de la nouvelle ecosse (Nova scotia / Nova scotia duck tolling retriever)",
  },
  {
    key: "retriever_labrador",
    label: "Retriever du labrador (Labrador / Labrador retriever)",
  },
  {
    key: "saint_bernard",
    label: "Saint Bernard",
  },
  {
    key: "schnauzer",
    label: "Schnauzer",
  },
  {
    key: "sealyham_terrier",
    label: "Sealyham Terrier",
  },
  {
    key: "setter_irlandais_rouge_blanc",
    label: "Setter irlandais rouge et blanc (Irish red and white setter)",
  },
  {
    key: "shar_pei",
    label: "Shar Pei",
  },
  {
    key: "shiba",
    label: "Shiba",
  },
  {
    key: "shih_tzu",
    label: "Shih Tzu",
  },
  {
    key: "smous_pays_bas",
    label: "Smous des pays bas (Smous / Hollandse smoushond)",
  },
  {
    key: "spitz_norrbotten",
    label: "Spitz de norrbotten (Norrbottenspets)",
  },
  {
    key: "spitz_visigoths",
    label: "Spitz des visigoths (Chiens des goths de l'ouest",
  },
  {
    key: "sussex_spaniel",
    label: "Sussex Spaniel",
  },
  {
    key: "terrier_australien_poil_soyeux",
    label: "Terrier australien a poil soyeux (Silky terrier / Australian silky terrier)",
  },
  {
    key: "terrier_agrement_anglais_noir_feu",
    label:
      "Terrier d'agrement anglais noir et feu (Toy terrier / English toy terrier black and tan)",
  },
  {
    key: "terrier_boston",
    label: "Terrier de boston (Boston terrier)",
  },
  {
    key: "terrier_chasse_allemand",
    label: "Terrier de chasse allemand (Jagd terrier / Deutscher jagdterrier)",
  },
  {
    key: "terrier_reverend_russell",
    label: "Terrier du reverend russell (Parson / Parson russell terrier)",
  },
  {
    key: "terrier_irlandais_poil_doux",
    label: "Terrier irlandais a poil doux (Soft coated / Irish soft coated wheaten terrier)",
  },
  {
    key: "terrier_irlandais_glen_of_imaal",
    label: "Terrier Irlandais Glen Of Imaal",
  },
  {
    key: "welsh_terrier",
    label: "Welsh Terrier",
  },
  {
    key: "croise",
    label: "Croisé",
  },
  {
    key: "zwergaffen",
    label: "Zwergaffen",
  },
  {
    key: "griffon-singe",
    label: "Griffon-singe",
  },
  {
    key: "waterside_terrier",
    label: "Waterside Terrier",
  },
  {
    key: "bingley_terrier",
    label: "Bingley Terrier",
  },
  {
    key: "working_terrier",
    label: "Working Terrier",
  },
  {
    key: "warfedale_terrier",
    label: "Warfedale Terrier",
  },
  {
    key: "akita_ken",
    label: "Akita Ken",
  },
  {
    key: "anglo_francais_de_petite_venerie",
    label: "Anglo Francais De Petite Venerie",
  },
  {
    key: "levrier_touareg",
    label: "Lévrier Touareg",
  },
  {
    key: "sloughi_touareg",
    label: "Sloughi Touareg",
  },
  {
    key: "levrier_du_sud_saharien",
    label: "Lévrier Du Sud Saharien",
  },
  {
    key: "terrier_nyam-nyam",
    label: "Terrier Nyam-Nyam",
  },
  {
    key: "berger_alsacien",
    label: "Berger Alsacien",
  },
  {
    key: "berger_belge_(toutes_varietes)",
    label: "Berger Belge (toutes Varietes)",
  },
  {
    key: "laekenois",
    label: "Laekenois",
  },
  {
    key: "cane_da_pastore_bergamasco",
    label: "Cane Da Pastore Bergamasco",
  },
  {
    key: "berger_français",
    label: "Berger Français",
  },
  {
    key: "berger_de_bosnie-herzegovine_et_de_croatie",
    label: "Berger De Bosnie-Herzegovine Et De Croatie",
  },
  {
    key: "berger_de_la_maremme_et_des_abruzzes",
    label: "Berger De La Maremme Et Des Abruzzes",
  },
  {
    key: "pastore_abruzzese",
    label: "Pastore Abruzzese",
  },
  {
    key: "maremmano",
    label: "Maremmano",
  },
  {
    key: "alentejo",
    label: "Alentejo",
  },
  {
    key: "berger_picard",
    label: "Berger Picard",
  },
  {
    key: "bergers_pikhaars",
    label: "Bergers Pikhaars",
  },
  {
    key: "ovtcharka_de_russie_meridionale",
    label: "Ovtcharka De Russie Méridionale",
  },
  {
    key: "youjak",
    label: "Youjak",
  },
  {
    key: "berger_des_pyrenees_a_face_rase",
    label: "Berger Des Pyrenees A Face Rase",
  },
  {
    key: "berger_des_pyrenees_a_poil_long",
    label: "Berger Des Pyrenees A Poil Long",
  },
  {
    key: "bouvier_de_l_appenzell",
    label: "Bouvier De L'Appenzell",
  },
  {
    key: "kurzhaar",
    label: "Kurzhaar",
  },
  {
    key: "perdiguero",
    label: "Perdiguero",
  },
  {
    key: "bruno_saint-hubert_francais",
    label: "Bruno Saint-Hubert Francais",
  },
  {
    key: "mops",
    label: "Mops",
  },
  {
    key: "chien_courant_de_bosnie_a_poil_dur",
    label: "Chien Courant De Bosnie A Poil Dur",
  },
  {
    key: "hamiltonstövare",
    label: "Hamiltonstövare",
  },
  {
    key: "chien_courant_suedois",
    label: "Chien Courant Suédois",
  },
  {
    key: "chien_courant_de_la_valle_de_la_save",
    label: "Chien Courant De La Valle De La Save",
  },
  {
    key: "posavski_gonic",
    label: "Posavski Gonic",
  },
  {
    key: "chien_courant_du_bassin_de_kras",
    label: "Chien Courant Du Bassin De Kras",
  },
  {
    key: "chien_courant_de_montagne_du_montenegro",
    label: "Chien Courant De Montagne Du Montenegro",
  },
  {
    key: "chien_courant_yougoslave_de_montagne",
    label: "Chien Courant Yougoslave De Montagne",
  },
  {
    key: "chien_courant_noir",
    label: "Chien Courant Noir",
  },
  {
    key: "chien_courant_italien_a_poil_dur",
    label: "Chien Courant Italien A Poil Dur",
  },
  {
    key: "courant_bernois",
    label: "Courant Bernois",
  },
  {
    key: "courant_lucernois",
    label: "Courant Lucernois",
  },
  {
    key: "courant_schwytzois",
    label: "Courant schwytzois",
  },
  {
    key: "braque_allemand_a_poil_dur",
    label: "Braque Allemand à Poil Dur",
  },
  {
    key: "chien_d_arret_allemand_a_poil_long",
    label: "Chien D'arret Allemand A Poil Long",
  },
  {
    key: "pointer_danois",
    label: "Pointer Danois",
  },
  {
    key: "grand_chien_d_artois",
    label: "Grand Chien D'Artois",
  },
  {
    key: "picard",
    label: "Picard",
  },
  {
    key: "gos_d_atura_catala",
    label: "Gos D'Atura Català",
  },
  {
    key: "perro_de_pastor_catalán",
    label: "Perro De Pastor Catalán",
  },
  {
    key: "chien_de_berger_croate",
    label: "Chien De Berger Croate",
  },
  {
    key: "shetland_sheepdog",
    label: "Shetland Sheepdog",
  },
  {
    key: "sheltie",
    label: "Sheltie",
  },
  {
    key: "chien_de_berger_yougoslave_de_charplanina",
    label: "Chien De Berger Yougoslave De Charplanina",
  },
  {
    key: "charplanina",
    label: "Charplanina",
  },
  {
    key: "levrier_portugais",
    label: "Lévrier Portugais",
  },
  {
    key: "chien_de_leonberg",
    label: "Chien De Leonberg",
  },
  {
    key: "chien_de_perdrix_de_drente",
    label: "Chien De Perdrix De Drente",
  },
  {
    key: "epagneul_hollandais_de_drente",
    label: "Épagneul Hollandais De Drente",
  },
  {
    key: "american_water_spaniel",
    label: "American Water Spaniel",
  },
  {
    key: "epagneul_d_eau_americain",
    label: "Epagneul D'eau Américain",
  },
  {
    key: "chien_noir_et_feu_pour_la_chasse_au_raton",
    label: "Chien Noir Et Feu Pour La Chasse Au Raton",
  },
  {
    key: "chien_nu_du_mexique",
    label: "Chien Nu Du Mexique",
  },
  {
    key: "epagneul_de_pont_audemer",
    label: "Epagneul De Pont Audemer",
  },
  {
    key: "epagneul_de_saint_usuge",
    label: "Epagneul De Saint Usuge",
  },
  {
    key: "whip_tail",
    label: "Whip Tail",
  },
  {
    key: "rat-tail",
    label: "Rat-tail",
  },
  {
    key: "epagneul_nain_continental_papillon",
    label: "Épagneul Nain Continental Papillon",
  },
  {
    key: "epagneul_nain_continental_phalene",
    label: "Épagneul Nain Continental Phalène",
  },
  {
    key: "fila_de_saint_miguel",
    label: "Fila De Saint Miguel",
  },
  {
    key: "grand_anglo-francais_tricolore",
    label: "Grand Anglo-Francais Tricolore",
  },
  {
    key: "griffon_a_poil_dur_korthals",
    label: "Griffon A Poil Dur Korthals",
  },
  {
    key: "ainou",
    label: "Aïnou",
  },
  {
    key: "ainu_ken",
    label: "Ainu Ken",
  },
  {
    key: "hokkaido_ken",
    label: "Hokkaïdo Ken",
  },
  {
    key: "malamute_de_l_alaska",
    label: "Malamute De L'alaska",
  },
  {
    key: "petit_courant_bernois",
    label: "Petit Courant Bernois",
  },
  {
    key: "petit_courant_du_jura",
    label: "Petit Courant Du Jura",
  },
  {
    key: "petit_courant_lucernois",
    label: "Petit Courant Lucernois",
  },
  {
    key: "petit_courant_schwytzois",
    label: "Petit Courant schwytzois",
  },
  {
    key: "petit_chien_hollandais_de_chasse_au_gibier_d_eau",
    label: "Petit Chien Hollandais De Chasse Au Gibier D'eau",
  },
  {
    key: "toy_terrier_russe",
    label: "Toy Terrier Russe",
  },
  {
    key: "retriever_a_poil_boucle",
    label: "Retriever A Poil Boucle",
  },
  {
    key: "curly",
    label: "Curly",
  },
  {
    key: "curly-coated_retriever",
    label: "Curly-Coated Retriever",
  },
  {
    key: "retriever_de_la_baie_de_chesapeake",
    label: "Retriever De La Baie De Chesapeake",
  },
  {
    key: "chesapeake",
    label: "Chesapeake",
  },
  {
    key: "chesapeake_bay_retriever",
    label: "Chesapeake Bay Retriever",
  },
  {
    key: "retriever_de_la_nouvelle_ecosse",
    label: "Retriever De La Nouvelle Ecosse",
  },
  {
    key: "nova_scotia",
    label: "Nova Scotia",
  },
  {
    key: "nova_scotia_duck_tolling_retriever",
    label: "Nova Scotia Duck Tolling Retriever",
  },
  {
    key: "retriever_du_labrador",
    label: "Retriever Du Labrador",
  },
  {
    key: "labrador",
    label: "Labrador",
  },
  {
    key: "labrador_retriever",
    label: "Labrador retriever",
  },
  {
    key: "chien_de_kōchi",
    label: "Chien De Kōchi",
  },
  {
    key: "kōchi_ken",
    label: "Kōchi Ken",
  },
  {
    key: "shikoku_inu",
    label: "Shikoku Inu",
  },
  {
    key: "levrier_berbere",
    label: "Lévrier Berbère",
  },
  {
    key: "pomeranien",
    label: "Poméranien",
  },
  {
    key: "spitz-loup",
    label: "Spitz-loup",
  },
  {
    key: "keeshond",
    label: "Keeshond",
  },
  {
    key: "grand_spitz",
    label: "Grand Spitz",
  },
  {
    key: "stafford",
    label: "Stafford",
  },
  {
    key: "staffy",
    label: "Staffy",
  },
  {
    key: "sbt",
    label: "SBT",
  },
  {
    key: "retriever_de_terre-neuve",
    label: "Retriever De Terre-Neuve",
  },
  {
    key: "newfoundland",
    label: "NewFoundland",
  },
  {
    key: "newfie",
    label: "Newfie",
  },
  {
    key: "english_toy_terrier",
    label: "English Toy Terrier",
  },
  {
    key: "black_and_tan",
    label: "Black And Tan",
  },
  {
    key: "toy_terrier_anglais_noir_et_feu",
    label: "Toy Terrier Anglais Noir Et Feu",
  },
  {
    key: "autres",
    label: "Autres",
  },
  {
    key: "boerboel",
    label: "Boerboel",
  },
  {
    key: "boyin_spaniel",
    label: "Boyin Spaniel",
  },
  {
    key: "griffon_boulet",
    label: "Griffon Boulet",
  },
];
