import { Skeleton } from "src/components/01_atoms/skeleton/skeleton";
import { styled, theme } from "src/styles/stitches/theme";

const Container = styled("div", {
  width: "100%",
  padding: "1rem 2rem 2rem",
});

const MenuItem = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  paddingLeft: theme.spacing.spacing12px,
});

const MenuItemText = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.spacing.spacing12px,
  flex: 1,
});

const MenuItemsLoading = () => {
  const textGradient =
    "linear-gradient(146deg, rgba(79, 104, 246, 0.4) 0%, rgba(79, 104, 246, 0.2) 20%, rgba(255, 255, 255, 0.5) 110%)";

  return (
    <Container>
      <Skeleton width="100%" height="3rem" borderRadius="0.375rem" margin="0px 0px 0.5rem 0px">
        <MenuItem>
          <Skeleton
            width="2rem"
            height="2rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <MenuItemText>
            <Skeleton
              width="7.625rem"
              height="1rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </MenuItemText>
        </MenuItem>
      </Skeleton>
      <Skeleton width="100%" height="3rem" borderRadius="0.375rem" margin="0px 0px 0.5rem 0px">
        <MenuItem>
          <Skeleton
            width="2rem"
            height="2rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <MenuItemText>
            <Skeleton
              width="7.625rem"
              height="1rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </MenuItemText>
        </MenuItem>
      </Skeleton>
      <Skeleton width="100%" height="3rem" borderRadius="0.375rem" margin="0px 0px 0.5rem 0px">
        <MenuItem>
          <Skeleton
            width="2rem"
            height="2rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <MenuItemText>
            <Skeleton
              width="7.625rem"
              height="1rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </MenuItemText>
        </MenuItem>
      </Skeleton>
      <Skeleton width="100%" height="3rem" borderRadius="0.375rem" margin="0px 0px 0.5rem 0px">
        <MenuItem>
          <Skeleton
            width="2rem"
            height="2rem"
            borderRadius="0.375rem"
            backgroundImage={textGradient}
          />
          <MenuItemText>
            <Skeleton
              width="7.625rem"
              height="1rem"
              borderRadius="0.375rem"
              backgroundImage={textGradient}
            />
          </MenuItemText>
        </MenuItem>
      </Skeleton>
    </Container>
  );
};

export { MenuItemsLoading };
