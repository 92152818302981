import { StepperItem } from "src/components/01_atoms/stepper-item";
import { Outlet } from "../../../01_atoms/outlet";
import { CSS } from "@stitches/react";
import { matchIsAccidentAndDiseaseCategory } from "src/helpers";

export type Props = {
  currentStep: number;
  numberOfSteps: number;
  css?: CSS;
  selectedConsultationTypes: string[];
};

export function Stepper({ currentStep, numberOfSteps, selectedConsultationTypes, css }: Props) {
  const steps = Array.from({ length: numberOfSteps }, (_, i) => i + 1);

  const matchIsActive = (step: number) => {
    if (numberOfSteps === 3) {
      return step === currentStep - 1;
    } else if (step === currentStep) {
      return step === currentStep;
    } else if (currentStep === 6 && !matchIsAccidentAndDiseaseCategory(selectedConsultationTypes)) {
      return step === currentStep - 1;
    }
  };

  return (
    <Outlet horizontal="spacing8px" css={css}>
      {steps.map((step) => (
        <StepperItem key={step} active={matchIsActive(step)} />
      ))}
    </Outlet>
  );
}
