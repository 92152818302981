import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { InlineAlert } from "../inline-alert";
import { ArrowsNames, FilledNames, OutlinedNames } from "src/components/01_atoms/icon";
import { styled, theme } from "src/styles/stitches/theme";
import { ReactNode } from "react";

const StyledOutlet = styled(Outlet, {
  "@bp3": {
    marginBottom: theme.spacing.spacing24px,
  },
});

const Subtitle = styled(Text, {
  display: "none",

  "@bp3": {
    display: "block",
  },
});

type Props = {
  title: string;
  subtitle?: string;
  description?: string;
  alertIconName?: ArrowsNames | FilledNames | OutlinedNames;
  alertText?: string | ReactNode;
  alertChevronRight?: boolean;
  onAlertClick?: () => void;
};

export function SidesheetContentHeader({
  title,
  subtitle,
  description,
  alertIconName,
  alertText,
  alertChevronRight,
  onAlertClick,
}: Props) {
  return (
    <StyledOutlet vertical="spacing8px" fluid>
      <Subtitle variant="paragraph2-Bold" color="textBaseSubdued">
        {subtitle}
      </Subtitle>
      <Outlet vertical="spacing16px" fluid>
        <Outlet
          vertical={{ "@initial": "spacing8px", "@bp3": "spacing16px" }}
          align="stretch"
          fluid
        >
          <Text
            variant={{ "@initial": "display4-Bold", "@bp3": "display3-Bold" }}
            color="textBaseDefault"
          >
            {title}
          </Text>
          {description ? (
            <Text variant="paragraph2-Medium" color="textBaseSubdued">
              {description}
            </Text>
          ) : null}
        </Outlet>
        {alertIconName && alertText ? (
          <InlineAlert
            iconName={alertIconName}
            text={alertText}
            chevronRight={alertChevronRight}
            onClick={onAlertClick}
          />
        ) : null}
      </Outlet>
    </StyledOutlet>
  );
}
