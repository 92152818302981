import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "src/components/01_atoms/toast";
import { Button } from "src/components/01_atoms/button";
import { Checkbox } from "src/components/01_atoms/checkbox";
import { Input } from "src/components/01_atoms/input";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { PasswordInputWithForgot } from "src/components/02_molecules/password-input-with-forgot";
import { useSignIn } from "src/hooks/signin";
import { routes } from "src/routes";
import { styled } from "src/styles/stitches/theme";
import { z } from "zod";
import { leadLoginApi } from "src/backApi/lead-login";
import { useTranslation } from "react-i18next";

const Form = styled("form", {
  width: "100%",
});

type SignUpFormProps = {
  firstConnectionLink?: string;
  forgotPasswordLink?: string;
  nextPage: string;
  setIsBlockFreeUserAlertModalOrDrawerOpen: (
    isBlockFreeUserAlertModalOrDrawerOpen: boolean
  ) => void;
};

interface Login {
  email: string;
  password: string;
}

const loginShema = z.object({
  email: z.string().email("Email invalide"),
  password: z.string().min(8, "Votre mot de passe doit contenir au moins 8 caractères"),
});

export function SignUpForm({
  forgotPasswordLink = routes.forgotPassword,
  firstConnectionLink = routes.firstConnection,
  nextPage,
  setIsBlockFreeUserAlertModalOrDrawerOpen,
}: SignUpFormProps) {
  const { t } = useTranslation();
  const [isLeadLoginLoading, setIsLeadLoginLoading] = useState(false);
  const { isLoading, signIn } = useSignIn({ firstConnectionLink, nextPage });

  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<Login>({
    resolver: zodResolver(loginShema),
    reValidateMode: "onSubmit",
  });

  const onSubmit: SubmitHandler<Login> = async (data) => {
    try {
      setIsLeadLoginLoading(true);
      await leadLoginApi(data.email, data.password);
      setIsBlockFreeUserAlertModalOrDrawerOpen(true);
    } catch (err) {
      const error = await signIn(data);

      // Make the inputs in critical state if there is an error from the server
      if (error) {
        error.fields.forEach((field) => {
          setError(field, {
            type: "manual",
            message: error.message,
          });
        });
      }
    } finally {
      setIsLeadLoginLoading(false);
    }
  };

  // Show error toast if there is an error with the validation
  useEffect(() => {
    if (errors?.email || errors?.password) {
      toast.error(errors?.email?.message || errors?.password?.message);
    }
  }, [errors]);

  // Set the email input focus on first mount
  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Outlet vertical="spacing48px">
        <Outlet vertical="spacing32px" fluid>
          <Text variant="display4-Bold">{t("loginTitle")}</Text>
          <Outlet vertical="spacing16px" fluid>
            <Input
              placeholder="Email"
              type="email"
              critical={!!errors.email}
              errorMessage={errors.email?.message}
              {...register("email")}
            />
            <PasswordInputWithForgot
              placeholder="Mot de passe"
              type="password"
              critical={!!errors.password}
              errorMessage={errors.password?.message}
              forgotPasswordLink={forgotPasswordLink}
              {...register("password")}
            />
          </Outlet>
          <Checkbox name="stay-connected" id="stay-connect">
            Rester connecté&middot;e
          </Checkbox>
          <Button
            variant="accent"
            size="lg"
            fluid
            disabled={isLoading || isLeadLoginLoading}
            loading={isLoading || isLeadLoginLoading}
            type="submit"
          >
            C&apos;est parti !
          </Button>
        </Outlet>
      </Outlet>
    </Form>
  );
}
