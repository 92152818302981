import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { reopenClaim } from "src/backApi/reopenClaimApi";
import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { toast } from "src/components/01_atoms/toast";
import { InlineAlert } from "src/components/02_molecules/inline-alert";
import { FDS } from "src/configs/Legal";
import { useGetContact } from "src/hooks/data";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";
import { ClaimFileType } from "src/types";
import { z } from "zod";
import { ImportDocument } from "../../refund-sidebar/documents-form/_import-document";
import { ClaimStatusSidebarContentHeader } from "../content-header";
import { ClaimStatusSidebarHeader } from "../header";
import { reportError } from "src/utils/error";
const Container = styled("div", {
  position: "fixed",
  backgroundColor: theme.colors.fillSecondary,
  height: "100%",
  width: "100%",
  maxWidth: "26.25rem",
  right: "-26.25rem",
  zIndex: 2,
  overflowY: "auto",
  transition: "right 0.3s ease-out",
  display: "flex",
  flexDirection: "column",

  variants: {
    isOpen: {
      true: {
        right: 0,
      },
    },
  },
});

const Form = styled("form", {
  flex: 1,
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing28px}`,

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing32px}`,
  },
});

const StyledLink = styled("a", {
  color: theme.colors.textAccent,
  textDecoration: "none",
});

export type Documents = {
  invoice: { value: File }[];
  careRecord: { value: File }[];
  prescription?: { value: File }[];
};

const fileArray = z.array(
  z.object({
    value: z.instanceof(File),
  })
);

const documentSchema = z
  .object({
    invoice: fileArray.nonempty("Vous devez ajouter au moins un justificatif"),
  })
  .or(
    z.object({
      careRecord: fileArray.nonempty("Vous devez ajouter au moins un justificatif"),
    })
  )
  .or(
    z.object({
      prescription: fileArray.nonempty("Vous devez ajouter au moins un justificatif"),
    })
  );

export function AddNeedInfoDocumentSidebar() {
  const {
    isAddNeedInfoDocumentSidebarOpen,
    setIsAddNeedInfoDocumentSidebarOpen,
    selectedClaim,
    setIsConfirmationScreenOpen,
  } = useClaimStatusSidebar();

  const { data: contact } = useGetContact();

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { isValid },
    control,
    reset,
    watch,
  } = useForm<Documents>({
    resolver: zodResolver(documentSchema),
    defaultValues: {
      careRecord: [],
      invoice: [],
      prescription: [],
    },
    mode: "onChange",
  });

  const invoiceMethods = useFieldArray({
    control,
    name: "invoice",
  });
  const careRecordMethods = useFieldArray({
    control,
    name: "careRecord",
  });
  const prescriptionMethods = useFieldArray({
    control,
    name: "prescription",
  });

  if (!selectedClaim) return null;

  const { pet_name, claim_id } = selectedClaim;

  const onSubmit: SubmitHandler<Documents> = async (values) => {
    if (!claim_id || !contact?.customer_uuid) {
      return;
    }

    setIsLoading(true);

    const careRecordFiles =
      values.careRecord?.map((file) => ({
        file: file.value,
        kind: "CARE_RECORD" as ClaimFileType,
        filename: file.value.name,
        mime_type: file.value.type,
      })) || [];
    const invoiceFiles =
      values.invoice?.map((file) => ({
        file: file.value,
        kind: "INVOICE" as ClaimFileType,
        filename: file.value.name,
        mime_type: file.value.type,
      })) || [];
    const prescriptionFiles =
      values.prescription?.map((file) => ({
        file: file.value,
        kind: "PRESCRIPTION" as ClaimFileType,
        filename: file.value.name,
        mime_type: file.value.type,
      })) || [];

    const files = [...careRecordFiles, ...invoiceFiles, ...prescriptionFiles];

    try {
      await reopenClaim(files, claim_id, contact.customer_uuid);
      setIsAddNeedInfoDocumentSidebarOpen(false);
      setIsConfirmationScreenOpen(true);
    } catch (error) {
      reportError(error);
      toast.error(
        "Une erreur s'est produite lors de l'envoi de votre demande. Vérifiez que tous les noms des fichiers ne contiennent pas de caractères spéciaux."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const MAX_FILE_SIZE = 50_000_000; // 50MB

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileTmp: File | undefined = event.target.files?.[0];
    if (fileTmp) {
      if (fileTmp.size > MAX_FILE_SIZE) {
        toast.error("Le fichier est trop volumineux, il doit faire moins de 50MB.");
        return;
      }

      if (event.currentTarget.name === "invoice") {
        invoiceMethods.append({ value: fileTmp });
      }
      if (event.currentTarget.name === "careRecord") {
        careRecordMethods.append({ value: fileTmp });
      }
      if (event.currentTarget.name === "prescription") {
        prescriptionMethods.append({ value: fileTmp });
      }

      event.target.value = "";
    }
  };

  const numberOfFiles =
    (watch("invoice")?.length || 0) +
    (watch("careRecord")?.length || 0) +
    (watch("prescription")?.length || 0);

  return (
    <Container isOpen={isAddNeedInfoDocumentSidebarOpen}>
      <ClaimStatusSidebarHeader
        isBackButtonDisplayed={true}
        onCloseButtonClick={() => {
          setIsAddNeedInfoDocumentSidebarOpen(false);
          reset();
        }}
        onBackButtonClick={() => {
          setIsAddNeedInfoDocumentSidebarOpen(false);
          reset();
        }}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledOutlet vertical={{ "@initial": "spacing32px", "@bp3": "spacing48px" }} fullHeight>
          <Outlet vertical={{ "@initial": "spacing16px", "@bp3": "0" }}>
            <ClaimStatusSidebarContentHeader
              title="Ajouter un justificatif"
              withStatusTag={false}
            />
            <InlineAlert
              iconName="filledInfo"
              text={
                <>
                  Pas de feuille de soins ? Vous pouvez toujours la{" "}
                  <StyledLink
                    href={FDS}
                    target="_blank"
                    rel="noreferrer"
                    title="Télécharger la feuille de soins"
                  >
                    télécharger
                  </StyledLink>{" "}
                  et la faire remplir par le vétérinaire qui s’est occupé de {pet_name}.
                </>
              }
            />
          </Outlet>
          <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
            <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fluid>
              <ImportDocument
                onFileChange={onFileChange}
                name="invoice"
                methods={invoiceMethods}
                title="Preuve de paiement"
                description="Facture avec preuve que le paiement a été effectué"
              />
              <ImportDocument
                onFileChange={onFileChange}
                name="careRecord"
                methods={careRecordMethods}
                title="Feuille de soins"
                description="Feuille de soins dûment remplie par votre vétérinaire"
              />
              <ImportDocument
                onFileChange={onFileChange}
                name="prescription"
                methods={prescriptionMethods}
                title="Autres documents"
                description="Ordonnance, compte-rendu  de la visite vétérinaire, justificatif vaccinal..."
              />
            </Outlet>
            <Button
              type="submit"
              variant="accent"
              size="lg"
              fluid
              disabled={!isValid || isLoading}
              loading={isLoading}
            >
              {numberOfFiles > 1 ? "Ajouter les justificatifs" : "Ajouter le justificatif"}
            </Button>
          </Outlet>
        </StyledOutlet>
      </Form>
    </Container>
  );
}
