import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

export const StyledText = styled(Text, {
  padding: `0 ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: "unset",
  },
});
