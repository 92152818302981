import { Auth } from "aws-amplify";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchResetPassword } from "src/backApi/fetchResetPassword";
import { fetchStatus } from "src/backApi/fetchStatus";
import { toast } from "src/components/01_atoms/toast";
import { routes } from "src/routes";
import { parseError } from "src/utils/error";

type useForgotPasswordProps = {
  nextPage: string;
};

export function useForgotPassword({ nextPage }: useForgotPasswordProps) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  async function sendNewPasswordCode(parsedEmail: string) {
    try {
      await Auth.forgotPassword(parsedEmail);
      setIsLoading(false);

      toast.success(`Un email a été envoyé à l'adresse ${parsedEmail}.`);
      history.push({
        pathname: nextPage,
        state: { email: parsedEmail },
      });
    } catch (error) {
      const parsedError = parseError(error);
      throw new Error(parsedError.code);
    }
  }

  async function sendForgotPasswordEmail(email: string) {
    setIsLoading(true);
    // HACK our new user pool is case sensitive, so we must lowercase emails
    const parsedEmail = email.toLowerCase();

    try {
      const res = await fetchStatus(parsedEmail);

      // This is the case is the user never logged in before but the user exist
      // and his temporary password is expired
      if (res === "FORCE_CHANGE_PASSWORD") {
        await fetchResetPassword(parsedEmail);

        toast.success(
          `Votre mot de passe temporaire a expiré.\n Un nouveau mot de passe temporaire vous a été envoyé à : ${parsedEmail}`
        );
        history.push({
          pathname: routes.firstConnection,
          state: { email: parsedEmail },
        });
      }

      // This is the case where the user has already confirmed his email address
      // and the email will be sent to him/her with a code to reset his/her password
      if (res === "CONFIRMED" || res === "RESET_REQUIRED" || res?.startsWith("TO_BE_MIGRATED")) {
        await sendNewPasswordCode(parsedEmail);
      }
    } catch (error) {
      // If user exist but reach the limit of reset password attempts
      if (error instanceof Error && error.message === "LimitExceededException") {
        toast.error(
          "Vous avez atteint le plafond de tentative de reinitialisation du mot de passe. Veuillez patientez 1h ou écrire à contact@dalma.co"
        );
        return;
      }

      // If the user does not exist or if there is a problem with the API
      toast.error(
        "Une erreur est survenue. Merci de nous écrire à contact@dalma.co si le problème persiste."
      );
    } finally {
      setIsLoading(false);
    }
  }

  return { sendForgotPasswordEmail, isLoading };
}
