import { FirstConnectionForm } from "src/components/03_organisms/login/first-connection-form";
import { LoginLayout } from "src/components/04_layouts/login-layout";
import { routes } from "src/routes";

export function FirstConnection() {
  return (
    <LoginLayout>
      <FirstConnectionForm nextPage={routes.home} />
    </LoginLayout>
  );
}
