import { useActor } from "@xstate/react";
import { useEffect } from "react";
import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { toastClose } from "src/components/01_atoms/toast";
import { SidesheetScreenWithImage } from "src/components/02_molecules/sidesheet-screen-with-image";
import { SidesheetLayout } from "src/components/04_layouts/sidesheet-layout";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { claimsService } from "src/store/claims/machine";
import { styled, theme } from "src/styles/stitches/theme";
import { AddNeedInfoDocumentSidebar } from "./add-need-info-document-sidebar";
import { ClaimStatusCard } from "./claim-status-card";
import { ClaimStatusSidebarContentHeader } from "./content-header";
import { ClaimStatusSidebarHeader } from "./header";
import { HelpSection } from "./help-section";
import { NeedInfoDetailMobileDrawer } from "./need-info-detail/mobile-drawer";
import { NeedInfoDetailSidebar } from "./need-info-detail/sidebar";
import { RefusedDetailMobileDrawer } from "./refused-detail/mobile-drawer";
import { RefusedDetailSidebar } from "./refused-detail/sidebar";

const Container = styled("div", {
  overflowY: "auto",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

const StyledOutlet = styled(Outlet, {
  flex: 1,
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing28px}`,

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing32px}`,
  },
});

const StyledButton = styled(Button, {
  "@bp3": {
    position: "sticky",
    bottom: "2rem",
  },
});

export function ClaimStatusSidebar() {
  const {
    isOpen,
    setIsOpen,
    formattedCategories,
    setIsNeedInfoDetailSidebarOpen,
    setIsAddNeedInfoDocumentSidebarOpen,
    isConfirmationScreenOpen,
    setIsConfirmationScreenOpen,
    selectedClaim,
    setSelectedClaim,
    setIsUpdatedClaimLoading,
    setIsRefusedDetailSidebarOpen,
    isUpdatedClaimLoading,
  } = useClaimStatusSidebar();

  const [state, send] = useActor(claimsService);

  // TODO: Update this with xstate
  useEffect(() => {
    if (state._event.data.type.includes("done.invoke.claims.loading")) {
      if (!selectedClaim) {
        return;
      }

      const allClaims = (state._event.data as any)?.data?.all_claims_v2;
      if (!allClaims || allClaims.length === 0) {
        return;
      }

      const updatedClaim = allClaims.find(
        (claim: { claim_id: string }) => claim.claim_id === selectedClaim.claim_id
      );
      if (!updatedClaim) {
        return;
      }

      setSelectedClaim(updatedClaim);
      setIsConfirmationScreenOpen(false);
      setIsUpdatedClaimLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (!selectedClaim) {
    return null;
  }

  return (
    <>
      <SidesheetLayout
        isOpen={isOpen}
        label="Mes remboursements"
        onDismiss={() => {
          setIsOpen(false);
          setIsNeedInfoDetailSidebarOpen(false);
          setIsAddNeedInfoDocumentSidebarOpen(false);
          setIsConfirmationScreenOpen(false);
          setIsRefusedDetailSidebarOpen(false);
          send("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
        }}
      >
        <Container>
          <ClaimStatusSidebarHeader
            isBackButtonDisplayed={false}
            onCloseButtonClick={() => {
              setIsOpen(false);
              send("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
            }}
          />
          <StyledOutlet vertical="spacing32px">
            <ClaimStatusSidebarContentHeader title={formattedCategories} />
            <ClaimStatusCard />
            <HelpSection />
            {selectedClaim.status === "NEED_INFO" ? (
              <StyledButton
                type="button"
                variant="accent"
                size={{ "@initial": "md", "@bp3": "lg" }}
                fluid
                onClick={() => setIsAddNeedInfoDocumentSidebarOpen(true)}
              >
                Ajouter un nouveau justificatif
              </StyledButton>
            ) : null}
          </StyledOutlet>
        </Container>
        <NeedInfoDetailSidebar />
        <AddNeedInfoDocumentSidebar />
        {isConfirmationScreenOpen && formattedCategories ? (
          <SidesheetScreenWithImage
            title="Justificatif envoyé"
            description="Bien joué ! Nous vous enverrons un message sous deux jours ouvrés pour valider votre demande de remboursement"
            image="/assets/media/paper-plane.svg"
            imageAlt="Illustration d'un avion en papier"
            onClickButton1={() => {
              setIsUpdatedClaimLoading(true);
              send({ type: "REFETCH_CLAIMS" });
              toastClose();
            }}
            buttonLabel1="J'ai compris"
            isLoadingButton1={isUpdatedClaimLoading}
          />
        ) : null}
        <RefusedDetailSidebar />
      </SidesheetLayout>
      <NeedInfoDetailMobileDrawer />
      <RefusedDetailMobileDrawer />
    </>
  );
}
