import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

const Container = styled(Outlet, {
  background: theme.colors.fillAccent,
  padding: theme.spacing.spacing24px,
  position: "absolute",
  width: "100%",
  overflowY: "auto",

  "@bp3": {
    padding: theme.spacing.spacing32px,
  },
});

const StyledOutlet = styled(Outlet, {
  "@bp3": {
    flex: 1,
  },
});

const Image = styled("img", {
  width: "10rem",
  height: "10rem",

  "@bp3": {
    width: "15rem",
    height: "15rem",
  },
});

type Props = {
  title: string;
  description: string;
  image: string;
  imageAlt: string;
  onClickButton1: () => void;
  buttonLabel1: string;
  isLoadingButton1?: boolean;
  onClickButton2?: () => void;
  buttonLabel2?: string;
  isLoadingButton2?: boolean;
};

export function SidesheetScreenWithImage({
  title,
  description,
  image,
  imageAlt,
  onClickButton1,
  buttonLabel1,
  isLoadingButton1,
  onClickButton2,
  buttonLabel2,
  isLoadingButton2,
}: Props) {
  return (
    <Container vertical="spacing32px" fullHeight justify="center" align="center">
      <StyledOutlet vertical="spacing32px" justify="center" align="center">
        <Image src={image} alt={imageAlt} />
        <Outlet vertical="spacing8px" align="center">
          <Text
            variant={{ "@initial": "display3-Bold", "@bp3": "display2-Bold" }}
            color="textSecondaryDefault"
            textAlign="center"
          >
            {title}
          </Text>
          <Text
            variant={{ "@initial": "paragraph2-Medium", "@bp3": "paragraph1-Medium" }}
            color="textSecondarySubdued"
            textAlign="center"
          >
            {description}
          </Text>
        </Outlet>
      </StyledOutlet>
      <Outlet vertical={{ "@initial": "spacing24px", "@bp3": "spacing32px" }} fluid>
        <Button
          variant="secondary"
          size={{ "@initial": "md", "@bp3": "lg" }}
          fluid
          onClick={onClickButton1}
          loading={isLoadingButton1}
        >
          {buttonLabel1}
        </Button>
        {buttonLabel2 ? (
          <Button
            variant="smallActionSecondary"
            fluid
            onClick={onClickButton2}
            loading={isLoadingButton2}
            inline
          >
            {buttonLabel2}
          </Button>
        ) : null}
      </Outlet>
    </Container>
  );
}
