import { Outlet } from "src/components/01_atoms/outlet";
import { styled, theme } from "src/styles/stitches/theme";
import { MenuItems } from "./menu-items";
import { HomeNavbarHeader } from "./header";
import { useBanner } from "src/hooks/use-banner";
import { useActor } from "@xstate/react";
import { claimsService } from "src/store/claims/machine";
import { MenuItemsLoading } from "./menu-items-loading";

const LeftSidebar = styled("div", {
  position: "fixed",
  left: 0,
  top: 0,
  bottom: 0,
  display: "none",
  boxShadow: `1px 0px 0px 0px ${theme.colors.borderBaseSubdued}`,
  overflowY: "auto",

  "@bp3": {
    display: "block",
    width: "18.25rem",
    flex: "0 0 18.25rem",
  },

  variants: {
    isBannerVisible: {
      true: {
        "@bp3": {
          top: `${theme.spacing.spacing64px}`, // banner height
        },
      },
    },
  },
});

export function HomeNavbar() {
  const { isClosed } = useBanner();
  const [state] = useActor(claimsService);

  return (
    <LeftSidebar isBannerVisible={!isClosed}>
      <Outlet vertical="0" align="center" justify="spaceBetween" fluid css={{ height: "100%" }}>
        <Outlet vertical="0" align="center" fluid>
          <HomeNavbarHeader />
          {state.matches("loading") ? <MenuItemsLoading /> : <MenuItems />}
        </Outlet>
      </Outlet>
    </LeftSidebar>
  );
}
