import { Modal } from "src/components/04_layouts/modal-layout";
import copy from "copy-to-clipboard";
import { toast } from "src/components/01_atoms/toast";

import {
  ExplanationSection,
  Note,
  RafShareButton,
  Step,
  StepBorder,
  StepDescription,
  StepSeparator,
  TitleExplanation,
  WrapperContent,
  WrapperExplanationSection,
  WrapperStepDescription,
  WrapperImage,
  WrapperTitle,
  CloseIcon,
  IconSponsorhsip,
  LastStepBorder,
  BlueText,
} from "./sponsorship-modal-200.styled";
import { matchIsAllContractsDeffered } from "src/helpers";
import { Text } from "src/components/01_atoms/text";
import { useGetContract } from "src/hooks/data";
import { useRafOperation } from "src/hooks/use-raf-operation";
import { Icon } from "src/components/01_atoms/icon";
import { useDevice } from "src/hooks/useDevice";
type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  rafCode?: string;
};

export function Sponsorship200Modal({ isOpen, onDismiss, rafCode }: Props) {
  const { data: contracts } = useGetContract();
  const { isOperation200Enabled } = useRafOperation();
  const { isMobile } = useDevice();

  return (
    <Modal
      isOpen={isOpen}
      label="test"
      onDismiss={onDismiss}
      fullScreen={isMobile() ? true : false}
      style={{ width: isMobile() ? "inherit" : "27rem", marginTop: isMobile() ? 0 : "12.5rem" }}
    >
      <WrapperContent>
        <WrapperExplanationSection>
          <WrapperTitle>
            <CloseIcon onClick={onDismiss}>
              <Icon name="outlinedClose" color="iconBaseSubdued" size={20} />
            </CloseIcon>
            <TitleExplanation variant="display4-Bold" color="textBaseDefault">
              Comment ça fonctionne ?
            </TitleExplanation>
          </WrapperTitle>
          <ExplanationSection>
            <Step>
              <StepSeparator />
              <WrapperImage>
                <IconSponsorhsip src="/assets/media/sponsorship-share.svg" alt="" />
                <StepBorder />
              </WrapperImage>
              <WrapperStepDescription>
                <StepDescription variant="paragraph2-Medium">
                  Je partage mon lien de parrainage
                </StepDescription>
              </WrapperStepDescription>
            </Step>
            <Step>
              <StepSeparator />
              <WrapperImage>
                <IconSponsorhsip src="/assets/media/sponsorship-coins-hand.svg" alt="" />
                <StepBorder />
              </WrapperImage>
              <WrapperStepDescription>
                <StepDescription variant="paragraph2-Medium">
                  Mon proche s’abonne à Dalma
                </StepDescription>
              </WrapperStepDescription>
            </Step>
            <Step>
              <StepSeparator />
              <WrapperImage>
                <IconSponsorhsip src="/assets/media/sponsorship-programs.svg" alt="" />
                <LastStepBorder />
              </WrapperImage>
              <WrapperStepDescription>
                <StepDescription variant="paragraph2-Medium">
                  Je participe au tirage au sort et tente de gagner{" "}
                  <BlueText variant="paragraph2-Bold" color="textLinkBlue">
                    entre 20 et 200€
                  </BlueText>
                </StepDescription>
              </WrapperStepDescription>
            </Step>
            <Step>
              <WrapperImage>
                <IconSponsorhsip src="/assets/media/sponsorship-gift.svg" alt="" />
              </WrapperImage>
              <WrapperStepDescription>
                <StepDescription variant="paragraph2-Medium">
                  Je reçois la somme gagnée par virement, directement sur mon compte en banque.
                </StepDescription>
              </WrapperStepDescription>
            </Step>
          </ExplanationSection>
          <Note>
            <IconSponsorhsip src="/assets/media/sponsorship-label.svg" alt="" />
            <Text variant="paragraph2-Medium">
              Mon proche parrainé bénéficie de son premier mois offert à la souscription.
            </Text>
          </Note>
          {isOperation200Enabled ? (
            <Text
              variant="paragraph2-Regular"
              style={{ margin: "1.5rem 0" }}
              color="textBaseSubdued"
              textAlign="center"
            >
              En rejoignant ce programme, vous confirmez avoir lu et accepté{" "}
              <a
                href="https://editique.dalma.co/2024_07_08_Reglement_de_parrainage_Dalma.pdf
                "
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#4E68F6", textDecoration: "underline" }}
              >
                notre réglement
              </a>
              .
            </Text>
          ) : null}
          <RafShareButton
            type="button"
            variant="accent"
            size="md"
            fluid
            disabled={contracts ? matchIsAllContractsDeffered(contracts) : false}
            onClick={() => {
              if (rafCode) {
                copy(
                  `Hello !\n Rejoins-moi chez Dalma avec ta boule de poils et profite de ton 1er mois gratuit. Pour ça, clique sur mon lien : 
                  https://parrain.dalma.co/?raf_code=${encodeURI(
                    rafCode
                  )}&utm_source=RAF&utm_medium=&utm_campaign=page-filleul
                  En cas de problème, tu peux renseigner mon code ${rafCode} au moment du paiement.`
                );
                toast.copy("Votre code de parrainage a été copié dans le presse-papier");
              }
            }}
            aria-label="Copier votre code de parrainage"
          >
            {isOperation200Enabled
              ? "Partager mon lien de parrainage"
              : "Copier mon lien de parrainage"}
          </RafShareButton>
        </WrapperExplanationSection>
      </WrapperContent>
    </Modal>
  );
}
