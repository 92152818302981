import { useMemo } from "react";
import { ApiUnpaidStatusResponse, getUnpaidStatus } from "src/backApi/get-unpaid-status";
import { SANDBOX } from "src/config";
import { useGetContract } from "./data";
import { displayPrice } from "src/components/cards/RefundCard";
import { useQuery } from "src/queryClient";
import { getUniqueValues } from "src/utils/utils";

type useGetUnpaidStatusType = {
  recoveryAmount?: string;
  recoveryPaymentLink?: string;
  hasUnpaidSubscription: boolean;
  hasMultipleUnpaidSubscriptions: boolean;
  unpaidSubscriptions: ApiUnpaidStatusResponse[];
  isUnpaidSubscriptionsLoading: boolean;
  isFetched: boolean;
};

export function useGetUnpaidStatus(): useGetUnpaidStatusType {
  const { data: contract } = useGetContract();

  const subscriptionIds = useMemo(() => {
    return SANDBOX
      ? // ["sub_fake_1"] // Fake single unpaid subscription for sandbox
        ["sub_fake_1", "sub_fake_2"] // Fake multiple unpaid subscriptions for sandbox
      : getUniqueValues(contract?.map((contract) => contract.contract.stripe_contract_id)) || [];
  }, [contract]);

  function useGetUnpaidSubscriptions() {
    return useQuery(
      ["unpaid"],
      async () => {
        const unpaidStatus = await Promise.all(
          subscriptionIds.map(async (subscriptionId) => {
            return await getUnpaidStatus(subscriptionId);
          })
        );

        const unpaidSubscriptions = unpaidStatus.filter(
          (unpaidStatus) => unpaidStatus.statusCode === 200
        );

        return unpaidSubscriptions;
      },
      {
        enabled: subscriptionIds.length > 0,
      }
    );
  }

  const {
    data: unpaidSubscriptions,
    isLoading: isUnpaidSubscriptionsLoading,
    isFetched,
  } = useGetUnpaidSubscriptions();

  if (!unpaidSubscriptions) {
    return {
      hasUnpaidSubscription: false,
      hasMultipleUnpaidSubscriptions: false,
      unpaidSubscriptions: [] as ApiUnpaidStatusResponse[],
      isUnpaidSubscriptionsLoading: false,
      isFetched,
    };
  }

  const recoveryAmount = unpaidSubscriptions[0]?.body
    ? displayPrice(unpaidSubscriptions[0].body.recovery_amount / 100)
    : undefined;

  const recoveryPaymentLink = unpaidSubscriptions[0]?.body?.recovery_payment_link;

  const hasUnpaidSubscription = unpaidSubscriptions.length > 0;

  const hasMultipleUnpaidSubscriptions = unpaidSubscriptions.length > 1;

  return {
    recoveryAmount,
    recoveryPaymentLink,
    hasUnpaidSubscription,
    hasMultipleUnpaidSubscriptions,
    unpaidSubscriptions,
    isUnpaidSubscriptionsLoading,
    isFetched,
  };
}
