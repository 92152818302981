import { ChangePasswordForm } from "src/components/03_organisms/login/change-password-form";
import { LoginLayout } from "src/components/04_layouts/login-layout";

type ChangePasswordProps = {
  nextPage: string;
};

export function ChangePassword({ nextPage }: ChangePasswordProps) {
  return (
    <LoginLayout>
      <ChangePasswordForm nextPage={nextPage} />
    </LoginLayout>
  );
}
