// This class exist to create a custom type of Error that we can instanceof check
export class AmountError extends Error {
  constructor(message: string) {
    super(message);
  }
}

// REGEX accept : 123 | 123.45 | 123,45
export const amountRegex = /^\d+([,.]\d{1,2})?$/;
/**
 * IMPORTANT: Read this first https://dalma-team.atlassian.net/browse/CLM-247
 * Parse a string for the claim amount that sould be a number with 2 decimals or an integer
 *
 * @param {string} amount
 * @returns {string | undefined} amount in cents
 */
export function parseAmount(amount: string | number): string | undefined {
  const validTypes = ["string", "number"];
  if (!validTypes.includes(typeof amount)) return;

  const strValue = amount.toString();
  const isValidAmount = amountRegex.test(strValue);
  if (!isValidAmount) return;

  const integer = strValue.replace(/[.,]/g, ".");
  const result = Math.round(Number(integer) * 10 * 10); // Convert to cents
  return result.toString();
}
