const CHIP_UUID_PATTERN = /(^[0-9]{15}$)/i;
export function matchValidChip(value: string): boolean {
  return CHIP_UUID_PATTERN.test(value);
}

const DOG_TATOO_UUID_PATTERN =
  /(^([0-9]{3}[a-zA-Z]{3}|[a-zA-Z]{3}[0-9]{3}|2{1}[a-zA-Z]{3}[0-9]{3})$)/i;
function matchValidDogTatoo(value: string): boolean {
  return DOG_TATOO_UUID_PATTERN.test(value);
}

const CAT_TATOO_UUID_PATTERN = /(^([0-9]{3}[a-zA-Z]{3}|[a-zA-Z]{3}[0-9]{3})$)/i;
function matchValidCatTatoo(value: string): boolean {
  return CAT_TATOO_UUID_PATTERN.test(value);
}

export function matchValidPetUuid(value: string): boolean {
  return matchValidChip(value) || matchValidDogTatoo(value) || matchValidCatTatoo(value);
}
