/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";
import { Icon } from "src/components/01_atoms/icon";

interface VisitMotiveItemProps {
  index: string;
  label: string;
  isChecked: boolean | undefined;
  isLastItem: boolean;
  onPress: () => void;
}

export const VisitMotiveItem = ({
  index,
  label,
  isChecked,
  isLastItem,
  onPress,
}: VisitMotiveItemProps) => {
  return (
    <Container key={index} isLastItem={isLastItem} onClick={onPress}>
      <ContainerText>
        <Label variant="paragraph2-Bold" color="textBaseDefault">
          {label}
        </Label>
      </ContainerText>
      <CheckboxContainer>
        <div>
          {isChecked ? (
            <Icon name="filledCheckboxSquare" color="iconPrimaryDefault" size="18" />
          ) : (
            <Icon name="filledEmptyCheckboxSquare" size="18" />
          )}
        </div>
      </CheckboxContainer>
    </Container>
  );
};

const Container = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingVertical: theme.spacing.spacing24px,
  borderBottomColor: theme.colors.borderBaseSubdued,
  padding: "12px 0",
  cursor: "pointer",

  variants: {
    isLastItem: {
      true: {
        borderBottomWidth: 0,
        marginBottom: 0,
      },
      false: {
        borderBottomWidth: 1,
        marginBottom: theme.spacing.spacing12px,
      },
    },
  },
});

const CheckboxContainer = styled("div", {
  marginLeft: "auto",
});

const ContainerText = styled("div", {
  flexDirection: "column",
});

const Label = styled(Text, {
  paddingTop: theme.spacing.spacing8px,
  marginBottom: theme.spacing.spacing12px,
});
