import { assign } from "@xstate/immer";
import { getClaims } from "src/backApi/getClaims";
import { AllClaims, Claim_v2 } from "src/types";
import { createMachine, interpret } from "xstate";
import { toast } from "src/components/01_atoms/toast";
import {
  ClaimsNotification,
  MultipleClaimsNotification,
} from "src/components/02_molecules/claims-notifications/claims-notification";
// import { SANDBOX } from "src/config";
// import { inspect } from "@xstate/inspect";

// if (SANDBOX) {
//   inspect({ iframe: false });
// }

type ClaimsMachineContext = {
  claims: Array<Claim_v2> | undefined;
  hasUnpaidSubscription: boolean;
};

type ClaimsMachineEvents =
  | { type: "GET_CLAIMS" }
  | { type: "REFETCH_CLAIMS" }
  | { type: "RESET_CLAIMS" }
  | { type: "REFETCH_CLAIMS_WITHOUT_DELAY" }
  | { type: "SET_HAS_UNPAID_SUBSCRIPTION"; value: boolean };

type ClaimsMachineServices = {
  getClaims: {
    data: AllClaims;
  };
};

export function filterNeedInfoClaims(claims: Array<Claim_v2>) {
  return claims?.filter((claim) => claim.status === "NEED_INFO");
}

const claimsMachine = createMachine(
  {
    id: "claims",
    initial: "idle",
    predictableActionArguments: true,
    schema: {
      context: {} as ClaimsMachineContext,
      events: {} as ClaimsMachineEvents,
      services: {} as ClaimsMachineServices,
    },
    tsTypes: {} as import("./machine.typegen").Typegen0,
    context: {
      claims: undefined,
      hasUnpaidSubscription: false,
    },
    states: {
      idle: {
        entry: "resetClaims",
        on: {
          GET_CLAIMS: "checkingUnpaidSubscription",
        },
      },
      checkingUnpaidSubscription: {
        on: {
          SET_HAS_UNPAID_SUBSCRIPTION: {
            target: "loading",
            actions: "setHasUnpaidSubscription",
          },
        },
      },
      loading: {
        invoke: {
          src: "getClaims",
          onDone: [
            {
              target: "initialized",
              actions: ["assignClaims", "showSingleNeedInfoToast"],
              cond: "hasSingleNeedInfoClaim",
            },
            {
              target: "initialized",
              actions: ["assignClaims", "showMultipleNeedInfoToast"],
              cond: "hasMultipleNeedInfoClaims",
            },
            {
              target: "initialized",
              actions: "assignClaims",
            },
          ],
          onError: "error",
        },
      },
      initialized: {
        on: {
          REFETCH_CLAIMS: "refetching",
          RESET_CLAIMS: { target: "idle" },
          REFETCH_CLAIMS_WITHOUT_DELAY: "loading",
        },
      },
      refetching: {
        after: {
          3000: "loading",
        },
      },
      error: {
        on: {
          REFETCH_CLAIMS: "refetching",
        },
      },
    },
  },
  {
    services: {
      getClaims,
    },
    guards: {
      hasSingleNeedInfoClaim: (context, event) => {
        if (!event.data?.all_claims_v2 || context.hasUnpaidSubscription) {
          return false;
        }

        return filterNeedInfoClaims(event.data?.all_claims_v2)?.length === 1;
      },
      hasMultipleNeedInfoClaims: (context, event) => {
        if (!event.data?.all_claims_v2 || context.hasUnpaidSubscription) {
          return false;
        }

        return filterNeedInfoClaims(event.data?.all_claims_v2)?.length > 1;
      },
    },
    actions: {
      assignClaims: assign((context, event) => {
        context.claims = event.data?.all_claims_v2;
      }),
      resetClaims: assign((context) => {
        context.claims = undefined;
      }),
      showSingleNeedInfoToast: (_, event) => {
        if (!event.data?.all_claims_v2) {
          return null;
        }
        const claimsNeedingInfo = filterNeedInfoClaims(event.data?.all_claims_v2);
        return toast.warning(
          <ClaimsNotification claim={claimsNeedingInfo?.[0]} />,
          {
            autoClose: 25000000,
          },
          true
        );
      },
      showMultipleNeedInfoToast: (_, event) => {
        return event.data?.all_claims_v2
          ? toast.warning(
              <MultipleClaimsNotification />,
              {
                autoClose: 25000000,
              },
              true
            )
          : null;
      },
      setHasUnpaidSubscription: assign((context, event) => {
        context.hasUnpaidSubscription = event.value;
      }),
    },
  }
);

export const claimsService = interpret(claimsMachine);
claimsService.start();
