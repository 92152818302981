import { AccessibleLink } from "src/components/01_atoms/link";
import { Logo } from "src/components/01_atoms/logo";
import { Outlet } from "src/components/01_atoms/outlet";
import { useRedirectAuthenticatedUser } from "src/hooks/useRedirectAuthenticatedUser";
import { styled } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";

const StyledBackground = styled("div", {
  backgroundImage: "url(/assets/media/background.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0% 0%",
  backgroundSize: "cover",

  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  padding: "2rem 0",

  "@bp1": {
    padding: "4rem 0",
  },
  flex: 1,
});

const StyledLayout = styled("div", {
  width: "100%",
  margin: "0 1.75rem",
  maxWidth: "28.75rem",
});

const LogoWrapper = styled("div", {
  margin: "0 auto",
});

const ChildrenWrapper = styled("div", {
  width: "100%",
});

type LoginLayoutProps = WithChildren;

export function LoginLayout({ children }: LoginLayoutProps) {
  useRedirectAuthenticatedUser();

  return (
    <StyledBackground>
      <StyledLayout>
        <Outlet vertical="spacing64px" justify="center">
          <LogoWrapper>
            <AccessibleLink
              to="/"
              title="Revenir à la page de connexion"
              aria-label="Revenir à la page de connexion"
            >
              <Logo
                size={{
                  "@initial": "xs",
                  "@bp1": "sm",
                }}
                space={false}
                color="fillAccent"
              />
            </AccessibleLink>
          </LogoWrapper>

          <ChildrenWrapper>{children}</ChildrenWrapper>
        </Outlet>
      </StyledLayout>
    </StyledBackground>
  );
}
