/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "@reach/dialog/styles.css";
import { ReactNode } from "react";

import { DialogContent, DialogOverlay } from "@reach/dialog";
import {
  deprecatedTheme,
  deprecatedStyled,
  deprecatedCSS,
  deprecatedKeyframes,
} from "src/styles/stitches/deprecated-theme";
import { VariantProps } from "@stitches/react";
import { motion } from "framer-motion";

import { useIntercom } from "src/use-intercom";

import { Button } from "src/components/button";

const fadeIn = deprecatedKeyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
});

export const buttonWrapper = deprecatedCSS({
  display: "inline-block",
  width: "100%",

  "@bp1": {
    width: "unset",
  },
});

const submitButtonWrapper = deprecatedCSS({
  padding: "16px 16px",
  background: deprecatedTheme.colors.white,

  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.08), 0px 10px 16px rgba(0, 0, 0, 0.16)",
  display: "flex",

  opacity: 0,
  animation: `${fadeIn} 0s 100ms linear forwards`,

  variants: {
    isValid: {
      true: {
        display: "flex",
      },
    },
  },

  "@bp1": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    border: "1px solid #F5F5F5",
  },
});

export function Intercom() {
  const { show } = useIntercom();

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          bottom: "calc(100% + 20px)",
          background: deprecatedTheme.colors.electric as any as string,
          borderRadius: 9999,
          width: 60,
          height: 60,
          right: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fill: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          show();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 32"
          style={{ width: 28, height: 32 }}
        >
          <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path>
        </svg>
      </div>
    </div>
  );
}

function Footer({ children }: { children: ReactNode }) {
  return (
    <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
      <Intercom />
      {children}
    </div>
  );
}

type SubmitButtonProps = {
  children: ReactNode;
  isValid?: boolean;
  isLoading?: boolean;
};

export function SubmitButton({ children, isValid, isLoading }: SubmitButtonProps) {
  return (
    <Footer>
      <div className={submitButtonWrapper({ isValid })}>
        <div className={buttonWrapper()}>
          <Button type="submit" isLoading={isLoading} disabled={!isValid} isBold fullWidth>
            {children}
          </Button>
        </div>
      </div>
    </Footer>
  );
}

const overlay = deprecatedCSS({
  "&[data-reach-dialog-overlay]": {
    position: "fixed",
    inset: 0,
    background: "rgba(0, 0, 0, 0.2)",
    // TODO hack display over all chakra content
    zIndex: 1300,
  },

  variants: {
    transparentOverlay: {
      true: {
        "&[data-reach-dialog-overlay]": {
          background: "transparent",
        },
      },
    },
  },
});

const content = deprecatedCSS({
  "&[data-reach-dialog-content]": {
    background: "white",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,

    "& > div": {
      padding: 24,
      overflowY: "auto",
      height: "100%",
    },
    width: "unset",
    margin: "unset",

    "@bp1": {
      "&[data-reach-dialog-content]": {
        left: "unset",
        width: 605,
        maxWidth: "80%",
      },
    },
    variants: {
      nested: {
        true: {
          "&[data-reach-dialog-content]": {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            top: "unset",
          },
        },
      },
    },
  },
});

const title = deprecatedCSS({
  fontFamily: "Roslindale",
  /* Header/H3 */
  fontSize: 24,
  lineHeight: "28px",

  color: deprecatedTheme.colors.gray900,
  margin: 0,

  variants: {
    size: {
      h4: {
        fontSize: 20,
        lineHeight: "28px",
      },
      h5: {
        fontSize: 18,
        lineHeight: "24px",
      },
    },
  },
});

export function H3({ children, size }: { children: ReactNode } & VariantProps<typeof title>) {
  return <div className={title({ size })}>{children}</div>;
}

export const ModalSeparator = deprecatedStyled("hr", {
  margin: "32px -24px",
  border: "none",
  borderTop: `1px solid ${deprecatedTheme.colors.gray300}`,
});

function ModalCloseButton({ onDismiss }: Pick<Props, "onDismiss">) {
  return (
    <button
      onClick={onDismiss}
      style={{
        flex: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        width: 44,
        height: 44,
        cursor: "pointer",

        /* Blue/Bright */
        background: "#DBEBFF",
        borderRadius: 24,
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Fermer"
      >
        <path
          d="M11.175 10.0002L16.425 4.75849C16.5819 4.60157 16.6701 4.38874 16.6701 4.16682C16.6701 3.9449 16.5819 3.73207 16.425 3.57515C16.2681 3.41823 16.0552 3.33008 15.8333 3.33008C15.6114 3.33008 15.3986 3.41823 15.2417 3.57515L9.99999 8.82515L4.75832 3.57515C4.6014 3.41823 4.38857 3.33008 4.16666 3.33008C3.94474 3.33008 3.73191 3.41823 3.57499 3.57515C3.41807 3.73207 3.32991 3.9449 3.32991 4.16682C3.32991 4.38874 3.41807 4.60157 3.57499 4.75849L8.82499 10.0002L3.57499 15.2418C3.49688 15.3193 3.43489 15.4115 3.39258 15.513C3.35027 15.6146 3.32849 15.7235 3.32849 15.8335C3.32849 15.9435 3.35027 16.0524 3.39258 16.154C3.43489 16.2555 3.49688 16.3477 3.57499 16.4252C3.65246 16.5033 3.74463 16.5653 3.84618 16.6076C3.94773 16.6499 4.05665 16.6717 4.16666 16.6717C4.27667 16.6717 4.38559 16.6499 4.48714 16.6076C4.58869 16.5653 4.68085 16.5033 4.75832 16.4252L9.99999 11.1752L15.2417 16.4252C15.3191 16.5033 15.4113 16.5653 15.5128 16.6076C15.6144 16.6499 15.7233 16.6717 15.8333 16.6717C15.9433 16.6717 16.0523 16.6499 16.1538 16.6076C16.2554 16.5653 16.3475 16.5033 16.425 16.4252C16.5031 16.3477 16.5651 16.2555 16.6074 16.154C16.6497 16.0524 16.6715 15.9435 16.6715 15.8335C16.6715 15.7235 16.6497 15.6146 16.6074 15.513C16.5651 15.4115 16.5031 15.3193 16.425 15.2418L11.175 10.0002Z"
          fill="#144283"
        />
      </svg>
    </button>
  );
}

type Props = {
  children: ReactNode;
  onDismiss: () => void;
  nested?: boolean;
  title?: string;
  formattedTitle?: ReactNode;
  subtitle?: string;
  transparentOverlay?: boolean;
};

const st = deprecatedCSS({
  fontSize: 14,
  lineHeight: "16px",

  letterSpacing: "-0.02em",
  marginTop: 4,

  color: deprecatedTheme.colors.gray600,
});

// TODO, convert this to usePresence + CSS transitions to improve bundle size
const Content = motion(DialogContent);

export const ModalFullBleed = deprecatedStyled("div", {
  margin: "0 -24px",
});

export function Modal({
  children,
  onDismiss,
  nested,
  title,
  formattedTitle = title,
  subtitle,
}: Props) {
  return (
    // We disable Focus Lock to allow intercom to work even when modals are open
    <DialogOverlay
      title={title}
      className={overlay()}
      onDismiss={onDismiss}
      dangerouslyBypassFocusLock
    >
      {/* @ts-ignore */}
      <Content
        aria-label={title}
        className={content({ nested })}
        initial={{ transform: "translateX(100%)" }}
        animate={{ transform: "translateX(0%)" }}
        exit={{ transform: "translateX(100%)" }}
        transition={{ ease: "easeOut" }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
              <H3>{formattedTitle}</H3>
              {subtitle ? <span className={st()}>{subtitle}</span> : null}
            </div>
            <ModalCloseButton onDismiss={onDismiss} />
          </div>
          {children}
        </div>
      </Content>
    </DialogOverlay>
  );
}
