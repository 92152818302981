import { useActor } from "@xstate/react";
import { useState } from "react";
import {
  FieldErrorsImpl,
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { Button } from "src/components/01_atoms/button";
import { Checkbox } from "src/components/01_atoms/checkbox";
import { Icon } from "src/components/01_atoms/icon";
import { InlineHint } from "src/components/01_atoms/inline-hint";
import { Input } from "src/components/01_atoms/input";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { matchIsAllContractsDeffered } from "src/helpers";
import { useGetContract } from "src/hooks/data";
import { onboardingStepsService } from "src/store/onboarding/machine";
import { ReferrerType } from "src/types";
import { OnboardingForm } from "./form";

type Props = {
  setVetClinicPhoneNumber: (vetClinicPhoneNumber: string) => void;
  register: UseFormRegister<OnboardingForm>;
  watch: UseFormWatch<OnboardingForm>;
  errors: FieldErrorsImpl<{
    referrer_type: NonNullable<ReferrerType>[];
    vet_phone_number: string;
  }>;
  handleSubmit: UseFormHandleSubmit<OnboardingForm>;
  onSubmit: SubmitHandler<FieldValues>;
};

export function VetClinic({
  setVetClinicPhoneNumber,
  register,
  watch,
  errors,
  handleSubmit,
  onSubmit,
}: Props) {
  const [, send] = useActor(onboardingStepsService);

  const [noVetClinicIsChecked, setNoVetClinicIsChecked] = useState(false);
  const { data: contract } = useGetContract();

  return (
    <>
      <SidesheetContentHeader
        title="Dans quelle clinique vétérinaire avez-vous l’habitude de vous rendre ?"
        description="Afin de faciliter nos échanges avec votre clinique vétérinaire, remplissez simplement son numéro de téléphone. Nous nous occupons de la trouver !"
        alertIconName="filledInfo"
        alertText="Pas d’inquiétude, Dalma vous rembourse dans toutes les cliniques du monde !"
      />
      <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
        <Outlet vertical="spacing32px" fluid>
          <Outlet vertical="spacing8px" fluid>
            <Input
              placeholder="N° de téléphone de la clinique"
              critical={!!errors.vet_phone_number && !noVetClinicIsChecked}
              {...register("vet_phone_number")}
              disabled={noVetClinicIsChecked}
              value={noVetClinicIsChecked ? "" : watch("vet_phone_number")}
            />
            {errors.vet_phone_number && !noVetClinicIsChecked ? (
              <InlineHint color="textCritical" leftIcon={<Icon name="filledClose" />}>
                Le format n’est pas correct
              </InlineHint>
            ) : null}
          </Outlet>
          <Checkbox
            id="vet_phone_number"
            onChange={(event) => setNoVetClinicIsChecked(event.target.checked)}
          >
            <Text variant="paragraph2-Medium" color="textBaseDefault">
              Je n’ai pas encore de clinique vétérinaire
            </Text>
          </Checkbox>
        </Outlet>
        <Button
          type="submit"
          variant="accent"
          size={{ "@initial": "md", "@bp3": "lg" }}
          fluid
          disabled={watch("vet_phone_number") === "" && !noVetClinicIsChecked}
          onClick={() => {
            noVetClinicIsChecked
              ? setVetClinicPhoneNumber("")
              : setVetClinicPhoneNumber(watch("vet_phone_number"));
            handleSubmit(onSubmit)();
            if (matchIsAllContractsDeffered(contract || [])) {
              send("NEXT_STEP");
            }
          }}
        >
          Continuer
        </Button>
      </Outlet>
    </>
  );
}
