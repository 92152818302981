import { useState, useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import Select from "react-select";
import { styled, theme } from "src/styles/stitches/theme";
import { dayOptions, monthOptions, selectStyles, yearOptions } from "./utils";
import { Text } from "src/components/01_atoms/text";
import { getDate, getMonth, getYear, isValid, set } from "date-fns";

interface DateInputProps {
  setValue: UseFormSetValue<any>;
  fieldName: string;
  selectedDate: Date | undefined;
}

export const CausesDateInput = ({ setValue, fieldName, selectedDate }: DateInputProps) => {
  const [day, setDay] = useState(selectedDate ? getDate(selectedDate) : null);
  const [month, setMonth] = useState(selectedDate ? getMonth(selectedDate) + 1 : null);
  const [year, setYear] = useState(selectedDate ? getYear(selectedDate) : null);

  const currentDayOption = dayOptions.find((option) => option.value === day);
  const currentMonthOption = monthOptions.find((option) => String(option.value) === String(month));
  const currentYearOption = yearOptions.find((option) => option.value === year);

  useEffect(() => {
    if (day && month && year) {
      const date = set(new Date(), { year, month: month - 1, date: day });
      if (isValid(date)) {
        const timestamp = date.getTime();
        setValue(fieldName, timestamp, { shouldValidate: true });
      }
    }
  }, [day, month, year, setValue, fieldName]);

  return (
    <>
      <Label variant="caption1-Medium" color="textBaseSubdued">
        {fieldName === "accident_date"
          ? "Date de l'accident"
          : "Date d'apparition des premiers symptômes"}
      </Label>
      <Container>
        <ContainerSelect>
          <Select
            components={{ IndicatorSeparator: () => null }}
            options={dayOptions}
            onChange={(selectedOption) => setDay(selectedOption?.value ?? null)}
            placeholder="Jour"
            styles={selectStyles}
            value={currentDayOption}
          />
        </ContainerSelect>
        <ContainerSelect>
          <Select
            components={{ IndicatorSeparator: () => null }}
            options={monthOptions}
            onChange={(selectedOption) => setMonth(selectedOption?.value ?? null)}
            placeholder="Mois"
            styles={selectStyles}
            value={currentMonthOption}
          />
        </ContainerSelect>
        <ContainerSelect>
          <Select
            components={{ IndicatorSeparator: () => null }}
            options={yearOptions}
            onChange={(selectedOption) => setYear(selectedOption?.value ?? null)}
            placeholder="Année"
            styles={selectStyles}
            value={currentYearOption}
          />
        </ContainerSelect>
      </Container>
    </>
  );
};

const Container = styled("div", {
  display: "flex",
  width: "100%",
  gap: "10px",
  justifyContent: "space-between",
  marginBottom: theme.spacing.spacing32px,
});

const ContainerSelect = styled("div", {
  flex: 1,
});

const Label = styled(Text, {
  marginBottom: theme.spacing.spacing8px,
  marginTop: theme.spacing.spacing12px,

  "@bp3": {
    marginTop: "0px !important",
  },
});
