import { Button } from "src/components/01_atoms/button";
import { Text } from "src/components/01_atoms/text";
import { useRafOperation } from "src/hooks/use-raf-operation";
import { useDevice } from "src/hooks/useDevice";
import { styled, theme } from "src/styles/stitches/theme";

const Container = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  padding: "1rem 1.5rem",
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 0%",
  backgroundSize: "cover",
  borderRadius: "1rem",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: theme.elevation.elevation2Primary,

  "@bp3": {
    flexDirection: "row",
  },
});

const WrapperText = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "22.25rem",
  alignItems: "center",

  "@bp3": {
    alignItems: "start",
  },
});

const ContainerTitle = styled("div", {
  margin: "1rem 0",
  span: {
    margin: 0,
    padding: 0,
    fontSize: theme.fontSizes.textDisplay3,
    lineHeight: 1.153,
    fontWeight: 700,
    letterSpacing: theme.letterSpacings.regular,
    color: "white",
  },
  ".stroked": {
    textDecoration: "line-through",
    opacity: 0.3,
  },
});

const Description = styled(Text, {
  textAlign: "center",
  marginBottom: "1rem",

  "@bp3": {
    marginBottom: "0px",
    textAlign: "start",
  },
});

type Props = {
  setIsRAFModalOpen?: (isOpen: boolean) => void;
  isFromModal?: boolean;
};

const MediaCardSponsorship = ({ setIsRAFModalOpen, isFromModal }: Props) => {
  const { isMobile } = useDevice();
  const { isOperation200Enabled } = useRafOperation();

  let backgroundImage = "";
  if (isMobile()) {
    backgroundImage = isOperation200Enabled
      ? "url(/assets/media/mobile-card-raf-wheel.png)"
      : "url(/assets/media/background-sponsorship-media-card-mobile.png)";
  } else {
    backgroundImage = isOperation200Enabled
      ? "url(/assets/media/desktop-card-raf-wheel.png)"
      : "url(/assets/media/background-sponsorship-media-card.png)";
  }

  return (
    <Container
      style={{
        backgroundImage: backgroundImage,
      }}
    >
      <WrapperText>
        {isOperation200Enabled ? (
          <ContainerTitle
            style={{
              textAlign: isMobile() ? "center" : "inherit",
              paddingTop: isMobile() ? "100px" : 0,
            }}
          >
            <span>Parrainez vos proches, recevez jusqu’à 200€ !</span>
          </ContainerTitle>
        ) : (
          <>
            <ContainerTitle style={{ textAlign: isMobile() ? "center" : "inherit" }}>
              <span>Recevez </span>
              <span className="stroked">25€ </span>
              <span>50€</span>
              {isFromModal ? (
                <>
                  <br />
                  <span>pour chaque parrainage !</span>
                </>
              ) : null}
            </ContainerTitle>
            <Description
              variant={isMobile() ? "paragraph3-Medium" : "paragraph2-Medium"}
              color="textSecondaryDefault"
            >
              {isFromModal
                ? "50€ pour vous, 1 mois offert pour vos proches"
                : "Parrainez vos proches : offrez leur leur 1er mois d’abonnement et recevez 50€."}
            </Description>
          </>
        )}
      </WrapperText>
      {isFromModal ? null : (
        <Button
          type="button"
          variant="secondary"
          size="sm"
          aria-label="Inviter mes proches"
          style={{ alignSelf: "center" }}
          fluid={isMobile() ? true : false}
          onClick={() => setIsRAFModalOpen?.(true)}
        >
          Inviter mes proches
        </Button>
      )}
    </Container>
  );
};

export { MediaCardSponsorship };
