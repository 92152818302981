import { Icon } from "src/components/01_atoms/icon";
import { InlineHint } from "src/components/01_atoms/inline-hint";

type PasswordHintProps = {
  label: string;
  state?: "valid" | "invalid" | "idle";
};

export function PasswordHint({ label, state = "idle" }: PasswordHintProps) {
  return state === "idle" ? (
    <InlineHint color="textBaseSubdued" leftIcon={<Icon name="filledClose" />}>
      {label}
    </InlineHint>
  ) : state === "invalid" ? (
    <InlineHint color="textCritical" leftIcon={<Icon name="filledClose" />}>
      {label}
    </InlineHint>
  ) : state === "valid" ? (
    <InlineHint color="textPrimaryDefault" leftIcon={<Icon name="filledCheckCircle" />}>
      {label}
    </InlineHint>
  ) : null;
}
