import { Center, Divider, Flex } from "@chakra-ui/react";
import Times from "@iconscout/react-unicons/icons/uil-times";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

import { FilesButton } from "src/components/buttons/FilesButton";

import { SmallInformationsCard } from "src/components/cards/SmallInformationsCard";
import { Title } from "src/components/texts/Title";

import { Banner } from "src/components/Banner";
import { AccountPriceCard } from "src/components/cards/AccountPriceCard";
import { ChevronIcon } from "src/components/Icons/ChevronIcon";
import { ProfileMenu } from "src/components/menus/ProfileMenu";
import { Modal } from "src/components/modal";
import { Texts } from "src/components/texts/Texts";
import { DocumentsRelatedToContract } from "./DocumentsRelatedToContract";

import { Auth } from "aws-amplify";
import { sub } from "date-fns";
import { useHistory } from "react-router-dom";
import { _deprecated_fetchContracts } from "src/backApi/fetchContracts";
import { FileIcon } from "src/components/Icons/FileIcon";
import { LEGAL_NOTICES, PRIVACY_POLICY } from "src/configs/Legal";
import { deprecatedTheme } from "src/styles/stitches/deprecated-theme";
import { Contract } from "src/types";
import { useIntercom } from "src/use-intercom";
import { getFormattedDateWithYear } from "src/utils/format";
import { getLegalVersion } from "src/utils/utils";

//TODO: to be used with future data renewal_status
function showBanner(endDate: string) {
  const _endDate = new Date(endDate);
  const now = new Date();

  const limit = sub(_endDate, { days: 45 });

  return now >= limit && now < _endDate;
}

export const Contracts = () => {
  const history = useHistory();
  const { show } = useIntercom();
  const [contracts, setContracts] = useState<Contract[] | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selected_legal_version, setSelected_legal_version] = useState("");

  const { ficLink, ipidLink, niName, FALLBACK_VERSION } = getLegalVersion(contracts);

  const openModal = (legalVersion: string) => {
    setSelected_legal_version(legalVersion);
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentAuthenticatedUser();
        _deprecated_fetchContracts(setContracts);
      } catch (e) {
        // TODO sentry
      }
    }
    onLoad();
  }, [history]);

  const onIntercomOpen = () => {
    show();
  };

  return (
    contracts && (
      <Center minH="100vh" mt={["50px", "50px", 0]} p={["0", "0", "100px", "100px", "156px"]}>
        <Center
          cursor="pointer"
          onClick={() => history.replace("/")}
          bg="#DBEBFF"
          w="44px"
          h="44px"
          position="absolute"
          right="5vw"
          top="5vh"
          rounded="full"
        >
          <Times color="#4E68F6" size="25px" />
        </Center>

        <Flex w={["90%", "90%", "100%"]}>
          <ProfileMenu profileState="contracts" />

          <Flex flex={1} maxW="90vw" direction="column">
            {contracts.map((item, petIdx) => (
              <Flex mb="55px" direction="column" key={petIdx}>
                <Flex mb="17px">
                  <Title size="24px">
                    Votre contrat pour {contracts[petIdx] && contracts[petIdx].pet.pet_name}{" "}
                  </Title>
                </Flex>
                {showBanner(item.contract.end_date) ? (
                  <Banner>
                    Le contrat de {item.pet.pet_name} sera{" "}
                    <b>renouvelé le {item.contract.end_date.split("T")[0]}.</b> Un email contenant
                    les modalités de renouvellement vous a été envoyé.
                  </Banner>
                ) : null}

                <Flex maxW="800px" direction={["column", "column", "column", "row"]}>
                  <AccountPriceCard
                    price={
                      contracts?.[petIdx].contract.after_discount
                        ? Number(contracts?.[petIdx].contract.after_discount)
                        : contracts?.[petIdx].contract.price
                        ? Number(contracts?.[petIdx].contract.price)
                        : contracts?.[petIdx].contract.amount_total
                    }
                    formulaName={
                      contracts &&
                      contracts[petIdx] &&
                      contracts[petIdx].contract.formula_name === "formula_dalma"
                        ? "Dalma"
                        : contracts[petIdx].options.pack === "accident"
                        ? "Essentielle"
                        : "Intégrale"
                    }
                  />

                  <Flex
                    ml={[0, 0, 0, "16px"]}
                    mt={["16px", "16px", "16px", 0]}
                    flex={1}
                    p={["20px", "30px"]}
                    bg="#F5F5F5"
                    maxH={["300px", "130px"]}
                    minH="130px"
                    w="100%"
                    align="center"
                    justify="center"
                    rounded="8px"
                  >
                    <Flex justify="center" w="100%" direction={["column", "row", "row"]}>
                      <Flex direction={"row"}>
                        <SmallInformationsCard
                          title={"N° de contrat"}
                          value={
                            contracts &&
                            contracts[petIdx] &&
                            (contracts[petIdx].contract.contract_uuid ||
                              contracts[petIdx].contract.contract_id)
                          }
                        />
                        <SmallInformationsCard
                          mr="0"
                          title={"Prise d'effet le"}
                          value={
                            contracts &&
                            contracts[petIdx] &&
                            getFormattedDateWithYear(
                              contracts[petIdx].contract.start_date.split("T")[0]
                            )
                          }
                        />{" "}
                      </Flex>

                      <Flex mt={["5px", 0]}>
                        <SmallInformationsCard
                          mr="0"
                          title={"Couvert jusqu’au "}
                          value={
                            contracts &&
                            contracts[petIdx] &&
                            getFormattedDateWithYear(
                              contracts[petIdx].contract.end_date.split("T")[0]
                            )
                          }
                          tooltip=" À cette date, votre contrat sera automatiquement renouvelé, à moins que
                          vous ayez formulé une demande de résiliation."
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex direction="column" mt="24px" maxW="800px">
                  <Flex mb="14px">
                    <Texts color="#8F8F8F">Télécharger votre contrat</Texts>
                  </Flex>

                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={
                      contracts && contracts[petIdx] && contracts[petIdx].contract.adhesion_contract
                    }
                  >
                    <Flex>
                      <FilesButton
                        maxW="456px"
                        downloadable
                        title={
                          contracts &&
                          contracts[petIdx] &&
                          "Contrat de " + contracts[petIdx].pet.pet_name
                        }
                        description="PDF"
                        icon={<FileIcon color={deprecatedTheme.colors.electric.value} />}
                        iconBackground="#DBEBFF"
                      />
                    </Flex>
                  </a>
                </Flex>
                <Flex align="center">
                  <Texts
                    fontSize="16px"
                    color="#4E68F6"
                    cursor="pointer"
                    onClick={() => openModal(item.legal_version || FALLBACK_VERSION)}
                  >
                    Documents associés à mon contrat
                  </Texts>
                  <ChevronIcon stroke="#4E68F6" color="none" />
                </Flex>
              </Flex>
            ))}
            <AnimatePresence>
              {modalIsOpen && (
                <Modal
                  title={"Documents associés à votre contrat"}
                  formattedTitle={
                    <div>
                      <span style={{ color: "#4E68F6" }}>Documents associés</span> à votre contrat
                    </div>
                  }
                  onDismiss={() => setModalIsOpen(false)}
                >
                  <DocumentsRelatedToContract legal_version={selected_legal_version} />
                </Modal>
              )}
            </AnimatePresence>
            <Flex direction="column">
              <Flex mb="10px">
                <Title size="24px">Résiliation</Title>
              </Flex>

              <Flex maxW="552px">
                <Texts color="#9E9E9E">
                  Vous pouvez résilier un contrat à tout moment, et sans motif un an après votre
                  date de souscription.
                </Texts>
              </Flex>
              <Flex align="center">
                <Texts
                  fontSize="16px"
                  color="#D6444C"
                  cursor="pointer"
                  onClick={onIntercomOpen}
                  data-intercom-vet="contracts"
                >
                  Résilier mon contrat / Me rétracter
                </Texts>
                <ChevronIcon stroke="#D6444C" color="none" />
              </Flex>
            </Flex>

            <Divider mt={["32px", "53px"]} mb={["32px", "51px"]} />

            <Flex direction={["column"]} w="100%">
              <Flex mb="32px">
                <Title size="24px">Autres documents</Title>
              </Flex>

              <Flex direction={["column", "column", "row"]}>
                <a rel="noopener noreferrer" target="_blank" href={ipidLink} style={{ flex: 1 }}>
                  <Flex flex={1} mr={[0, 0, "32px"]}>
                    <FilesButton
                      downloadable
                      title="Fiche IPID"
                      description="PDF (174ko)"
                      icon={<FileIcon color={deprecatedTheme.colors.electric.value} />}
                      iconBackground="#DBEBFF"
                    />
                  </Flex>
                </a>
                <a rel="noopener noreferrer" target="_blank" href={ficLink} style={{ flex: 1 }}>
                  <Flex flex={1}>
                    <FilesButton
                      downloadable
                      title={niName}
                      description="PDF (244ko)"
                      icon={<FileIcon color={deprecatedTheme.colors.electric.value} />}
                      iconBackground="#DBEBFF"
                    />
                  </Flex>
                </a>
              </Flex>
              <Flex direction={["column", "column", "row"]}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={LEGAL_NOTICES}
                  style={{ flex: 1 }}
                >
                  <Flex flex={1} mr={[0, 0, "32px"]}>
                    <FilesButton
                      downloadable
                      title="Mentions légales"
                      description="PDF (51,7ko)"
                      icon={<FileIcon color={deprecatedTheme.colors.electric.value} />}
                      iconBackground="#DBEBFF"
                    />
                  </Flex>
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={PRIVACY_POLICY}
                  style={{ flex: 1 }}
                >
                  <Flex flex={1}>
                    <FilesButton
                      downloadable
                      title="Politique de confidentialité"
                      description="PDF (119ko)"
                      icon={<FileIcon color={deprecatedTheme.colors.electric.value} />}
                      iconBackground="#DBEBFF"
                    />
                  </Flex>
                </a>
              </Flex>
            </Flex>

            <Divider mt={["32px", "53px"]} mb={["32px", "51px"]} />
          </Flex>
        </Flex>
      </Center>
    )
  );
};
