import { Outlet } from "src/components/01_atoms/outlet";
import { StepperItem } from "src/components/01_atoms/stepper-item";

type Props = {
  steps: string[];
  currentStep: string;
};

export function Stepper({ steps, currentStep }: Props) {
  return (
    <Outlet horizontal="spacing8px">
      {steps.map((step, index) => (
        <StepperItem key={index} active={step === currentStep} />
      ))}
    </Outlet>
  );
}
