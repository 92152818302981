import { AnchorButton } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { useGetContract } from "src/hooks/data";
import { externalRoutes } from "src/routes";
import { styled } from "src/styles/stitches/theme";
import { PetItem } from "./_pet-item";

const StyledAnchorButton = styled(AnchorButton, {
  alignSelf: "flex-start",
});

export function PetList() {
  const { data, isLoading, isError } = useGetContract();
  const goneWrong = (!isLoading && !data) || isError;

  return (
    <Outlet vertical="spacing16px" fluid align="stretch">
      {goneWrong
        ? "Vous n'avez pas de contrats en cours..."
        : data?.map((pet) => (
            <PetItem
              key={pet.contract.contract_id}
              type={pet.pet.pet_type}
              name={pet.pet.pet_name}
            />
          ))}
      <StyledAnchorButton
        variant="smallActionAccent"
        size="sm"
        iconLeft="outlinedPlus"
        href={externalRoutes.subscriptionFlow}
        target="_blank"
        rel="noopener noreferrer"
      >
        Ajouter un animal
      </StyledAnchorButton>
    </Outlet>
  );
}
