import { Outlet } from "src/components/01_atoms/outlet";
import { styled, theme } from "src/styles/stitches/theme";

export const StyledForm = styled("form", {
  flex: 1,
});

export const StyledOutlet = styled(Outlet, {
  padding: theme.spacing.spacing32px,
});
