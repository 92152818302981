import React, { useEffect, useState } from "react";
import { deprecatedStyled } from "src/styles/stitches/deprecated-theme";
import { usePopper } from "react-popper";
import { theme } from "src/styles/stitches/theme";

const TooltipRef = deprecatedStyled("div", {
  position: "relative",
  display: "inline-block",
  outline: "none",
});

const TooltipContent = deprecatedStyled("div", {
  "--duration": "0.5s",
  position: "relative",
  padding: 16,
  color: "white",
  background: theme.colors.backgroundNeutral,
  borderRadius: 16,
  transition: "opacity 0.5s ease, visibility 0s 0.5s linear",
  opacity: 0,
  visibility: "hidden",

  "[data-show] &": {
    opacity: 1,
    visibility: "visible",
    transition: "opacity 0.5s ease, visibility 0s",
  },

  width: 250,
  maxWidth: "90vw",
});

const Arrow = deprecatedStyled("div", {
  transition: "none",
  "&, &::before": { position: "absolute", width: 8, height: 8, background: "inherit" },

  "&": {
    visibility: "hidden",
  },

  "&::before": {
    visibility: "visible",
    content: "",
    transform: "rotate(45deg)",
  },

  "[data-popper-placement^='top']  &": {
    bottom: -4,
  },

  "[data-popper-placement^='bottom']  &": {
    top: -4,
  },

  "[data-popper-placement^='right']  &": {
    left: -4,
  },
  "[data-popper-placement^='left']  &": {
    right: -4,
  },
});

type DivNull = HTMLDivElement | null;

type Props = {
  className?: string;
  children: React.ReactNode;
  message: React.ReactNode;
  size?: number;
  enabled?: boolean;
};

export function Tooltip({ className, children, message, enabled }: Props) {
  const [referenceElement, setReferenceElement] = useState<DivNull>(null);
  const [popperElement, setPopperElement] = useState<DivNull>(null);
  const [arrowElement, setArrowElement] = useState<DivNull>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "preventOverflow",
        options: {
          padding: 8,
        },
      },
    ],
  });
  useEffect(() => {
    if (!enabled) return;

    const tooltip = popperElement;
    const button = referenceElement;

    function show() {
      tooltip?.setAttribute("data-show", "");

      // We need to tell Popper to update the tooltip position
      // after we show the tooltip, otherwise it will be incorrect
      update?.();
    }

    function hide() {
      tooltip?.removeAttribute("data-show");
    }

    const showEvents = ["mouseenter", "focus"];
    const hideEvents = ["mouseleave", "blur"];

    if (button) {
      showEvents.forEach((event) => {
        button.addEventListener(event, show);
      });

      hideEvents.forEach((event) => {
        button.addEventListener(event, hide);
      });

      return () => {
        showEvents.forEach((event) => {
          button.removeEventListener(event, show);
        });
        hideEvents.forEach((event) => {
          button.removeEventListener(event, hide);
        });
      };
    }
  });
  return (
    <TooltipRef className={className} ref={setReferenceElement} tabIndex={-1}>
      {children}
      <div
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: 1, position: "absolute", height: 0 }}
        {...attributes.popper}
      >
        <TooltipContent>
          <Background>
            <Arrow data-arrow ref={setArrowElement} style={styles.arrow} />
          </Background>
          {message}
        </TooltipContent>
      </div>
    </TooltipRef>
  );
}

const Background = deprecatedStyled("div", {
  position: "absolute",
  inset: 0,
  background: "rgb(0,0,0)",
  opacity: 0.7,
  zIndex: -1,
  borderRadius: "inherit",
});
