import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";
import { useIntercom } from "src/use-intercom";

const Container = styled(Outlet, {
  flex: 1,
});

const Content = styled("button", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  borderBottom: `1px solid ${theme.colors.borderBaseSubdued}`,
  paddingBottom: theme.spacing.spacing16px,
});

const StyledOutlet = styled(Outlet, {
  marginLeft: theme.spacing.spacing16px,
  marginRight: "auto",
});

export function HelpSection() {
  const { show } = useIntercom();

  return (
    <Container vertical="spacing32px" fluid>
      <Text variant="display5-Bold" color="textBaseDefault">
        On vous aide
      </Text>
      <Content onClick={show}>
        <Icon name="filledQuestion" color="iconBaseSubdued" />
        <StyledOutlet vertical="spacing4px">
          <Text variant="paragraph2-Bold" color="textBaseDefault">
            Une question ?
          </Text>
          <Text variant="paragraph2-Medium" color="textBaseSubdued">
            Contacter le service client
          </Text>
        </StyledOutlet>
        <Icon name="chevronRight" color="iconBaseSubdued" />
      </Content>
    </Container>
  );
}
