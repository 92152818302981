import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "src/components/01_atoms/button";
import { Input } from "src/components/01_atoms/input";
import { Outlet } from "src/components/01_atoms/outlet";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { z } from "zod";
import { StyledForm, StyledIbanInputLabel, StyledOutlet } from "./iban.styled";
import { postIban } from "src/backApi/updateIban";
import { useActor } from "@xstate/react";
import { onboardingStepsService } from "src/store/onboarding/machine";
import { useEffect, useState } from "react";
import { toast } from "src/components/01_atoms/toast";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { IBAN_REGEX } from "src/utils/regexp";

const ibanSchema = z.object({
  iban: z.string().regex(IBAN_REGEX),
});

type Iban = {
  iban: string;
};

type Props = {
  canSkip?: boolean;
  isFromOnboarding?: boolean;
  setIsIbanSidebarOpen?: (isIbanSidebarOpen: boolean) => void;
  onClickButton1?: () => void;
};

export function Iban({
  canSkip = true,
  isFromOnboarding = false,
  setIsIbanSidebarOpen,
  onClickButton1,
}: Props) {
  const [iban, setIban] = useState("");

  const { openRefundSidebar } = useRefundSidebar();

  const [, send] = useActor(onboardingStepsService);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    watch,
    setValue,
  } = useForm<Iban>({
    resolver: zodResolver(ibanSchema),
    defaultValues: {
      iban: "",
    },
  });

  useEffect(() => {
    setFocus("iban");
  }, [setFocus]);

  useEffect(() => {
    setValue("iban", iban);
  }, [iban, setValue]);

  useEffect(() => {
    if (errors.iban) {
      toast.error("Le format de l'IBAN est incorrect");
    }
  }, [errors.iban]);

  const onSubmit: SubmitHandler<Iban> = (values) => {
    if (canSkip) {
      postIban(values.iban.replaceAll(" ", ""), () => send("NEXT_STEP"));
      return;
    }

    postIban(values.iban.replaceAll(" ", ""), () => {
      if (!isFromOnboarding) {
        openRefundSidebar();

        if (setIsIbanSidebarOpen) {
          setIsIbanSidebarOpen(false);
        }
      } else {
        if (onClickButton1) {
          onClickButton1();
        }
      }
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledOutlet vertical={{ "@initial": "spacing32px", "@bp3": "0" }} fullHeight>
        <SidesheetContentHeader
          title="Renseignez vos informations bancaires"
          description="Afin de pouvoir vous rembourser, merci de renseigner votre IBAN. Ce compte bancaire servira également de mode de paiement sur vos prochains prélèvements"
        />
        <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
          <Outlet vertical="spacing8px" fluid>
            <StyledIbanInputLabel variant="caption1-Medium" color="textBaseSubdued">
              IBAN
            </StyledIbanInputLabel>
            <Input
              placeholder="FR7500000000000000000000000"
              {...register("iban")}
              value={watch("iban")}
              onChange={(event) => setIban(event.target.value)}
              critical={!!errors.iban}
            />
          </Outlet>
          <Outlet vertical="spacing16px" fluid>
            <Button
              type="submit"
              variant="accent"
              size={{ "@initial": "md", "@bp3": "lg" }}
              fluid
              disabled={iban.length < 1}
            >
              Continuer
            </Button>
            {canSkip ? (
              <Button
                type="button"
                variant="secondary"
                size={{ "@initial": "md", "@bp3": "lg" }}
                fluid
                onClick={() => send("NEXT_STEP")}
              >
                Passer cette étape
              </Button>
            ) : null}
          </Outlet>
        </Outlet>
      </StyledOutlet>
    </StyledForm>
  );
}
