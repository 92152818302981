import { MouseEvent } from "react";
import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

const Wrapper = styled("button", {
  width: "100%",
  padding: `${theme.spacing.spacing20px} ${theme.spacing.spacing24px}`,
  backgroundColor: theme.colors.fillSecondary,
  transition: "background-color 0.15s ease-out",
  borderBottomRightRadius: theme.radii["24"],
  borderBottomLeftRadius: theme.radii["24"],

  "@withHover": {
    "&:hover": {
      backgroundColor: theme.colors.fillSecondaryPressed,
    },
  },
  "&:active": {
    backgroundColor: theme.colors.fillSecondaryPressed,
  },
});

type AlertProps = { text: string; onAlertClick?: (event?: MouseEvent<HTMLButtonElement>) => void };

export function Alert({ text, onAlertClick }: AlertProps) {
  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
        onAlertClick?.(e);
      }}
      // Make sure the button is not focusable inside the card
      tabIndex={-1}
      type="button"
    >
      <Outlet horizontal="spacing8px" fluid align="center" justify="spaceBetween">
        <Outlet horizontal="spacing8px" align="center">
          <Icon name="filledInfo" color="iconWarning" />
          <Text variant="paragraph3-Bold" color="textBaseDefault">
            {text}
          </Text>
        </Outlet>
        <Icon name="chevronRight" color="iconBaseSubdued" />
      </Outlet>
    </Wrapper>
  );
}
