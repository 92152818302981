type Props = {
  color?: string;
  stroke?: string;
};

export const ChevronIcon = ({ color = "currentColor", stroke = "#4E68F6" }: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0, marginLeft: 4 }}
    >
      <path
        d="M4 2L8 6L4 10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
