import { styled } from "src/styles/stitches/theme";
import { useBanner } from "src/hooks/use-banner";
import { setBannerClosedLocalStorage } from "src/utils/local-storage";

// We use px instead of rem here so that the Banner component is reusable in all projects (since the equivalence px/rem is different in each project)
const Container = styled("div", {
  position: "fixed",
  zIndex: 1,
  width: "100%",
  display: "none",

  "@bp3": {
    display: "flex",
  },
});

const BannerContent = styled("div", {
  background: "#101531",
  padding: "16px 32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 16,
});

const Button = styled("button", {
  width: "100%",
});

const A = styled("a", {
  width: "100%",
});

const Img = styled("img", {
  width: 32,
  height: 32,
});

const Text = styled("p", {
  margin: 0,
  fontWeight: 500,
  fontSize: 13,
  lineHeight: 1.08,
  color: "#FFFFFF",
});

const LinkText = styled("span", {
  fontWeight: 700,
});

const CloseButton = styled("button", {
  background: "none",
  border: "none",
  padding: 0,
  position: "absolute",
  top: 20,
  right: 20,
  cursor: "pointer",
});

function CloseSmallIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5873 12.0001L7.7917 9.20893C7.60378 9.02101 7.49792 8.76585 7.49792 8.50009C7.49792 8.23433 7.6035 7.97946 7.79142 7.79154C7.97934 7.60362 8.23421 7.49805 8.49997 7.49805C8.76573 7.49805 9.0206 7.60362 9.20852 7.79154L12 10.5874L14.7911 7.79182C14.9791 7.6039 15.2342 7.49805 15.5 7.49805C15.7657 7.49805 16.0206 7.60362 16.2085 7.79154C16.3964 7.97946 16.502 8.23433 16.502 8.50009C16.502 8.76585 16.3964 9.02073 16.2085 9.20865L13.4126 12.0001L16.2071 14.7901L16.2079 14.7909C16.3012 14.8837 16.3753 14.994 16.426 15.1155C16.4767 15.2374 16.5029 15.3681 16.5029 15.5001C16.5029 15.6321 16.4767 15.7628 16.426 15.8847C16.3755 16.0059 16.3016 16.116 16.2085 16.2086C16.1159 16.3017 16.0058 16.3756 15.8845 16.4261C15.7627 16.4769 15.632 16.503 15.5 16.503C15.368 16.503 15.2373 16.4769 15.1154 16.4261C14.9938 16.3754 14.8834 16.3012 14.7906 16.2078L12 13.4128L9.20997 16.2072C9.11713 16.3007 9.00616 16.3754 8.88455 16.4261C8.76269 16.4769 8.63198 16.503 8.49997 16.503C8.36796 16.503 8.23725 16.4769 8.11539 16.4261C7.99416 16.3756 7.88408 16.3017 7.79141 16.2086C7.69837 16.116 7.62448 16.0059 7.57398 15.8847C7.52321 15.7628 7.49707 15.6321 7.49707 15.5001C7.49707 15.3681 7.52321 15.2374 7.57398 15.1155C7.62462 14.994 7.69877 14.8836 7.79217 14.7908L10.5873 12.0001Z"
        fill="#678AB8"
      />
    </svg>
  );
}

type Props = {
  image?: string;
  imageAlt?: string;
  text: string;
  link?: string;
  linkText?: string;
  isClosable?: boolean;
  onClick?: () => void;
};

export function Banner({ image, imageAlt, text, link, linkText, isClosable, onClick }: Props) {
  const { isClosed, setIsClosed } = useBanner();

  if (isClosed) {
    return null;
  }

  return (
    <Container>
      {onClick ? (
        <Button onClick={onClick}>
          <BannerContent>
            <Img src={image} alt={imageAlt} />
            <Text>
              {text} <LinkText>{linkText}</LinkText>
            </Text>
          </BannerContent>
        </Button>
      ) : link ? (
        <A href={link} target="_blank" rel="noreferrer noopener">
          <BannerContent>
            <Img src={image} alt={imageAlt} />
            <Text>
              {text} <LinkText>{linkText}</LinkText>
            </Text>
          </BannerContent>
        </A>
      ) : null}
      {isClosable ? (
        <CloseButton
          onClick={() => {
            setIsClosed(true);
            setBannerClosedLocalStorage();
          }}
        >
          <CloseSmallIcon />
        </CloseButton>
      ) : null}
    </Container>
  );
}
