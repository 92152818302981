import { styled, theme } from "src/styles/stitches/theme";

export const Tag = styled("div", {
  fontSize: theme.fontSizes.textCaption1,
  fontWeight: theme.fontWeights.bold,
  borderRadius: theme.radii[8],
  flexShrink: 0,

  variants: {
    variant: {
      progress: {
        backgroundColor: theme.colors.fillBaseSubdued,
        color: theme.colors.textProgress,
        "&:disabled": {
          color: theme.colors.textPrimaryDisabled,
        },
      },
      success: {
        backgroundColor: theme.colors.fillPrimarySubdued,
        color: theme.colors.textAccent,
        "&:disabled": {
          color: theme.colors.textPrimaryDisabled,
        },
      },
      critical: {
        backgroundColor: theme.colors.fillCriticalSubdued,
        color: theme.colors.textCriticalAccent,
        "&:disabled": {
          color: theme.colors.textCriticalDisabled,
        },
      },
      warning: {
        backgroundColor: theme.colors.fillWarningSubdued,
        color: theme.colors.textWarning,
        "&:disabled": {
          color: theme.colors.textWarningDisabled,
        },
      },
      secondary: {
        backgroundColor: theme.colors.fillSecondary,
        color: theme.colors.textAccent,
        "&:disabled": {
          color: theme.colors.textPrimaryDisabled,
        },
      },
    },
    size: {
      md: {
        padding: "0.375rem 0.5rem",
      },
      lg: {
        padding: "0.5rem 0.75rem",
      },
    },
  },

  defaultVariants: {
    variant: "sucess",
    size: "md",
  },
});
