import { Flex, Center } from "@chakra-ui/react";
import Arrow from "@iconscout/react-unicons/icons/uil-angle-right";
import Download from "@iconscout/react-unicons/icons/uil-download-alt";
import { Texts } from "../texts/Texts";
import { ReactNode } from "react";

type Props = {
  description?: string;
  downloadable?: boolean;
  fileName?: string | null;
  icon?: ReactNode;
  iconBackground?: string;
  maxW?: string;
  title?: string;
};

export const FilesButton = ({
  description = "",
  downloadable = false,
  fileName = null,
  icon = null,
  iconBackground = "white",
  maxW,
  title = "",
}: Props) => (
  <Flex
    w="100%"
    h={"72px"}
    borderWidth={1}
    borderColor={"#E0E0E0"}
    mb="4"
    flex={1}
    borderRadius={"card"}
    bg="white"
    rounded={"card"}
    cursor={"pointer"}
    maxW={maxW}
  >
    <Flex flex={1} mr="2vw">
      <Flex flex={10} w="100%">
        <Flex ml={["1", "1", "3", "3", "3"]} w="100%">
          <Flex rounded={"card"} h="100%" justifyContent="center" alignItems="center">
            <Center bg={iconBackground} w="60px" h="60px" rounded={"card"}>
              {icon}
            </Center>
          </Flex>

          <Flex justifyContent="center" maxW={["400px", "400px"]} direction="column" w="100%">
            {title && (
              <Flex direction="row" minW="130px" ml="20px">
                <Texts
                  display="inline"
                  fontWeight="600"
                  color="#212121"
                  mr="0"
                  fontSize={["sm", "sm", "sm", "sm", "sm"]}
                  mb="1"
                >
                  {title}
                  <Texts
                    display={["none", "inline"]}
                    ml="1"
                    color="#757575"
                    fontSize={["sm", "sm", "sm", "sm", "sm"]}
                    mb="1"
                  >
                    {fileName}
                  </Texts>
                </Texts>
              </Flex>
            )}
            <Flex w="100%">
              <Texts
                textAlign="left"
                ml="20px"
                fontSize={["xs", "xs", "xs", "xs", "xs"]}
                fontWeight="400"
                color={"#757575"}
              >
                {description}
              </Texts>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex flex={1} justifyContent="center" align="center">
        {downloadable ? <Download color="#616161" /> : <Arrow color="#616161" />}
      </Flex>
    </Flex>
  </Flex>
);
