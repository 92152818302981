import { API, Auth, Storage } from "aws-amplify";
import config, { OLD_CLAIM_SERVICE_IS_ONLINE } from "src/config";
import { Files } from "src/types";
import {
  addFilesToClaim,
  getRenamedFiles,
  registerFiles,
  uploadFiles,
} from "./newClaim/new-claim-api";

async function reopenClaimOldApi(files: Files, claimId: string, customerUuid: String) {
  const user = await Auth.currentAuthenticatedUser();
  const email = user.attributes.email.toLowerCase();
  const claimUpdatedDate = new Date().toISOString();

  const uploads = files.map((myFile) => {
    const filePath = [email, claimId, myFile.kind, claimUpdatedDate, myFile.filename];

    return Storage.put(filePath.join("/"), myFile["file"], {
      level: "protected",
      contentType: myFile["file"]["type"],
    });
  });

  const clientId = user.pool.clientId;

  const currentUserInfos = await Auth.currentUserInfo();
  const identityId = currentUserInfos.id;

  const filesInfos = files.map((myFile) => {
    const filePath = [
      "protected",
      identityId,
      email,
      claimId,
      myFile.kind,
      claimUpdatedDate,
      myFile.filename,
    ];

    return {
      path: filePath.join("/"),
      id: clientId,
      type: myFile.kind,
      bucket_name: config.s3.BUCKET,
    };
  });

  await Promise.all(uploads);

  const token = user.signInUserSession.idToken.jwtToken;
  const apiName = "apiClaimsGateway";
  const path = "/reopen";
  const myInit = {
    headers: { Authorization: token },
    response: false,
    body: {
      type: "claim.reopen",
      claim_id: claimId,
      customer_uuid: customerUuid,
      files: filesInfos,
    },
  };

  await API.post(apiName, path, myInit);
}

async function reopenClaimNewApi(files: Files, claimId: string, customerUuid: string) {
  const user = await Auth.currentAuthenticatedUser();
  const clientId = user.pool.clientId;

  // Register and upload files
  const renamedFiles = getRenamedFiles(files);
  const registeredFiles = await registerFiles(renamedFiles, claimId, clientId);
  await uploadFiles(renamedFiles, registeredFiles);
  await addFilesToClaim(customerUuid, claimId, registeredFiles, true);
}

export async function reopenClaim(files: Files, claimId: string, customerUuid: string) {
  if (OLD_CLAIM_SERVICE_IS_ONLINE) {
    await reopenClaimOldApi(files, claimId, customerUuid);
    return;
  }

  reopenClaimNewApi(files, claimId, customerUuid);
}
