import { baseColors, rgba, styled, theme } from "src/styles/stitches/theme";
import { Text } from "src/components/01_atoms/text";
import { Outlet } from "src/components/01_atoms/outlet";
import { Icon, IconProps } from "src/components/01_atoms/icon";
import { useDevice } from "src/hooks/useDevice";
import { Button } from "src/components/01_atoms/button";
import { appDownloadLink } from "src/configs/external-links";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";

type ReasonProps = {
  text: string;
};

type FourPointFiveStarsProps = {
  size?: IconProps["size"];
};

type StoreRatingProps = {
  store: "apple" | "google";
  rate: number;
  small?: boolean;
};

type StoreRatingSectionProps = {
  small?: boolean;
};

type BoostAppDownloadLoginProps = {
  isFromRefundSidebar?: boolean;
};

const Reason = ({ text }: ReasonProps) => {
  return (
    <StyledReasons horizontal="spacing8px" align="center">
      <Icon name="filledCheckCircle" />
      <Text variant="paragraph1-Medium" color="textSecondaryDefault">
        {text}
      </Text>
    </StyledReasons>
  );
};

const ReasonsSection = () => {
  return (
    <Outlet vertical="spacing16px">
      <Reason text="La meilleure expérience Dalma est dans votre poche" />
      <Reason text="Parlez gratuitement et en illimité à des vétérinaires" />
      <Reason text="Retrouvez des articles éducatifs sur la santé et le bien-être animal présentés par des professionnels" />
    </Outlet>
  );
};

const FourPointFiveStars = ({ size = "12" }: FourPointFiveStarsProps) => {
  return (
    <Outlet horizontal="spacing4px">
      <Icon name="filledStar" size={size} />
      <Icon name="filledStar" size={size} />
      <Icon name="filledStar" size={size} />
      <Icon name="filledStar" size={size} />
      <Icon name="filledStarHalf" size={size} />
    </Outlet>
  );
};

const StoreRating = ({ store, rate, small = false }: StoreRatingProps) => {
  return (
    <StoreRatingContainer horizontal={small ? "spacing8px" : "spacing12px"} align="center">
      <Icon
        name={store === "apple" ? "appleStore" : "googlePlayStore"}
        size={small ? "12" : "24"}
      />
      <Outlet horizontal={small ? "spacing4px" : "spacing8px"} align="center">
        <Text variant={small ? "caption2-Bold" : "paragraph1-Medium"} color="textSecondaryDefault">
          {rate}
        </Text>
        <FourPointFiveStars size={small ? "8" : "12"} />
      </Outlet>
    </StoreRatingContainer>
  );
};

const StoreRatingSection = ({ small = false }: StoreRatingSectionProps) => {
  return (
    <Outlet horizontal={small ? "spacing24px" : "spacing16px"}>
      <StoreRating small={small} store="google" rate={4.7} />
      <StoreRating small={small} store="apple" rate={4.9} />
    </Outlet>
  );
};

const QRCodeSection = () => {
  return (
    <QRCodeSectionContainer align="center" horizontal="spacing16px">
      <QRCodeImage src="/assets/media/download-app-qr-code.svg" alt="qr code app download" />
      <Outlet vertical="spacing4px" justify="center">
        <Text variant="paragraph1-Bold" color="textBaseDefault">
          Scannez pour télécharger l’application Dalma
        </Text>
        <Text variant="paragraph1-Medium" color="textBaseDefault">
          Gratuite et disponible sur iOS et Android
        </Text>
      </Outlet>
    </QRCodeSectionContainer>
  );
};

const DownloadAppButton = () => {
  return (
    <Button
      variant="secondary"
      size="lg"
      fluid
      iconRight="arrowRight"
      onClick={() => window.open(appDownloadLink, "_blank", "noreferrer")}
    >
      Télécharger l’application
    </Button>
  );
};

export const BoostAppDownloadLogin = ({ isFromRefundSidebar }: BoostAppDownloadLoginProps) => {
  const { isMobile } = useDevice();
  const { closeRefundSidebar } = useRefundSidebar();
  return (
    <BoostAppStyledContainer
      isFromRefundSidebar={isFromRefundSidebar}
      vertical={isMobile() ? "spacing24px" : "spacing40px"}
    >
      <BoostAppStyledContent align="center" justify="center" vertical="spacing24px" fluid>
        {isFromRefundSidebar && !isMobile() ? (
          <Img
            src="/assets/media/user-call-illu.svg"
            alt="Illustration d'une femme qui regarde un écran de téléphone"
          />
        ) : (
          <BoostAppMockupImageContainer>
            <BoostAppMockupImage src="/assets/media/app-mockup.png" alt="app mockup" />
          </BoostAppMockupImageContainer>
        )}

        <Outlet vertical="spacing8px" css={{ flex: 1 }}>
          {isFromRefundSidebar ? (
            <Text
              variant={isMobile() ? "display5-Bold" : "display4-Bold"}
              color="textSecondaryDefault"
              textAlign="center"
            >
              Tout votre suivi disponible depuis l’app
            </Text>
          ) : (
            <Text
              variant={isMobile() ? "display5-Bold" : "display4-Bold"}
              color="textSecondaryDefault"
            >
              C’est encore mieux depuis l’app !
            </Text>
          )}
          {isMobile() && !isFromRefundSidebar ? (
            <Text variant="paragraph2-Medium" color="textSecondaryDefault">
              Ne soyez pas limités ! Profitez de toutes les fonctionnalités de votre assurance 100%
              digitale depuis votre mobile.
            </Text>
          ) : null}
        </Outlet>
        {isMobile() ? (
          <>
            <ReasonsSection />
            <StyledButton
              variant="secondary"
              size={{ "@initial": "md", "@bp3": "lg" }}
              fluid
              onClick={() => window.open(appDownloadLink, "_blank", "noreferrer")}
            >
              Télécharger l&apos;application Dalma
            </StyledButton>
          </>
        ) : (
          <>
            <ReasonsSection />
            <QRCodeSection />
          </>
        )}
        {isFromRefundSidebar ? (
          <StyledButton
            variant="secondary"
            size={{ "@initial": "md", "@bp3": "lg" }}
            fluid
            onClick={closeRefundSidebar}
            isMobile={isMobile()}
          >
            J&apos;ai compris
          </StyledButton>
        ) : (
          <>
            <StoreRatingSection small={isMobile()} />
            {isMobile() && isFromRefundSidebar ? <DownloadAppButton /> : null}
          </>
        )}
      </BoostAppStyledContent>
    </BoostAppStyledContainer>
  );
};

const BoostAppStyledContent = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing16px}`,
  "@bp1": {
    padding: `${theme.spacing.spacing64px} ${theme.spacing.spacing32px}`,
  },
});

const BoostAppStyledContainer = styled(Outlet, {
  width: "100%",
  alignSelf: "stretch",
  background: theme.colors.fillAccent,
  variants: {
    isFromRefundSidebar: {
      true: {
        height: "100%",
      },
    },
  },
  "@bp1": {
    maxWidth: "30.625rem",
  },
});

const BoostAppMockupImageContainer = styled("div", {
  height: "9.375rem",
  width: "8.125rem",
  "@bp1": {
    height: "25rem",
    width: "21.875rem",
  },
});

const BoostAppMockupImage = styled("img", {
  height: "100%",
  width: "auto",
  filter: `drop-shadow(${theme.elevation.elevation1Light})`,
});

const QRCodeSectionContainer = styled(Outlet, {
  background: theme.colors.backgroundPrimary,
  padding: theme.spacing.spacing16px,
  borderRadius: theme.radii["16"],
});

const QRCodeImage = styled("img", {
  width: "4.5rem",
});

const StyledReasons = styled(Outlet, {
  color: theme.colors.textSecondaryDefault,
});

const StoreRatingContainer = styled(Outlet, {
  background: rgba(baseColors.neutral000, 0.2),
  color: theme.colors.textSecondaryDefault,
  fill: theme.colors.textCriticalAccent,
  borderRadius: theme.radii["8"],
  padding: `${theme.spacing.spacing8px} ${theme.spacing.spacing16px}`,
  "@bp1": {
    borderRadius: theme.radii["12"],
    padding: `${theme.spacing.spacing12px} ${theme.spacing.spacing32px}`,
  },
});

const Img = styled("img", {
  width: "11.25rem",
  height: "11.25rem",
});

const StyledButton = styled(Button, {
  variants: {
    isMobile: {
      true: {
        marginTop: 12,
        color: theme.colors.textSecondaryDefault,
        backgroundColor: "transparent",
        border: "none",
      },
    },
  },
});
