// http://www.indicatifs.htpweb.fr/indicatif-france-outre-mer.html
// Guadeloupe	590
// Guyane	594
// La reunion	262
// Martinique	596
// Mayotte	269
// Nouvelle-calédonie	687
// Polynésie française	689
// Saint-barthélemy	590
// Saint-martin	590
// Saint-pierre-et-miquelon	508
// Wallis-et-futuna	681
export function isValidPhone(phoneNumber: string) {
  const numCheck =
    /^(?:(?:\+|00)(262|508|590|594|596|269|681|687|689|33)[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
  return numCheck.test(String(phoneNumber).replaceAll(" ", "").toLowerCase());
}

export const IBAN_REGEX =
  /(^(?:VA|DE)\d{20}$)|(^(?:EE|LT|AT)\d{18}$)|(^(?:AD)\d{10}[a-z0-9]{12}$)|(^(?:BE)\d{14}$)|(^(?:BG)\d{2}\w{4}\d{6}[a-z0-9]{8}$)|(^(?:CY)\d{10}[a-z0-9]{16}$)|(^(?:HR)\d{19}$)|(^(?:FI|DK)\d{16}$)|(^(?:FR)\d{12}[a-z0-9]{11}\d{2}$)|(^(?:GI)\d{2}\w{4}[a-z0-9]{15}$)|(^(?:GR)\d{9}[a-z0-9]{16}$)|(^(?:PL|HU)\d{26}$)|(^(?:GB|IE)\d{2}\w{4}\d{14}$)|(^(?:IS)\d{24}$)|(^(?:IT)\d{2}\w\d{10}[a-z0-9]{12}$)|(^(?:LV)\d{2}\w{4}[a-z0-9]{13}$)|(^(?:CH|LI)\d{7}[a-z0-9]{12}$)|(^(?:LU)\d{5}[a-z0-9]{13}$)|(^(?:MT)\d{2}\w{4}\d{5}[a-z0-9]{18}$)|(^(?:MF|MC)\d{12}[a-z0-9]{11}\d{2}$)|(^(?:NO)\d{13}$)|(^(?:NL)\d{2}\w{4}[a-z0-9]{10}$)|(^(?:PT)\d{23}$)|(^(?:ES|SE|SK|CZ)\d{22}$)|(^(?:RO)\d{2}\w{4}[a-z0-9]{16}$)|(^(?:SI)\d{17}$)|(^(?:GP|MQ|GF|RE|YT|BL|PM)\d{12}[a-z0-9]{11}\d{2}$)/i;
