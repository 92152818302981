import { SkeletonWrapper } from "./skeleton.styled";

interface SkeletonProps {
  width: string;
  height: string;
  borderRadius?: string;
  margin?: string;
  backgroundImage?: string;
  style?: any;
  children?: any;
}

export const Skeleton = ({
  width,
  height,
  borderRadius,
  margin,
  backgroundImage,
  style,
  children,
}: SkeletonProps) => {
  const skeletonStyle = {
    position: "relative",
    width: width || "100%",
    height: height || "100%",
    borderRadius: borderRadius || "0",
    margin: margin || "0",
    backgroundImage:
      backgroundImage ||
      "linear-gradient(146deg, rgba(0, 85, 251, 0.2) 0%, rgba(0, 85, 251, 0.1) 20%, rgba(255, 255, 255, 0.7) 110%)",
    ...style,
  };

  return <SkeletonWrapper css={skeletonStyle}>{children}</SkeletonWrapper>;
};
