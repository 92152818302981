import { VariantProps } from "@stitches/react";
import { backgroundColors, styled } from "src/styles/stitches/theme";

const LogoWrapper = styled("div", {
  variants: {
    size: {
      lg: { width: "14.875rem" },
      md: { width: "10.25rem" },
      sm: { width: "7.5rem" },
      xs: { width: "5rem" },
    },
    space: {
      true: {},
    },
  },
  compoundVariants: [
    {
      space: true,
      size: "lg",
      css: { padding: "1.56rem" },
    },
    {
      space: true,
      size: "md",
      css: { padding: "1.125rem" },
    },
    {
      space: true,
      size: "sm",
      css: { padding: "0.8125rem" },
    },
    {
      space: true,
      size: "xs",
      css: { padding: "0.5rem", width: "6rem" },
    },
  ],
});

const LogoSVG = styled("svg", {
  width: "100%",
});

type FillColors = keyof typeof backgroundColors;
type LogoProps = {
  color: Extract<
    FillColors,
    "fillBaseAccent" | "fillPrimaryDefault" | "fillSecondary" | "fillAccent"
  >;
  size: VariantProps<typeof LogoWrapper>["size"];
  space: VariantProps<typeof LogoWrapper>["space"];
};

export function Logo({ color, size, space }: LogoProps) {
  const fill = color === "fillAccent" ? "url(#f)" : backgroundColors[color].value;

  return (
    <LogoWrapper size={size} space={space}>
      <LogoSVG fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234 61">
        <path d="M102.447 60.134V0H90.89v60.134h11.557Z" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.884 48.821v.326c0 7.285 6.283 11.744 13.143 11.744 4.298 0 8.775-1.478 11.774-5.691l.107.034c0 1.514 0 3.388.254 4.9h10.772a53.074 53.074 0 0 1-.324-6.34V35.706c0-10.592-7.224-15.888-17.047-15.888-8.27 0-16.391 4.034-17.73 12.431l10.112 2.27c.865-3.315 3.574-5.368 7.077-5.368 4.19 0 6.248 2.342 6.248 4.897 0 1.588-.94 2.703-3.105 2.703H65.3c-7.704 0-17.417 2.668-17.417 12.07Zm24.303-4.828v.901c0 3.927-2.781 7.603-7.838 7.614-2.454 0-4.801-1.2-4.801-4.01 0-3.099 3.357-4.505 5.742-4.505h6.897Z"
          fill={fill}
        />
        <path
          d="M146.649 38.12v22.014h-11.492V38.253c0-4.25-1.699-7.638-6.431-7.638s-7.066 3.147-7.066 7.638v21.87h-11.485v-39.37h11.316v5.154h.107c2.313-3.964 7.08-6.055 11.557-6.055 4.732 0 9.282 1.947 11.522 6.27 3.719-4.144 7.439-6.27 13.146-6.27 9.679 0 13.867 6.702 13.867 15.674v24.608h-11.522V38.12c0-4.277-1.699-7.485-6.431-7.485-4.668-.008-7.088 3.018-7.088 7.485Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.727 48.821v.326c0 7.285 6.284 11.744 13.146 11.744 4.296 0 8.776-1.478 11.772-5.691l.109.034c0 1.514 0 3.388.252 4.9h10.772a53.095 53.095 0 0 1-.324-6.34V35.706c0-10.592-7.224-15.888-17.045-15.888-8.272 0-16.393 4.034-17.733 12.431l10.113 2.27c.865-3.315 3.574-5.368 7.076-5.368 4.191 0 6.249 2.342 6.249 4.897 0 1.588-.938 2.703-3.106 2.703h-3.864c-7.703 0-17.417 2.668-17.417 12.07Zm24.304-4.828v.901c0 3.927-2.781 7.603-7.838 7.614-2.454 0-4.802-1.2-4.802-4.01 0-3.099 3.358-4.505 5.743-4.505h6.897Z"
          fill={fill}
        />
        <path
          d="M218.003 53.07a7.968 7.968 0 0 1 1.324-4.447 7.994 7.994 0 0 1 3.58-2.962 8.018 8.018 0 0 1 8.735 1.701 7.97 7.97 0 0 1-1.197 12.293A8.013 8.013 0 0 1 225.994 61a8.03 8.03 0 0 1-5.626-2.329 7.994 7.994 0 0 1-2.365-5.6ZM30.922 39.87V.037h11.522v60.1H31.238v-5.008h-.144c-3.685 5.403-9.644 5.764-12.278 5.764C7.366 60.893 0 51.02 0 40.39c0-9.982 7.61-20.43 19.017-20.43a16.71 16.71 0 0 1 5.16.83l.137.045c.389.125.761.296 1.11.508l.089.058c.074.049.147.097.221.15a4.863 4.863 0 0 1 0 7.924 4.892 4.892 0 0 1-2.899.912l-1.34-.014c-5.418 0-9.785 4.032-9.785 10.014 0 5.983 4.367 10.055 9.748 10.055 5.938 0 9.657-4.54 9.464-10.573Z"
          fill={fill}
        />

        <defs>
          <radialGradient
            id="a"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-266.06317 -67.96209 56.33627 -220.54953 250.359 66.168)"
          >
            <stop stopColor="#4E68F6" />
            <stop offset="1" stopColor="#0B2AD6" />
          </radialGradient>
          <radialGradient
            id="b"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-266.06317 -67.96209 56.33627 -220.54953 250.359 66.168)"
          >
            <stop stopColor="#4E68F6" />
            <stop offset="1" stopColor="#0B2AD6" />
          </radialGradient>
          <radialGradient
            id="c"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-266.06317 -67.96209 56.33627 -220.54953 250.359 66.168)"
          >
            <stop stopColor="#4E68F6" />
            <stop offset="1" stopColor="#0B2AD6" />
          </radialGradient>
          <radialGradient
            id="d"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-266.06317 -67.96209 56.33627 -220.54953 250.359 66.168)"
          >
            <stop stopColor="#4E68F6" />
            <stop offset="1" stopColor="#0B2AD6" />
          </radialGradient>
          <radialGradient
            id="e"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-266.06317 -67.96209 56.33627 -220.54953 250.359 66.168)"
          >
            <stop stopColor="#4E68F6" />
            <stop offset="1" stopColor="#0B2AD6" />
          </radialGradient>
          <radialGradient
            id="f"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-266.06317 -67.96209 56.33627 -220.54953 250.359 66.168)"
          >
            <stop stopColor="#4E68F6" />
            <stop offset="1" stopColor="#0B2AD6" />
          </radialGradient>
        </defs>
      </LogoSVG>
    </LogoWrapper>
  );
}
