import { Icon } from "src/components/01_atoms/icon";
import { AccessibleLink } from "src/components/01_atoms/link";
import { Mark } from "src/components/01_atoms/logo";
import { Outlet } from "src/components/01_atoms/outlet";
import { useMobileMenu } from "src/hooks/use-mobile-menu";
import { styled, theme } from "src/styles/stitches/theme";

const AccessibleButton = styled("button", {
  border: "none",
  padding: 0,
  margin: 0,
  appearance: "none",
  borderRadius: 0,
  pointer: "cursor",
});

const Wrapper = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing24px}`,
  borderBottom: "1px solid #DBEBFF",

  "@bp3": {
    display: "none",
  },
});

export function MobileHeader() {
  const { isMobileMenuOpen, toggleMobileMenu } = useMobileMenu();

  return (
    <Wrapper horizontal="0" justify="spaceBetween" align="center" fluid>
      <AccessibleLink
        to="/"
        title="Revenir à votre espace client"
        aria-label="Revenir à votre espace client"
      >
        <Mark size="sm" color="fillBaseAccent" />
      </AccessibleLink>

      <AccessibleButton
        onClick={toggleMobileMenu}
        aria-label={isMobileMenuOpen ? "Fermer le menu" : "Ouvrir le menu"}
      >
        <Icon name={isMobileMenuOpen ? "outlinedClose" : "outlinedMenu"} size="24" />
      </AccessibleButton>
    </Wrapper>
  );
}
