import { Flex, Image, Center } from "@chakra-ui/react";
import { DefaultButton } from "../../components/buttons/Button";
import { Texts } from "../../components/texts/Texts";
import { Title } from "../../components/texts/Title";
import Check from "@iconscout/react-unicons/icons/uil-check";
import Times from "@iconscout/react-unicons/icons/uil-times";

import { useIntercom } from "src/use-intercom";
import { useHistory } from "react-router-dom";

export const LiveChat = () => {
  const { show } = useIntercom();
  const history = useHistory();

  return (
    <Flex minW={["100vw", "100vw", "100vw", "100vw", "100vw"]} minH="100vh">
      <Center
        cursor="pointer"
        onClick={() => history.replace("/")}
        bg="#DBEBFF"
        w="44px"
        h="44px"
        position="absolute"
        right="5vw"
        top="5vh"
        rounded="full"
      >
        <Times color="#4E68F6" size="25px" />
      </Center>

      <Flex w="100%" bg="rgba(195, 215, 240, 0.24)" direction={"column"}>
        <Flex
          pt={["100px", "100px", "100px", "200px"]}
          direction={["column", "column", "column", "row"]}
          alignItems={["flex-start", "flex-start", "center", "flex-start", "flex-start"]}
          justify="center"
          mb="70"
        >
          <Flex maxW="552px" direction="column" p={[0, 0, "10", "5"]}>
            <Flex ml={["25px", "25px", 0]} mb="15px">
              <Title
                textAlign={["left", "left", "center", "left"]}
                fontSize={["24px", "24px", "32px", "32px"]}
                color="#212121"
              >
                Envie de discuter par chat avec nos vétérinaires ?
              </Title>
            </Flex>

            <Flex mb="30px" ml={["25px", "25px", 0]}>
              <Texts
                textAlign={["left", "left", "center", "left"]}
                color="#616161"
                fontWeight="400px"
              >
                Vous avez une question liée à sa santé, son bien-être ou son éducation ? Bonne
                nouvelle, nous avons les réponses. Profitez des conseils d’experts.
              </Texts>
            </Flex>

            <Flex
              mb="14px"
              ml={["25px", "25px", 0]}
              justify={["flex-start", "flex-start", "center", "flex-start"]}
            >
              <Flex mr="12px">
                <Check color="#4E68F6" size="20px" />
              </Flex>
              <Flex>
                <Texts color="#616161" fontWeight="400px">
                  Conseils santé, bien-être, éducation
                </Texts>
              </Flex>
            </Flex>
            <Flex
              mb="30px"
              ml={["25px", "25px", 0]}
              justify={["flex-start", "flex-start", "center", "flex-start"]}
            >
              <Flex mr="12px">
                <Check color="#4E68F6" size="20px" />
              </Flex>
              <Flex>
                <Texts color="#616161" fontWeight="400px">
                  Réponses en quelques heures
                </Texts>
              </Flex>
            </Flex>
            <Flex w="100%" justify={["flex-start", "flex-start", "center", "flex-start"]}>
              <Flex>
                <DefaultButton
                  ml={["25px", "25px", 0]}
                  w={["90vw", "260px", "260px", "260px", "260px"]}
                  onClick={() => {
                    show();
                  }}
                  data-intercom-vet="liveChat"
                >
                  <Texts color="white">Parler à un vétérinaire</Texts>
                </DefaultButton>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            minW={["100vw", "100vw", "650px"]}
            px={["5", "5", "0"]}
            justify="center"
            mt={["150px", "120px", "0px", "-120px"]}
            mb={["56px", "56px", 0]}
          >
            <Flex>
              <Image
                rounded="8px"
                w={["100vw", "100vw", "650px"]}
                h={["100vw", "100vw", "650px"]}
                src="https://dalma-images.s3.eu-west-3.amazonaws.com/03_CHAT_QUESTIONS.png"
              ></Image>
            </Flex>
          </Flex>
        </Flex>

        <Flex h="100%" pt="73px" bg="white" w="100%" align="center" flex={1} direction="column">
          <Flex mb="51px">
            <Title fontSize={["24px", "24px", "32px"]} color="#212121">
              Une urgence ?
            </Title>
          </Flex>

          <Flex
            minW={["100vw", "100vw", "100%"]}
            px={["0", "0", "350px"]}
            mb="100px"
            direction={["column", "column", "column", "row"]}
          >
            <Flex
              flex={1}
              rounded="8px"
              align="center"
              p={["20px", "20px", "52px"]}
              minH={["230px", "230px", "284px"]}
              m={["5", "5", "10", "10"]}
              bg="#FAE6E7"
              direction="column"
            >
              <Flex mb="10px">
                <Title fontSize={"20px"} color="#D6444C">
                  Une urgence médicale
                </Title>
              </Flex>

              <Flex mb="31px" textAlign="center">
                <Texts color="#616161" fontWeight="400px">
                  Pas de temps à perdre. Pour toute question urgente liée à la santé de votre
                  animal, nous vous invitons à appeler directement les urgences
                </Texts>
              </Flex>

              <Flex>
                <DefaultButton
                  h={["100px", "90px"]}
                  bg="#D6444C"
                  p="5"
                  w={["100%", "100%", "328px"]}
                >
                  <Texts textAlign="center" color="white">
                    Appeler les urgences vétérinaires
                    <br></br>
                    3115
                  </Texts>
                </DefaultButton>
              </Flex>
            </Flex>

            <Flex
              flex={1}
              rounded="8px"
              align="center"
              p={["20px", "20px", "52px"]}
              minH={["230px", "230px", "284px"]}
              m={["5", "5", "10", "10"]}
              bg="#F5F5F5"
              direction="column"
            >
              <Flex mb="10px">
                <Title fontSize={"20px"} color="#144283">
                  Votre animal a disparu ?
                </Title>
              </Flex>

              <Flex mb="31px" textAlign="center">
                <Texts color="#616161" fontWeight="400px">
                  Nous sommes navrés de l’apprendre. Nous vous invitons à contacter le numéro public
                  de l’I-CAD qui vous guidera dans la suite de vos démarches{" "}
                </Texts>
              </Flex>

              <Flex>
                <DefaultButton
                  h={["80px", "90px"]}
                  bg="#144283"
                  p="5"
                  w={["100%", "100%", "328px"]}
                >
                  <Texts minW="200px" color="white">
                    Appeler l’I-CAD
                    <br></br>
                    09 77 40 30 77
                  </Texts>
                </DefaultButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
