import { forwardRef } from "react";
import { LinkButton } from "src/components/01_atoms/button";
import { Input, InputProps } from "src/components/01_atoms/input";
import { Outlet } from "src/components/01_atoms/outlet";

type passwordInputWithForgotProps = InputProps & {
  name: string;
  forgotPasswordLink: string;
};

export const PasswordInputWithForgot = forwardRef<HTMLInputElement, passwordInputWithForgotProps>(
  ({ name, forgotPasswordLink, ...rest }, ref) => {
    return (
      <Outlet vertical="spacing8px" fluid align="end">
        <Input type="password" name={name} {...rest} ref={ref} />
        <LinkButton to={forgotPasswordLink} size="xs" variant="smallActionAccent">
          Un problème avec votre mot de passe ?
        </LinkButton>
      </Outlet>
    );
  }
);

PasswordInputWithForgot.displayName = "PasswordInputWithForgot";
