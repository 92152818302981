import { styled, theme } from "src/styles/stitches/theme";
import { Icon } from "src/components/01_atoms/icon";
import { WithChildren } from "src/types";
import { Outlet } from "../outlet";
import { ComponentProps, forwardRef, InputHTMLAttributes } from "react";

const SpanCheckbox = styled("span", {
  transition: "all .15s ease-out",
  // add more radius to the checkbox for visual alignment with the svg
  borderRadius: `calc(${theme.radii[4]} + 0.04rem)`,
  width: "1.125rem",
  height: "1.125rem",
  border: `2px solid ${theme.colors.borderBaseSubdued}`,
  appearance: "none",
  display: "block",
  position: "relative",

  "& svg": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
});

const StyledLabel = styled("label", {
  cursor: "pointer",
  userSelect: "none",

  [`&:hover ${SpanCheckbox}`]: {
    boxShadow: theme.boxShadow.hovered,
  },

  variants: {
    disabled: {
      true: {
        color: theme.colors.textBaseDisabled,
        cursor: "not-allowed",

        [`&:hover ${SpanCheckbox}`]: {
          boxShadow: "none",
        },
      },
    },
  },
});

const StyledInput = styled("input", {
  // Visually hidden
  position: "absolute !important",
  width: "1px !important",
  height: "1px !important",
  padding: "0 !important",
  margin: "-1px !important",
  overflow: "hidden !important",
  clip: "rect(0,0,0,0) !important",
  whiteSspace: "nowrap !important",
  border: "0 !important",

  "& + label": {
    [`${SpanCheckbox} svg`]: {
      opacity: 0,
    },
  },
  "&:checked + label": {
    [`${SpanCheckbox} svg`]: {
      opacity: 1,
    },
    [`${SpanCheckbox}`]: {
      outline: "none",
    },
  },
  "&:focus-within + label": {
    [`${SpanCheckbox}`]: {
      boxShadow: theme.boxShadow.selected,
      border: `2px solid ${theme.colors.borderBaseDefault}`,
    },
  },
  "&:focus-within,&:checked + label": {
    [`${SpanCheckbox}`]: {
      border: `none`,
    },
  },
});

type OptionProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "value"> &
  WithChildren<{
    labelPosition?: "left" | "down";
    value?: string | number;
    id: string;
  }>;

type outletDirectionType = Pick<ComponentProps<typeof Outlet>, "vertical" | "horizontal">;

export const Checkbox = forwardRef<HTMLInputElement, OptionProps>(
  ({ children, labelPosition, ...rest }, ref) => {
    const labelLeft = labelPosition === "left";
    const labelDown = labelPosition === "down";
    const outletDirection: outletDirectionType = labelDown
      ? { vertical: "spacing8px" }
      : { horizontal: "spacing8px" };

    return (
      <div>
        <StyledInput {...rest} type="checkbox" id={rest.id ?? rest.value?.toString()} ref={ref} />
        <StyledLabel disabled={rest.disabled} htmlFor={rest.id ?? rest.value?.toString()}>
          <Outlet {...outletDirection} align="center">
            {labelLeft && <div>{children}</div>}
            <SpanCheckbox>
              <Icon
                name="filledCheckboxSquare"
                color={rest.disabled ? "iconPrimaryDisabled" : "iconPrimaryDefault"}
                size="18"
              />
            </SpanCheckbox>
            {!labelLeft && <div>{children}</div>}
          </Outlet>
        </StyledLabel>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";
