import { captureException } from "@sentry/react";
import { SANDBOX } from "src/config";
import { z } from "zod";

// z object so we can relay on it for form validation using zod
export const BackAPIErrorSchema = z.object({
  code: z.string(),
  message: z.string(),
  name: z.string(),
});

export function parseError(err: any) {
  return BackAPIErrorSchema.parse(err);
}

export class CrashAPIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CrashAPIError";
  }
}

export function checkIfAPICrash(response: any) {
  // This is the response type from the API that crash
  try {
    const crashErrorSchema = z.object({
      errorMessage: z.string(),
      errorType: z.string(),
      requestId: z.string(),
    });

    const crashobject = crashErrorSchema.parse(response);
    if (crashobject) {
      throw new CrashAPIError(crashobject.errorMessage);
    }
  } catch (error) {
    if (error instanceof CrashAPIError) {
      throw new CrashAPIError(error.message);
    }

    throw new Error("Unexpected response from API");
  }
}

// TODO: Add more logic to handle errors and handle sentry
type ReportErrorParams = {
  capture?: boolean;
};

export function reportError(error: any, params?: ReportErrorParams) {
  const sendTosentry = params?.capture ?? true;
  if (!SANDBOX && sendTosentry === true) {
    captureException(error);
    return;
  }

  console.error(error);
}
