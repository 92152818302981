import { API } from "aws-amplify";
import { checkIfAPICrash } from "src/utils/error";

export async function fetchStatus(email: string) {
  const apiName = "apiAuthGateway";
  const path = "/status";
  const myInit = {
    headers: {},
    queryStringParameters: {
      email: email,
    },
  };

  const response = await API.get(apiName, path, myInit);

  // This is the response type from the API that we except
  if (typeof response === "string") {
    return response;
  }

  checkIfAPICrash(response);
}
