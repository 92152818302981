import { SubmitHandler, useForm } from "react-hook-form";
import { Outlet } from "src/components/01_atoms/outlet";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { styled, theme } from "src/styles/stitches/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { Button } from "src/components/01_atoms/button";
import { useGetContract } from "src/hooks/data";
import { reportError } from "src/utils/error";
import { matchIsAccidentAndDiseaseCategory } from "src/helpers";
import { CausesDateInput } from "src/components/02_molecules/causes-date-input";
import { VisitMotivesInput } from "src/components/02_molecules/visit-motives-input/visit-motives-input";

const Form = styled("form", {
  flex: 1,
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing24px}`,
  flexDirection: "column",

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing36px}`,
  },
});

const accidentCausesSchema = z.object({
  accident_date: z.number(),
  visit_motives_accident: z.array(z.string()).nonempty(),
});

type AccidentCauses = {
  accident_date: Date | undefined;
  visit_motives_accident: string[];
};

export function AccidentCausesForm() {
  const {
    currentStep,
    setCurrentStep,
    selectedPetToContext,
    selectedConsultationTypes,
    selectedAccidentDate,
    setSelectedAccidentDate,
    visitMotivesAccident,
    setVisitMotivesAccident,
  } = useRefundSidebar();

  const { data: contracts } = useGetContract();

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm<AccidentCauses>({
    resolver: zodResolver(accidentCausesSchema),
    mode: "onChange",
    defaultValues: {
      accident_date: selectedAccidentDate,
      visit_motives_accident: visitMotivesAccident,
    },
  });

  if (!contracts) {
    return null;
  }

  const currentPetContract = contracts?.find(
    (contract) => contract.contract.contract_uuid === selectedPetToContext?.contract_uuid
  );

  if (!currentPetContract) {
    reportError(
      `No current pet contract found on the accident causes form. selectedPet = ${selectedPetToContext?.contract_uuid}`
    );
    return null;
  }

  const onSubmit: SubmitHandler<AccidentCauses> = (values) => {
    setSelectedAccidentDate(values.accident_date);
    setVisitMotivesAccident(values.visit_motives_accident);
    matchIsAccidentAndDiseaseCategory(selectedConsultationTypes)
      ? setCurrentStep(currentStep + 1)
      : setCurrentStep(currentStep + 2);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledOutlet fullHeight>
          <SidesheetContentHeader
            title="Dites-nous en un peu plus sur cet accident ?"
            subtitle="Demande de remboursement"
            alertIconName="filledInfo"
            alertText="Cette information nous permettra de traiter votre demande de remboursement plus rapidement"
          />
          <CausesDateInput
            setValue={setValue}
            fieldName="accident_date"
            selectedDate={selectedAccidentDate}
          />
          <VisitMotivesInput
            fieldName="visit_motives_accident"
            claimCategory="ACCIDENT"
            watch={watch}
            setValue={setValue}
            getValues={getValues}
          />
          <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
            <Button type="submit" variant="accent" size="lg" fluid disabled={!isValid}>
              Continuer
            </Button>
          </Outlet>
        </StyledOutlet>
      </Form>
    </>
  );
}
