import { useHistory } from "react-router-dom";
import { CognitoUser, Credentials, LoginErrorMessages, useLogin } from "./_useLogin";

export type LoginFields = "email" | "password";

type UseSignInProps = {
  firstConnectionLink?: string;
  nextPage: string;
};

// TODO in the futur: use i18n
export const SignInErrorMessages: LoginErrorMessages = Object.freeze({
  wrongLogin: "Email ou mot de passe incorrect.",
  wrongVerificationCode: "Le code de vérification est incorrect.",
  alreadyUsedVerificationCode: "Le code de vérification a déjà été utilisé.",
  limitedExceeded:
    "Vous avez atteint le plafond de tentatives de réinitialisation du mot de passe. Veuillez patienter 1h ou écrire à contact@dalma.co",
  unsecuredPassword:
    "Le mot de passe n'est pas sécurisé. Il doit contenir au moins 8 lettres, majuscule et minuscule, caractères spéciaux.",
  verificationCodeError:
    "Une erreur est survenue. Vérifiez que vous avez saisi le bon 'code de vérification' qui vous a été envoyé par mail et renseigné un mot de passe contenant au moins 8 lettres, majuscule et minuscule, caractères spéciaux.",
  temporaryPasswordExpire:
    "Votre mot de passe temporaire a expiré.\n Un nouveau mot de passe temporaire vous a été envoyé à : ",
  default: "Une erreur est survenue. Veuillez nous écrire à contact@dalma.co",
});

export const useSignIn = ({ firstConnectionLink, nextPage }: UseSignInProps) => {
  const history = useHistory();
  const { isLoading, loginHandler } = useLogin({ errorMessages: SignInErrorMessages });

  const handleSignIn = (user: CognitoUser, values: Credentials) => {
    // Login successfull
    if (user.challengeName !== "NEW_PASSWORD_REQUIRED") return history.push(nextPage);
    // We have to setup our password -> go to first connection page
    return history.push({
      pathname: firstConnectionLink,
      state: {
        email: values.email,
        tmpPassword: values.password,
      },
    });
  };

  const signIn = async (values: Credentials) => loginHandler(values, handleSignIn);
  return { isLoading, signIn };
};
