export const SANDBOX = import.meta.env.REACT_APP_PRODUCTION !== "true";
export const OLD_CLAIM_SERVICE_IS_ONLINE =
  import.meta.env.REACT_APP_OLD_CLAIM_SERVICE_IS_ONLINE === "true";

export const STRIPE_PUB_KEY = SANDBOX
  ? "pk_test_51II9kbF5i98BakySobr2jd3JWG10uu8F579b9fMS6sgFyOxCVeOluX75YOEeFGH5APnF6kOgvT5mEXSpy9PWPvk300ME6DLYVS" // sandbox key
  : "pk_live_51II9kbF5i98BakySdxETLJWq2OZb5hY2DxL0L9eZoRtO9e1YQySiZuxoHPHfuPUSU5TPXekgEFFLF9Ufenda20yy00hcNJo0fE"; // prod env

const DOMAIN_URL = SANDBOX ? "dalma.dev" : "dalma.tech";

const BACKEND_APPLICATION = SANDBOX ? "dalma-latest" : "dalma";

export const INTERCOM_APP_ID = "zkur88l2";

const OLD_CLAIM_SERVICE_BUCKET = SANDBOX ? "test-dalma-latest-claims" : "dalma-claims-prod";
const NEW_CLAIM_SERVICE_BUCKET = SANDBOX ? "dalma-claims-test" : "dalma-claims-prod";
const BUCKET = OLD_CLAIM_SERVICE_IS_ONLINE ? OLD_CLAIM_SERVICE_BUCKET : NEW_CLAIM_SERVICE_BUCKET;

const config = {
  s3: {
    REGION: "eu-west-3",
    BUCKET,
  },
  cognito: SANDBOX
    ? {
        REGION: "eu-west-3",
        USER_POOL_ID: "eu-west-3_rrBYwWxIh",
        APP_CLIENT_ID: "2e8ohr287degbgauuopj2k0jcv",
        IDENTITY_POOL_ID: "eu-west-3:b0dd8c4f-184f-4a29-b95c-0ef95c64e596",
        AUTHENTICATION_FLOW_TYPE: "USER_PASSWORD_AUTH", // To be changed to 'USER_SRP_AUTH' after user pool migration
      }
    : {
        REGION: "eu-west-3",
        USER_POOL_ID: "eu-west-3_XXxFbmRMC",
        APP_CLIENT_ID: "v53kr3ns5ddplrn0ck8i63t0j",
        IDENTITY_POOL_ID: "eu-west-3:2dc66ce6-e35d-497a-90c8-044540786664",
        AUTHENTICATION_FLOW_TYPE: "USER_PASSWORD_AUTH", // To be changed to 'USER_SRP_AUTH' after user pool migration
      },
  apiMySpaceGateway: {
    NAME: "apiMySpaceGateway",
    REGION: "eu-west-3",
    URL: `https://myspace.${DOMAIN_URL}/${BACKEND_APPLICATION}`,
  },
  apiFilesGateway: {
    NAME: "apiFilesGateway",
    REGION: "eu-west-3",
    URL: `https://file.${DOMAIN_URL}/${SANDBOX ? "storage-latest" : "file-storage"}`,
  },
  apiClaimsGateway: {
    NAME: "apiClaimsGateway",
    REGION: "eu-west-3",
    URL: `https://claims.${DOMAIN_URL}/${BACKEND_APPLICATION}`,
  },
  apiOldMySpaceGateway: {
    NAME: "apiOldMySpaceGateway",
    REGION: "eu-west-3",
    URL: SANDBOX ? "https://dev.dalma.co/myspace" : "https://prod.dalma.co/myspace",
  },
  apiStripeGateway: {
    NAME: "apiStripeGateway",
    REGION: "eu-west-3",
    URL: `https://checkout.${DOMAIN_URL}/${BACKEND_APPLICATION}`,
  },
  apiAuthGateway: {
    NAME: "apiAuthGateway",
    REGION: "eu-west-3",
    URL: `https://auth.${DOMAIN_URL}/${SANDBOX ? "dalma-latest" : BACKEND_APPLICATION}`,
  },
  INTERCOM_APP_ID,
  UNPAID_API_URL: SANDBOX
    ? "https://backoffice.dalma.dev/dalma-bo-latest/unpaid/recovery-invoice"
    : "https://backoffice.dalma.tech/dalma-bo/unpaid/recovery-invoice",
};

// eslint-disable-next-line import/no-default-export
export default config;

const DOMAIN_SEP = SANDBOX ? ".dev." : ".";
export const SIGNUP_DOMAIN = `https://signup${DOMAIN_SEP}dalma.co`;
