import { Icon } from "src/components/01_atoms/icon";
import { styled, theme } from "src/styles/stitches/theme";

export const Card = styled("div", {
  width: "100%",
});

export const TopCard = styled("div", {
  padding: theme.spacing.spacing24px,

  variants: {
    disabled: {
      true: {
        opacity: "0.5",
        background: theme.colors.fillBaseDisabled,
      },
    },
    checked: {
      true: {
        boxShadow: theme.elevation.elevation1Primary,
      },
      false: {
        boxShadow: theme.elevation.elevation1Purple,
      },
    },
  },
});

export const BottomCard = styled("div", {
  padding: theme.spacing.spacing24px,
  background: theme.colors.fillSecondary,
});

export const PetIcon = styled(Icon, {
  transition: "color .15s ease-out",
});
