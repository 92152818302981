import { ComponentProps } from "react";
import { Icon, IconProps } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

const StyledChevronIcon = styled(Icon, {
  display: "none",
});

const StyledLockIcon = styled(Icon, {
  display: "none",

  "@bp3": {
    display: "block",
    color: theme.colors.iconBaseSubdued,
  },
});

const StyledButton = styled("button", {
  padding: "1.25rem 0.75rem",
  width: "100%",
  borderRadius: theme.radii[12],
  backgroundColor: "transparent",
  border: `1.5px solid ${theme.colors.borderBaseSubdued}`,
  textAlign: "start",
  transition: "background-color 0.15s ease-out",

  "&:hover": {
    backgroundColor: theme.colors.buttonBackgroundSecondaryHovered,
  },

  "& div > svg": {
    display: "none",
  },

  "& div > svg + svg": {
    display: "block",
  },

  "&:active": {
    backgroundColor: theme.colors.buttonBackgroundSecondaryPressed,
    boxShadow: `0px 0px 0px 1.5px ${theme.colors.borderBaseSubdued}`,
  },

  "&:focus-visible": {
    outline: "none",
    boxShadow: theme.boxShadow.focus,
  },

  "&:disabled": {
    cursor: "not-allowed",
    backgroundColor: "transparent",

    "& div > svg": {
      display: "block",
    },

    "& div > svg + svg": {
      display: "none",
    },

    [`& ${StyledChevronIcon}`]: {
      display: "block",
    },
  },

  "@bp3": {
    padding: "0.74rem 1rem",
    border: "none",

    [`& ${StyledChevronIcon}`]: {
      display: "none",
    },

    "& div > svg": {
      display: "block",
    },

    "& div > svg + svg": {
      display: "none",
    },

    "&:hover,&:focus-visible": {
      backgroundColor: theme.colors.buttonBackgroundSecondaryHovered,

      [`& ${StyledChevronIcon}`]: {
        display: "block",
        color: theme.colors.iconBaseDefault,
      },

      [`& ${StyledLockIcon}`]: {
        display: "block",
        color: theme.colors.iconBaseDefault,
      },

      "& div > svg": {
        display: "none",
      },

      "& div > svg + svg": {
        display: "block",
      },
    },

    "&:active": {
      [`& ${StyledChevronIcon}`]: {
        display: "block",
        color: theme.colors.iconBaseSubdued,
      },

      [`& ${StyledLockIcon}`]: {
        display: "block",
        color: theme.colors.iconBaseSubdued,
      },
    },

    "&:disabled:hover,&:disabled:focus-visible": {
      outline: "none",
      boxShadow: "none",

      [`& ${StyledChevronIcon}`]: {
        display: "none",
      },

      "& div > svg + svg": {
        display: "none",
      },
    },
  },
});

type PossibleIcons = Extract<
  IconProps["name"],
  "filledSpark" | "filledDownload" | "filledFile" | "filledVeto"
>;
type PossibleColors = Extract<
  IconProps["color"],
  "iconDecorativeLightBlue" | "iconDecorativeGreen" | "iconWarning" | "iconDecorativePurple"
>;

type MenuButtonProps = {
  label: string;
  iconName: PossibleIcons;
  disabled?: boolean;
  onClick?: ComponentProps<"button">["onClick"];
  lockIcon?: boolean;
};

const iconColor: Record<PossibleIcons, PossibleColors> = {
  filledSpark: "iconDecorativeLightBlue",
  filledDownload: "iconDecorativePurple",
  filledFile: "iconWarning",
  filledVeto: "iconDecorativeGreen",
};

export function MenuButton({
  label,
  iconName,
  onClick,
  disabled = false,
  lockIcon = false,
  ...rest
}: MenuButtonProps) {
  return (
    <StyledButton type="button" disabled={disabled} onClick={onClick} {...rest}>
      <Outlet horizontal="spacing8px" align="center" justify="spaceBetween">
        <Outlet horizontal="spacing8px" align="center" justify="start">
          <div>
            <Icon name={iconName} color={disabled ? "iconBaseDisabled" : "iconBaseSubdued"} />
            <Icon name={iconName} color={iconColor[iconName]} />
          </div>
          <Text variant="paragraph3-Bold" color={disabled ? "textBaseDisabled" : "textBaseDefault"}>
            {label}
          </Text>
        </Outlet>
        {lockIcon ? (
          <StyledLockIcon name="filledLock" color="currentColor" />
        ) : (
          <StyledChevronIcon name="chevronRightSmall" color="currentColor" />
        )}
      </Outlet>
    </StyledButton>
  );
}
