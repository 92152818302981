import { ReactNode } from "react";
import { deprecatedTheme, deprecatedStyled } from "src/styles/stitches/deprecated-theme";
import { InfoIcon } from "../Icons/InfoIcon";

const MessageContainer = deprecatedStyled("div", {
  backgroundColor: deprecatedTheme.colors.blue100,
  border: "1px solid rgba(254, 111, 24, 0.1)",
  borderRadius: 8,
  padding: 16,
  display: "flex",
  marginBottom: 24,
  alignItems: "center",
});

const Text = deprecatedStyled("div", {
  fontWeight: 400,
  fontSize: 14,
  color: deprecatedTheme.colors.gray900,
  marginLeft: 10,
});

type Props = {
  children: ReactNode;
};
export const Banner = ({ children }: Props) => {
  return (
    <MessageContainer>
      <InfoIcon color={deprecatedTheme.colors.electric.value} />
      <Text>{children}</Text>
    </MessageContainer>
  );
};
