export const otherIcons = {
  googlePlayStore: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 1.1056 1.1064 C 1.045 1.311 1.0125 1.5447 1.0125 1.8051 V 22.4942 C 1.0125 22.7553 1.0451 22.9894 1.1059 23.1941 L 12.1488 12.1496 L 1.1056 1.1064 Z M 2.0576 24.1516 C 2.438 24.2325 2.8968 24.146 3.3896 23.8665 L 16.4462 16.4471 L 13.1034 13.1042 L 2.0576 24.1516 Z M 17.6643 15.7559 L 21.6324 13.5015 C 22.9392 12.7577 22.9392 11.5425 21.6324 10.7998 L 17.6629 8.5442 L 14.058 12.1495 L 17.6643 15.7559 Z M 16.4454 7.8524 L 3.3896 0.4337 C 2.8965 0.1535 2.4374 0.0672 2.0569 0.1485 L 13.1034 11.1949 L 16.4454 7.8524 Z"
      fill="inherit"
    />
  ),
  appleStore: (
    <>
      <path
        d="M 15.6556 12.4111 C 15.6782 10.6653 16.6158 9.0134 18.1031 8.0989 C 17.1648 6.7588 15.5931 5.9092 13.958 5.858 C 12.2139 5.675 10.5232 6.9016 9.6345 6.9016 C 8.7286 6.9016 7.3603 5.8762 5.8868 5.9065 C 3.9662 5.9686 2.1757 7.0605 1.2413 8.7396 C -0.7673 12.2173 0.731 17.3281 2.655 20.1391 C 3.6177 21.5156 4.7428 23.0531 6.2149 22.9986 C 7.6555 22.9389 8.1935 22.08 9.9324 22.08 C 11.6551 22.08 12.1599 22.9986 13.6619 22.964 C 15.2077 22.9388 16.1816 21.5814 17.1105 20.1918 C 17.8021 19.2111 18.3344 18.1271 18.6875 16.9801 C 16.871 16.2117 15.6577 14.3836 15.6556 12.4111 Z"
        fill="inherit"
      />
      <path
        d="M 12.8186 4.0094 C 13.6614 2.9976 14.0767 1.6971 13.9761 0.3841 C 12.6884 0.5193 11.499 1.1347 10.6448 2.1077 C 9.8095 3.0584 9.3748 4.336 9.457 5.5988 C 10.7451 5.612 12.0114 5.0133 12.8186 4.0094 Z"
        fill="inherit"
      />
    </>
  ),
};
