import { createContext, useContext, useState } from "react";
import { toastClose } from "src/components/01_atoms/toast";
import { getClaimPaidAmount, getClaimRefundAmount } from "src/components/02_molecules/claim/_utils";
import { Claim_v2, WithChildren } from "src/types";

type ClaimStatusSidebarStates = {
  formattedCategories: string;
  petType: "cat" | "dog" | undefined;
  claim: Claim_v2;
};

type ClaimStatusSidebarContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  formattedCategories: string | undefined;
  petType: "cat" | "dog" | undefined;
  isNeedInfoDetailSidebarOpen: boolean;
  setIsNeedInfoDetailSidebarOpen: (isNeedInfoDetailSidebarOpen: boolean) => void;
  isAddNeedInfoDocumentSidebarOpen: boolean;
  setIsAddNeedInfoDocumentSidebarOpen: (isAddNeedInfoDocumentSidebarOpen: boolean) => void;
  openClaimStatusSidebar: (claimStatusSidebarStates: ClaimStatusSidebarStates) => void;
  isConfirmationScreenOpen: boolean;
  setIsConfirmationScreenOpen: (isConfirmationScreenOpen: boolean) => void;
  selectedClaim: Claim_v2 | undefined;
  setSelectedClaim: (claim: Claim_v2 | undefined) => void;
  isUpdatedClaimLoading: boolean;
  setIsUpdatedClaimLoading: (isUpdatedClaimLoading: boolean) => void;
  refundAmount: number;
  setRefundAmount: (refundAmount: number) => void;
  isRefusedDetailSidebarOpen: boolean;
  setIsRefusedDetailSidebarOpen: (isRefusedDetailSidebarOpen: boolean) => void;
  paidAmount: number;
  setPaidAmount: (paidAmount: number) => void;
};

export const ClaimStatusSidebarContext = createContext<ClaimStatusSidebarContextType>({
  isOpen: false,
  setIsOpen: () => undefined,
  formattedCategories: undefined,
  petType: undefined,
  isNeedInfoDetailSidebarOpen: false,
  setIsNeedInfoDetailSidebarOpen: () => undefined,
  isAddNeedInfoDocumentSidebarOpen: false,
  setIsAddNeedInfoDocumentSidebarOpen: () => undefined,
  openClaimStatusSidebar: () => undefined,
  isConfirmationScreenOpen: false,
  setIsConfirmationScreenOpen: () => undefined,
  selectedClaim: undefined,
  setSelectedClaim: () => undefined,
  isUpdatedClaimLoading: false,
  setIsUpdatedClaimLoading: () => undefined,
  refundAmount: 0,
  setRefundAmount: () => undefined,
  isRefusedDetailSidebarOpen: false,
  setIsRefusedDetailSidebarOpen: () => undefined,
  paidAmount: 0,
  setPaidAmount: () => undefined,
});

export const ClaimStatusSidebarContextProvider = ({ children }: WithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formattedCategories, setFormattedCategories] = useState<string | undefined>();
  const [petType, setPetType] = useState<"cat" | "dog" | undefined>();
  const [isNeedInfoDetailSidebarOpen, setIsNeedInfoDetailSidebarOpen] = useState(false);
  const [isAddNeedInfoDocumentSidebarOpen, setIsAddNeedInfoDocumentSidebarOpen] = useState(false);
  const [isConfirmationScreenOpen, setIsConfirmationScreenOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState<Claim_v2>();
  const [isUpdatedClaimLoading, setIsUpdatedClaimLoading] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [isRefusedDetailSidebarOpen, setIsRefusedDetailSidebarOpen] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);

  const openClaimStatusSidebar = (claimStatusSidebarStates: ClaimStatusSidebarStates) => {
    const { claim, formattedCategories, petType } = claimStatusSidebarStates;

    setIsOpen(true);
    setFormattedCategories(formattedCategories);
    setPetType(petType);
    setSelectedClaim(claim);
    setRefundAmount(getClaimRefundAmount(claim.claim_acts));
    setPaidAmount(getClaimPaidAmount(claim.claim_acts));
    toastClose();
  };

  return (
    <ClaimStatusSidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        formattedCategories,
        petType,
        openClaimStatusSidebar,
        isNeedInfoDetailSidebarOpen,
        setIsNeedInfoDetailSidebarOpen,
        isAddNeedInfoDocumentSidebarOpen,
        setIsAddNeedInfoDocumentSidebarOpen,
        isConfirmationScreenOpen,
        setIsConfirmationScreenOpen,
        selectedClaim,
        setSelectedClaim,
        isUpdatedClaimLoading,
        setIsUpdatedClaimLoading,
        refundAmount,
        setRefundAmount,
        isRefusedDetailSidebarOpen,
        setIsRefusedDetailSidebarOpen,
        paidAmount,
        setPaidAmount,
      }}
    >
      {children}
    </ClaimStatusSidebarContext.Provider>
  );
};

export const useClaimStatusSidebar = (): ClaimStatusSidebarContextType => {
  const context = useContext(ClaimStatusSidebarContext);

  if (context === null) {
    throw new Error(
      "useClaimStatusSidebar must be used within a ClaimStatusSidebarContextProvider"
    );
  }

  return context;
};
