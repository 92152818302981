import { Icon, IconProps } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled } from "src/styles/stitches/theme";

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",
});

type Props = {
  iconName?: IconProps["name"];
  iconColor: IconProps["color"];
  title: string;
  description: string;
  withInfoIcon?: boolean;
  onInfoIconClick?: () => void;
};

export function LegendItem({
  iconName = "filledSimple",
  iconColor,
  title,
  description,
  withInfoIcon,
  onInfoIconClick,
}: Props) {
  return (
    <Container>
      <Icon name={iconName} color={iconColor} />
      <Outlet vertical="spacing2px">
        <Text
          variant={{ "@initial": "paragraph3-Bold", "@bp3": "paragraph2-Bold" }}
          color="textBaseDefault"
        >
          {title}
        </Text>
        <Outlet horizontal="spacing4px">
          <Text variant="paragraph3-Medium" color="textBaseSubdued">
            {description}
          </Text>
          {withInfoIcon ? (
            <button onClick={onInfoIconClick}>
              <Icon name="filledInfo" color="iconBaseSubdued" size="16" />{" "}
            </button>
          ) : null}
        </Outlet>
      </Outlet>
    </Container>
  );
}
