import { API } from "aws-amplify";

export async function fetchResetPassword(email: string) {
  const apiName = "apiAuthGateway";
  const path = "/reset-password";
  const myInit = {
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    body: {
      email: email,
    },
  };

  try {
    await API.post(apiName, path, myInit);
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error(
      "Une erreur est survenue. Merci de nous écrire à contact@dalma.co si le problème persiste."
    );
  }
}
