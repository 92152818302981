import { deprecatedCSS, deprecatedTheme } from "src/styles/stitches/deprecated-theme";
const sandbox = deprecatedCSS({
  position: "fixed",
  background: deprecatedTheme.colors.fire,
  color: deprecatedTheme.colors.white,
  padding: "8px 40px",
  transform: "translate(-47px, 15px) rotate(-45deg)",
  textTransform: "uppercase",
  zIndex: 20,
  opacity: 0.8,
});

export function Sandbox() {
  return <div className={sandbox()}>Sandbox</div>;
}
