import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

export const StyledOutlet = styled(Outlet, {
  padding: theme.spacing.spacing32px,
});

export const StyledIbanInputLabel = styled(Text, {
  display: "none",

  "@bp3": {
    display: "block",
  },
});

export const StyledForm = styled("form", {
  flex: 1,
});
