import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Outlet } from "src/components/01_atoms/outlet";
import { MediaCard } from "src/components/02_molecules/media-card";
import { useIsVisible } from "src/hooks/useIsVisible";
import { routes } from "src/routes";
import { styled } from "src/styles/stitches/theme";

const Relative = styled("div", {
  position: "relative",
  width: "100%",
});

const StyledOutlet = styled(Outlet, {
  overflowX: "auto",
  scrollSnapType: "x mandatory",
  padding: `0.25rem 0 0.87rem 1.5rem`,

  // HACK : Padding-right isn't working on Safari
  "&::after": {
    content: "''",
    display: "block",
    flex: "none",
    width: "1.5rem",
    height: "8.25rem",
  },

  "@bp3": {
    padding: "0.25rem 0 0.87rem 0.25rem", // padding for the box-shadow hover effect and the scrollbar

    // HACK : Padding-right isn't working on Safari
    "&::after": {
      content: "''",
      display: "block",
      flex: "none",
      width: "0.25rem",
      height: "9.88rem",
    },
  },
});

const GradientBackground = styled("div", {
  transition: "opacity 0.15s ease-in-out",
  opacity: 0,

  "@bp3": {
    position: "absolute",
    width: "75%",
    height: "12.5rem",
    bottom: 0,
    right: 0,
    pointerEvents: "none",
    background: "linear-gradient(270deg, #FFFFFF 4.07%, rgba(255, 255, 255, 0) 78.2%)",
  },

  "@bp4": {
    opacity: 0,
  },

  variants: {
    isVisible: {
      true: {
        opacity: 1,
      },
    },
  },
});

export function MediaCards() {
  const history = useHistory();

  const lastMediaCardRef = useRef<HTMLDivElement>(null);

  const lastMediaCardisVisible = useIsVisible(lastMediaCardRef, "330px");

  return (
    <Relative>
      <StyledOutlet horizontal="spacing16px">
        <MediaCard
          title="Comment me faire rembourser ?"
          background="fillAccent"
          hover="blue"
          image="/assets/media/money-note.svg"
          imageDescription="Illustration d'un billet avec un chien"
          link="https://intercom.help/dalma/fr/articles/6154812-comment-effectuer-ma-demande-de-remboursement"
        />
        <MediaCard
          title="Les articles à la une"
          background="fillDecorativeYellow"
          hover="yellow"
          image="/assets/media/newspaper.svg"
          imageDescription="Illustration d'un journal"
          link="https://www.dalma.co/blog"
        />
        <MediaCard
          title="Offrez 1 mois, gagnez jusqu’à 200€"
          background="fillDecorativePurple"
          hover="purple"
          image="/assets/media/gift-raf.svg"
          imageDescription="Illustration d'un cadeau"
          imagePosition="raf"
          onClick={() => history.push(routes.sponsorhip)}
          ref={lastMediaCardRef}
        />
      </StyledOutlet>
      <GradientBackground isVisible={!lastMediaCardisVisible} />
    </Relative>
  );
}
