import { Flex, FlexProps, Center, SimpleGrid, Tooltip } from "@chakra-ui/react";
import {
  OST,
  EXAM,
  DET,
  VET,
  CALIM,
  PHYT,
  LABO,
  CLINI,
  SOIN,
  EUTH,
  HOSP,
  ANTP,
  PHAR,
  VACC,
  VERM,
  STER,
  AMB,
  HOM,
  EXMALA,
  MALAHERI,
  MALAEVI,
  EXTEVET,
} from "./../../styles/CustomIcons";
import FilesIcon from "@iconscout/react-unicons/icons/uil-file-alt";
import { Title } from "../texts/Title";
import { Texts } from "../texts/Texts";
import { OptionsDetails } from "../accordion/OptionsDetails";
import { FilesButton } from "../buttons/FilesButton";
import { Contract } from "src/types";
import { GUARANTEES_TABLE } from "src/configs/Legal";

type Props = {
  small?: boolean;
  mh?: string;
  boxShadow?: string;
  rounded?: string;
  color?: string;
  iconColor?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  paidAmount?: number;
  values: Contract;
  fromPricing?: boolean;
};

export const RefundInformations = ({
  w = "100%",
  mh = "52px",
  rounded = "card",
  values,
  fromPricing = true,
  ...props
}: Props & FlexProps) => {
  return (
    <Flex
      minH={mh}
      w={w}
      flex={1}
      borderRadius={rounded}
      rounded={rounded}
      direction="column"
      {...props}
    >
      {fromPricing && (
        <Flex
          maxW={["100%", "100%", "546px"]}
          align={["center", "center", "flex-start", "flex-start"]}
        >
          <Title textAlign={["center", "center", "left"]} mt="72px" fontSize="32px">
            Tout comprendre sur mes remboursements
          </Title>
        </Flex>
      )}

      <Flex direction={["column", "column", "column", "column", "row", "row"]} mt="50px">
        <Flex>
          <Title
            fontSize="20px"
            textAlign="left"
            mb={["10", "10", "10", "10", "10", "0"]}
            w="250px"
            mr="40px"
          >
            Ce que nous couvrons chez Dalma
          </Title>
        </Flex>

        <Flex direction="column" flex={1}>
          <Flex flex={1} w={w}>
            <OptionsDetails
              bg="#FAFAFA"
              informations={
                fromPricing
                  ? [
                      {
                        name: "Intégrale",
                        color: "#4E68F6",
                        bg: "#DBEBFF",
                      },
                      {
                        name: "Essentielle",
                        color: "#4E68F6",
                        bg: "#DBEBFF",
                      },
                    ]
                  : [
                      {
                        name: "Inclus",
                        color: "#4E68F6",
                        bg: "#DBEBFF",
                      },
                    ]
              }
              optionTitle="Les frais liés à un accident"
              optionDescription="Quelles sont les dépenses remboursées ?"
              optionIcon={
                <Flex w="50px" h="50px">
                  <HOSP size="45px" color="#4E68F6" />
                </Flex>
              }
              optionsIncluded={[
                {
                  icon: <VET size="35px" color="#4E68F6" />,
                  description: "Honoraires du docteur vétérinaire",
                },
                {
                  icon: <SOIN size="35px" color="#4E68F6" />,
                  description: "Soins et de médicaments prescrits par le docteur vétérinaire",
                },
                {
                  icon: <LABO size="35px" color="#4E68F6" />,
                  description:
                    "Analyses de laboratoire, examens radiologiques, radiothérapie et radiodiagnostics",
                },
                {
                  icon: <AMB size="35px" color="#4E68F6" />,
                  description:
                    "Transport en ambulance animalière, validé par le docteur vétérinaire",
                },
                {
                  icon: <OST size="35px" color="#4E68F6" />,
                  description: "Intervention chirurgicale",
                },
                {
                  icon: <CLINI size="35px" color="#4E68F6" />,
                  description:
                    "Séjour en clinique vétérinaire nécéssité par une intervention chirurgicale",
                },
                {
                  icon: <PHAR size="35px" color="#4E68F6" />,
                  description:
                    "Frais de pharmacie, d’anesthésie et de soins liés directement à une intervention chirurgicale",
                },
              ]}
            />
          </Flex>
          <Flex mt="32px" w={w}>
            <OptionsDetails
              bg="#FAFAFA"
              informations={
                fromPricing
                  ? [
                      {
                        name: "Intégrale",
                        color: "#4E68F6",
                        bg: "#DBEBFF",
                      },
                      {
                        name: "Essentielle",
                        color: "#9E9E9E",
                        bg: "#F5F5F5",
                      },
                    ]
                  : values.options && values.options.pack && values.options.pack.includes("sick")
                  ? [
                      {
                        name: "Inclus",
                        color: "#4E68F6",
                        bg: "#DBEBFF",
                      },
                    ]
                  : [
                      {
                        name: "Non inclus",
                        color: "#9E9E9E",
                        bg: "#F5F5F5",
                      },
                    ]
              }
              optionTitle="Les frais liés à une maladie"
              optionDescription="Quelles sont les dépenses remboursées ?"
              optionIcon={
                <Flex w="50px" h="50px">
                  <SOIN size="45px" color="#4E68F6" />
                </Flex>
              }
              optionsIncluded={[
                {
                  icon: <VET size="35px" color="#4E68F6" />,
                  description: "Honoraires du docteur vétérinaire",
                },
                {
                  icon: <SOIN size="35px" color="#4E68F6" />,
                  description: "Soins et de médicaments prescrits par le docteur vétérinaire",
                },
                {
                  icon: <LABO size="35px" color="#4E68F6" />,
                  description:
                    "Analyses de laboratoire, examens radiologiques, radiothérapie et radiodiagnostics",
                },
                {
                  icon: <AMB size="35px" color="#4E68F6" />,
                  description:
                    "Transport en ambulance animalière, validé par le docteur vétérinaire",
                },
                {
                  icon: <OST size="35px" color="#4E68F6" />,
                  description: "Intervention chirurgicale",
                },
                {
                  icon: <CLINI size="35px" color="#4E68F6" />,
                  description:
                    "Séjour en clinique vétérinaire nécéssité par une intervention chirurgicale",
                },
                {
                  icon: <PHAR size="35px" color="#4E68F6" />,
                  description:
                    "Frais de pharmacie, d’anesthésie et de soins liés directement à une intervention chirurgicale",
                },
                {
                  icon: <EUTH size="35px" color="#4E68F6" />,
                  description: "Frais d’euthanasie en cas de maladie",
                },
              ]}
            />
          </Flex>
          <Flex mt="32px" w={w}>
            <OptionsDetails
              bg="#FAFAFA"
              bgOptionIcons={"#DDF0EA"}
              optionTitle="Les frais liés au forfait bien-être"
              optionDescription="Quelles sont les dépenses remboursées ?"
              optionIcon={
                <Flex w="50px" h="50px">
                  <VACC size="45px" color="#00704F" />
                </Flex>
              }
              informations={
                fromPricing
                  ? [
                      {
                        name: "Forfait bien-être",
                        color: "#00704F",
                        bg: "#DDF0EA",
                      },
                    ]
                  : values &&
                    values.coverage &&
                    values.coverage.prevention_limit &&
                    parseInt(values.coverage.prevention_limit) > 0
                  ? [
                      {
                        name: "Inclus",
                        color: "#4E68F6",
                        bg: "#DBEBFF",
                      },
                    ]
                  : [
                      {
                        name: "Non inclus",
                        color: "#9E9E9E",
                        bg: "#F5F5F5",
                      },
                    ]
              }
              optionsIncluded={[
                {
                  icon: <OST size="35px" color="#00704F" />,
                  description: "Ostéopathie",
                },
                {
                  icon: <EXAM size="35px" color="#00704F" />,
                  description: "Examen Annuel",
                },
                {
                  icon: <DET size="35px" color="#00704F" />,
                  description: "Détartrage",
                },
                {
                  icon: <CALIM size="35px" color="#00704F" />,
                  description: "Complément alimentaire",
                },
                {
                  icon: <PHYT size="35px" color="#00704F" />,
                  description: "Phytothérapie",
                },
                {
                  icon: <ANTP size="35px" color="#00704F" />,
                  description: "Antiparasite",
                },
                {
                  icon: <VACC size="35px" color="#00704F" />,
                  description: "Vaccin",
                },
                {
                  icon: <VERM size="35px" color="#00704F" />,
                  description: "Vermifuge",
                },
                {
                  icon: <STER size="35px" color="#00704F" />,
                  description: "Stérilisation",
                },
                {
                  icon: <HOM size="35px" color="#00704F" />,
                  description: "Homéopathie",
                },
                {
                  icon: <EXAM size="35px" color="#00704F" />,
                  description: "Désensibilisation",
                },
                {
                  icon: <EXAM size="35px" color="#00704F" />,
                  description: "Physiothérapie",
                },
                {
                  icon: <EXAM size="35px" color="#00704F" />,
                  description: "Aromathérapie",
                },
                {
                  icon: <EXAM size="35px" color="#00704F" />,
                  description: "Luminothérapie",
                },
                {
                  icon: <EXAM size="35px" color="#00704F" />,
                  description: "Thérapie laser",
                },
                {
                  icon: <EXAM size="35px" color="#00704F" />,
                  description: "Naturopathie",
                },
              ]}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex w="100%" direction={["column", "column", "column", "column", "row", "row"]} mt="50px">
        <Flex>
          <Title
            fontSize="20px"
            textAlign="left"
            mb={["10", "10", "10", "10", "10", "0"]}
            w="250px"
            mr="40px"
          >
            Ce que nous ne couvrons pas
          </Title>
        </Flex>

        <Flex justifyContent="flex-end" w={["100%", "100%", "100%", "100%", "100%", "100%"]}>
          <SimpleGrid
            rounded="8px"
            w="100%"
            bg="#FAFAFA"
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.08)"
            p={["5", "10"]}
            spacing={["15px", "24px"]}
            columns={[1, 1, 2, 2, 2, 2, 2]}
          >
            <Flex>
              <Flex>
                <Center bg="#FAE6E7" h="56px" w="56px" rounded="8px">
                  <EXMALA size="50px" borderWidth="1px" borderColor="#D6444C" color="#D6444C" />
                </Center>
              </Flex>

              <Tooltip
                label={
                  "Ce sont les maladies ou accidents survenus ou constatés avant la souscription de votre contrat ou dont l’origine est antérieure à la date de souscription de votre contrat ainsi que leurs suites ou conséquences."
                }
                bg="#FFFFFF"
              >
                <Flex alignItems="center" ml="24px">
                  <Texts
                    textDecoration="underline"
                    textAlign="left"
                    fontSize="12px"
                    color="#212121"
                  >
                    Maladies ou accidents préexistants à la souscription
                  </Texts>
                </Flex>
              </Tooltip>
            </Flex>

            <Flex>
              <Flex>
                <Center bg="#FAE6E7" h="56px" w="56px" rounded="8px">
                  <MALAHERI size="35px" color="#D6444C" />
                </Center>
              </Flex>

              <Tooltip
                label={
                  "Ce sont les frais exposés pour toute anomalie, infirmité, malformation et maladie d’origine congénitale ou héréditaire. Retrouvez la liste complète dans notre tableau de garanties."
                }
                bg="#FFFFFF"
              >
                <Flex alignItems="center" ml="24px">
                  <Texts
                    textDecoration="underline"
                    textAlign="left"
                    fontSize="12px"
                    color="#212121"
                  >
                    {"Maladies d'origine congénitales ou héréditaire"}
                  </Texts>
                </Flex>
              </Tooltip>
            </Flex>
            <Flex>
              <Flex>
                <Center bg="#FAE6E7" h="56px" w="56px" rounded="8px">
                  <MALAEVI size="35px" color="#D6444C" />
                </Center>
              </Flex>

              <Tooltip
                label={
                  "Ce sont les frais exposés par les maladies qui auraient normalement pu être évitées si des vaccins préventifs avaient été faits. Retrouvez la liste complète dans notre tableau de garanties."
                }
                bg="#FFFFFF"
              >
                <Flex alignItems="center" ml="24px">
                  <Texts
                    textDecoration="underline"
                    textAlign="left"
                    fontSize="12px"
                    color="#212121"
                  >
                    Maladies qui auraient pu être évitées
                  </Texts>
                </Flex>
              </Tooltip>
            </Flex>

            <Flex>
              <Flex>
                <Center bg="#FAE6E7" h="56px" w="56px" rounded="8px">
                  <EXTEVET size="35px" color="#D6444C" />
                </Center>
              </Flex>

              <Tooltip
                label={
                  "Toute intervention qui n’est pas effectuée par un docteur vétérinaire régulièrement inscrit à l’Ordre des Vétérinaires."
                }
                bg="#FFFFFF"
              >
                <Flex alignItems="center" ml="24px">
                  <Texts
                    textDecoration="underline"
                    textAlign="left"
                    fontSize="12px"
                    color="#212121"
                  >
                    {"Tous frais prescrits par un vétérinaire non inscrit à l’Ordre"}
                  </Texts>
                </Flex>
              </Tooltip>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Flex>
      <a href={GUARANTEES_TABLE} target="_blank" rel="noopener noreferrer">
        <Flex mt="24px" w={["100%", "100%", "100%", "100%", "100%", "100%"]}>
          <Flex display={["none", "none", "none", "none", "flex", "flex"]}>
            <Flex w="250px" mr="40px"></Flex>
          </Flex>

          <Flex flex={1} w={["100%", "100%", "100%", "100%", "100%", "100%"]}>
            <FilesButton
              downloadable
              description="Télécharger notre tableau de garanties"
              icon={<FilesIcon color="#4E68F6" />}
              iconBackground="#DBEBFF"
            />
          </Flex>
        </Flex>
      </a>
    </Flex>
  );
};
