import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing16px} ${theme.spacing.spacing24px}`,
  borderBottom: `1px solid ${theme.colors.borderBaseSubdued}`,

  "@bp3": {
    padding: `${theme.spacing.spacing32px}`,
    borderBottom: "none",
  },
});

const EmptyDiv = styled("div", {
  width: theme.spacing.spacing24px,
  height: theme.spacing.spacing24px,

  variants: {
    isVisibleOnMobile: {
      true: {
        display: "block",

        "@bp3": {
          display: "none",
        },
      },
    },
  },
});

const StyledText = styled(Text, {
  "@bp3": {
    display: "none",
  },
});

const StyledButton = styled("button", {
  variants: {
    isHiddenOnMobile: {
      true: {
        display: "none",

        "@bp3": {
          display: "block",
        },
      },
    },
    isHiddenOnDesktop: {
      true: {
        "@bp3": {
          display: "none",
        },
      },
    },
  },
});

type Props = {
  isBackButtonDisplayed?: boolean;
  onCloseButtonClick?: () => void;
  onBackButtonClick?: () => void;
};

export function ClaimStatusSidebarHeader({
  isBackButtonDisplayed,
  onCloseButtonClick,
  onBackButtonClick,
}: Props) {
  const { isAddNeedInfoDocumentSidebarOpen } = useClaimStatusSidebar();

  return (
    <StyledOutlet horizontal="0" align="center" justify="spaceBetween">
      {isBackButtonDisplayed ? (
        <StyledButton
          type="button"
          onClick={onBackButtonClick}
          isHiddenOnMobile={isAddNeedInfoDocumentSidebarOpen}
        >
          <Icon name="arrowLeft" />
        </StyledButton>
      ) : (
        <EmptyDiv />
      )}
      {isAddNeedInfoDocumentSidebarOpen ? (
        <EmptyDiv isVisibleOnMobile={isAddNeedInfoDocumentSidebarOpen} />
      ) : null}
      <StyledText variant="paragraph1-Bold" color="textBaseDefault">
        Mes remboursements
      </StyledText>
      <StyledButton
        type="button"
        onClick={onCloseButtonClick}
        isHiddenOnDesktop={isAddNeedInfoDocumentSidebarOpen}
      >
        <Icon name="outlinedClose" />
      </StyledButton>
    </StyledOutlet>
  );
}
