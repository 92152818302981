import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { Modal } from "src/components/04_layouts/modal-layout";
import { styled, theme } from "src/styles/stitches/theme";

const StyledOutlet = styled(Outlet, {
  padding: theme.spacing.spacing32px,
});

const StyledText = styled(Text, {
  textAlign: "center",
});

const QRCodeContainer = styled("div", {
  display: "flex",
  padding: theme.spacing.spacing16px,
  background: theme.colors.fillBaseDisabled,
  borderRadius: theme.radii[16],
});

const Img = styled("img", {
  width: "10rem",
  height: "10rem",
});

const QRCode = styled("img", {
  variants: {
    size: {
      8: {
        width: "8rem",
        height: "8rem",
      },
      10: {
        width: "10rem",
        height: "10rem",
      },
    },
    borderRadius: {
      16: {
        borderRadius: theme.radii[16],
      },
    },
    boxShadow: {
      true: {
        boxShadow: theme.elevation.elevation1Primary,
      },
    },
  },
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  qrCodeWithImage?: boolean;
};

export function DownloadAppModal({ isOpen, onClose, title, description, qrCodeWithImage }: Props) {
  return (
    <Modal isOpen={isOpen} label="Téléchargez l’app Dalma" onDismiss={onClose}>
      <StyledOutlet vertical="spacing16px" align="center">
        <StyledText variant="display3-Bold" color="textBaseDefault">
          {title}
        </StyledText>
        <Outlet vertical="spacing32px" align="center">
          <StyledText variant="paragraph1-Medium" color="textBaseSubdued">
            {description}
          </StyledText>
          {qrCodeWithImage ? (
            <QRCodeContainer>
              <Img
                src="/assets/media/vet-advice.svg"
                alt="Illustration d'une vétérinaire dans un téléphone"
              />
              <QRCode
                src="/assets/media/download-app-qr-code.svg"
                size={10}
                borderRadius={16}
                boxShadow
              />
            </QRCodeContainer>
          ) : (
            <QRCode src="/assets/media/download-app-qr-code.svg" size={8} />
          )}
          <Button variant="accent" size="lg" fluid onClick={onClose}>
            J’ai compris
          </Button>
        </Outlet>
      </StyledOutlet>
    </Modal>
  );
}
