import { createStitches } from "@stitches/react";

/** @deprecated */
export const {
  theme: deprecatedTheme,
  styled: deprecatedStyled,
  css: deprecatedCSS,
  keyframes: deprecatedKeyframes,
} = createStitches({
  media: {
    bp1: "(min-width: 576px)",
    bp2: "(min-width: 768px)",
    bp3: "(min-width: 1024px)",
  },
  theme: {
    colors: {
      white: "#FFFFFF",
      black: "#0B2214",

      lilac: "#B9C5FA",

      inputBlueBorder: "#C3D7F0",
      inputGreyBorder: "#DEDFE6", //DBEBFF

      electric: "#4E68F6",
      electric500: "#4E68F6",
      electric800: "#1F2A62",
      electric900: "#101531",
      electricOpacity: "rgba(78, 104, 246, 0.5)",
      fire: "#FE6F18",

      accentOrange: "#FE6F18",
      accentOrangeLight: "#FAEEE6",

      blueBackGround: "#F1F7FF",
      greenBackGround: "#DDF0EA",
      orangeBackGround: "#FAE6E7",
      redBackGround: "#FAEBF7",
      yellowBackGround: "#FAEEE6",

      infoIconColor: "#688AB8",
      textColor: "#212121",

      green: "#00704F",

      blue100: "#E9F3FD",
      blue200: "#DBEBFF",
      blue600: "#688AB8",
      blue800: "#144283",

      gray100: "#F5F5F5",
      gray300: "#E0E0E0",
      gray400: "#BDBDBD",
      gray500: "#9E9E9E",
      gray600: "#757575",
      gray700: "#616161",
      gray800: "#424242",
      gray900: "#212121",

      pink: "#FEC6E3",
      red: "#e6787a",
    },
  },
});
