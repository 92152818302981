import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { CheckboxCard } from "src/components/02_molecules/selectable-card";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { FilledNames, OutlinedNames } from "src/components/01_atoms/icon";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { useActor } from "@xstate/react";
import { onboardingStepsService } from "src/store/onboarding/machine";
import { OnboardingForm } from "./form";
import { ReferrerType } from "src/types";
import { Input } from "src/components/01_atoms/input";

const referrerTypes: {
  value: ReferrerType;
  label: string;
  iconName: FilledNames | OutlinedNames;
}[] = [
  { value: "MY_VET", label: "Mon vétérinaire", iconName: "filledDog" },
  { value: "SOCIAL_NETWORKS", label: "Réseaux sociaux", iconName: "filledFacebook" },
  { value: "RESEARCH_ON_INTERNET", label: "Recherches sur internet", iconName: "outlinedWorld" },
  { value: "COMPARATORS", label: "Comparateur d’assurances", iconName: "filledGraph" },
  { value: "MEDIAS", label: "Médias (TV, Radio) ", iconName: "filledTv" },
  { value: "WORD_OF_MOUTH", label: "Bouche à oreille", iconName: "filledUserEcho" },
  { value: "REFERRER", label: "Parrainage", iconName: "filledFace" },
  { value: "BREEDERS", label: "Éleveur", iconName: "filledBone" },
  { value: "OUT_OF_HOME", label: "Publicité dans la rue", iconName: "filledQuote" },
  { value: "OTHERS", label: "Autre : Précisez", iconName: "filledSpark" },
];

type Props = {
  setSelectedReferrerTypes: (selectedReferrerTypes: ReferrerType[]) => void;
  setReferrerDetails: (referrerDetails: string) => void;
  register: UseFormRegister<OnboardingForm>;
  watch: UseFormWatch<OnboardingForm>;
};

export function HowDidYouKnowDalma({
  setSelectedReferrerTypes,
  setReferrerDetails,
  register,
  watch,
}: Props) {
  const [, send] = useActor(onboardingStepsService);

  return (
    <>
      <SidesheetContentHeader
        title="Comment avez-vous connu Dalma ?"
        description="Vous pouvez choisir plusieurs réponses"
      />
      <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
        <Outlet vertical="spacing16px" fluid>
          {referrerTypes.map((referrerType) => (
            <CheckboxCard
              key={referrerType.value}
              title={referrerType.label}
              iconName={referrerType.iconName}
              value={referrerType.value}
              checked={watch("referrer_type").includes(referrerType.value)}
              {...register("referrer_type")}
              avatarBackgroundColor="base"
            />
          ))}
          {watch("referrer_type").includes("OTHERS") ? (
            <Input placeholder="Précisez" {...register("referrer_details")} />
          ) : null}
        </Outlet>
        <Button
          type="button"
          variant="accent"
          size={{ "@initial": "md", "@bp3": "lg" }}
          fluid
          disabled={
            watch("referrer_type").length < 1 ||
            (watch("referrer_type").includes("OTHERS") && watch("referrer_details").length < 1)
          }
          onClick={() => {
            setSelectedReferrerTypes(watch("referrer_type"));
            setReferrerDetails(watch("referrer_details"));
            send("NEXT_STEP");
          }}
        >
          Continuer
        </Button>
      </Outlet>
    </>
  );
}
