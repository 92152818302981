import { ClaimStatusSidebar } from "src/components/03_organisms/claim-status-sidebar";
import { HomeNavbar } from "src/components/03_organisms/home-navbar";
import { RightSidebar } from "src/components/03_organisms/home-navbar/right-sidebar";
import { MobileHeader } from "src/components/03_organisms/mobile-header";
import { MobileMenu } from "src/components/03_organisms/mobile-menu";
import { RefundSidebar } from "src/components/03_organisms/refund-sidebar";
import { useBanner } from "src/hooks/use-banner";
import { styled, theme } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  width: "100vw",
});

const ContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",

  "@bp3": {
    height: "100%",
    minHeight: "100vh",
  },
});

const Main = styled("main", {
  paddingBottom: theme.spacing.spacing64px,
  width: "100%",

  "@bp2": {
    width: "unset",
  },

  "@bp3": {
    width: "100%",
    paddingLeft: `20.25rem`, // width of the left sidebar + padding
    paddingRight: `15.5rem`, // width of the tight sidebar + padding
  },

  "@bp4": {
    maxWidth: `calc(45.5rem + 2 * ${theme.spacing.spacing32px})`,
    paddingLeft: theme.spacing.spacing28px,
    paddingRight: theme.spacing.spacing28px,
  },

  variants: {
    isBannerVisible: {
      true: {
        "@bp3": {
          paddingTop: `${theme.spacing.spacing64px}`, // banner height
        },
      },
    },
  },
});

type HomeLayoutProps = WithChildren;

export function HomeLayout({ children }: HomeLayoutProps) {
  const { isClosed } = useBanner();

  return (
    <Wrapper>
      <MobileHeader />
      <MobileMenu />
      <ContentWrapper>
        <HomeNavbar />
        <Main isBannerVisible={!isClosed}>{children}</Main>
        <RightSidebar />
      </ContentWrapper>
      <RefundSidebar />
      <ClaimStatusSidebar />
    </Wrapper>
  );
}
