import { forwardRef, useState } from "react";
import { Input, InputProps } from "src/components/01_atoms/input";
import { Outlet } from "src/components/01_atoms/outlet";
import { PasswordHint } from "src/components/02_molecules/password-hint/password-hint";
import { PasswordCriteria, PasswordCriteriasChecker } from "./_utils";

type Props = {
  criterias: PasswordCriteria<string, string>[];
  criteriasChecker: PasswordCriteriasChecker;
};

export const InputPasswordWithCriteria = forwardRef<HTMLInputElement, InputProps & Props>(
  ({ criterias, criteriasChecker, ...rest }, ref) => {
    const [passwordErrors, setPasswordErrors] =
      useState<ReturnType<PasswordCriteriasChecker> | null>(null);

    return (
      <Outlet vertical="spacing8px" fluid>
        <Input
          ref={ref}
          autoComplete="new-password"
          {...rest}
          onChange={(e) => {
            rest.onChange?.(e);
            setPasswordErrors(criteriasChecker(e.target.value));
          }}
        />
        {criterias.map((data) => {
          const state = !rest.value
            ? "idle"
            : passwordErrors && passwordErrors.includes(data.errorMessage)
            ? "invalid"
            : "valid";
          return <PasswordHint key={data.criteria} state={state} label={data.criteria} />;
        })}
      </Outlet>
    );
  }
);

InputPasswordWithCriteria.displayName = "InputPasswordWithCriteria";
