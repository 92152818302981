import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { AmountError } from "src/backApi/newClaim/utils/parse-amount";
import { newClaim } from "src/backApi/newClaimApi";
import { Button } from "src/components/01_atoms/button";
import { Icon } from "src/components/01_atoms/icon";
import { Outlet } from "src/components/01_atoms/outlet";
import { Spinner } from "src/components/01_atoms/spinner";
import { toast } from "src/components/01_atoms/toast";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { FDS } from "src/configs/Legal";
import { useGetContract } from "src/hooks/data";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { styled, theme } from "src/styles/stitches/theme";
import { ClaimFileType, Contract } from "src/types";
import { reportError } from "src/utils/error";
import { z } from "zod";
import { ImportDocument } from "./_import-document";
import { Text } from "src/components/01_atoms/text";

const Form = styled("form", {
  flex: 1,
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing36px}`,
  },
});

const StyledLink = styled("a", {
  color: theme.colors.textAccent,
  textDecoration: "none",
});

const SpinnerWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

export type Documents = {
  invoice: { value: File }[];
  careRecord: { value: File }[];
  prescription?: { value: File }[];
};

const fileArray = z.array(
  z.object({
    value: z.instanceof(File),
  })
);
const documentSchema = z.object({
  invoice: fileArray.nonempty("Vous devez ajouter au moins une facture"),
  careRecord: fileArray.nonempty("Vous devez ajouter au moins une feuille de soins"),
  prescription: fileArray,
});
const documentSchemaIfSelectedConsultationIsOnlyPrevention = z.object({
  invoice: fileArray.nonempty("Vous devez ajouter au moins une facture"),
  prescription: fileArray,
});

export function DocumentsForm() {
  const {
    currentStep,
    setCurrentStep,
    selectedPetToContext,
    amount,
    selectedConsultationTypes,
    setIsOpen,
    selectedAccidentDate,
    selectedDiseaseDate,
    visitMotivesAccident,
    visitMotivesDisease,
  } = useRefundSidebar();
  const { data, isLoading: isContractLoading } = useGetContract();
  const [currentPetContract, setCurrentPetContract] = useState<Contract>();
  const [isLoading, setIsLoading] = useState(false);

  // Get the current pet
  useEffect(() => {
    if (currentPetContract) return;
    if (!isContractLoading && data) {
      const currentPet = data.find(
        (contract) => contract.contract.contract_uuid === selectedPetToContext?.contract_uuid
      );

      // Sentry
      if (!currentPet) {
        toast.error(
          "Impossible de trouver votre animal, veuillez réessayer. Si le problème persiste, contactez-nous."
        );
        reportError(
          `No pet found for the selected pet on the documents form. selectedPet = ${selectedPetToContext?.contract_uuid}`
        );
        return;
      }

      setCurrentPetContract(currentPet);
    }
  }, [isContractLoading, data, selectedPetToContext, currentPetContract]);

  const isConsultationTypeOnlyPrevention =
    selectedConsultationTypes.length === 1 && selectedConsultationTypes[0] === "PREVENTION";

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<Documents>({
    resolver: zodResolver(
      isConsultationTypeOnlyPrevention
        ? documentSchemaIfSelectedConsultationIsOnlyPrevention
        : documentSchema
    ),
    defaultValues: {
      careRecord: [],
      invoice: [],
      prescription: [],
    },
    mode: "onChange",
  });

  const invoiceMethods = useFieldArray({
    control,
    name: "invoice",
  });
  const careRecordMethods = useFieldArray({
    control,
    name: "careRecord",
  });
  const prescriptionMethods = useFieldArray({
    control,
    name: "prescription",
  });

  const onSubmit: SubmitHandler<Documents> = async (values) => {
    setIsLoading(true);

    const careRecordFiles = isConsultationTypeOnlyPrevention
      ? []
      : values.careRecord.map((file) => ({
          filename: file.value.name,
          mime_type: file.value.type,
          kind: "CARE_RECORD" as ClaimFileType,
          file: file.value,
        }));
    const invoiceFiles = values.invoice.map((file) => ({
      filename: file.value.name,
      mime_type: file.value.type,
      kind: "INVOICE" as ClaimFileType,
      file: file.value,
    }));
    const prescriptionFiles =
      values.prescription?.map((file) => ({
        filename: file.value.name,
        mime_type: file.value.type,
        kind: "PRESCRIPTION" as ClaimFileType,
        file: file.value,
      })) || [];

    const files = [...careRecordFiles, ...invoiceFiles, ...prescriptionFiles];

    if (!currentPetContract || !amount || !selectedConsultationTypes.length) {
      setIsOpen(false);
      toast.error(
        "Des informations sont manquantes pour créer une demande de remboursement. Veuillez recommencer."
      );

      return;
    }

    try {
      await newClaim(
        files,
        currentPetContract.contract.contract_id,
        currentPetContract.contract.contract_uuid,
        amount,
        selectedConsultationTypes,
        selectedAccidentDate,
        selectedDiseaseDate,
        visitMotivesAccident,
        visitMotivesDisease
      );

      setCurrentStep(currentStep + 1);
    } catch (error) {
      reportError(error);

      if (error instanceof AmountError) {
        toast.error(error.message);
        return;
      }

      toast.error(
        "Une erreur s'est produite lors de l'envoi de votre demande. Vérifiez que les noms des fichiers ne contiennent pas de caractères spéciaux. Si le problème persiste, contactez-nous."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const MAX_FILE_SIZE = 50_000_000; // 50MB
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileTmp: File | undefined = event.target.files?.[0];
    if (fileTmp) {
      if (fileTmp.size > MAX_FILE_SIZE) {
        toast.error("Le fichier est trop volumineux, il doit faire moins de 50MB.");
        return;
      }

      if (event.currentTarget.name === "invoice") {
        invoiceMethods.append({ value: fileTmp });
      }
      if (event.currentTarget.name === "careRecord") {
        careRecordMethods.append({ value: fileTmp });
      }
      if (event.currentTarget.name === "prescription") {
        prescriptionMethods.append({ value: fileTmp });
      }

      event.target.value = "";
    }
  };

  if (isContractLoading || !currentPetContract) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledOutlet vertical={{ "@initial": "spacing32px", "@bp3": "0" }} fullHeight>
        <SidesheetContentHeader
          title="Justificatifs"
          subtitle="Demande de remboursement"
          alertIconName="filledInfo"
          alertText={
            isConsultationTypeOnlyPrevention ? undefined : (
              <>
                En fonction du pépin de santé de votre animal, nous pouvons être amenés à vous
                demander des documents ou justificatifs additionnels.
              </>
            )
          }
        />
        <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
          <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fluid>
            <ImportDocument
              loading={isLoading}
              step={1}
              onFileChange={onFileChange}
              name="invoice"
              methods={invoiceMethods}
              title="Preuve de paiement"
              description="Facture avec preuve que le paiement a été effectué"
            />
            {isConsultationTypeOnlyPrevention ? null : (
              <ImportDocument
                loading={isLoading}
                step={2}
                onFileChange={onFileChange}
                name="careRecord"
                methods={careRecordMethods}
                title="Feuille de soins"
                description="Feuille de soins dûment remplie par votre vétérinaire"
              />
            )}
            <ImportDocument
              loading={isLoading}
              step={"optionnelle"}
              onFileChange={onFileChange}
              name="prescription"
              methods={prescriptionMethods}
              title="Autres documents"
              description="Ordonnance, compte-rendu  de la visite vétérinaire, justificatif vaccinal..."
            />
          </Outlet>
          <Outlet
            vertical="spacing32px"
            justify={{ "@bp3": "spaceBetween" }}
            align={"center"}
            fluid
          >
            <Button
              type="submit"
              variant="accent"
              size="lg"
              fluid
              disabled={!isValid || isLoading}
              loading={isLoading}
            >
              Envoyer la demande
            </Button>
            <StyledLink
              href={FDS}
              target="_blank"
              rel="noreferrer"
              title="Télécharger la feuille de soins"
            >
              <Outlet horizontal="spacing8px" justify="start" align="center" as="span">
                <Text variant="paragraph2-Bold" color="textLinkBlue">
                  Télécharger une feuille de soin
                </Text>
                <Icon name="outlinedDownload" color={"iconPrimaryDefault"} size="20" />
              </Outlet>
            </StyledLink>
          </Outlet>
        </Outlet>
      </StyledOutlet>
    </Form>
  );
}
