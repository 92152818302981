import { useActor } from "@xstate/react";
import { useState } from "react";
import { Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { IbanSidebar } from "src/components/03_organisms/iban-sidebar";
import { PetUuidForm } from "src/components/03_organisms/refund-sidebar/pet-uuid-form";
import { matchIsAllContractsDeffered } from "src/helpers";
import { useGetContract } from "src/hooks/data";
import { useGetUnpaidStatus } from "src/hooks/use-get-unpaid-status";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { onboardingStepsService } from "src/store/onboarding/machine";
import { styled, theme } from "src/styles/stitches/theme";
import { AlertDrawer } from "../alert-drawer";
import { AlertModal } from "../alert-modal";

const Container = styled("div", {
  width: "100%",
  padding: `0 ${theme.spacing.spacing24px}`,

  "@bp3": {
    padding: "unset",
  },
});

const Wrapper = styled("div", {
  backgroundColor: theme.colors.fillBaseDisabled,
  padding: "1.5rem 1rem 1rem 1rem",
  borderRadius: theme.radii[20],
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  gap: "2rem",

  "@bp3": {
    flexDirection: "row",
    justifyContent: "center",
    padding: "1.5rem 4rem",
    gap: "1rem",
  },

  "@bp4": {
    padding: "1rem 2rem",
  },
});

const Illustration = styled("img", {
  width: "10rem",
  height: "10rem",
  objectFit: "contain",

  "@bp3": {
    width: "7.5rem",
    height: "7.5rem",
  },
});

const WrapperWithoutIllustration = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: "2rem",

  "@bp3": {
    gap: "1.375rem",
  },

  "@bp4": {
    gap: "6.063rem",
    flexDirection: "row",
    alignItems: "center",
  },
});

const StyledText = styled(Text, {
  textAlign: "center",

  "@bp3": {
    textAlign: "left",
  },
});

export function ClaimsEmptyState() {
  const [isIbanAlertModalOrDrawerOpen, setIsIbanAlertModalOrDrawerOpen] = useState(false);
  const [isIbanSidebarOpen, setIsIbanSidebarOpen] = useState(false);
  const [isUnpaidAlertModalOrDrawerOpen, setIsUnpaidAlertModalOrDrawerOpen] = useState(false);
  const [isDefaultPetModalOpen, setIsDefaultPetModalOpen] = useState(false);
  const [isDefferedAlertModalOrDrawerOpen, setIsDefferedAlertModalOrDrawerOpen] = useState(false);
  const { openRefundSidebar } = useRefundSidebar();

  const { data: contracts } = useGetContract();
  const {
    recoveryAmount,
    recoveryPaymentLink,
    hasUnpaidSubscription,
    hasMultipleUnpaidSubscriptions,
  } = useGetUnpaidStatus();

  const [onboardingStepsState] = useActor(onboardingStepsService);

  if (!contracts) {
    return null;
  }
  const invalidPetUuid = contracts.find((contract) => {
    return contract.pet.is_pet_uuid_default === true;
  });
  const hasSinglePet = contracts.length === 1;
  const iban = onboardingStepsState.context.iban;

  return (
    <>
      <Container>
        <Wrapper>
          <Illustration src="assets/media/pets-empty-state.svg" alt="Illustration d'un chien" />
          <WrapperWithoutIllustration>
            <Outlet
              vertical={{ "@initial": "spacing4px", "@bp3": "spacing2px" }}
              align={{ "@initial": "center", "@bp3": "start" }}
            >
              <>
                <StyledText variant="paragraph2-Bold" color="textBaseDefault">
                  Vous n’avez aucun remboursement
                </StyledText>
                <StyledText variant="paragraph3-Medium" color="textBaseSubdued">
                  Faîtes votre première demande de remboursement
                </StyledText>
              </>
            </Outlet>
            <Button
              type="button"
              variant={{ "@initial": "accent", "@bp3": "smallActionAccent" }}
              size="sm"
              fluid={{ "@initial": "true", "@bp3": "false" }}
              inline={{ "@bp3": "true" }}
              onClick={() => {
                if (hasSinglePet && Boolean(invalidPetUuid)) {
                  setIsDefaultPetModalOpen(true);
                  return;
                }

                if (matchIsAllContractsDeffered(contracts)) {
                  setIsDefferedAlertModalOrDrawerOpen(true);
                  return;
                }

                if (hasUnpaidSubscription) {
                  setIsUnpaidAlertModalOrDrawerOpen(true);
                  return;
                }

                if (!iban) {
                  setIsIbanAlertModalOrDrawerOpen(true);
                  return;
                }

                openRefundSidebar();
              }}
            >
              Me faire rembourser
            </Button>
          </WrapperWithoutIllustration>
        </Wrapper>
      </Container>
      <AlertModal
        isOpen={isUnpaidAlertModalOrDrawerOpen}
        label="Modale d'alerte de régularisation des paiements"
        title="Vous devez régulariser vos paiements"
        description={
          hasMultipleUnpaidSubscriptions
            ? "Nous ne sommes pas parvenus à prélever vos mensualités sur plusieurs de vos contrats. Chacun d’entre eux nécessite une régularisation individuelle.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti."
            : `Nous ne sommes pas parvenus à prélever vos mensualités pour un montant de ${recoveryAmount}€. Régularisez votre situation au plus vite.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti.`
        }
        onDismiss={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
        }}
        buttonText1="Régulariser mes paiements"
        onDismissButton1={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
          window.open(recoveryPaymentLink, "_blank", "noreferrer");
        }}
        buttonText2="Créer une demande de remboursement"
        onDismissButton2={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);

          if (!iban) {
            setIsIbanAlertModalOrDrawerOpen(true);
            return;
          }

          openRefundSidebar();
        }}
      />
      <AlertDrawer
        isOpen={isUnpaidAlertModalOrDrawerOpen}
        title="Vous devez régulariser vos paiements"
        description={
          hasMultipleUnpaidSubscriptions
            ? "Nous ne sommes pas parvenus à prélever vos mensualités sur plusieurs de vos contrats. Chacun d’entre eux nécessite une régularisation individuelle.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti."
            : `Nous ne sommes pas parvenus à prélever vos mensualités pour un montant de ${recoveryAmount}€. Régularisez votre situation au plus vite.  Dans le cas contraire, le traitement de vos demandes de remboursement sera ralenti.`
        }
        onClose={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
        }}
        buttonText1="Régulariser mes paiements"
        onCloseButton1={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);
          window.open(recoveryPaymentLink, "_blank", "noreferrer");
        }}
        buttonText2="Créer une demande de remboursement"
        onCloseButton2={() => {
          setIsUnpaidAlertModalOrDrawerOpen(false);

          if (!iban) {
            setIsIbanAlertModalOrDrawerOpen(true);
            return;
          }

          openRefundSidebar();
        }}
      />
      <AlertModal
        isOpen={isIbanAlertModalOrDrawerOpen}
        label="Modale d'alerte de demande des informations bancaires"
        title="Nous avons besoin de vos informations bancaires"
        description="Vos informations bancaires nous sont utiles pour rembourser vos soins vétérinaires. Cela ne vous prendra qu’une petite minute et vous pourrez faire votre première demande de remboursement"
        onDismiss={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
        }}
        buttonText1="Compléter mes informations"
        onDismissButton1={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          setIsIbanSidebarOpen(true);
        }}
        buttonText2="Annuler"
        onDismissButton2={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
        }}
      />
      <AlertDrawer
        isOpen={isIbanAlertModalOrDrawerOpen}
        title="Nous avons besoin de vos informations bancaires"
        description="Vos informations bancaires nous sont utiles pour rembourser vos soins vétérinaires. Cela ne vous prendra qu’une petite minute et vous pourrez faire votre première demande de remboursement"
        onClose={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
        }}
        buttonText1="Compléter mes informations"
        onCloseButton1={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
          setIsIbanSidebarOpen(true);
        }}
        buttonText2="Annuler"
        onCloseButton2={() => {
          setIsIbanAlertModalOrDrawerOpen(false);
        }}
      />
      <IbanSidebar isOpen={isIbanSidebarOpen} setIsOpen={setIsIbanSidebarOpen} />
      <AlertModal
        isOpen={isDefaultPetModalOpen}
        label="Modale d'alerte du numéro d'identification à compléter"
        title={`Renseignez le numéro d'identification de ${invalidPetUuid?.pet.pet_name}`}
        description="Afin de pouvoir vous rembourser, merci de renseigner le numéro de puce ou tatouage de votre animal. Sans cette information nous ne pourrons pas traiter votre demande."
        onDismiss={() => setIsDefaultPetModalOpen(false)}
      >
        {invalidPetUuid && (
          <PetUuidForm
            selectedPetContract={{
              stripe_contract_id: invalidPetUuid.contract.stripe_contract_id,
              pet_idx: Number(invalidPetUuid.pet.pet_idx),
              pet_uuid_type: invalidPetUuid.pet.pet_uuid_type,
            }}
            setIsDefaultPetModalOpen={setIsDefaultPetModalOpen}
          />
        )}
      </AlertModal>
    </>
  );
}
