import { Intercom } from "./components/intercom";
import { Routes } from "./router";
import { globalStyles } from "./styles/stitches/theme";
import "./utils/i18n";

function App() {
  // Apply Stitches global styles
  globalStyles();

  return (
    <>
      <Routes />
      <div style={{ position: "fixed", bottom: 0, right: 0 }}>
        <Intercom />
      </div>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
