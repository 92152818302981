// import { inspect } from "@xstate/inspect";
import { ContractsResponse, fetchContractsPromise } from "src/backApi/fetchContracts";
import { fetchPaymentMethods, PaymentResponse } from "src/backApi/fetchPaymentMethods";
// import { SANDBOX } from "src/config";
import { Contract } from "src/types";
import { hasAnActiveContract } from "src/utils/data";
import { assign, createMachine, interpret } from "xstate";

// if (SANDBOX) {
//   inspect({ iframe: false });
// }

type OnboardingStepsMachineContext = {
  iban: {
    brand: string;
    last4: string;
  } | null;
  contracts: Contract[];
};

type OnboardingStepsMachineEvents =
  | { type: "FETCH_PAYMENT_METHODS" }
  | { type: "NEXT_STEP" }
  | { type: "PREV_STEP" }
  | {
      type: "IBAN_UPDATED";
      iban: {
        brand: string;
        last4: string;
      } | null;
    };

type OnboardingStepsMachineServices = {
  fetchPaymentMethods: {
    data: PaymentResponse;
  };
  fetchContractsPromise: {
    data: ContractsResponse;
  };
};

export const getOnboardingStepperItems = (iban: { brand: string; last4: string } | null) => {
  const steps = ["howDidYouKnowDalma", "vetClinic"];

  if (!iban) {
    return ["iban", ...steps];
  }

  return steps;
};

const onboardingStepsMachine = createMachine(
  {
    id: "onboardingSteps",
    initial: "idle",
    predictableActionArguments: true,
    context: {
      iban: null,
      contracts: [],
    },
    schema: {
      context: {} as OnboardingStepsMachineContext,
      events: {} as OnboardingStepsMachineEvents,
      services: {} as OnboardingStepsMachineServices,
    },
    tsTypes: {} as import("./machine.typegen").Typegen0,
    states: {
      idle: {
        on: {
          FETCH_PAYMENT_METHODS: {
            target: "fetchingPaymentMethods",
          },
        },
      },
      fetchingPaymentMethods: {
        invoke: {
          src: "fetchPaymentMethods",
          onDone: {
            target: "fetchingContracts",
            actions: "assignIban",
          },
          onError: "error",
        },
      },
      fetchingContracts: {
        invoke: {
          src: "fetchContractsPromise",
          onDone: {
            target: "welcome",
            actions: "assignContracts",
          },
          onError: "error",
        },
      },
      welcome: {
        on: {
          NEXT_STEP: [
            {
              target: "howDidYouKnowDalma",
              cond: "userHasInactiveContract",
            },
            {
              target: "raf",
              cond: "userHasIban",
            },
            {
              target: "iban",
            },
          ],
        },
      },
      iban: {
        on: {
          NEXT_STEP: [
            {
              target: "raf",
              cond: "userHasActiveContract",
            },
            {
              target: "howDidYouKnowDalma",
            },
          ],
        },
      },
      raf: {
        on: {
          NEXT_STEP: "howDidYouKnowDalma",
        },
      },
      howDidYouKnowDalma: {
        on: {
          NEXT_STEP: "vetClinic",
          PREV_STEP: [
            {
              target: "raf",
              cond: "userHasActiveContract",
            },
            {
              target: "welcome",
            },
          ],
        },
      },
      vetClinic: {
        on: {
          PREV_STEP: "howDidYouKnowDalma",
          NEXT_STEP: "deferred",
        },
        invoke: {
          src: "fetchPaymentMethods",
          onDone: {
            actions: "assignIban",
          },
          onError: "error",
        },
      },
      deferred: {
        type: "final",
      },
      error: {
        on: {
          NEXT_STEP: [
            {
              target: "raf",
              cond: "userHasIban",
            },
            {
              target: "iban",
            },
          ],
        },
      },
    },
  },
  {
    services: {
      fetchPaymentMethods,
      fetchContractsPromise,
    },
    guards: {
      userHasIban: (context) => Boolean(context.iban),
      userHasActiveContract: (context) => Boolean(hasAnActiveContract(context.contracts)),
      userHasInactiveContract: (context) => Boolean(!hasAnActiveContract(context.contracts)),
    },

    actions: {
      assignIban: assign({
        iban: (_, event) => {
          return event.data.selected_payment.refund;
        },
      }),
      assignContracts: assign({
        contracts: (_, event) => event.data.data,
      }),
    },
  }
);
export const onboardingStepsService = interpret(onboardingStepsMachine);
onboardingStepsService.start();
