import { createContext, ReactNode, useContext, useEffect, useReducer, useRef } from "react";
import { IntercomProvider as Provider } from "src/use-intercom";
import { INTERCOM_APP_ID } from "src/config";

const Ctx = createContext(false);

type Props = { children: ReactNode };

export function IntercomProvider({ children }: Props) {
  const [, forceUpdate] = useReducer(() => ({}), {});

  const loadedRef = useRef(false);

  useEffect(() => {
    const start = Date.now();
    function cb() {
      if ("Intercom" in window) {
        loadedRef.current = true;
        forceUpdate();
      }
      if (!loadedRef.current && Date.now() - start < 30_000) {
        setTimeout(cb, 100);
      }
    }

    cb();
  }, []);
  return (
    <Ctx.Provider value={loadedRef.current}>
      <Provider appId={INTERCOM_APP_ID} autoBoot={false} shouldInitialize={false}>
        {children}
      </Provider>
    </Ctx.Provider>
  );
}

/**
 * Checks if intercom has loaded every 100ms for 30s
 * @returns {bool} is Intercom loaded
 */
export const useIntercomLoaded = () => useContext(Ctx);
