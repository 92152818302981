import { Flex } from "@chakra-ui/react";
import { deprecatedStyled } from "src/styles/stitches/deprecated-theme";

import { Texts } from "../texts/Texts";
import { EditIcon } from "@chakra-ui/icons";

import { Tooltip } from "src/components/tooltip";

const StyledTooltip = deprecatedStyled(Tooltip, { marginLeft: 5 });

type Props = {
  title: string;
  value?: string;
  titleSize?: string;
  valueSize?: string;
  titleColor?: string;
  valueColor?: string;
  editable?: boolean;
  onClick?: () => void;
  mr?: React.ComponentProps<typeof Flex>["mr"];
  mt?: React.ComponentProps<typeof Flex>["mt"];
  tooltip?: string;
};

export const SmallInformationsCard = ({
  title = "",
  value = "",
  titleSize = "14px",
  valueSize = "16px",
  titleColor = "#9E9E9E",
  valueColor = "#212121",
  editable = false,
  onClick,
  mr = "40px",
  mt = "0",
  tooltip,
}: Props) => {
  return (
    <Flex direction="column" mt={mt} mr={mr} minW={["0", "127px"]}>
      <Flex alignItems="center">
        <StyledTooltip size={14} message={tooltip} enabled={!!tooltip}>
          <Texts fontSize={titleSize} color={titleColor} width="max-content">
            {title}
          </Texts>
        </StyledTooltip>
      </Flex>

      <Flex>
        <Flex>
          <Texts fontSize={valueSize} color={valueColor}>
            {value}
          </Texts>
        </Flex>
        {editable && (
          <Flex ml="2" align="center" cursor="pointer" onClick={onClick}>
            <EditIcon color={valueColor}></EditIcon>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
