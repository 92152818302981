import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { styled } from "src/styles/stitches/theme";
import { WithChildren } from "src/types";
import { Outlet } from "../outlet";
import { Spinner, SpinnerWrapper, StyledButton, _Button } from "./_button";
import { InnerButton } from "./_utils";

const StyledLink = styled(Link, StyledButton);

type LinkButtonProps = WithChildren<
  {
    to: string;
  } & _Button
>;

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ to, iconLeft, iconRight, children, size, loading, ...rest }, ref) => {
    const baseInner = (
      <InnerButton iconLeft={iconLeft} iconRight={iconRight} size={size}>
        {children}
      </InnerButton>
    );

    return (
      <StyledLink to={to} size={size} {...rest} ref={ref}>
        <Outlet horizontal="spacing8px" justify="center" align="center">
          {loading ? (
            <SpinnerWrapper>
              <Outlet horizontal="spacing8px" justify="center" align="center">
                {baseInner}
              </Outlet>
              <Spinner size={size} />
            </SpinnerWrapper>
          ) : (
            baseInner
          )}
        </Outlet>
      </StyledLink>
    );
  }
);

LinkButton.displayName = "LinkButton";
