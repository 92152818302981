import { API, Auth } from "aws-amplify";
import { Contact } from "src/types";

export const getContact = async (): Promise<Contact> => {
  const user = await Auth.currentAuthenticatedUser();
  const response = await API.get("apiMySpaceGateway", "/contact", {
    headers: { Authorization: user.signInUserSession.idToken.jwtToken },
    response: false,
  });

  return response.data;
};
