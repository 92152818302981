import { Image } from "@chakra-ui/image";
import { Flex, VStack } from "@chakra-ui/layout";
import { Texts } from "../../components/texts/Texts";
import { Title } from "../../components/texts/Title";
import dog from "./dog.png";

export function Error503() {
  return (
    <Flex alignItems="center" justifyContent="center">
      <VStack
        minHeight="100vh"
        width="1110px"
        maxW="90vw"
        spacing="30px"
        padding={["20px 0 100px", "100px 0"]}
      >
        <Image w="400px" maxW="80%" src={dog} margin="-15% 0 -5%" />
        <Title fontSize="56px" lineHeight="56px">
          Woops !
        </Title>
        <Texts fontSize="18px" lineHeight="28px">
          Vous êtes hors ligne, veuillez vérifier votre connexion à internet et réessayer
        </Texts>
      </VStack>
    </Flex>
  );
}
