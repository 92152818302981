import { deprecatedStyled, deprecatedTheme } from "src/styles/stitches/deprecated-theme";

import { FileDownloadIcon } from "src/components/Icons/FileDownloadIcon";
import { DownloadIcon } from "src/components/Icons/DownloadIcon";

const FileContainer = deprecatedStyled("div", {
  border: "1px solid #E0E0E0",
  borderRadius: 12,
  padding: 24,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "&:last-child": {
    marginBottom: 58,
  },
});

const FileWrapper = deprecatedStyled("div", {
  display: "flex",
  justifyContent: "space-between",
});

const FileInfos = deprecatedStyled("div", {
  marginLeft: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const FileTitle = deprecatedStyled("div", {
  fontWeight: 700,
  fontSize: 16,
  color: deprecatedTheme.colors.gray900,
});

const FileFormat = deprecatedStyled("div", {
  fontWeight: 400,
  fontSize: 14,
  color: deprecatedTheme.colors.gray700,
});

type Props = {
  title: string;
  format: string;
};

export const FileDownloadButton = ({ title, format }: Props) => {
  return (
    <FileContainer>
      <FileWrapper>
        <FileDownloadIcon />
        <FileInfos>
          <FileTitle>{title}</FileTitle>
          <FileFormat>{format}</FileFormat>
        </FileInfos>
      </FileWrapper>
      <DownloadIcon color="none" />
    </FileContainer>
  );
};
