import { styled, theme } from "src/styles/stitches/theme";

const StyledStepperItem = styled("div", {
  width: theme.spacing.spacing8px,
  height: theme.spacing.spacing8px,
  background: theme.colors.fillBaseSubdued,
  borderRadius: theme.radii[100],

  variants: {
    active: {
      true: {
        width: theme.spacing.spacing16px,
        background: theme.colors.fillAccent,
      },
    },
  },
});

type Props = { active?: boolean };

export function StepperItem({ active }: Props) {
  return <StyledStepperItem active={active} />;
}
