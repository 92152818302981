import { Outlet } from "src/components/01_atoms/outlet";
import { ProgressBar } from "src/components/01_atoms/progress-bar";
import { Text } from "src/components/01_atoms/text";
import { LegendItem } from "src/components/02_molecules/legend-item";
import { useClaimStatusSidebar } from "src/hooks/use-claim-status-sidebar";
import { styled, theme } from "src/styles/stitches/theme";
import { getFormattedPrice } from "src/utils/format";

const Container = styled("div", {
  padding: theme.spacing.spacing24px,
});

const ProgressBarLegend = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",

  "@bp3": {
    flexDirection: "row",
    gap: "unset",
    justifyContent: "space-between",
  },
});

export function ReimbursedCardSection() {
  const { paidAmount, refundAmount, setIsRefusedDetailSidebarOpen } = useClaimStatusSidebar();

  const reimbursedPartPercentage = `${(refundAmount / paidAmount) * 100}%`;

  return (
    <Container>
      <Outlet vertical={{ "@initial": "spacing32px", "@bp3": "spacing16px" }}>
        <Outlet vertical="spacing16px" fluid>
          <Outlet vertical="spacing2px">
            <Text
              variant={{ "@initial": "paragraph3-Medium", "@bp3": "caption1-Medium" }}
              color="textBaseSubdued"
            >
              Facture totale
            </Text>
            <Text variant="paragraph1-Bold" color="textBaseDefault">
              {getFormattedPrice(paidAmount)}
            </Text>
          </Outlet>
          <ProgressBar filledPercentage={reimbursedPartPercentage} />
        </Outlet>
        <ProgressBarLegend>
          <LegendItem
            iconColor="iconAccent"
            title={getFormattedPrice(refundAmount)}
            description="par Dalma"
          />
          <LegendItem
            iconColor="iconBaseDisabled"
            title={getFormattedPrice(paidAmount - refundAmount)}
            description="Non pris en charge"
            withInfoIcon
            onInfoIconClick={() => setIsRefusedDetailSidebarOpen(true)}
          />
        </ProgressBarLegend>
      </Outlet>
    </Container>
  );
}
