import { API } from "aws-amplify";

// Using any here because for some reason <T> couldn't be "resolved"
export function fetchQuoteInfo(
  quoteId: string,
  cb: (value?: any | PromiseLike<any>) => void,
  onError: (reason?: any) => void
) {
  API.get("apiMySpaceGateway", "/welcome", {
    response: false,
    queryStringParameters: {
      quote_id: quoteId,
    },
  })
    .then((response) => {
      // console.log( "API :: fetchQuoteInfo :: ", response );
      cb(response);

      // store leadSource in localstorage in order to reuse it in the ABTest
      const leadSourceHyperassur = "Hyperassur";
      if (response.pet_owner.lead_source === leadSourceHyperassur) {
        localStorage.setItem("leadSource", leadSourceHyperassur);
      }
    })
    .catch((error) => {
      if (typeof onError === "function") {
        onError(error);
      }
      // console.log(error.response);
    });
}
