import { useActor } from "@xstate/react";
import { Icon } from "src/components/01_atoms/icon";
import { SidesheetLayout } from "src/components/04_layouts/sidesheet-layout";
import { claimsService } from "src/store/claims/machine";
import { Iban } from "../onboarding/iban";
import { StyledContainer, StyledEmptyDiv, StyledOutlet } from "./index.styled";

type Props = {
  isOpen: boolean;
  setIsOpen: (isIbanSidebarOpen: boolean) => void;
};

export function IbanSidebar({ isOpen, setIsOpen }: Props) {
  const [, send] = useActor(claimsService);

  return (
    <SidesheetLayout
      isOpen={isOpen}
      label="Iban"
      onDismiss={() => {
        setIsOpen(false);
        send("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
      }}
    >
      <StyledContainer>
        <StyledOutlet horizontal="spacing16px" align="center" justify="spaceBetween">
          <StyledEmptyDiv />
          <button
            type="button"
            onClick={() => {
              setIsOpen(false);
              send("REFETCH_CLAIMS_WITHOUT_DELAY"); // to make need info claim toast reappear
            }}
          >
            <Icon name="outlinedClose" />
          </button>
        </StyledOutlet>
        <Iban canSkip={false} setIsIbanSidebarOpen={setIsOpen} />
      </StyledContainer>
    </SidesheetLayout>
  );
}
