import { keyframes, styled, theme } from "src/styles/stitches/theme";
import { Icon } from "src/components/01_atoms/icon";
import { VariantProps } from "@stitches/react";
import { Outlet } from "src/components/01_atoms/outlet";
import { ComponentProps, forwardRef } from "react";
import { WithChildren } from "src/types";
import { InnerButton } from "./_utils";

export const StyledButton = styled("button", {
  fontSize: theme.fontSizes.buttonLabel,
  fontWeight: theme.fontWeights.bold,
  transition: "all .15s ease-out",
  position: "relative",

  "&:focus-visible": {
    outline: "none",
    boxShadow: theme.boxShadow.selected,
  },

  "&:focus-within": {
    outline: "none",
  },

  "&:disabled": {
    cursor: "not-allowed",
  },

  variants: {
    variant: {
      accent: {
        backgroundColor: theme.colors.buttonBackgroundAccentDefault,
        color: theme.colors.buttonLabelOnAccentDefault,
        boxShadow: theme.elevation.elevation1Primary,
        "&:hover": {
          backgroundColor: theme.colors.buttonBackgroundAccentHovered,
          color: theme.colors.buttonLabelOnAccentHovered,
          boxShadow: theme.boxShadow.hovered,
        },
        "&:active": {
          backgroundColor: theme.colors.buttonBackgroundAccentPressed,
          color: theme.colors.buttonLabelOnAccentDefault,
          boxShadow: theme.boxShadow.selected,
        },
        "&:disabled": {
          backgroundColor: theme.colors.buttonBackgroundAccentDisabled,
          color: theme.colors.buttonLabelOnAccentDisabled,
          boxShadow: "none",
        },
      },
      critical: {
        backgroundColor: theme.colors.buttonBackgroundCriticalDefault,
        color: theme.colors.buttonLabelCriticalDefault,
        outline: theme.outline.base,
        "&:hover": {
          backgroundColor: theme.colors.buttonBackgroundCriticalHovered,
          color: theme.colors.buttonLabelCriticalHovered,
          outline: theme.outline.criticalHovered,
        },
        "&:active": {
          backgroundColor: theme.colors.buttonBackgroundCriticalPressed,
          color: theme.colors.buttonLabelCriticalDefault,
          outline: theme.outline.criticalPressed,
        },
        "&:disabled": {
          backgroundColor: theme.colors.buttonBackgroundCriticalDisabled,
          color: theme.colors.buttonLabelCriticalDisabled,
          outline: theme.outline.disabled,
        },
        "&:focus-visible": {
          boxShadow: theme.boxShadow.criticalSelected,
        },
      },
      secondary: {
        backgroundColor: theme.colors.buttonBackgroundSecondaryDefault,
        color: theme.colors.buttonLabelOnSecondaryDefault,
        border: theme.outline.base,
        "&:hover": {
          backgroundColor: theme.colors.buttonBackgroundSecondaryHovered,
          color: theme.colors.buttonLabelOnSecondaryHovered,
        },
        "&:active": {
          backgroundColor: theme.colors.buttonBackgroundSecondaryPressed,
          color: theme.colors.buttonLabelOnSecondaryDefault,
          border: theme.outline.pressed,
        },
        "&:disabled": {
          backgroundColor: theme.colors.buttonBackgroundSecondaryDisabled,
          color: theme.colors.buttonLabelOnSecondaryDisabled,
          border: theme.outline.disabled,
        },
      },
      base: {
        backgroundColor: theme.colors.buttonBackgroundSecondaryDefault,
        color: theme.colors.buttonLabelBaseDefault,
        outline: theme.outline.base,
        "&:hover": {
          backgroundColor: theme.colors.buttonBackgroundSecondaryHovered,
          color: theme.colors.buttonLabelBaseHovered,
        },
        "&:active": {
          backgroundColor: theme.colors.buttonBackgroundSecondaryPressed,
          color: theme.colors.buttonLabelBasePressed,
          outline: theme.outline.pressed,
        },
        "&:disabled": {
          backgroundColor: theme.colors.buttonBackgroundSecondaryDisabled,
          color: theme.colors.buttonLabelBaseDisabled,
          outline: theme.outline.disabled,
        },
      },
      smallActionAccent: {
        padding: "0.125rem",
        lineHeight: "1.143",
        color: theme.colors.buttonLabelOnSecondaryDefault,
        border: "none",
        backgroundColor: "transparent",
        boxShadow: "none",

        "&:hover": {
          color: theme.colors.buttonLabelOnSecondaryHovered,
          border: "none",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        "&:active": {
          color: theme.colors.buttonLabelOnSecondaryHovered,
          border: "none",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        "&:disabled": {
          backgroundColor: "transparent",
          boxShadow: "none",
          color: theme.colors.buttonLabelBaseDisabled,
        },
      },
      smallActionWhite: {
        padding: "0.125rem",
        lineHeight: "1.143",
        color: theme.colors.buttonLabelOnAccentDefault,
        "&:hover": {
          color: theme.colors.buttonLabelOnAccentHovered,
        },
        "&:active": {
          color: theme.colors.buttonLabelOnAccentDefault,
        },
        "&:disabled": {
          display: "none",
        },
      },
      smallActionSecondary: {
        padding: "0.125rem",
        lineHeight: "1.143",
        color: theme.colors.buttonLabelOnAccentDefault,
        "&:hover": {
          color: theme.colors.buttonLabelOnAccentHovered,
        },
        "&:active": {
          color: theme.colors.buttonLabelOnAccentDefault,
        },
        "&:disabled": {
          color: theme.colors.buttonLabelOnAccentDisabled,
        },
      },
      smallActionCritical: {
        padding: "0.125rem",
        lineHeight: "1.143",
        color: theme.colors.buttonLabelCriticalDefault,
        "&:hover": {
          color: theme.colors.buttonLabelCriticalHovered,
        },
        "&:active": {
          color: theme.colors.buttonLabelCriticalDefault,
        },
        "&:disabled": {
          color: theme.colors.buttonLabelCriticalDisabled,
        },
      },
      smallActionBase: {
        padding: "0.125rem",
        lineHeight: "1.143",
        color: theme.colors.buttonLabelBaseHovered,
        "&:hover": {
          color: theme.colors.buttonLabelBaseDefault,
        },
        "&:active": {
          color: theme.colors.buttonLabelBasePressed,
        },
        "&:disabled": {
          color: theme.colors.buttonLabelBaseDisabled,
        },
      },
    },
    size: {
      xs: {
        fontSize: theme.fontSizes.buttonLabelSmall,
        padding: `0.125rem 0.25rem`,
        borderRadius: theme.radii[8],
      },
      sm: {
        padding: `0.75rem`,
        borderRadius: theme.radii[12],
      },
      md: {
        padding: `1.0625rem`,
        borderRadius: theme.radii[16],
      },
      lg: {
        padding: `1.4375rem`,
        borderRadius: theme.radii[16],
      },
    },
    inline: {
      true: {
        padding: "0.125rem 0",
        borderRadius: theme.radii[1],
        "&:focus": {
          boxShadow: theme.boxShadow.focus,
        },
      },
    },
    fluid: {
      true: {
        width: "100%",
      },
      false: {
        width: "auto",
      },
    },
  },

  defaultVariants: {
    variant: "accent",
    size: "md",
  },
});

export type _Button = {
  iconLeft?: ComponentProps<typeof Icon>["name"];
  iconRight?: ComponentProps<typeof Icon>["name"];
  loading?: boolean;
} & VariantProps<typeof StyledButton>;

type ButtonProps = WithChildren<_Button & ComponentProps<"button">>;

const SpinnerAnimation = keyframes({
  to: {
    transform: "translate(-50%, -50%) rotate(1turn)",
  },
});

export const Spinner = styled("div", {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) rotate(0)",
  transformOrigin: "center center",
  borderRadius: "50%",
  background: "conic-gradient(#0000 10%, currentColor)",
  "-webkit-mask": "radial-gradient(farthest-side, #0000 calc(100% - 4px),#000 0)",
  animation: `${SpinnerAnimation} 1s infinite linear`,

  variants: {
    size: {
      xs: {
        width: "1rem",
        height: "1rem",
        padding: "0.5px",
      },
      sm: {
        width: "1.25rem",
        height: "1.25rem",
        padding: "0.8px",
      },
      md: {
        width: "1.5rem",
        height: "1.5rem",
        padding: "0.125rem",
      },
      lg: {
        width: "1.5rem",
        height: "1.5rem",
        padding: "0.125rem",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

export const SpinnerWrapper = styled("div", {
  position: "relative",

  "& > *:first-child": {
    opacity: 0,
  },
});

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, iconLeft, iconRight, size, loading, variant, ...rest }, ref) => {
    const baseInner = (
      <InnerButton iconLeft={iconLeft} iconRight={iconRight} size={size}>
        {children}
      </InnerButton>
    );

    return (
      <StyledButton variant={variant} {...rest} size={size} ref={ref}>
        <Outlet horizontal="spacing8px" justify="center" align="center">
          {loading ? (
            <SpinnerWrapper>
              <Outlet horizontal="spacing8px" justify="center" align="center" as="span">
                {baseInner}
              </Outlet>
              <Spinner size={size} />
            </SpinnerWrapper>
          ) : (
            baseInner
          )}
        </Outlet>
      </StyledButton>
    );
  }
);

Button.displayName = "Button";
