import "react-app-polyfill/stable";
import "intersection-observer";
import "src/utils/sentry";
// /!\ Important, load polyfills and Sentry before any other imports
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

import { dalmaTheme } from "./styles/chakra/DalmaTheme";

import { IntercomProvider } from "./utils/intercom";

import { Amplify } from "aws-amplify";
import config from "./config";

import App from "./App";
import { OptimizeProvider } from "./components/optimize";
import * as Sentry from "@sentry/react";

import { Sandbox } from "./components/Sandbox";
import { SANDBOX } from "./config";
import { Toastify } from "./components/01_atoms/toast";
import { queryClient, QueryClientProvider } from "./queryClient";
import { MobileMenuContextProvider } from "./hooks/use-mobile-menu";
import { RefundSidebarContextProvider } from "./hooks/use-refund-sidebar";
import { BannerContextProvider } from "./hooks/use-banner";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ClaimStatusSidebarContextProvider } from "./hooks/use-claim-status-sidebar";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: config.cognito.AUTHENTICATION_FLOW_TYPE,
  },
  Storage: {
    AWSS3: {
      bucket: config.s3.BUCKET,
      region: config.s3.REGION,
    },
  },
  API: {
    endpoints: [
      {
        name: config.apiMySpaceGateway.NAME,
        endpoint: config.apiMySpaceGateway.URL,
        region: config.apiMySpaceGateway.REGION,
      },
      {
        name: config.apiClaimsGateway.NAME,
        endpoint: config.apiClaimsGateway.URL,
        region: config.apiClaimsGateway.REGION,
      },
      {
        name: config.apiFilesGateway.NAME,
        endpoint: config.apiFilesGateway.URL,
        region: config.apiFilesGateway.REGION,
      },
      {
        name: config.apiOldMySpaceGateway.NAME,
        endpoint: config.apiOldMySpaceGateway.URL,
        region: config.apiOldMySpaceGateway.REGION,
      },
      {
        name: config.apiStripeGateway.NAME,
        endpoint: config.apiStripeGateway.URL,
        region: config.apiStripeGateway.REGION,
      },
      {
        name: config.apiAuthGateway.NAME,
        endpoint: config.apiAuthGateway.URL,
        region: config.apiAuthGateway.REGION,
      },
    ],
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary>
    <React.StrictMode>
      <OptimizeProvider>
        <BrowserRouter>
          <ChakraProvider resetCSS theme={dalmaTheme}>
            <QueryClientProvider client={queryClient}>
              <IntercomProvider>
                <BannerContextProvider>
                  <MobileMenuContextProvider>
                    <RefundSidebarContextProvider>
                      <ClaimStatusSidebarContextProvider>
                        <ColorModeScript initialColorMode="light" />
                        {SANDBOX ? <Sandbox /> : null}
                        <App />
                        <Toastify />
                      </ClaimStatusSidebarContextProvider>
                    </RefundSidebarContextProvider>
                  </MobileMenuContextProvider>
                </BannerContextProvider>
              </IntercomProvider>
              {SANDBOX ? <ReactQueryDevtools initialIsOpen={false} /> : null}
            </QueryClientProvider>
          </ChakraProvider>
        </BrowserRouter>
      </OptimizeProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>
);
