/* eslint-disable prettier/prettier */
import { styled, theme } from "src/styles/stitches/theme";
import { Text } from "src/components/01_atoms/text";
import { Outlet } from "src/components/01_atoms/outlet";
import { Icon } from "src/components/01_atoms/icon";
import { useDevice } from "src/hooks/useDevice";

type ReasonProps = {
  text: string;
};

const Reason = ({ text }: ReasonProps) => {
  return (
    <StyledReasons horizontal="spacing8px" align="center">
      <Icon name="filledCheckCircle" />
      <Text variant="paragraph1-Medium" color="textSecondaryDefault">
        {text}
      </Text>
    </StyledReasons>
  );
};

const ReasonsSection = () => {
  return (
    <Outlet vertical="spacing16px">
      <Reason
        text="Partagez votre lien de parrainage à un maximum
de parents de chiens et chats de votre entourage."
      />
      <Reason
        text="Votre proche doit souscrire à Dalma  via ce lien.
Son mois offert sera directement appliqué !"
      />
      <Reason
        text="Attendez que votre proche passe sa période
de rétractation (14 jours)"
      />
      <Reason
        text="Vous recevez 50€ et la gratitude éternelle
        de votre proche."
      />
    </Outlet>
  );
};

export const Referral = () => {
  const { isMobile } = useDevice();

  return (
    <ReferralContainer vertical={isMobile() ? "spacing24px" : "spacing40px"}>
      <ReferralStyledContent align="center" justify="center" vertical="spacing24px" fluid>
        <Img src="/assets/media/referral-illustration.svg" alt="Faux billet de 25 et 50 euros" />
        <Outlet vertical="spacing8px" css={{ flex: 1, paddingBottom: "32px" }}>
          <ContainerTitle>
            <span>Recevez </span>
            <span className="stroked">25€ </span>
            <span>50€ pour chaque parrainage !</span>
          </ContainerTitle>
          <Text
            variant={isMobile() ? "paragraph2-Medium" : "paragraph1-Medium"}
            color="textSecondaryDefault"
          >
            50€ pour vous, 1 mois offert pour vos proches
          </Text>
          <ReasonsSection />
        </Outlet>
      </ReferralStyledContent>
    </ReferralContainer>
  );
};

const ReferralStyledContent = styled(Outlet, {
  marginTop: "11.25rem",
  padding: `0 ${theme.spacing.spacing16px}`,
  "@bp1": {
    padding: `0 ${theme.spacing.spacing32px}`,
  },
});

const ReferralContainer = styled(Outlet, {
  width: "100%",
  alignSelf: "stretch",
  backgroundImage: "url(/assets/media/referral-sidebar-background.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0% 0%",
  backgroundSize: "cover",
  "@bp1": {
    maxWidth: "30.625rem",
  },
});

const ContainerTitle = styled("div", {
  margin: "1rem 0",
  span: {
    margin: 0,
    padding: 0,
    fontSize: theme.fontSizes.textDisplay3,
    lineHeight: 1.153,
    letterSpacing: theme.letterSpacings.regular,
    color: "white",
  },
  ".stroked": {
    textDecoration: "line-through",
    opacity: 0.3,
  },
});

const Img = styled("img", {
  width: "15rem",
  height: "15rem",
});

const StyledReasons = styled(Outlet, {
  marginTop: "2rem",
  color: theme.colors.textSecondaryDefault,
});
