/* eslint-disable react/no-unescaped-entities */
import { SubmitHandler, useForm } from "react-hook-form";
import { Outlet } from "src/components/01_atoms/outlet";
import { styled } from "src/styles/stitches/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "src/components/01_atoms/button";
import { Input } from "src/components/01_atoms/input";
import { Text } from "src/components/01_atoms/text";
import { updatePetUuid } from "src/backApi/updatePetUuid";
import { toast } from "src/components/01_atoms/toast";
import { matchValidChip, matchValidPetUuid } from "./utils";
import { useState } from "react";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";

const Form = styled("form", {
  width: "100%",
  flex: 1,
});

const petUuidSchema = z.object({
  pet_uuid: z.string().refine((value) => matchValidPetUuid(value.replace(/\s/g, "")), {
    message: "Le format du numéro d'identification est incorrect.",
  }),
  pet_uuid_type: z.string(),
});

type PetUuid = {
  pet_uuid: string;
  pet_uuid_type: string;
};

interface PetUuidFormProps {
  selectedPetContract: {
    stripe_contract_id: string;
    pet_idx: number;
    pet_uuid_type: string;
  };
  setIsDefaultPetModalOpen: (isOpen: boolean) => void;
}

export function PetUuidForm({ selectedPetContract, setIsDefaultPetModalOpen }: PetUuidFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { closeRefundSidebar } = useRefundSidebar();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PetUuid>({
    resolver: zodResolver(petUuidSchema),
    mode: "onChange",
    defaultValues: {
      pet_uuid: "",
      pet_uuid_type: "",
    },
  });

  const onSubmit: SubmitHandler<PetUuid> = async (values) => {
    setIsLoading(true);
    try {
      await updatePetUuid(
        {
          pet_uuid: values.pet_uuid,
          pet_uuid_type: matchValidChip(values.pet_uuid) ? "chip" : "tatoo",
        },
        selectedPetContract.stripe_contract_id,
        selectedPetContract.pet_idx
      );
      toast.success("Le numéro d'identification a été ajouté avec succès !");
      setIsDefaultPetModalOpen(false);
      closeRefundSidebar();
    } catch (error) {
      reportError(error);
      toast.error(
        "Une erreur est survenue. Assurez vous que le numéro renseigné est valide. Si le problème persiste, merci de contacter le service client"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Outlet vertical="spacing12px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
        <Label variant="caption1-Medium" color="textBaseSubdued">
          Numéro d'identification
        </Label>
        <Input
          {...register("pet_uuid")}
          placeholder="000000000000000"
          critical={Boolean(errors.pet_uuid)}
        />
        {errors?.pet_uuid ? (
          <Label variant="caption1-Medium" color="textWarning">
            {errors.pet_uuid.message}
          </Label>
        ) : null}
        <Button
          type="submit"
          variant="accent"
          size="lg"
          fluid
          disabled={!isValid}
          loading={isLoading}
        >
          Continuer
        </Button>
      </Outlet>
    </Form>
  );
}

const Label = styled(Text);
