import { Token } from "@stitches/react/types/theme";
import { forwardRef, InputHTMLAttributes, MouseEvent, useCallback, useState } from "react";
import { styled, theme } from "src/styles/stitches/theme";
import { Icon, IconProps } from "../icon";
import { StyledOutlet } from "../outlet";
import { paragraph2CSS } from "../text";
import { VisuallyHidden } from "../visually-hidden";

function webkitAutofillFix(
  background: string,
  textColor: string | Token<any, string, "colors", "">
) {
  return {
    "&:-webkit-autofill": {
      WebkitTextFillColor: textColor,
      boxShadow: `inset 0 0 0 999px ${background}`,
    },

    "&:-webkit-autofill::first-line": {
      WebkitTextFillColor: textColor,
      boxShadow: `inset 0 0 0 999px ${background}`,
    },

    "&:autofill": {
      WebkitTextFillColor: textColor,
      boxShadow: `inset 0 0 0 999px ${background}`,
    },
  };
}

const StyledInput = styled("input", {
  ...paragraph2CSS,
  fontWeight: theme.fontWeights.medium,
  color: theme.colors.textBaseDefault,
  background: "transparent",
  width: "100%",
  padding: 0,
  margin: 0,
  height: "1.5rem",

  // HACK fix webkit browsers autofill overridding styles
  ...webkitAutofillFix(theme.colors.backgroundPrimary.value, theme.colors.textBaseDefault),

  "&::placeholder": {
    ...paragraph2CSS,
    fontWeight: theme.fontWeights.medium,
    color: theme.colors.textBaseSubdued,
  },

  "&:focus, &:focus-within": {
    outline: "none",
  },

  "&[disabled]": {
    cursor: "not-allowed",
    color: theme.colors.textBaseSubdued,
  },
});

const StyledLabel = styled("label", StyledOutlet, {
  width: "100%",
  padding: "1rem",
  backgroundColor: theme.colors.fillSecondary,
  borderRadius: theme.radii[12],
  border: `1.5px solid ${theme.colors.borderBaseSubdued}`,
  transition: "all .15s ease-out",

  "&:focus-within": {
    boxShadow: theme.boxShadow.hovered,
    border: `1.5px solid ${theme.colors.borderSelected}`,
  },

  "&:hover": {
    boxShadow: theme.boxShadow.hovered,
  },

  variants: {
    critical: {
      true: {
        border: `1.5px solid ${theme.colors.borderCriticalDefault}`,

        "&:focus-within": {
          border: `1.5px solid ${theme.colors.borderCriticalDefault}`,
          boxShadow: theme.boxShadow.criticalSelected,
        },

        "&:hover": {
          boxShadow: theme.boxShadow.criticalSelected,
        },
      },
    },
    disabled: {
      true: {
        backgroundColor: theme.colors.fillBaseDisabled,
        border: `1.5px solid ${theme.colors.borderBaseSubdued}`,
        cursor: "not-allowed",

        "&:focus-within": {
          boxShadow: "none",
          border: `1.5px solid ${theme.colors.borderBaseSubdued}`,
        },

        "&:hover": {
          boxShadow: "none",
        },
      },
    },
  },
});

export type InputProps = {
  rightIcon?: IconProps["name"];
  leftIcon?: IconProps["name"];
  critical?: boolean;
  errorMessage?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ rightIcon, leftIcon, type, disabled, critical, errorMessage, ...rest }, ref) => {
    const [inputType, setInputType] = useState(type);

    const errorMessageId = `${rest.name}-error-message`;

    const togglePasswordVisibility = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setInputType(inputType === "text" ? "password" : "text");
      },
      [inputType]
    );

    return (
      <StyledLabel
        horizontal="spacing12px"
        align="center"
        justify="stretch"
        // Hack to make the input not focusable
        tabIndex={disabled ? -1 : undefined}
        disabled={disabled}
        critical={critical}
      >
        {leftIcon && <Icon name={leftIcon} color="iconBaseSubdued" />}
        <StyledInput
          type={inputType || "text"}
          disabled={disabled}
          aria-invalid={critical ? true : undefined}
          // aria-errormessage target the id of a visualy hidden error message
          aria-errormessage={errorMessageId}
          {...rest}
          ref={ref}
        />
        <VisuallyHidden id={errorMessageId}>{errorMessage}</VisuallyHidden>
        {type === "password" && !disabled ? (
          <button
            // this button is used to toggle the password visibility
            // it shouldn't be focusable
            tabIndex={-1}
            type="button"
            onClick={togglePasswordVisibility}
          >
            <Icon
              name={inputType === "password" ? "filledEye" : "filledEyeOff"}
              color="iconBaseSubdued"
            />
          </button>
        ) : (
          rightIcon && <Icon name={rightIcon} color="iconBaseSubdued" />
        )}
      </StyledLabel>
    );
  }
);

Input.displayName = "Input";
